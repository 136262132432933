import { ListType } from './list-type.enum';

export enum RouterUriComponents {
  LIST_SHIPMENTS = 'list-shipments',
  SHIPMENT_DETAILS_PAGE = 'shipment-details',
  INSPECT_SHIPMENT_PAGE = 'inspect-shipment',
  TRAILER_DETAIL_PAGE = 'trailer-detail',
  ADD_PRO_DETAIL_PAGE = 'add-pro-detail',
  NOT_AUTHORIZED_PAGE = 'not-authorized',
  CALCULATORS = 'calculators',
  RECOMMENDATION_RULES = 'recommendation-rules',
  CUSTOMER_GUIDELINES = 'customer-guidelines',
  ITEM_680_2B_PAGE = 'tools/680Note2b',
  BROADCAST_MESSAGES = 'broadcast-messages',
  PICKUP_REQUESTS = 'pickup-requests'
}

export enum RouteLabels {
  LIST_SHIPMENTS = 'List Shipments',
  RECOMMENDATION_RULES = 'Recommendation Rules',
  PICKUP_REQUESTS = 'Pickup Requests',
  CUSTOMER_GUIDELINES = 'Customer Guidelines',
  BROADCAST_MESSAGES = 'Broadcast Messages'
}

export function getRouterUriComponentsFromListType(listType: ListType): string {
  switch (listType) {
    case ListType.AddPros:
      return RouterUriComponents.ADD_PRO_DETAIL_PAGE;
    case ListType.Lookup:
      return RouterUriComponents.TRAILER_DETAIL_PAGE;
    case ListType.PickUpRequest:
      return RouterUriComponents.PICKUP_REQUESTS;
    case ListType.RecommendationRules:
      return RouterUriComponents.RECOMMENDATION_RULES;
    case ListType.CustomerGuidelines:
      return RouterUriComponents.CUSTOMER_GUIDELINES;
    case ListType.BroadcastMessage:
      return RouterUriComponents.BROADCAST_MESSAGES;
    case ListType.Shipment:
      return RouterUriComponents.LIST_SHIPMENTS;
    default:
      return RouterUriComponents.LIST_SHIPMENTS;
  }
}
