import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

export interface CustomManifestSearchParam {
  routerLink?: string;
}

@Component({
  selector: 'app-manifest-search-column',
  templateUrl: './manifest-search-column.component.html',
  styleUrls: ['./manifest-search-column.component.scss']
})
export class ManifestSearchColumnComponent implements AgRendererComponent {
  searchableValue: string;
  routerLink: string;
  params: any;

  constructor() {}

  agInit(params: ICellRendererParams & CustomManifestSearchParam): void {
    this.searchableValue = params?.value;
    this.routerLink = params?.routerLink;
  }

  refresh(params: ICellRendererParams): boolean {
    this.searchableValue = params?.value;
    return true;
  }
}
