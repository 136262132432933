import { Pipe, PipeTransform } from '@angular/core';
import {
  InspectionState,
  toInspectionStateDisplayName
} from '../enums/inspection-state.enum';

@Pipe({
  name: 'inspectionStatusCodePipe'
})
export class InspectionStatusCodePipe implements PipeTransform {
  transform(code: InspectionState): string {
    return toInspectionStateDisplayName(code);
  }
}
