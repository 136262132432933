<div>
  <div class="toolbar-status-fix" *ngIf="(appConstants?.inspectionState$ | async) == 'P'">
    <app-toolbar
      [showBackButton]="true"
      (backButtonClicked)="backButtonClicked()"
      [title]="proNumber + ' - In Progress'"
      [actionButton2Label]="'INSPECT'"
      (actionButton2Clicked)="inspectShipment()"
      [actionButton2IsMain]="true"
      [actionButton3Label]="'INSPECTED NOT CORRECTED'"
      (actionButton3Clicked)="markInspectedNotCorrected()"
      [actionButton3IsMain]="true"
      [actionButton4Label]="'SEND TO RECOMMENDED LIST'"
      (actionButton4Clicked)="sendToRecommendedList()"
      [actionButton4IsMain]="false"
      [actionButton5Label]="'SEND TO FLAGGED LIST'"
      (actionButton5Clicked)="sendToFlaggedList()"
      [actionButton5IsMain]="false"
      [actionButton6Label]="'DISMISS'"
      (actionButton6Clicked)="dismissShipment()"
      [actionButton6IsMain]="false"
    ></app-toolbar>
  </div>

  <div class="toolbar-status-fix" *ngIf="(appConstants?.inspectionState$ | async) == 'K'">
    <app-toolbar
      [showBackButton]="true"
      (backButtonClicked)="backButtonClicked()"
      [title]="proNumber + ' - In Edit'"
      [actionButton2Label]="'INSPECT'"
      (actionButton2Clicked)="inspectShipment()"
      [actionButton2IsMain]="true"
      [actionButton3Label]="'INSPECTED NOT CORRECTED'"
      (actionButton3Clicked)="markInspectedNotCorrected()"
      [actionButton3IsMain]="true"
    ></app-toolbar>
  </div>

  <div class="toolbar-status-fix" *ngIf="(appConstants?.inspectionState$ | async) == 'I'">
    <app-toolbar
      [showBackButton]="true"
      (backButtonClicked)="backButtonClicked()"
      [title]="proNumber + ' - Inspected'"
      [actionButton2Label]="'INSPECTED NOT CORRECTED'"
      (actionButton2Clicked)="markInspectedNotCorrected()"
      [actionButton2IsMain]="true"
      [actionButton3Label]="'MARK CORRECTED'"
      (actionButton3Clicked)="markCorrected()"
      [actionButton3IsMain]="true"
      [actionButton4Label]="'EDIT INSPECTION'"
      (actionButton4Clicked)="editInspection()"
      [actionButton4IsMain]="false"
    ></app-toolbar>
  </div>

  <div class="toolbar-status-fix" *ngIf="(appConstants?.inspectionState$ | async) == 'Y'">
    <app-toolbar
      [showBackButton]="true"
      (backButtonClicked)="backButtonClicked()"
      [title]="proNumber + ' - Recommended' + (isRevoked ? ' (Revoked)' : '')"
      [actionButton1Label]="'KEEP'"
      (actionButton1Clicked)="keepShipment()"
      [actionButton1IsMain]="true"
      [actionButton1Show]="isRevoked"
      [actionButton2Label]="'INSPECT'"
      (actionButton2Clicked)="inspectShipment()"
      [actionButton2IsMain]="true"
      [actionButton3Label]="'SEND TO FLAGGED LIST'"
      (actionButton3Clicked)="sendToFlaggedList()"
      [actionButton3IsMain]="false"
      [actionButton4Label]="'DISMISS'"
      (actionButton4Clicked)="dismissShipment()"
      [actionButton4IsMain]="false"
      [actionButton5Label]="'INSPECTED NOT CORRECTED'"
      (actionButton5Clicked)="markInspectedNotCorrected()"
      [actionButton5IsMain]="false"
    ></app-toolbar>
  </div>

  <div class="toolbar-status-fix" *ngIf="(appConstants?.inspectionState$ | async) == 'R'">
    <app-toolbar
      [showBackButton]="true"
      (backButtonClicked)="backButtonClicked()"
      [title]="proNumber + ' - Flagged' + (isRevoked ? ' (Revoked)' : '')"
      [actionButton1Label]="'KEEP'"
      (actionButton1Clicked)="keepShipment()"
      [actionButton1IsMain]="true"
      [actionButton1Show]="isRevoked"
      [actionButton2Label]="'INSPECT'"
      (actionButton2Clicked)="inspectShipment()"
      [actionButton2IsMain]="true"
      [actionButton3Label]="'SEND TO RECOMMENDED LIST'"
      (actionButton3Clicked)="sendToRecommendedList()"
      [actionButton3IsMain]="false"
      [actionButton4Label]="'DISMISS'"
      (actionButton4Clicked)="dismissShipment()"
      [actionButton4IsMain]="false"
      [actionButton5Label]="'INSPECTED NOT CORRECTED'"
      (actionButton5Clicked)="markInspectedNotCorrected()"
      [actionButton5IsMain]="false"
    ></app-toolbar>
  </div>

  <div class="toolbar-status-fix" *ngIf="(appConstants?.inspectionState$ | async) == 'E'">
    <app-toolbar
      [showBackButton]="true"
      (backButtonClicked)="backButtonClicked()"
      [title]="proNumber + ' - Dismissed' + (isRevoked ? ' (Revoked)' : '')"
      [actionButton1Label]="'KEEP'"
      (actionButton1Clicked)="keepShipment()"
      [actionButton1IsMain]="true"
      [actionButton1Show]="isRevoked"
      [actionButton2Label]="'INSPECT'"
      (actionButton2Clicked)="inspectShipment()"
      [actionButton2IsMain]="true"
      [actionButton3Label]="'SEND TO RECOMMENDED LIST'"
      (actionButton3Clicked)="sendToRecommendedList()"
      [actionButton3IsMain]="false"
      [actionButton4Label]="'SEND TO FLAGGED LIST'"
      (actionButton4Clicked)="sendToFlaggedList()"
      [actionButton4IsMain]="false"
      [actionButton5Label]="'INSPECTED NOT CORRECTED'"
      (actionButton5Clicked)="markInspectedNotCorrected()"
      [actionButton5IsMain]="false"
    ></app-toolbar>
  </div>

  <div class="toolbar-status-fix" *ngIf="(appConstants?.inspectionState$ | async) == 'T'">
    <app-toolbar
      [showBackButton]="true"
      (backButtonClicked)="backButtonClicked()"
      [title]="proNumber + ' - Correction Submitted'"
      [actionButton2Label]="'INSPECT'"
      (actionButton2Clicked)="inspectShipment()"
      [actionButton2IsMain]="true"
      [actionButton3Label]="'SEND TO RECOMMENDED LIST'"
      (actionButton3Clicked)="sendToRecommendedList()"
      [actionButton3IsMain]="false"
      [actionButton4Label]="'SEND TO FLAGGED LIST'"
      (actionButton4Clicked)="sendToFlaggedList()"
      [actionButton4IsMain]="false"
    ></app-toolbar>
  </div>

  <div class="toolbar-status-fix" *ngIf="(appConstants?.inspectionState$ | async) == 'N'">
    <app-toolbar
      [showBackButton]="true"
      (backButtonClicked)="backButtonClicked()"
      [title]="proNumber + ' - Inspected Not Corrected'"
      [actionButton2Label]="'INSPECT'"
      (actionButton2Clicked)="inspectShipment()"
      [actionButton2IsMain]="true"
      [actionButton3Label]="'SEND TO RECOMMENDED LIST'"
      (actionButton3Clicked)="sendToRecommendedList()"
      [actionButton3IsMain]="false"
      [actionButton4Label]="'SEND TO FLAGGED LIST'"
      (actionButton4Clicked)="sendToFlaggedList()"
      [actionButton4IsMain]="false"
    ></app-toolbar>
  </div>

  <div class="toolbar-status-fix" *ngIf="(appConstants?.inspectionState$ | async) == ''">
    <app-toolbar
      [showBackButton]="true"
      (backButtonClicked)="backButtonClicked()"
      [title]="proNumber + ' - No Status'"
      [actionButton2Label]="'INSPECT'"
      (actionButton2Clicked)="inspectShipment()"
      [actionButton2IsMain]="true"
      [actionButton3Label]="'SEND TO RECOMMENDED LIST'"
      (actionButton3Clicked)="sendToRecommendedList()"
      [actionButton3IsMain]="false"
      [actionButton4Label]="'SEND TO FLAGGED LIST'"
      (actionButton4Clicked)="sendToFlaggedList()"
      [actionButton4IsMain]="false"
      [actionButton5Label]="'INSPECTED NOT CORRECTED'"
      (actionButton5Clicked)="markInspectedNotCorrected()"
      [actionButton5IsMain]="false"
    ></app-toolbar>
  </div>
</div>
