import { Injectable } from '@angular/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ConfigManagerProperties } from '../enums/config-manager-properties.enum';

@Injectable({
  providedIn: 'root'
})
export class AppConfigManagerService {
  private static PREPROD = 'TCTSS';

  constructor(private configManagerService: ConfigManagerService) {}

  public isProduction(): boolean {
    return this.configManagerService.getSetting<boolean>(ConfigManagerProperties.production);
  }

  public isPreProd(): boolean {
    return this.getRegion() === AppConfigManagerService.PREPROD;
  }

  public getBuildVersion(): string {
    return this.configManagerService.getSetting<string>(ConfigManagerProperties.buildVersion);
  }

  public getTeamEmail(): string {
    return this.configManagerService.getSetting<string>(ConfigManagerProperties.feedbackToAddress);
  }

  public getRegion(): string {
    return this.configManagerService.getSetting<string>(ConfigManagerProperties.region);
  }

  public getEmailSubject(): string {
    return this.configManagerService.getSetting<string>(ConfigManagerProperties.feedbackSubject);
  }

  public getAccessRoles(): string {
    return this.configManagerService.getSetting<string>(ConfigManagerProperties.accessRoles);
  }

  public getAccessEmployeeIds(): string {
    return this.configManagerService.getSetting<string>(ConfigManagerProperties.accessEmployeeIds);
  }

  public getAccessJobRoleCodes(): string {
    return this.configManagerService.getSetting<string>(ConfigManagerProperties.accessJobRoleCodes);
  }

  public getReleaseNotesLink(): string {
    return this.configManagerService.getSetting<string>(ConfigManagerProperties.releaseNotesLink);
  }
}
