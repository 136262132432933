import { AppConstantsService } from '../services/app-constants.service';
import { EnrichedCustomerGuideline } from '@xpo-ltl/sdk-inspections';

export class CustomerGuidelines {
  public customerGuidelineId: number;
  public acctName: string;
  public acctInstId: string;
  public acctMadCd: string;
  public guideline: string;
  public createByName: string;
  public createdTimestamp: string;
  public updateByName: string;
  public updatedTimestamp: string;

  public setData(item: EnrichedCustomerGuideline) {
    this.guideline = item?.guideline;
    this.createByName = item?.auditInfo?.createdById;
    this.createdTimestamp = item?.auditInfo?.createdTimestamp?.toString();
    this.updateByName = item?.auditInfo?.updateById;
    this.updatedTimestamp = item?.auditInfo?.updatedTimestamp?.toString(); 
    this.acctName = item?.customer?.acctName;
    this.acctMadCd = item?.customer?.acctMadCd;
    this.acctInstId = item?.customer?.acctInstId;
    this.customerGuidelineId = item?.customerGuidelineId;
  }
}
