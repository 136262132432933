import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ListName } from '../../enums/list-name.enum';
import { AppNavigationService } from '../../services/app-navigation.service';
import { GridSettingsService } from '../../services/grid-settings/grid-settings.service';
import { ShipmentDetailsService } from '../../services/shipment-details.service';
import { ListCompleteInspectionsComponent } from './list-completed-inspections/list-completed-inspections.component';
import { ListDismissedShipmentsComponent } from './list-dismissed-shipments/list-dismissed-shipments.component';
import { ListFlaggedShipmentsComponent } from './list-flagged-shipments/list-flagged-shipments.component';
import { ListInspectionsComponent } from './list-inspected-inspections/list-inspected-inspections.component';
import { ListPlanningShipmentsComponent } from './list-recommended-shipments/list-recommended-shipments.component';

@Component({
  selector: 'app-list-shipments',
  templateUrl: './list-shipments.component.html',
  styleUrls: ['./list-shipments.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ListShipmentsComponent implements OnInit, OnDestroy {
  public selectedListName = undefined;
  public selectedCount = 0;

  public listName = ListName;

  public dateRange = '';

  private columnsSorted: boolean;
  private columnsFiltered: boolean;
  private unsubscriber$: Subject<any> = new Subject();

  @ViewChild(ListPlanningShipmentsComponent, { static: true })
  public planningList: ListPlanningShipmentsComponent;

  @ViewChild(ListFlaggedShipmentsComponent, { static: true })
  public flaggedList: ListFlaggedShipmentsComponent;

  @ViewChild(ListDismissedShipmentsComponent, { static: true })
  public dismissedList: ListDismissedShipmentsComponent;

  @ViewChild(ListInspectionsComponent, { static: true })
  public inspectionsList: ListInspectionsComponent;

  @ViewChild(ListCompleteInspectionsComponent, { static: true })
  public completedList: ListCompleteInspectionsComponent;

  public toolbarTitle: string;

  constructor(
    public router: Router,
    private gridSettings: GridSettingsService,
    private route: ActivatedRoute,
    private appNavigation: AppNavigationService,
    private changeDetector: ChangeDetectorRef,
    private shipmentDetailsService: ShipmentDetailsService
  ) {}

  ngOnInit() {
    this.appNavigation.listShipmentsTab$.pipe(takeUntil(this.unsubscriber$)).subscribe((tabName) => {
      // if the tabName is null, then don't do anything
      if (tabName) {
        this.selectedListName = tabName;
        this.columnsFiltered = this.gridSettings.hasFilterGridSettings(this.selectedListName);
        this.columnsSorted = this.gridSettings.hasSortGridSettings(this.selectedListName);
        this.dateRange = this.shipmentDetailsService.getListDateRange(this.selectedListName);

        // NOTE: This timeout is to force the table to resize correctly when rendered (after becoming visible).
        setTimeout(() => {
          this.resetRowHeights();
        });
      }
    });

    const tabParameter = this.route.snapshot.params['tab'];
    if (tabParameter) {
      this.appNavigation.setListShipmentsTab(tabParameter);
    }

    // refresh the lists in case if inspection status changed
    this.refreshAllLists();
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

  public selectedCountChanged(count: number) {
    this.selectedCount = count;
    this.toolbarTitle = count + ' Item(s) selected from ' + this.selectedListName + ' List!';
  }

  // todo: At some point do this a different way. I tried to use a view child
  // on the Mat-Tab-Group, but it just caused other issues, so just hardcodeing
  // this for now. If the order of the tabs change, this must be updated

  public getTabIndex(listName: ListName): number {
    switch (listName) {
      case ListName.Recommended:
        return 0;
      case ListName.Flagged:
        return 1;
      case ListName.Inspected:
        return 2;
      case ListName.Completed:
        return 3;
      case ListName.Dismissed:
        return 4;
    }
    return 0;
  }

  /*  What I tried to do, but the #tabGroup has issues
  public getTabIndex(tabName: string): number {
    let tabIndex = 0;
    if (this.tabGroup) {
      this.tabGroup._tabs.forEach( (item, index) => {
        if (item.textLabel === tabName) {
          tabIndex = index;
        }
     });
    }
    return tabIndex;
  }
  */

  public gridSettingsChanged() {
    this.columnsFiltered = this.gridSettings.hasFilterGridSettings(this.selectedListName);
    this.columnsSorted = this.gridSettings.hasSortGridSettings(this.selectedListName);
    this.changeDetector.detectChanges();
  }

  public clearGridFilters() {
    this.gridSettings.clearFilterGridSettings(this.selectedListName, this.getSelectedList().gridOptions);
  }

  public clearGridSorts() {
    this.gridSettings.clearSortGridSettings(this.selectedListName, this.getSelectedList().gridOptions);
  }

  public clearGridSortAndFilters() {
    this.clearGridSorts();
    this.clearGridFilters();
  }

  public resetGridSettings() {
    this.gridSettings.resetGridSettings(this.selectedListName, this.getSelectedList().gridOptions);
  }

  public loadGridSettings() {
    this.gridSettings.loadListGridSettings(this.selectedListName, this.getSelectedList().gridOptions);
  }

  public saveGridSettings() {
    const list = this.getSelectedList();
    if (list && list.gridOptions) {
      this.gridSettings.saveListGridSettings(this.selectedListName);
    }
  }

  public getSelectedList(): any {
    if (this.selectedListName === ListName.Recommended) {
      return this.planningList;
    } else if (this.selectedListName === ListName.Dismissed) {
      return this.dismissedList;
    } else if (this.selectedListName === ListName.Flagged) {
      return this.flaggedList;
    } else if (this.selectedListName === ListName.Inspected) {
      return this.inspectionsList;
    } else if (this.selectedListName === ListName.Completed) {
      return this.completedList;
    }
    return null;
  }

  public resetRowHeights() {
    const list = this.getSelectedList();
    if (list && list.gridOptions && list.gridOptions.api) {
      list.gridOptions.api.resetRowHeights();
    }
  }

  public selectAllRows() {
    const list = this.getSelectedList();
    if (list && list.gridOptions && list.gridOptions.api) {
      list.gridOptions.api.selectAll();
    }
  }

  public deselectAllRows() {
    const list = this.getSelectedList();
    if (list && list.gridOptions && list.gridOptions.api) {
      list.gridOptions.api.deselectAll();
    }
  }

  public listShipmentsTabChanged(value: string) {
    this.appNavigation.setListShipmentsTab(<ListName>value);
  }

  // Only the Refresh Lists Icon should force a full list refresh
  public refreshAllLists(forceRefreshAllLists: boolean = false) {
    this.shipmentDetailsService.loadAllLists(forceRefreshAllLists);
  }

  public dateRangeChanged() {
    this.shipmentDetailsService.setListDateRange(this.selectedListName, this.dateRange);
  }
}
