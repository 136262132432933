<div fxLayout="column" fxFlex="" fxFlexAlign="stretch start" class="list-shipments">
  <div *ngIf="selectedCount == 0" fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%">
    <div>
      <mat-tab-group
        mat-stretch-tabs
        [selectedIndex]="getTabIndex(selectedListName)"
        (selectedTabChange)="listShipmentsTabChanged($event.tab.textLabel)"
        class="tabGroup"
      >
        <mat-tab label="{{ listName.Recommended }}">
          <ng-template mat-tab-label>
            Recommended
            <div *ngIf="planningList" class="grid-totalcount">
              {{ planningList.totalCount }}
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab label="{{ listName.Flagged }}">
          <ng-template mat-tab-label>
            Flagged
            <div *ngIf="flaggedList" class="grid-totalcount">
              {{ flaggedList.totalCount }}
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab label="{{ listName.Inspected }}">
          <ng-template mat-tab-label>
            Inspected
            <div *ngIf="inspectionsList" class="grid-totalcount">
              {{ inspectionsList.totalCount }}
            </div>
            <div *ngIf="inspectionsList?.inProgressCount > 0" class="list-shipments__count">
              {{ inspectionsList.inProgressCount }}
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab label="{{ listName.Completed }}">
          <ng-template mat-tab-label>
            Completed
            <div *ngIf="completedList" class="grid-totalcount">
              {{ completedList.totalCount }}
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab label="{{ listName.Dismissed }}">
          <ng-template mat-tab-label>
            Dismissed
            <div *ngIf="dismissedList" class="grid-totalcount">
              {{ dismissedList.totalCount }}
            </div>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
    <div>
      <div (click)="refreshAllLists(true)">
        <mat-icon class="list-shipments__refresh-icon" svgIcon="refresh-icon" style="width: 16px"></mat-icon>
      </div>
    </div>
    <div class="refreshTime" fxLayout="row" fxLayoutAlign="start center">
      {{ shipmentDetailsService.lastRefreshDate$ | async }}
    </div>
    <div fxLayout="row" fxLayoutAlign="end center" fxFlex="flex" style="margin-right: 10px">
      <button
        class="icon-button"
        mat-button
        [matMenuTriggerFor]="columnMenu"
        *ngIf="
          selectedListName == listName.Completed ||
          selectedListName == listName.Dismissed ||
          columnsFiltered ||
          columnsSorted
        "
      >
        <mat-icon
          svgIcon="filter"
          class="filterIconRed"
          *ngIf="columnsFiltered || columnsSorted || +dateRange > 0"
        ></mat-icon>
        <mat-icon svgIcon="filter" *ngIf="!columnsFiltered && !columnsSorted && !(+dateRange > 0)"></mat-icon>
      </button>

      <mat-menu #columnMenu="matMenu">
        <div *ngIf="selectedListName == listName.Completed || selectedListName == listName.Dismissed">
          <mat-form-field class="list-shipments__date-select">
            <mat-label>Date Range</mat-label>
            <mat-select [(value)]="dateRange" (selectionChange)="dateRangeChanged()">
              <mat-option value="1">Last Day</mat-option>
              <mat-option value="2">Last 2 Days</mat-option>
              <mat-option value="7">Last 7 Days</mat-option>
              <mat-option value="">All Records</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <button (click)="clearGridFilters()" mat-menu-item *ngIf="columnsFiltered">Clear Filters</button>
        <button (click)="clearGridSorts()" mat-menu-item *ngIf="columnsSorted">Clear Sorting</button>
        <button (click)="clearGridSortAndFilters()" mat-menu-item *ngIf="columnsFiltered && columnsSorted">
          Clear Both
        </button>
      </mat-menu>

      <button class="icon-button" mat-button [matMenuTriggerFor]="menu">
        <mat-icon svgIcon="gear-icon"></mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button (click)="saveGridSettings()" mat-menu-item>Save Column Settings</button>
        <button (click)="loadGridSettings()" mat-menu-item>Load Column Settings</button>
        <button (click)="resetGridSettings()" mat-menu-item>Reset Column Settings</button>
      </mat-menu>
    </div>
  </div>
  <div [hidden]="selectedListName !== listName.Recommended">
    <app-toolbar
      [hidden]="selectedCount == 0"
      [showBackButton]="false"
      [title]="toolbarTitle"
      [showSelectDeselectAll]="true"
      (selectAllButtonClicked)="selectAllRows()"
      (deselectAllButtonClicked)="deselectAllRows()"
      [actionButton1Label]="'SEND TO FLAGGED LIST'"
      (actionButton1Clicked)="planningList.moveShipmentsToInspectionList()"
      [actionButton2Label]="'DISMISS'"
      (actionButton2Clicked)="planningList.dismissShipments()"
    ></app-toolbar>

    <app-list-planning-shipments
      (listChanged)="refreshAllLists()"
      (selectedCountChanged)="selectedCountChanged($event)"
      (gridSettingsChanged)="gridSettingsChanged()"
    ></app-list-planning-shipments>
  </div>

  <div [hidden]="selectedListName !== listName.Dismissed">
    <app-toolbar
      [hidden]="selectedCount == 0"
      [showBackButton]="false"
      [title]="toolbarTitle"
      [showSelectDeselectAll]="true"
      (selectAllButtonClicked)="selectAllRows()"
      (deselectAllButtonClicked)="deselectAllRows()"
      [actionButton1Label]="'SEND TO RECOMMENDED LIST'"
      (actionButton1Clicked)="dismissedList.moveShipmentsToPlanningList()"
      [actionButton2Label]="'SEND TO FLAGGED LIST'"
      (actionButton2Clicked)="dismissedList.moveShipmentsToInspectionList()"
    ></app-toolbar>

    <app-list-dismissed-shipments
      (listChanged)="refreshAllLists()"
      (selectedCountChanged)="selectedCountChanged($event)"
      (gridSettingsChanged)="gridSettingsChanged()"
    ></app-list-dismissed-shipments>
  </div>

  <div [hidden]="selectedListName !== listName.Flagged">
    <app-toolbar
      [hidden]="selectedCount == 0"
      [showBackButton]="false"
      [title]="toolbarTitle"
      [showSelectDeselectAll]="true"
      (selectAllButtonClicked)="selectAllRows()"
      (deselectAllButtonClicked)="deselectAllRows()"
      [actionButton1Label]="'SEND TO RECOMMENDED LIST'"
      (actionButton1Clicked)="flaggedList.moveShipmentsToPlanningList()"
      [actionButton2Label]="'DISMISS'"
      (actionButton2Clicked)="flaggedList.dismissShipments()"
    ></app-toolbar>

    <app-list-inspection-shipments
      (listChanged)="refreshAllLists()"
      (selectedCountChanged)="selectedCountChanged($event)"
      (gridSettingsChanged)="gridSettingsChanged()"
    ></app-list-inspection-shipments>
  </div>

  <div [hidden]="selectedListName !== listName.Inspected">
    <app-toolbar [hidden]="selectedCount == 0" [showBackButton]="false" [title]="toolbarTitle"></app-toolbar>

    <app-list-inspections
      (listChanged)="refreshAllLists()"
      (selectedCountChanged)="selectedCountChanged($event)"
      (gridSettingsChanged)="gridSettingsChanged()"
    ></app-list-inspections>
  </div>

  <div [hidden]="selectedListName !== listName.Completed">
    <app-toolbar
      [hidden]="selectedCount == 0"
      [showBackButton]="false"
      [title]="toolbarTitle"
      [showSelectDeselectAll]="true"
      (selectAllButtonClicked)="selectAllRows()"
      (deselectAllButtonClicked)="deselectAllRows()"
      [actionButton1Label]="'SEND TO RECOMMENDED LIST'"
      (actionButton1Clicked)="completedList.moveShipmentsToPlanningList()"
      [actionButton2Label]="'SEND TO FLAGGED LIST'"
      (actionButton2Clicked)="completedList.moveShipmentsToInspectionList()"
    ></app-toolbar>

    <app-list-complete-inspections
      (listChanged)="refreshAllLists()"
      (selectedCountChanged)="selectedCountChanged($event)"
      (gridSettingsChanged)="gridSettingsChanged()"
    ></app-list-complete-inspections>
  </div>
</div>
