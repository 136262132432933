import { PlanningListItem } from '../../../classes/planning-list-item';
import { InspectionShipment } from '@xpo-ltl/sdk-inspections';
import { InspectionState, toInspectionStateDisplayName } from '../../../enums/inspection-state.enum';

export class AddPro extends PlanningListItem {
  public inspectionStatus: string;

  constructor(inspectionShipment: InspectionShipment) {
    super(inspectionShipment);
    this.inspectionStatus = toInspectionStateDisplayName(<InspectionState>inspectionShipment.inspectionStatusCd);
  }

  public static mapInspectionShipments(inspectionShipments: InspectionShipment[]): AddPro[] {
    if (inspectionShipments) {
      return inspectionShipments.map(shipment => new AddPro(shipment));
    }
    return [];
  }
}
