import { DatePipe, TitleCasePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import {
  GetInspectionShipmentDetailsResp,
  GetShipmentLocationDetailsResp,
  InspectionContext
} from '@xpo-ltl/sdk-inspections';
import { detect } from 'detect-browser';
import { BehaviorSubject } from 'rxjs';
import { DateUtils } from '../classes/date-utils';
import { InspectionState } from '../enums/inspection-state.enum';
import { ShiftCode } from '../enums/shift-code.enum';

@Injectable()
export class AppConstantsService {
  private static dateTimeFormatString = 'M/d/yy h:mm:ss a';

  // NMFC rules
  static readonly nmfcRulesGroup = {
    '150390-1': [0, 1],
    '150390-2': [1, 2],
    '150390-3': [2, 4],
    '150390-4': [4, 6],
    '150390-5': [6, 8],
    '150390-6': [8, 10],
    '150390-7': [10, 12],
    '150390-8': [12, 15],
    '150390-9': [15, 22.5],
    '150390-10': [22.5, 30],
    '150390-11': [30, Infinity]
  };

  // Class rules
  static readonly nmfcClassRulesGroup = {
    '400': [0, 1],
    '300': [1, 2],
    '250': [2, 4],
    '175': [4, 6],
    '125': [6, 8],
    '100': [8, 10],
    '92.5': [10, 12],
    '85': [12, 15],
    '70': [15, 22.5],
    '65': [22.5, 30],
    '60': [30, Infinity]
  };

  // inspection photos limited to 20
  private _inspectionPhotoCountLimit = 20;
  private _inspectionPhotoLocalStorageLimit = 10000;
  private _proNbrInputs: string[];
  // Note: 11 digits PROs
  public changedProNbrs = new Set<string>();
  isGeneralUserPreferencesLoaded = false;

  private lastRefreshRecommendationsDateSubject = new BehaviorSubject(<String>'');
  lastRefreshRecommendationsDate$ = this.lastRefreshRecommendationsDateSubject.asObservable();

  private inspectionContextSubject = new BehaviorSubject(<InspectionContext>undefined);
  inspectionContext$ = this.inspectionContextSubject.asObservable();

  private inspectionShipmentDetailsSubject = new BehaviorSubject(<GetInspectionShipmentDetailsResp>undefined);
  inspectionShipmentDetails$ = this.inspectionShipmentDetailsSubject.asObservable();

  private shipmentLocationDetailsSubject = new BehaviorSubject(<GetShipmentLocationDetailsResp>undefined);
  shipmentLocationDetails$ = this.shipmentLocationDetailsSubject.asObservable();

  private inspectionStateSubject = new BehaviorSubject(InspectionState.NO_STATUS);
  inspectionState$ = this.inspectionStateSubject.asObservable();

  private _sicCode: string = undefined;
  private _shiftCode: string = undefined;
  private _inspectionContext: InspectionContext;
  private _imageCorpCode: string;
  private _versionString: string;
  private _maximumCommodityDimensionWidth = 98.0;
  private _maximumCommodityDimensionLength = 340.0;
  private _maximumCommodityDimensionHeight = 110.0;

  static calculateDensity(weight: number, volume: number): number {
    let retValue = 0;
    if (volume > 0) {
      retValue = parseFloat((weight / volume).toFixed(2));
    }
    return retValue;
  }

  static getFormattedDateTime(dateTime: Date): string {
    const formattedDateTime = new DatePipe(DateUtils.locale).transform(dateTime, this.dateTimeFormatString);
    return formattedDateTime;
  }

  static getCurrentFormattedDateTime(): string {
    return this.getFormattedDateTime(new Date());
  }

  static toTitleCase(text: string): string {
    return new TitleCasePipe().transform(text);
  }

  constructor(private configManagerService: ConfigManagerService) {
    this._imageCorpCode = configManagerService.getSetting('imageCorpCode');
    const browserInfo = detect();
    this._versionString =
      configManagerService.getSetting('buildVersion') + '-' + browserInfo.name + ':' + browserInfo.version;
    if (!this.imageCorpCode) {
      // todo: Add warning that imageCorpCode not found!
      // probably stop the app?
      console.error('CORP CODE NOT FOUND!');
    }
  }

  get proNbrInputs(): string[] {
    return this._proNbrInputs;
  }

  set proNbrInputs(proNbrInputs: string[]) {
    this._proNbrInputs = proNbrInputs;
  }

  get inspectionContext(): InspectionContext {
    return this._inspectionContext;
  }

  get maximumCommodityDimensionWidth(): number {
    return this._maximumCommodityDimensionWidth;
  }

  get maximumCommodityDimensionLength(): number {
    return this._maximumCommodityDimensionLength;
  }

  get maximumCommodityDimensionHeight(): number {
    return this._maximumCommodityDimensionHeight;
  }

  get imageCorpCode(): string {
    return this._imageCorpCode;
  }

  get inspectionShipmentDetails(): GetInspectionShipmentDetailsResp {
    return this.inspectionShipmentDetailsSubject.value;
  }

  get inspectionState(): InspectionState {
    return this.inspectionStateSubject.value;
  }

  get inspectionPhotoCountLimit(): number {
    return this._inspectionPhotoCountLimit;
  }

  get inspectionPhotoLocalStorageLimit(): number {
    return this._inspectionPhotoLocalStorageLimit;
  }

  get sicCode(): string {
    return this._sicCode;
  }

  get shiftCode(): string {
    return this._shiftCode;
  }

  get versionString(): string {
    return this._versionString;
  }

  clearChangedProNumbers() {
    this.changedProNbrs = new Set<string>();
  }

  setInspectionShipmentDetails(inspectionShipmentDetails: GetInspectionShipmentDetailsResp) {
    this.inspectionShipmentDetailsSubject.next(inspectionShipmentDetails);
    // anytime shipment details is updated, update the inspection state from the shipment details
    if (inspectionShipmentDetails?.shipmentDetails) {
      this.setInspectionState(<InspectionState>inspectionShipmentDetails.shipmentDetails.inspectionStatusCd);
    } else {
      this.setInspectionState(InspectionState.NO_STATUS);
    }
  }

  setShipmentLocationDetails(shipmentLocationDetails: GetShipmentLocationDetailsResp) {
    this.shipmentLocationDetailsSubject.next(shipmentLocationDetails);
  }

  setInspectionState(inspectionState: InspectionState) {
    this.inspectionStateSubject.next(inspectionState);
  }

  setLastRefreshRecommendationsDate(refreshDate: Date) {
    if (refreshDate) {
      this.lastRefreshRecommendationsDateSubject.next(AppConstantsService.getFormattedDateTime(refreshDate));
    } else {
      this.lastRefreshRecommendationsDateSubject.next('Never');
    }
  }

  setInspectionContext(sicCode: string, shiftCode: ShiftCode, firstName: string, lastName: string, employeeId: string) {
    if (!this.inspectionContext) {
      // only need to create if it doesn't already exist
      this._inspectionContext = new InspectionContext();
    }
    this.inspectionContext.inspectorFirstNm = firstName;
    this.inspectionContext.inspectorLastNm = lastName;
    this.inspectionContext.inspectorEmployeeId = employeeId;
    this.inspectionContext.shiftCd = <string>shiftCode;
    this.inspectionContext.inspectionSic = sicCode;

    // don't need to do anything if the sic and shift are the same
    if (
      this._sicCode !== this.inspectionContext?.inspectionSic ||
      this._shiftCode !== this.inspectionContext?.shiftCd
    ) {
      this._sicCode = sicCode;
      this._shiftCode = <string>shiftCode;
      this.inspectionContextSubject.next(this.inspectionContext);
    }
  }

  /*
 APPENDS THE URI PIECE TO THE URL PIECE TO COME UP WITH THE ABSOLUTE PATH TO THE RESOURCE
 PARAMETERS:
 URL - PROVIDED BY THE CALLER: /services/shipment/etc/etc/etc
 PROTOCOL (OPTIONAL) - protocol desired: HTTP, HTTPS, WSS, ETC... defaults to HTTP

 URI - PROVIDED BY $location: http://tcts4.con-way.com:80

 IF CONSTANT SERVICE_REDIRECT IS SET THE RETURNED PATH WILL CONTAIN ITS VALUE INSTEAD
 OF THE VALUE OF THE HOST.
 IF...
 SERVICE_REDIRECT = FOO.CON-WAY.COM
 RETURN = {PROTOCOL}+FOO.CON-WAY.COM+{URL}

 IF...
 SERVICE_REDIRECT = ''
 RETURN = {PROTOCOL}+{HOST AND PORT FOR LOCATION THAT SERVED THE  PAGE}+{URL}

 ANOTHER EXAMPLE RETURN - http://tcts4.con-way.com:80/services/shipment/etc/etc/etc
 */
  buildEndPoint(baseUrl, relativeUrl, protocol = 'http', pathParams?: string[], queryParams?: string[]): string {
    let retValue;

    if (baseUrl && baseUrl.toString().indexOf('://') < 0) {
      baseUrl = protocol + '://' + baseUrl;
    }
    retValue = `${baseUrl}${relativeUrl}`;
    if (pathParams !== undefined) {
      pathParams.forEach((item, index, array) => {
        retValue = `${retValue}/${item}`;
      });
    }
    if (queryParams !== undefined) {
      retValue += '?';
      queryParams.forEach((item, index, array) => {
        retValue = `${retValue}${item}&`;
      });
      retValue = retValue.substring(0, retValue.length - 1);
    }

    return retValue;
  }

  getPricingAppEndpoint(pathParams, queryParams): string {
    const pricingAppEndpoint = this.configManagerService.getSetting<string>('pricingAppEndpoint');
    return this.buildEndPoint(this.getNavigatorUrl(), pricingAppEndpoint, 'http', pathParams, queryParams);
  }

  getNmftaSearchUrl(rawNmfc: string): string {
    // Removing the sub from nmfc code
    const nmfc = rawNmfc.split('-')[0];
    const nmftaSearchUrl = this.configManagerService.getSetting<string>('nmftaSearchUrl');
    return nmftaSearchUrl + nmfc;
  }

  getCorrectionAppEndpoint(pathParams, queryParams): string {
    const correctionAppEndpoint = this.configManagerService.getSetting<string>('CorrectionAppEndpoint');
    return this.buildEndPoint(this.getNavigatorUrl(), correctionAppEndpoint, 'http', pathParams, queryParams);
  }

  getItem680note2bVersion(): string {
    let version = '';

    const item680note2bVersion = this.configManagerService.getSetting<string>('item680note2bVersion');

    if (item680note2bVersion) {
      version = item680note2bVersion;
    }

    return version;
  }

  getPhotoCleanupStrategyDays(): string {
    let days = '';
    const photoCleanupStrategyDays = this.configManagerService.getSetting<string>('photoCleanupStrategyDays');

    if (photoCleanupStrategyDays) {
      days = photoCleanupStrategyDays;
    }
    return days;
  }

  private getNavigatorUrl(): string {
    return this.configManagerService.getSetting<string>('navUrl');
  }
}
