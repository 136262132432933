import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-add-pro',
  templateUrl: './add-pro.component.html',
  styleUrls: ['./add-pro.component.scss']
})
export class AddProComponent implements OnInit {
  public title: string;
  public proNbrs: UntypedFormControl;

  constructor(private dialogRef: MatDialogRef<AddProComponent>) {
    this.title = 'Add PROs';
    this.proNbrs = new UntypedFormControl();
  }

  ngOnInit() {}

  public submit(): void {
    if (!this.proNbrs.hasError('required')) {
      this.dialogRef.close(this.proNbrs.value);
    }
  }

  public close(): void {
    this.dialogRef.close(undefined);
  }
}
