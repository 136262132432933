import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { BeaconStatusCd } from '@xpo-ltl/sdk-common';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { BroadcastMessage } from '../../classes/broadcast-messages/broadcast-message';
import { FormUtils } from '../../classes/form-utils.class';
import { DialogAction, toDialogActionPastTense } from '../../enums/dialog-actions.enum';
import { BroadcastMessageDialogFormNames } from '../../enums/form-names/broadcast-message-dialog-form-names.enum';
import { BroadcastMessagesService } from '../../services/broadcast-messages/broadcast-messages.service';
import { BroadcastMessageDialogFormBuilder } from './broadcast-message-dialog.form-builder';

@Component({
  selector: 'app-broadcast-message-dialog',
  templateUrl: './broadcast-message-dialog.component.html',
  styleUrls: ['./broadcast-message-dialog.component.scss']
})
export class BroadcastMessageDialogComponent implements OnInit, OnDestroy {
  readonly BroadcastMessageDialogFormNames = BroadcastMessageDialogFormNames;
  readonly DialogAction = DialogAction;

  // #region MAT_DIALOG_DATA
  title: string;
  action: DialogAction;
  broadcastMessage: BroadcastMessage;
  // #endregion

  messageForm: UntypedFormGroup;
  expiryDateIsOpen = false;
  sendInformation = false;
  isReadOnly = false;

  private unsubscriber$: Subject<any> = new Subject();

  constructor(
    private fb: UntypedFormBuilder,
    private broadcastMessagesService: BroadcastMessagesService,
    private dialogRef: MatDialogRef<BroadcastMessageDialogComponent>,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.initDialogData();
    this.initForm();
  }

  ngOnDestroy() {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

  /**
   * Create and update the message Form
   */
  private initForm() {
    this.messageForm = BroadcastMessageDialogFormBuilder.create(this.fb);
    const thereIsMessage = !!this.broadcastMessage && !!this.broadcastMessage.messageId;

    if (thereIsMessage) {
      BroadcastMessageDialogFormBuilder.setValue(this.messageForm, this.broadcastMessage);
      this.isReadOnly = this.broadcastMessage.messageStatus !== BeaconStatusCd.NEW;

      if (this.isReadOnly) {
        FormUtils.disable(this.messageForm);
      }
    }
  }

  /**
   * Init properties from MAT_DIALOG_DATA
   */
  private initDialogData() {
    this.title = this.data.title;
    this.action = this.data.action;
    this.broadcastMessage = this.data.broadcastMessage;
  }

  private createBroadcastMessage(): Observable<BroadcastMessage> {
    const newBroadcastMessage = BroadcastMessageDialogFormBuilder.getValue(this.messageForm);
    return this.broadcastMessagesService
      .createBroadcastMessage(newBroadcastMessage)
      .pipe(tap((broadcastMessage) => (this.broadcastMessage = broadcastMessage)));
  }

  private updateBroadcastMessage(): Observable<BroadcastMessage> {
    const formBroadcastMessage = BroadcastMessageDialogFormBuilder.getValue(this.messageForm);
    this.broadcastMessage.message = formBroadcastMessage.message;
    this.broadcastMessage.effectiveDate = formBroadcastMessage.effectiveDate;
    return this.broadcastMessagesService
      .updateBroadcastMessage(this.broadcastMessage)
      .pipe(tap((broadcastMessage) => (this.broadcastMessage = broadcastMessage)));
  }

  private deleteBroadcastMessage(): Observable<boolean> {
    return this.broadcastMessagesService.cancelMessage(this.broadcastMessage);
  }

  private sendMessages(): Observable<boolean> {
    if (!this.broadcastMessage.messageId) {
      return;
    }
    return this.broadcastMessagesService.sendBroadcastMessage(this.broadcastMessage);
  }

  onEffectiveDateClosed() {
    this.expiryDateIsOpen = true;
  }

  onExpiryDateClosed() {
    this.expiryDateIsOpen = false;
  }

  onSaveBroadcastMessage() {
    this.sendInformation = true;
  }

  onDeleteBroadcastMessage() {
    this.action = DialogAction.DELETE;
    this.sendInformation = true;
  }

  onBroadcastMessage() {
    this.action = DialogAction.BROADCAST;
    this.sendInformation = true;
  }

  backFormScreen() {
    this.sendInformation = false;

    if (this.action === DialogAction.DELETE) {
      this.action = DialogAction.EDIT;
    }
  }

  performOperation() {
    let action$: any;
    let showMessage = '';

    switch (this.action) {
      case DialogAction.EDIT:
        action$ = this.updateBroadcastMessage();
        break;
      case DialogAction.DELETE:
        action$ = this.deleteBroadcastMessage();
        break;
      case DialogAction.CREATE:
        action$ = this.createBroadcastMessage();
        break;
      case DialogAction.BROADCAST:
        action$ = this.sendMessages();
        break;
      default:
        return;
    }

    showMessage = `The broadcast message was ${toDialogActionPastTense(this.action as DialogAction)} successfully`;
    action$.pipe(takeUntil(this.unsubscriber$)).subscribe(
      () => {
        this.snackBar.open(showMessage, 'Close', {
          duration: 3000
        });
        this.dialogRef.close(this.broadcastMessage);
      },
      () => {
        this.backFormScreen();
      }
    );
  }

  close() {
    this.dialogRef.close();
  }
}
