import { AppStorageService } from './../app-storage.service';
import { GetacService } from './getac-service';
// import { WebBrowserService } from './web-browser-service';
import { PhotoGalleryService } from '../../components/photo-gallery/photo-gallery.service';
import { MobileWebBrowserService } from './mobile-web-browser-service';
import { CameraService } from '../../components/camera/camera.service';
import { AppConstantsService } from '../app-constants.service';

export function HardwareServiceFactory(
  photoGalleryService: PhotoGalleryService,
  appStorageService: AppStorageService,
  cameraService: CameraService,
  constantsService: AppConstantsService
) {
  if (window['container']) {
    return new GetacService(photoGalleryService);
    // } else if (window.location.search.includes('beta')) {
  } else {
    return new MobileWebBrowserService(photoGalleryService, appStorageService, cameraService, constantsService);
  }
}
