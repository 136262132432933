import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { GetShipmentLocationDetailsResp, InspectionHandlingUnit, InspectionShipment } from '@xpo-ltl/sdk-inspections';
import { LocationBreakdown, LocationUtil } from '../../../../classes/location-utils';
import { RouterUriComponents } from '../../../../enums/router-uri-components.enum';

export class ShipmentLocationInfo {
  pltProNumber?: string;
  locationBreakdown: LocationBreakdown;
}
@Component({
  selector: 'app-location-movement',
  templateUrl: './location-movement.component.html',
  styleUrls: ['./location-movement.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocationMovementComponent implements OnChanges {
  @Input()
  shipmentDetails: InspectionShipment;

  @Input()
  shipmentLocationDetails: GetShipmentLocationDetailsResp;

  isExpanded: boolean;
  showChildPro: boolean;
  routerLink: string;
  shipmentLocations: ShipmentLocationInfo[] = [];

  constructor() {}

  ngOnChanges() {
    this.isExpanded = true;
    this.showChildPro = !!this.shipmentDetails?.inspectionHandlingUnits?.length;
    this.routerLink = `/${RouterUriComponents.TRAILER_DETAIL_PAGE}`;

    if (this.showChildPro) {
      // get plt PROs' location breakdown
      this.shipmentLocations = [];
      this.shipmentDetails?.inspectionHandlingUnits?.forEach((handlingUnit: InspectionHandlingUnit) => {
        const shipmentLocation = new ShipmentLocationInfo();
        shipmentLocation.pltProNumber = handlingUnit.handlingUnitProNbr;
        shipmentLocation.locationBreakdown = LocationUtil.getLocationBreakdownForPLT(handlingUnit.currentLocation);
        this.shipmentLocations.push(shipmentLocation);
      });
    } else {
      // get Legacy PRO's location breakdown
      this.shipmentLocations = [new ShipmentLocationInfo()];
      this.shipmentLocations[0].locationBreakdown = LocationUtil.buildLocationBreakdown(
        this.shipmentLocationDetails?.shipmentDetail?.shipmentLocation
      );
      this.shipmentLocations[0].locationBreakdown.trailerNumber =
        this.shipmentLocationDetails?.shipmentDetail?.trailerNbr;
    }
  }
}
