import { ShipmentDetailsComponent } from './shipment-details.component';
import { NgModule } from '@angular/core';
import { MaterialModule } from '../../material.module';
import { ShipmentDetailsRoutingModule } from './shipment-details-routing.module';
import { OverviewComponent } from './components/overview/overview.component';
import { CustomerInstructionsComponent } from './components/customer-instructions/customer-instructions.component';
import { MeasurementsComponent } from './components/measurements/measurements.component';
import { BillDetailsComponent } from './components/bill-details/bill-details.component';
import { PricingComponent } from './components/pricing/pricing.component';
import { LocationMovementComponent } from './components/location-movement/location-movement.component';
import { DimensionsComponent } from './components/dimensions/dimensions.component';
import { XpoPipesModule } from '../../pipes/xpo-pipes.module';
import { ToolbarModule } from '../toolbar/toolbar.module';
import { ShipmentDetailsToolbarComponent } from './components/shipment-details-toolbar/shipment-details-toolbar.component';
import { ShipmentDetailsGuard } from '../../guards/shipment-details.guard';
import { PreviousInspectionComponent } from './components/previous-inspection/previous-inspection.component';
import { PhotoGalleryModule } from '../photo-gallery/photo-gallery.module';
import { UserProfileGuard } from '../../guards/user-profile-guard';
import { DocumentListModule } from '../document-list/document-list.module';

@NgModule({
    imports: [
        MaterialModule,
        ShipmentDetailsRoutingModule,
        XpoPipesModule,
        ToolbarModule,
        PhotoGalleryModule,
        DocumentListModule
    ],
    declarations: [
        ShipmentDetailsComponent,
        OverviewComponent,
        CustomerInstructionsComponent,
        MeasurementsComponent,
        BillDetailsComponent,
        PricingComponent,
        LocationMovementComponent,
        DimensionsComponent,
        ShipmentDetailsToolbarComponent,
        PreviousInspectionComponent
    ],
    providers: [ShipmentDetailsGuard, UserProfileGuard]
})
export class ShipmentDetailsModule {}
