import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { AppConstantsService } from '../../../services/app-constants.service';
import { XpoDialogManagerService } from '../../../services/xpo-dialog-manager.service';

@Component({
  selector: 'app-nmfc-column',
  templateUrl: './nmfc-column.component.html',
  styleUrls: ['./nmfc-column.component.scss']
})
export class NmfcColumnComponent implements AgRendererComponent {
  nmfcList: Array<string>;

  constructor(public xpoDialog: XpoDialogManagerService, public appConstantsService: AppConstantsService) {}

  agInit(params: any): void {
    this.nmfcList = params.value;
  }

  refresh(params: ICellRendererParams): boolean {
    this.nmfcList = params.value;
    return true;
  }

  public openNmftaSite(nmfc: string, event: Event): void {
    event.stopPropagation();
    const nmftaSearchUrl = this.appConstantsService.getNmftaSearchUrl(nmfc);
    this.xpoDialog.openExternalWindow(nmftaSearchUrl, 'NMFTA Search');
  }
}
