import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Comment, Dimensions } from '@xpo-ltl/sdk-common';
import { InspectionCommodityLine, InspectionShipment, PieceDimensions } from '@xpo-ltl/sdk-inspections';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ProNumber } from '../../classes/pronumber';
import { ConfirmMessage, buildInspectedNotCorrectedWarningMessage } from '../../enums/confirm-message.enum';
import { DocTypes } from '../../enums/doc-type.enum';
import { DocumentListTitles } from '../../enums/document-list-titles.enum';
import { InspectionState, toInspectionStateDisplayName } from '../../enums/inspection-state.enum';
import { AlertDialogData } from '../../interfaces/alert-dialog-data.interface';
import { AppConstantsService } from '../../services/app-constants.service';
import { AppNavigationService } from '../../services/app-navigation.service';
import { HardwareService } from '../../services/hardware/hardware-service';
import { InspectionLocalStorageService } from '../../services/inspection-local-storage.service';
import { ShipmentDetailsService } from '../../services/shipment-details.service';
import { XpoDialogManagerService } from '../../services/xpo-dialog-manager.service';
import { PendingPhotosComponent } from './dialog/pending-photos/pending-photos.component';

@Component({
  selector: 'app-inspect-shipment',
  templateUrl: './inspect-shipment.component.html',
  styleUrls: ['./inspect-shipment.component.scss']
})
export class InspectShipmentComponent implements OnInit, OnDestroy {
  constructor(
    public shipmentDetailsService: ShipmentDetailsService,
    public router: Router,
    private route: ActivatedRoute,
    private constants: AppConstantsService,
    private xpoDialog: XpoDialogManagerService,
    private hardwareService: HardwareService,
    private inspectionLocalStorage: InspectionLocalStorageService,
    private appNavigation: AppNavigationService,
    private matDialog: MatDialog
  ) {}

  proNumber: ProNumber;
  status: string;
  shipmentDetails: InspectionShipment;
  inspectionDetails: InspectionShipment;
  dimensionErrors: string[];
  DocumentListTitles = DocumentListTitles;
  readonly photoDocTypes = [DocTypes.INSPECTION_PHOTO];

  private unsubscriber$: Subject<any> = new Subject();

  private numberOfPhotos = 0;

  static addRowToInspectionDetails(inspectionShipment: InspectionShipment) {
    const inspectionPieceDimensions = new PieceDimensions();
    inspectionPieceDimensions.dimensions = new Dimensions();
    inspectionShipment.inspectorPieceDimensions.push(inspectionPieceDimensions);
  }

  ngOnInit() {
    this.dimensionErrors = new Array<string>();

    this.constants.inspectionShipmentDetails$
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((inspectionShipmentDetails) => {
        this.shipmentDetails = inspectionShipmentDetails.shipmentDetails;
        this.proNumber = new ProNumber(this.shipmentDetails.shipmentId.proNumber);
        this.inspectionDetails = this.buildInspectionDetails(
          this.route.snapshot.data['inspectionDetails'],
          this.shipmentDetails
        );
      });

    this.constants.inspectionState$.pipe(takeUntil(this.unsubscriber$)).subscribe((inspectionStatusCd) => {
      this.status = toInspectionStateDisplayName(<InspectionState>inspectionStatusCd);
    });

    this.hardwareService.removePendingPhotos();
  }

  setNumberOfPhotos(aNumberOfPhotos: number) {
    this.numberOfPhotos = aNumberOfPhotos;
  }

  backButtonClicked() {
    this.appNavigation.navigateToShipmentDetails(this.proNumber);
  }

  inspectedNotCorrected() {
    this.inspectionDetails.inspectionStatusCd = InspectionState.INSPECTED_NOT_CORRECTED;
    this.buildAndCreateInspection(this.inspectionDetails);
  }

  submitInspection() {
    if (this.validateInspection(this.inspectionDetails, true)) {
      this.inspectionDetails.inspectionStatusCd = InspectionState.INSPECTED;
      this.buildAndCreateInspection(this.inspectionDetails);
    }
  }

  private buildAndCreateInspection(inspectionDetails: InspectionShipment) {
    const DEFAULT_PIECES_COUNT: number = 1;
    const DEFAULT_SEQUENCE_NUMBER: number = 1;
    if (inspectionDetails) {
      // before submitting.. Validate that things are in the correct state to submit
      // Must not be in Inspected Status to continue.
      // Validate Max Length and copy newPieceDimensions into new array
      let exceedsWidth = false;
      let exceedsHeight = false;
      let exceedsLength = false;
      const newPieceDimensions = new Array<PieceDimensions>();

      inspectionDetails.inspectorPieceDimensions?.forEach((pieceDimension: PieceDimensions) => {
        if (
          pieceDimension?.pieceCnt > 0 &&
          pieceDimension?.dimensions?.width > 0 &&
          pieceDimension?.dimensions?.length > 0 &&
          pieceDimension?.dimensions?.height > 0
        ) {
          if (pieceDimension?.dimensions?.width > this.constants?.maximumCommodityDimensionWidth) {
            exceedsWidth = true;
          }
          if (pieceDimension?.dimensions?.length > this.constants?.maximumCommodityDimensionLength) {
            exceedsLength = true;
          }
          if (pieceDimension?.dimensions?.height > this.constants?.maximumCommodityDimensionHeight) {
            exceedsHeight = true;
          }

          pieceDimension.seqNbr = newPieceDimensions.length + 1;
          newPieceDimensions.push(pieceDimension);
        }
      });
      const newInspectionDetails = new InspectionShipment();
      newInspectionDetails.shipmentId = inspectionDetails.shipmentId; // we're not changing this.. direct copy is ok
      newInspectionDetails.inspectionStatusCd = inspectionDetails.inspectionStatusCd;

      newInspectionDetails.commodity = new Array<InspectionCommodityLine>();
      const commodityLine = new InspectionCommodityLine();
      commodityLine.pieceCnt = DEFAULT_PIECES_COUNT;
      commodityLine.seq = DEFAULT_SEQUENCE_NUMBER;
      commodityLine.totGrossWeight = inspectionDetails.totGrossWeight;
      newInspectionDetails.commodity.push(commodityLine);

      newInspectionDetails.inspectorPieceDimensions = newPieceDimensions;
      newInspectionDetails.inspectionNote = new Comment();
      newInspectionDetails.inspectionNote.note = inspectionDetails.inspectionNote?.note;

      this.showConfirmCreateInspectionDialog(
        newInspectionDetails,
        exceedsLength,
        exceedsWidth,
        exceedsHeight,
        this.numberOfPhotos
      );
    } else {
      throw new Error('Inspection Shipment cannot be null/undefined.');
    }
  }

  private createInspection(inspectionDetails: InspectionShipment) {
    this.shipmentDetailsService
      .createInspection(inspectionDetails)
      .pipe(take(1))
      .subscribe(
        () => {
          // remove PieceDimensions from Local Storage on Create Inspection
          this.inspectionLocalStorage.clearInspectionData(new ProNumber(this.inspectionDetails.shipmentId.proNumber));
          this.constants.changedProNbrs.add(inspectionDetails.shipmentId.proNumber);

          this.hardwareService.submitInspectionPhotos(this.proNumber);

          if (this.hardwareService.showPendingPhotosDialogBox() && this.numberOfPhotos > 0) {
            this.showProgressDialog()
              .pipe(take(1))
              .subscribe(() => {
                this.appNavigation.navigateToList();
              });
          } else {
            this.appNavigation.navigateToList();
          }
        },
        (error) => {
          this.showAlertDialog(error);
        }
      );
  }

  private validateInspection(inspectionDetails: InspectionShipment, dimensionsRequired: boolean): boolean {
    this.dimensionErrors = new Array<string>();
    let validCount = 0;
    let hasError = false;
    for (let idx = 0; idx < inspectionDetails.inspectorPieceDimensions.length; idx++) {
      const pieceDimension = inspectionDetails.inspectorPieceDimensions[idx];
      const pieceCount: number = pieceDimension.pieceCnt;
      const width = pieceDimension.dimensions.width;
      const length = pieceDimension.dimensions.length;
      const height = pieceDimension.dimensions.height;
      let errorMessage: string;

      if (pieceCount || width || length || height) {
        if (pieceCount && width && length && height) {
          if (!Number.isInteger(+pieceCount)) {
            errorMessage = 'Invalid Piece Count!';
          } else if (!this.isValidDimension(+width)) {
            errorMessage = 'Invalid Width! Must be > 0 and in .5 increments.';
          } else if (!this.isValidDimension(+length)) {
            errorMessage = 'Invalid Length! Must be > 0 and in .5 increments.';
          } else if (!this.isValidDimension(+height)) {
            errorMessage = 'Invalid Height! Must be > 0 and in .5 increments.';
          } else {
            validCount++;
          }
        } else {
          errorMessage = 'Missing Data in Row!';
        }
      }

      if (errorMessage) {
        hasError = true;
      }

      this.dimensionErrors.push(errorMessage);
    }

    if (hasError) {
      return false;
    }

    if (dimensionsRequired && validCount === 0) {
      this.dimensionErrors[0] = 'At least One Dimension is Required to Submit Inspection!';
      return false;
    }
    return true;
  }

  private buildInspectionDetails(
    inspectionDetails: InspectionShipment,
    shipmentDetails: InspectionShipment
  ): InspectionShipment {
    if (!inspectionDetails) {
      inspectionDetails = new InspectionShipment();
      inspectionDetails.shipmentId = shipmentDetails.shipmentId;
    }

    inspectionDetails.totPieceCnt = shipmentDetails.totPieceCnt;
    inspectionDetails.totGrossWeight = shipmentDetails.totGrossWeight;
    inspectionDetails.motorizedPiecesCnt = shipmentDetails.motorizedPiecesCnt;
    inspectionDetails.loosePiecesCnt = shipmentDetails.loosePiecesCnt;

    if (!inspectionDetails.inspectorPieceDimensions) {
      inspectionDetails.inspectorPieceDimensions = new Array<PieceDimensions>();
    }

    if (!inspectionDetails.inspectionNote) {
      inspectionDetails.inspectionNote = new Comment();
    }

    InspectShipmentComponent.addRowToInspectionDetails(inspectionDetails);

    return inspectionDetails;
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

  private showConfirmCreateInspectionDialog(
    inspectionShipment: InspectionShipment,
    exceedsLength: boolean,
    exceedsWidth: boolean,
    exceedsHeight: boolean,
    numberOfPhotos: number
  ) {
    const inspectionState = inspectionShipment.inspectionStatusCd;
    let messageBody: string = '';
    if (exceedsLength) {
      messageBody += 'Length Exceeds ' + this.constants.maximumCommodityDimensionLength + ' Inches. <br />';
    }
    if (exceedsWidth) {
      messageBody += 'Width Exceeds ' + this.constants.maximumCommodityDimensionWidth + ' Inches. <br />';
    }
    if (exceedsHeight) {
      messageBody += 'Height Exceeds ' + this.constants.maximumCommodityDimensionHeight + ' Inches. <br />';
    }

    if (messageBody.length > 0) {
      messageBody = 'Warning: Dimension Value Exceeded<br /><br />' + messageBody + '<br />';
    }

    if (inspectionState === InspectionState.INSPECTED_NOT_CORRECTED) {
      const currentState: InspectionState = this.constants.inspectionState;
      messageBody += '<br />' + ConfirmMessage.InspectedNotCorrected;
    } else {
      // Only if Submitting an Inspection, check for 3 photos
      if (numberOfPhotos < 3) {
        messageBody += 'Less than 3 photos taken.<br>';
      }
      messageBody += '<br />' + ConfirmMessage.InspectionSubmitMessage;
    }

    this.xpoDialog
      .showConfirmCancelDialog(messageBody)
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          this.createInspection(inspectionShipment);
        }
      });
  }

  private isValidDimension(value: number): boolean {
    if (Number.isNaN(value) || value <= 0) {
      return false;
    }
    const testValue = (Math.ceil((value * 10) / 5) * 5) / 10;
    if (testValue !== value) {
      return false;
    }
    return true;
  }

  private showAlertDialog(error: any) {
    let contentText = <string>ConfirmMessage.InspectionSubmitError;
    if (error && error.error && error.error.message && error.error.message.indexOf('Inspection already') >= 0) {
      contentText = ConfirmMessage.InspectionAlreadySubmitted;
    } else if (error && error.error && error.error.message) {
      contentText = contentText + ' ' + error.error.message;
    } else if (error && error.message) {
      contentText = contentText + ' ' + error.message;
    } else if (error && error.constructor.name === 'ProgressEvent') {
      contentText = contentText + ' ' + 'Communication Error!';
    } else {
      contentText = contentText + ' ' + error;
    }
    const alertDialogData: AlertDialogData = <AlertDialogData>{
      titleText: 'Error!',
      contentText: contentText
    };
    this.xpoDialog.alert(alertDialogData);
  }

  private showProgressDialog(): Observable<any | undefined> {
    const data = {};
    const dialogRef = this.matDialog.open(PendingPhotosComponent, {
      disableClose: true,
      data: data
    });
    return dialogRef.afterClosed();
  }
}
