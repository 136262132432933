<h2 mat-dialog-title>{{ title }}</h2>
<mat-divider></mat-divider>
<mat-dialog-content class="add-pro-content">
  <mat-form-field class="add-pro-content-field">
    <textarea matInput required [formControl]="proNbrs" rows="20" placeholder="Enter or paste PRO(s) below"></textarea>
    <mat-hint class="add-pro-content-field-hint" align="start">
      <strong>Separate PROs by comma or semi-colon</strong>
    </mat-hint>
    <mat-error class="add-pro-content-field-error" *ngIf="proNbrs.hasError('required')">
      Please Enter Separate PROs by comma or semi-colon!
    </mat-error>
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button color="primary" (click)="submit()">Submit</button>
  <button mat-button (click)="close()">Close</button>
</mat-dialog-actions>
