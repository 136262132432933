import { Injectable } from '@angular/core';
import { Dimension3D } from '../../../../classes/dimension3d';
import { Dimension2D } from '../../../../classes/dimension2d';
import { CollectionUtils } from '../../../../classes/collection-utils';
import { AppConstantsService } from '../../../../services/app-constants.service';
import { Item680AppStorageService } from './item680-app-storage.service';
import { PieceDimensions } from '@xpo-ltl/sdk-inspections';

@Injectable({
  providedIn: 'root'
})
export class Item680ValidationsService {
  private overallDimensions: Array<Dimension3D> = new Array<Dimension3D>();
  private palletSurfaces: Array<Dimension2D> = new Array<Dimension2D>();
  private commoditySurfaces: Array<Dimension2D> = new Array<Dimension2D>();
  private shipmentWeight: number;

  private NMFCItem: string;
  private NMFCClass: number;
  private percentageAreaUsed: number;
  private isItem680Applicable: boolean;
  private shipmentVolume: number;
  private density: number;
  private item680Comments: string;

  // Used to store the pro being inspected
  private pro: string;

  // Used to know the actual state of the form
  // submitted or not
  private item680CalculatorSubmitted = false;

  constructor(
    private appConstantsService: AppConstantsService,
    private item680StorageService: Item680AppStorageService
  ) {}

  public getItem680CalculatorSubmitted(): boolean {
    return this.item680CalculatorSubmitted;
  }

  public setItem680CalculatorSubmitted(status: boolean): void {
    this.item680CalculatorSubmitted = status;
  }

  public getPro(): string {
    return this.pro;
  }

  public setPro(pro: string): void {
    this.pro = pro;
  }

  public resetAllValues(): void {
    this.overallDimensions = new Array<Dimension3D>();
    this.palletSurfaces = new Array<Dimension2D>();
    this.commoditySurfaces = new Array<Dimension2D>();
    this.shipmentWeight = undefined;
    this.shipmentVolume = undefined;
    this.NMFCItem = '';
    this.NMFCClass = 0;
    this.percentageAreaUsed = 0;
    this.density = 0;
    this.resetComments();
  }

  public resetComments(): void {
    this.setItem680CalculatorSubmitted(false);
    this.isItem680Applicable = undefined;
    this.item680Comments = '';
  }

  public getOverallDimensions(): Array<Dimension3D> {
    return this.overallDimensions;
  }

  public setOverallDimensions(dimensions: Array<PieceDimensions>): void {
    const dimensions3D = dimensions.map((dimension: PieceDimensions) => {
      const dimension3D = new Dimension3D();

      dimension3D.setHeight(dimension.dimensions.height);
      dimension3D.setLength(dimension.dimensions.length);
      dimension3D.setWidth(dimension.dimensions.width);
      dimension3D.setPieces(dimension.pieceCnt);
      return dimension3D;
    });

    dimensions3D.push(new Dimension3D());

    this.overallDimensions = dimensions3D;
  }

  private setPalletSurfaces(pallets: Array<any>): void {
    const dimensions2D = pallets.map(pallet => {
      const dimension2D = new Dimension2D();

      dimension2D.setLength(pallet.length);
      dimension2D.setWidth(pallet.width);
      dimension2D.setPieces(pallet.pieces);
      return dimension2D;
    });

    dimensions2D.push(new Dimension2D());

    this.palletSurfaces = dimensions2D;
  }

  private setCommoditySurfaces(commodities: Array<any>): void {
    const dimensions2D = commodities.map(commodity => {
      const dimension2D = new Dimension2D();

      dimension2D.setLength(commodity.length);
      dimension2D.setWidth(commodity.width);
      dimension2D.setPieces(commodity.pieces);
      return dimension2D;
    });

    dimensions2D.push(new Dimension2D());

    this.commoditySurfaces = dimensions2D;
  }

  public getPalletSurfaces(): Array<Dimension2D> {
    return this.palletSurfaces;
  }

  public getCommoditySurfaces(): Array<Dimension2D> {
    return this.commoditySurfaces;
  }

  private getValidPalletSurfaces(): Array<Dimension2D> {
    return this.palletSurfaces.filter(palletSurface => palletSurface.getArea());
  }

  private getValidCommoditySurfaces(): Array<Dimension2D> {
    return this.commoditySurfaces.filter(commoditySurface => commoditySurface.getArea());
  }

  public getWidestPalletDimension(): number {
    let width = 0;

    const mappedArray = this.getValidPalletSurfaces().map(surface => surface.getWidth());
    width = Math.max(...mappedArray);

    return width;
  }

  public getLargestPalletDimension(): number {
    let length = 0;

    const mappedArray = this.getValidPalletSurfaces().map(surface => surface.getLength());
    length = Math.max(...mappedArray);

    return length;
  }

  public getLargestPalletArea(): number {
    let area = 0;

    const mappedArray = this.getValidPalletSurfaces().map(surface => surface.getArea());
    area = Math.max(...mappedArray);

    return area;
  }

  public getPalletSurfacesAreaInSquareFeet(): number {
    let area = 0;

    if (this.getValidPalletSurfaces().length) {
      area = this.getValidPalletSurfaces()
        .map(palletSurface => palletSurface.getAreaFromInchesToFeet())
        .reduce((prev, next) => prev + next);
    }

    return area;
  }

  public getFormattedPalletSurfaces(): string {
    let formattedPalletSurfaces = '';

    if (this.getValidPalletSurfaces().length) {
      formattedPalletSurfaces = this.getValidPalletSurfaces()
        .map(surface => surface.getFormattedSurface())
        .reduce((prev, next) => prev + ', ' + next);
    }

    return formattedPalletSurfaces;
  }

  public getCommoditySurfacesAreaInSquareFeet(): number {
    let area = 0;

    if (this.getValidCommoditySurfaces().length) {
      area = this.getValidCommoditySurfaces()
        .map(commoditySurface => commoditySurface.getAreaFromInchesToFeet())
        .reduce((prev, next) => prev + next);
    }

    return area;
  }

  public getFormattedCommoditySurfaces(): string {
    let formattedCommoditySurfaces = '';

    if (this.getValidCommoditySurfaces().length) {
      formattedCommoditySurfaces = this.getValidCommoditySurfaces()
        .map(surface => surface.getFormattedSurface())
        .reduce((prev, next) => prev + ', ' + next);
    }

    return formattedCommoditySurfaces;
  }

  public isValidDimension(value: number): boolean {
    let isValid = true;

    if (Number.isNaN(value) || value <= 0) {
      isValid = false;
    }

    const testValue = (Math.ceil((value * 10) / 5) * 5) / 10;
    if (testValue !== value) {
      isValid = false;
    }

    return isValid;
  }

  public getShipmentWeight(): number {
    return this.shipmentWeight;
  }

  public setShipmentWeight(shipmentWeight: number): void {
    this.shipmentWeight = shipmentWeight;
  }

  public calculatePercentageOfSurfaceAreaUsed(): void {
    let percentageUsed = 0;
    if (this.getPalletSurfacesAreaInSquareFeet()) {
      percentageUsed = this.getCommoditySurfacesAreaInSquareFeet() / this.getPalletSurfacesAreaInSquareFeet();
    }

    this.percentageAreaUsed = percentageUsed * 100;
  }

  public getPercentageOfSurfaceAreaUsed(): number {
    return this.percentageAreaUsed;
  }

  public getShipmentVolume(): number {
    return this.shipmentVolume;
  }

  public calculateShipmentVolume(): void {
    let volume = 0;

    if (this.getOverallDimensions().length) {
      volume = this.getOverallDimensions()
        .map(dimension => dimension.getVolumeFromInchesToFeet())
        .reduce((prev, next) => prev + next);
    }

    // We truncate to 2 decimals
    this.shipmentVolume = Math.floor(volume * 100) / 100;
  }

  public calculateShipmentDensity(): void {
    let density = 0;
    if (this.getShipmentVolume()) {
      density = this.shipmentWeight / this.getShipmentVolume();
    }
    this.density = density;
  }

  public getShipmentDensity(): number {
    return this.density;
  }

  public setShipmentDensity(density: number): void {
    this.density = density;
  }

  public getItem680Comments(): string {
    return this.item680Comments;
  }

  public calculateNMFCItem(): void {
    if (this.density) {
      this.NMFCItem = CollectionUtils.getGroupBasedOnValue(AppConstantsService.nmfcRulesGroup, this.density);
    }
  }

  public getNMFCItem(): string {
    return this.NMFCItem;
  }

  public calculateNMFCClass(): void {
    if (this.density) {
      this.NMFCClass = parseFloat(
        CollectionUtils.getGroupBasedOnValue(AppConstantsService.nmfcClassRulesGroup, this.density)
      );
    }
  }

  public getNMFCClass(): number {
    return this.NMFCClass;
  }

  public calculateItem680Applicable(): void {
    this.isItem680Applicable = false;

    if (this.isDensityAplicable() && this.isSurfaceAreaAplicable()) {
      this.isItem680Applicable = true;
    }
  }

  public isDensityAplicable(): boolean {
    return this.density && this.density > 0.0; // Just check that is > 0.0
  }

  public isSurfaceAreaAplicable(): boolean {
    return this.percentageAreaUsed && this.percentageAreaUsed < 60;
  }

  public getItem680Applicable(): boolean {
    return this.isItem680Applicable;
  }

  public almostOneSectionsIsEmpty(): boolean {
    let response = false;

    if (
      !this.getShipmentVolume() ||
      !this.getValidPalletSurfaces().length ||
      !this.getValidCommoditySurfaces().length
    ) {
      response = true;
    }

    return response;
  }

  public calculateShipmentProperties(): void {
    this.calculateShipmentVolume();
    this.calculatePercentageOfSurfaceAreaUsed();
    this.calculateShipmentDensity();
    this.calculateNMFCItem();
    this.calculateNMFCClass();

    // We store the data in session storage
    this.saveCalculatorData();
  }

  private saveCalculatorData(): void {
    const data = {
      weight: this.shipmentWeight,
      dimensions: this.overallDimensions,
      pallets: this.palletSurfaces,
      commodities: this.commoditySurfaces
    };

    this.item680StorageService.saveItem6802bData(data, this.pro);
  }

  public setCalculatorData(data: any): void {
    if (data.weight) {
      this.shipmentWeight = data.weight;
    }

    if (data.dimensions) {
      this.setOverallDimensions(data.dimensions);
    }

    if (data.pallets) {
      this.setPalletSurfaces(data.pallets);
    }

    if (data.commodities) {
      this.setCommoditySurfaces(data.commodities);
    }
  }

  public shipmentWeightIsZero(): boolean {
    return this.shipmentWeight <= 0 || !this.shipmentWeight;
  }

  public generateItem680Comments(): void {
    if (!this.almostOneSectionsIsEmpty() && !this.shipmentWeightIsZero()) {
      this.calculateItem680Applicable();
      this.buildItem680Comments();
    }
  }

  private getItem680Version(): string {
    return this.appConstantsService.getItem680note2bVersion();
  }

  private buildItem680Comments(): void {
    let comments = '';

    if (this.getItem680Applicable()) {
      // When the item is applicable
      comments =
        'In accordance with NMFC Rules Item 680 Note 2 (b): When less than 65 percent of the surface area of the lift truck ' +
        'skid, pallet or platform is occupied, the gross weight of the lift truck skid, pallet or platform and the ' +
        'commodity(ies) unitized or secured thereon will be subject to the class applicable to either the lift truck skid, ' +
        'pallet or platform or the commodity(ies), whichever is higher. Pallet Surface Area Dimensions (In inches): ' +
        `${this.getFormattedPalletSurfaces()}. Commodity Surface Area Dimensions (In inches): ${this.getFormattedCommoditySurfaces()}. ` +
        `Total Pallet Surface Area is ${this.getPalletSurfacesAreaInSquareFeet().toFixed(2)} square feet and ` +
        `the total Commodity Surface Area is: ${this.getCommoditySurfacesAreaInSquareFeet().toFixed(2)} square feet. ` +
        `As a result, ${this.getPercentageOfSurfaceAreaUsed().toFixed(2)}% of the pallet's surface area was used. ` +
        `Therefore, NMFC ${this.getNMFCItem()}, ${this.getNMFCClass()} applies.`;
    } else if (this.isDensityAplicable() && !this.isSurfaceAreaAplicable()) {
      // When it is not applicable because of surface area used
      comments =
        `680 Note 2 (b) is not applicable due to: Surface area used of ${this.getPercentageOfSurfaceAreaUsed().toFixed(
          2
        )}% ` + 'is equal to or exceeding 60%.';
    } else if (!this.isDensityAplicable() && this.isSurfaceAreaAplicable()) {
      // When it is not applicable because of density
      comments =
        `680 Note 2 (b) is not applicable due to: Shipment density of ${this.getShipmentDensity().toFixed(2)} ` +
        'PCF must be greater than 0 PCF.';
    } else if (!this.isDensityAplicable() && !this.isSurfaceAreaAplicable()) {
      // When it is not applicable because of both: density and surface area used
      comments = `680 Note 2 (b) is not applicable due to: Surface area used of ${this.getPercentageOfSurfaceAreaUsed().toFixed(
        2
      )}% is equal to or exceeding 60%`;
    }

    // After the comments we add the version of the calculator
    comments += ` [${this.getItem680Version()}]`;

    this.item680Comments = comments;
  }
}
