import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { RecommendedRulesService } from '../services/recommended-rules.service';
import { RuleFilter } from '../classes/rule-filter';
import { PartyRole } from '../enums/party_role.enum';
import { AccountService } from '../../../services/account.service';
import { AppConstantsService } from '../../../services/app-constants.service';

@Component({
  selector: 'app-recommended-rules-summary',
  templateUrl: './recommended-rules-summary.component.html',
  styleUrls: ['./recommended-rules-summary.component.scss']
})
export class RecommendedRulesSummaryComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  constructor(private recommendedRulesService: RecommendedRulesService) {}

  public getAccountName() {
    return this.recommendedRulesService.getAccountName();
  }

  public getAccount() {
    let madCode = '';

    if (this.recommendedRulesService.getMadCode()) {
      madCode = this.recommendedRulesService.getMadCode().toUpperCase();
    }

    return madCode;
  }

  public filterToString(filter: RuleFilter): string {
    if (filter.getValue() && filter.getValue().acctName) {
      return AccountService.accountToString(filter.getValue());
    } else if (filter.getValue() && filter.getValue().acctBasicDetails) {
      return AccountService.accountToString(filter.getValue().acctBasicDetails.acctIdInfo);
    } else {
      return AppConstantsService.toTitleCase(filter.getValue());
    }
  }

  public isValidAcoount(): boolean {
    return this.recommendedRulesService.isAccountValid();
  }

  public getRecommendation() {
    return this.recommendedRulesService.getRecommendation();
  }

  public getFormattedParty() {
    let formattedParty = '';
    if (this.recommendedRulesService.getParty() === PartyRole.BillTo) {
      formattedParty = 'bill to';
    } else {
      formattedParty = this.recommendedRulesService.getParty();
    }

    return formattedParty.toLowerCase();
  }

  public getFilters(): Array<RuleFilter> {
    return this.recommendedRulesService.getRecommendationRuleFilters().filter((filter: RuleFilter) => {
      return filter.getIsValid() && filter.getValue();
    });
  }

  public ngOnInit() {}

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
