import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/legacy-autocomplete';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ServiceCenter } from '@xpo-ltl-2.0/sdk-location';
import { Observable, Subject, of } from 'rxjs';
import { take } from 'rxjs/internal/operators';
import { SicShiftPreference } from '../../classes/sic-shift-preference';
import { ListType } from '../../enums/list-type.enum';
import { ShiftCode, ShiftCodeDisplayMap } from '../../enums/shift-code.enum';
import { AppConstantsService } from '../../services/app-constants.service';
import { AppNavigationService } from '../../services/app-navigation.service';
import { LocationApiWrapperService } from '../../services/location/locationApiWrapperService';
import { UserPreferencesService } from '../../services/user-preferences.service';
import { UserService } from '../../services/user.service';
import { InputValidators } from '../../validators/input.validator';
import { RequestValidator } from '../../validators/request.validator';

@Component({
  selector: 'app-change-location',
  templateUrl: './change-location.component.html',
  styleUrls: ['./change-location.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangeLocationComponent implements OnInit, OnDestroy {
  static readonly SIC = 'SIC';
  static readonly SHIFT = 'Shift';
  static readonly SIC_FORM_CONTROL = 'inputSicFormControl';
  static readonly SHIFT_FORM_CONTROL = 'inputShiftFormControl';
  static readonly INVALID = 'Invalid';

  shiftCodeKeys: string[] = Object.keys(ShiftCode);
  shiftCodeEnum = ShiftCode;
  sicMap: Map<string, string> = new Map<string, string>();
  errorMessage: string;
  sicShiftFromGroup: UntypedFormGroup;
  filteredSicLocations$: Observable<string[]>;
  filteredShifts$: Observable<string[]>;
  isEveryValueValid = false;
  dataObservable: Observable<string>;
  clickedFrom: string;

  private unsubscriber$: Subject<any> = new Subject();
  private filteredSicLocations: string[];
  private filteredShifts: string[];

  @ViewChild('sicInput') sicInputField: ElementRef;
  @ViewChild('shiftInput') shiftInputField: ElementRef;
  @ViewChildren(MatAutocompleteTrigger) triggerCollection: QueryList<MatAutocompleteTrigger>;

  constructor(
    public constants: AppConstantsService,
    private dialogRef: MatDialogRef<ChangeLocationComponent>,
    private appNavigation: AppNavigationService,
    private userPreferenceService: UserPreferencesService,
    private userService: UserService,
    private locationApiWrapperService: LocationApiWrapperService,
    private formBuilder: UntypedFormBuilder
  ) {}

  get sicInput(): string {
    return this.sicShiftFromGroup?.get(ChangeLocationComponent.SIC_FORM_CONTROL).value;
  }

  get shiftInput(): string {
    return this.sicShiftFromGroup?.get(ChangeLocationComponent.SHIFT_FORM_CONTROL).value;
  }

  get isSicInputValid(): boolean {
    return this.sicShiftFromGroup?.get(ChangeLocationComponent.SIC_FORM_CONTROL).valid;
  }

  get isShiftInputValid(): boolean {
    return this.sicShiftFromGroup?.get(ChangeLocationComponent.SHIFT_FORM_CONTROL).valid;
  }

  ngOnInit(): void {
    this.dataObservable.pipe(take(1)).subscribe((aClickedFrom: string) => {
      this.clickedFrom = aClickedFrom;
      this.initFormGroup();
      this.initSicLocations();
      this.initShifts();
    });
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

  /**
   * change user's home sic/shift when user clicked OK on the change location dialog
   */
  onOkClicked(): void {
    const requestSic: string = this.sicShiftFromGroup
      .get(ChangeLocationComponent.SIC_FORM_CONTROL)
      .value?.substr(0, 3)
      .toUpperCase();

    // remove spaces for 'Night FAC' and 'Day Reship' to make API call
    const requestShiftCode: ShiftCode = this.sicShiftFromGroup
      .get(ChangeLocationComponent.SHIFT_FORM_CONTROL)
      .value?.replace(/\s+/g, '');

    RequestValidator.validateStringNotNullOrEmpty(requestSic, ChangeLocationComponent.SIC_FORM_CONTROL);
    RequestValidator.validateStringNotNullOrEmpty(requestShiftCode, ChangeLocationComponent.SHIFT_FORM_CONTROL);
    this.updateErrorMessageOnDialog(this.isSicInputValid, this.isShiftInputValid, this.sicInput, this.shiftInput);

    if (this.isSicInputValid && this.isShiftInputValid) {
      const preference: SicShiftPreference = new SicShiftPreference();
      preference.sic = requestSic;
      preference.shift = requestShiftCode;
      this.userPreferenceService.updateSicShiftUserPreference(preference);
      this.constants.setInspectionContext(
        requestSic,
        requestShiftCode,
        this.userService.currentUserFirstName,
        this.userService.currentUserLastName,
        this.userService.currentUserEmployeeId
      );
      this.appNavigation.navigateToList(ListType.Shipment);
      this.dialogRef.close();
    } else {
      // do nothing. Error message is already created in updateErrorMessage() and displayed on the dialog
    }
  }

  handleFormGroupValues() {
    const isSicMatchedWithOption: boolean = this.filteredSicLocations.includes(this.sicInput);
    const isShiftMatchedWithOption: boolean = this.filteredShifts.includes(this.shiftInput?.replace(/\s+/g, ''));
    if (!isSicMatchedWithOption) {
      this.setSic(undefined);
    }
    if (!isShiftMatchedWithOption) {
      this.setShift(undefined);
    }
  }

  /**
   * clear the shift from input box
   * @param event
   */
  onShiftClearClicked(event: PointerEvent): void {
    this.errorMessage = undefined;
    this.setShift(undefined);
  }

  /**
   * clear the sic from input box
   * @param event
   */
  onSicClearClicked(event: PointerEvent): void {
    this.errorMessage = undefined;
    this.setSic(undefined);
  }

  /**
   * build error message
   * @param formControlName
   * @param inputValue
   */
  private buildErrorMessage(formControlName: string, inputValue: string): string {
    return `${ChangeLocationComponent.INVALID} ${formControlName}: ${inputValue?.toUpperCase()}`;
  }

  /**
   * values will be shown in sic dropdown
   * @param sics
   */
  private buildSicMap(sics: ServiceCenter[]): void {
    sics.forEach((sic: ServiceCenter) => this.sicMap.set(sic.sicCd, `${sic.sicCd} - ${sic.reference.sicName}`));
  }

  /**
   * validates input in form control
   * @param validateFieldName
   */
  private formControlInputValidator(formControlName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      this.updateDropdownOptions(formControlName);
      const isInputHasMatchedOptions: boolean = !!this.getDropDownOptionsLength(formControlName);
      const validatorResp: ValidationErrors = InputValidators.validateInput(control, isInputHasMatchedOptions);

      return validatorResp;
    };
  }

  /**
   * validates inputs in the form group
   */
  private formGroupInputsValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const validationErrors: ValidationErrors = InputValidators.validateFormGroupInputs(control);

      this.isEveryValueValid = !validationErrors;
      this.updateErrorMessageOnDialog(this.isSicInputValid, this.isShiftInputValid, this.sicInput, this.shiftInput);

      return validationErrors;
    };
  }

  /**
   * return dropdown length
   * @param formControlName
   */
  private getDropDownOptionsLength(formControlName: string): number {
    if (formControlName === ChangeLocationComponent.SIC_FORM_CONTROL) {
      return this.filteredSicLocations?.length;
    } else {
      return this.filteredShifts?.length;
    }
  }

  /**
   * returns filtered dropdown options
   * @param dropdownName
   * @param baseDropdownOptions
   * @param inputValue
   */
  private getFilteredDropdownOptions(
    formControlName: string,
    baseDropdownOptions: string[],
    inputValue: string
  ): string[] {
    let filteringDropdownOptions: string[] = [];
    const filterValue: string = inputValue.replace(/\s+/g, '').toUpperCase();

    filteringDropdownOptions = baseDropdownOptions.filter((dropdownOption: string) =>
      dropdownOption.replace(/\s+/g, '').toUpperCase().includes(filterValue)
    );

    formControlName === ChangeLocationComponent.SIC_FORM_CONTROL
      ? (this.filteredSicLocations = filteringDropdownOptions)
      : (this.filteredShifts = filteringDropdownOptions);

    return filteringDropdownOptions;
  }

  /**
   * returns observable of service center.
   * makes API call if its the first time, otherwise get stored sics$
   */
  private getServiceCenters$(): Observable<ServiceCenter[]> {
    if (this.locationApiWrapperService.isEverySicLocationLoaded) {
      return this.locationApiWrapperService.sicLocations$;
    } else {
      return this.locationApiWrapperService.listOperationalServiceCenters();
    }
  }

  /**
   * initialize shift
   */
  private initShifts(): void {
    this.setFilteredShifts();
    if (this.clickedFrom === ChangeLocationComponent.SHIFT) {
      this.setShift(undefined);
      setTimeout(() => {
        this.sicInputField.nativeElement.blur();
        this.shiftInputField.nativeElement.focus();
      }, 100);
    } else {
      const initialShift: string = ShiftCodeDisplayMap.get(
        this.userPreferenceService.getSicShiftUserPreference()?.shift
      );
      this.setShift(initialShift);
    }
  }

  /**
   * initialize sic
   */
  private initSicLocations(): void {
    this.getServiceCenters$()
      .pipe(take(1))
      .subscribe((sics: ServiceCenter[]) => {
        this.buildSicMap(sics);
        this.setFilteredSicLocations();
        if (this.clickedFrom === ChangeLocationComponent.SIC) {
          this.setSic(undefined);
          setTimeout(() => {
            this.shiftInputField.nativeElement.blur();
            this.sicInputField.nativeElement.focus();
          }, 200);
        } else {
          const initialSic: string = this.sicMap.get(this.userPreferenceService.getSicShiftUserPreference()?.sic);
          this.setSic(initialSic);
        }
      });
  }

  /**
   * initialize the form group(sic and shift)
   */
  private initFormGroup(): void {
    this.sicShiftFromGroup = this.formBuilder.group(
      {
        inputSicFormControl: this.formBuilder.control(undefined, [
          Validators.required,
          this.formControlInputValidator(ChangeLocationComponent.SIC_FORM_CONTROL)
        ]),
        inputShiftFormControl: this.formBuilder.control(undefined, [
          Validators.required,
          this.formControlInputValidator(ChangeLocationComponent.SHIFT_FORM_CONTROL)
        ])
      },
      {
        validators: this.formGroupInputsValidator()
      }
    );
  }

  /**
   * set shift dropdown option
   */
  private setFilteredShifts(): void {
    this.filteredShifts = this.getFilteredDropdownOptions(
      ChangeLocationComponent.SHIFT_FORM_CONTROL,
      this.shiftCodeKeys,
      this.sicShiftFromGroup?.get(ChangeLocationComponent.SHIFT_FORM_CONTROL)?.value || ''
    );

    // observable is used in template
    this.filteredShifts$ = of(this.filteredShifts);
  }

  /**
   * set sic dropdown option
   */
  private setFilteredSicLocations(): void {
    this.filteredSicLocations = this.getFilteredDropdownOptions(
      ChangeLocationComponent.SIC_FORM_CONTROL,
      Array.from(this.sicMap.values()),
      this.sicShiftFromGroup?.get(ChangeLocationComponent.SIC_FORM_CONTROL)?.value || ''
    );

    // observable is used in template
    this.filteredSicLocations$ = of(this.filteredSicLocations);
  }

  /**
   * set shift value in the input box
   */
  setShift(shiftInput: string): void {
    this.sicShiftFromGroup.get(ChangeLocationComponent.SHIFT_FORM_CONTROL).setValue(shiftInput);
  }

  /**
   * set sic value in the input box
   */
  setSic(sicInput: string): void {
    this.sicShiftFromGroup.get(ChangeLocationComponent.SIC_FORM_CONTROL).setValue(sicInput);
  }

  /**
   * update dropdown options
   * @param formControlName
   */
  private updateDropdownOptions(formControlName: string): void {
    if (formControlName === ChangeLocationComponent.SIC_FORM_CONTROL) {
      this.setFilteredSicLocations();
    } else {
      this.setFilteredShifts();
    }
  }

  /**
   * update error message that is displayed on the dialog
   * @param isSicValid
   * @param isShiftValid
   * @param sicValue
   * @param shiftValue
   */
  private updateErrorMessageOnDialog(isSicValid: boolean, isShiftValid: boolean, sicValue: string, shiftValue: string) {
    if ((isSicValid && isShiftValid) || (isSicValid === undefined && isShiftValid === undefined)) {
      this.errorMessage = undefined;
    } else {
      if (!isSicValid && sicValue?.trim().length > 0) {
        this.errorMessage = this.buildErrorMessage(ChangeLocationComponent.SIC, sicValue);
      }
      if (!isShiftValid && shiftValue?.trim().length > 0) {
        this.errorMessage = this.buildErrorMessage(ChangeLocationComponent.SHIFT, shiftValue);
      }
    }
  }
}
