import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerGuidelinesComponent } from './customer-guidelines.component';
import { ListCustomerGuidelinesComponent } from './list-customer-guidelines/list-customer-guidelines.component';
import { MaterialModule } from '../../material.module';
import { AgGridModule } from 'ag-grid-angular';
import { CustomerGuidelinesCellRendererComponent } from './customer-guidelines-cell-renderer/customer-guidelines-cell-renderer.component';

@NgModule({
    declarations: [CustomerGuidelinesComponent, ListCustomerGuidelinesComponent, CustomerGuidelinesCellRendererComponent],
    imports: [CommonModule, MaterialModule, AgGridModule.withComponents([])],
    exports: [CustomerGuidelinesComponent, ListCustomerGuidelinesComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CustomerGuidelinesModule {}
