import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { RouterUriComponents } from '../../../enums/router-uri-components.enum';
import { RoleCheckGuard } from '../../../guards/role-check-guard';
import { SequentialGuard } from '../../../guards/sequential.guard';
import { UnreadMessagesGuard } from '../../../guards/unread-messages.guard';
import { UserProfileGuard } from '../../../guards/user-profile-guard';
import { Item680CalculatorComponent } from './item680calculator.component';

const routes: Routes = [
  {
    path: `${RouterUriComponents.ITEM_680_2B_PAGE}`,
    component: Item680CalculatorComponent,
    canActivate: [SequentialGuard],
    data: {
      // TODO: for SSO
      // sequentialGuards: [XpoAuthenticationGuard, RoleCheckGuard, UserProfileGuard, UnreadMessagesGuard]
      sequentialGuards: [RoleCheckGuard, UserProfileGuard, UnreadMessagesGuard]
    }
  },
  {
    path: `${RouterUriComponents.ITEM_680_2B_PAGE}/:pro`,
    component: Item680CalculatorComponent,
    canActivate: [SequentialGuard],
    data: {
      sequentialGuards: [RoleCheckGuard, UserProfileGuard]
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class Item680CalculatorRoutingModule {}
