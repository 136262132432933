import { Component } from '@angular/core';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { SicShiftPreference } from '../../classes/sic-shift-preference';
import { AppConstantsService } from '../../services/app-constants.service';
import { DevTestingService } from '../../services/dev-testing.service';
import { UserPreferencesService } from '../../services/user-preferences.service';
import { UserService } from '../../services/user.service';

/**
 * ONLY USED IN TEST ENVIRONMENT
 *
 * unread-broadcast-message-dialog.component.ts also has remove/restore ItemInstanceId()
 * as dev only methods
 */
@Component({
  selector: 'app-dev-only',
  templateUrl: './dev-only.component.html',
  styleUrls: ['./dev-only.component.scss']
})
export class DevOnlyComponent {
  constructor(
    private constants: AppConstantsService,
    private userPreferenceService: UserPreferencesService,
    public devTestingService: DevTestingService,
    private userService: UserService
  ) {}

  public onDeleteSicShiftUserPreferenceClicked(): void {
    const preference: SicShiftPreference = new SicShiftPreference();
    preference.sic = undefined;
    preference.shift = undefined;
    this.constants.setInspectionContext(
      preference.sic,
      preference.shift,
      this.userService.currentUserFirstName,
      this.userService.currentUserLastName,
      this.userService.currentUserEmployeeId
    );
    this.userPreferenceService.updateSicShiftUserPreference(preference);
  }

  public onManagerRolesChanged(changedSlide: MatSlideToggleChange): void {
    this.userService.devOnlyChangeManagerRole(changedSlide.checked);
    this.devTestingService.isManagerRolesChecked = changedSlide.checked;
  }
}
