import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';

import { ChangeInspectionStatusGuard } from '../../guards/change-inspection-status.guard';
import { ShipmentDetailsGuard } from '../../guards/shipment-details.guard';
import { UserProfileGuard } from '../../guards/user-profile-guard';
import { MaterialModule } from '../../material.module';
import { XpoPipesModule } from '../../pipes/xpo-pipes.module';
import { InspectionDetailsResolver } from '../../resolvers/inspection-details.resolver';
import { DocumentListModule } from '../document-list/document-list.module';
import { PhotoGalleryModule } from '../photo-gallery/photo-gallery.module';
import { ToolbarModule } from '../toolbar/toolbar.module';
import { DimensionLineComponent } from './components/dimensions/dimension-line/dimension-line.component';
import { DimensionsComponent } from './components/dimensions/dimensions.component';
import { NotesComponent } from './components/notes/notes.component';
import { ImageGalleryComponent } from './components/photos/photos.component';
import { PendingPhotosComponent } from './dialog/pending-photos/pending-photos.component';
import { InspectShipmentRoutingModule } from './inspect-shipment-routing.module';
import { InspectShipmentComponent } from './inspect-shipment.component';

@NgModule({
    imports: [
        MaterialModule,
        MatDialogModule,
        InspectShipmentRoutingModule,
        XpoPipesModule,
        ToolbarModule,
        DocumentListModule,
        PhotoGalleryModule
    ],
    declarations: [
        InspectShipmentComponent,
        NotesComponent,
        DimensionsComponent,
        DimensionLineComponent,
        ImageGalleryComponent,
        PendingPhotosComponent
    ],
    providers: [ShipmentDetailsGuard, UserProfileGuard, ChangeInspectionStatusGuard, InspectionDetailsResolver],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class InspectShipmentModule {}
