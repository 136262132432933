<div class="overview">
  <mat-expansion-panel [(expanded)]="isExpanded">
    <mat-expansion-panel-header>
      <mat-panel-title class="section-label">
        <span class="overview-title"> Overview </span>
        <button
          (click)="openShipmentDetailsManagementApp($event)"
          mat-button
          color="primary"
          class="document-list__trailer-photo-link"
        >
          SHIPMENT DETAILS
        </button>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <button
      class="previous-button"
      mat-button
      color="primary"
      (click)="viewPreviousCorrection()"
      *ngIf="showPreviousCorrection"
    >
      View Previous Correction
    </button>

    <div class="overview__row1">
      <div class="overview__row1-col1">
        <div>
          <div class="overview__row1-col1-total-pieces">
            <div class="label">Total Pieces</div>
            <div class="value">
              {{ inspectionShipmentDetails?.shipmentDetails?.totPieceCnt }}
            </div>
          </div>
          <div class="overview__row1-col1-motor-moves">
            <div class="label">Motor Moves</div>
            <div class="value">
              {{ inspectionShipmentDetails?.shipmentDetails?.motorizedPiecesCnt }}
            </div>
          </div>
          <div class="overview__row1-col1-loose-pieces">
            <div class="label">Loose Pieces</div>
            <div class="value">
              {{ inspectionShipmentDetails?.shipmentDetails?.loosePiecesCnt }}
            </div>
          </div>
        </div>
        <div>
          <div class="overview__row1-col1-total-weight">
            <div class="label">Total Weight</div>
            <div class="value">
              {{ inspectionShipmentDetails?.shipmentDetails?.totGrossWeight }}
            </div>
          </div>
          <div class="overview__row1-col1-bill-class">
            <div class="label">Cu Ft</div>
            <div class="value">
              {{ inspectionShipmentDetails?.shipmentDetails?.totGrossVolume }}
            </div>
          </div>
          <div class="overview__row1-col1-bill-status">
            <div class="label">PCF</div>
            <div class="value">
              {{ inspectionShipmentDetails?.shipmentDetails?.totDensity }}
            </div>
          </div>
        </div>
        <div>
          <div class="overview__row1-col1-bill-class">
            <div class="label">Bill Class</div>
            <div class="value">
              {{ inspectionShipmentDetails?.shipmentDetails?.billClassCd }}
            </div>
          </div>
          <div class="overview__row1-col1-bill-status">
            <div class="label">Bill Status</div>
            <div class="value">
              {{ inspectionShipmentDetails?.shipmentDetails?.billStatusCd }}
            </div>
          </div>
          <div class="overview__row1-col1-bill-status"></div>
        </div>
      </div>
      <div class="overview__row1-col2">
        <div>
          <div class="overview__row1-col2-fak">
            <div class="label">FAK</div>
            <div
              *ngFor="
                let fakText of inspectionShipmentDetails?.shipmentDetails?.agreementFakText?.toString().split(' ')
              "
            >
              <div class="value">{{ fakText }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="horizontal-divider" style="width: 2%; margin-bottom: 16px"></div>
      <div class="overview__row1-col3">
        <div class="label">History</div>
        <div
          class="overview__row1-col3-history"
          *ngFor="let detail of inspectionShipmentDetails?.previousInspectionDetails; let i = index"
        >
          <div fxLayout="row">
            <i class="material-icons overview__row1-col3-history-check">check_circle_outline</i>
            <div fxLayout="column">
              <div class="overview__row1-col3-history-status">
                {{ detail?.inspectionStatusCd | inspectionStatusCodePipe }}
              </div>
              <div class="label">
                by {{ detail?.inspectionContext?.inspectorFirstNm }} {{ detail?.inspectionContext?.inspectorLastNm }} at
                {{ detail?.inspectionContext?.inspectionSic }} on
                {{ detail?.lastUpdateDateTime | date : 'medium' }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div
      *ngFor="let commodity of inspectionShipmentDetails?.shipmentDetails?.commodity; let i = index"
      class="overview__row2"
    >
      <div class="overview__row2-pieces">
        <div class="label">Pieces</div>
        <div class="value">{{ commodity.pieceCnt }}</div>
      </div>
      <div class="overview__row2-packaging">
        <div class="label">Packaging</div>
        <div class="value">{{ commodity.packageCd }}</div>
      </div>
      <div class="overview__row2-weight">
        <div class="label">Weight</div>
        <div class="value">{{ commodity.totGrossWeight }}</div>
      </div>
      <div class="overview__row2-nmfc">
        <div class="label">NMFC</div>
        <div class="value nmfc-search-link">
          <a (click)="openNmftaSite(commodity.nmfcItemCd)">
            {{ commodity.nmfcItemCd }}
          </a>
        </div>
      </div>
      <div class="overview__row2-class">
        <div class="label">Class</div>
        <div class="value">{{ commodity.nmfcClass }}</div>
      </div>
      <div class="overview__row2-description">
        <div class="label">Commodity Description</div>
        <div class="value">{{ commodity.desc }}</div>
      </div>
    </div>
  </mat-expansion-panel>
</div>
