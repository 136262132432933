import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { RegionInfo, XpoLtlAuthenticationService } from '@xpo-ltl/ngx-auth';
import { XpoShellRoute } from '@xpo-ltl/ngx-ltl-core/shell';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AccountUrls } from './enums/account-urls.enum';
import { IndicatorName } from './enums/indicator-name.enum';
import { RouteLabels, RouterUriComponents } from './enums/router-uri-components.enum';
import { AppConfigManagerService } from './services/app-config-manager.service';
import { AppConstantsService } from './services/app-constants.service';
import { AppRouteHistoryService } from './services/app-route-history.service';
import { EmailService } from './services/email/email.service';
import { HardwareService } from './services/hardware/hardware-service';
import { PhotoUploadService } from './services/photo-upload.service';
import { UserService } from './services/user.service';
import { XpoDialogManagerService } from './services/xpo-dialog-manager.service';
import { XpoAccountPopoverConfig } from '@xpo-ltl/ngx-ltl-core/account-popover';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, OnDestroy {
  build: string;
  accountPopoverConfig: XpoAccountPopoverConfig;
  isAuthorizedUser: boolean;
  releaseNotesLink: string;
  readonly AccountUrls = AccountUrls;

  private unsubscriber$: Subject<any> = new Subject();

  // we're using routes$ for testing so we can switch Manager ver and Inspector ver.
  private routesSubject = new BehaviorSubject<XpoShellRoute[]>([]);
  routes$ = this.routesSubject.asObservable();

  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private authService: XpoLtlAuthenticationService,
    public appConstantsService: AppConstantsService,
    public hardwareService: HardwareService,
    public userService: UserService,
    private appConfigManagerService: AppConfigManagerService,
    private emailService: EmailService,
    private dialogManager: XpoDialogManagerService,
    private routeHistoryService: AppRouteHistoryService,
    // ******************** Important!!! Need to bring this in to start the Photo Upload Service.
    private photoUpload: PhotoUploadService // <---- Don't remove
  ) {
    this.iconsInit();

    this.build = this.appConfigManagerService.getBuildVersion();
    const region = this.appConfigManagerService.getRegion();
    this.releaseNotesLink = this.appConfigManagerService.getReleaseNotesLink();
    this.authService.initAuthSetup$(region).subscribe((info: RegionInfo) => {
      // Extra config or API calls here if needed
    });
  }

  ngOnInit(): void {
    this.userService.isUserLoggedIn$
      .pipe(
        filter((isUserLoggedIn: boolean) => isUserLoggedIn === true),
        takeUntil(this.unsubscriber$)
      )
      .subscribe((isUserLoggedIn: boolean) => {
        this.handleLoggedInUser();
      });

    this.routeHistoryService.listenRoutingEvt();
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

  onFeedbackClick(): void {
    this.emailService.openEmailWindow();
  }

  private handleLoggedInUser(): void {
    this.isAuthorizedUser = this.userService.isCurrentUserAuthorizedForInspectionsApp();
    if (this.isAuthorizedUser) {
      this.setAccountPopover();
      this.setRoutes();
      this.setDynatraceUserIdentity();
    } else {
      this.userService.logNotAuthorizedUser();
      this.dialogManager.showNotAuthorizedDialog();
    }
  }

  private setAccountPopover(): void {
    this.accountPopoverConfig = this.userService.setAccountPopover();
  }

  private setDynatraceUserIdentity(): void {
    this.userService.setDynatraceUserIdentity();
  }

  /**
   * We can call this method if we use <xpo-account-popover>,
   * however, we're using <xpo-auth-user-profile> to display the profile photo so we cannot use this.
   * <xpo-auth-user-profile> logoff automatically and its handled from the library.
   *
   * We're saving this method in case if we get feedback and users want a warning before
   * they actually logoff.
   */
  // private displayLogOffDialog(): void {
  //   // checks for Pending Photos to upload before logging out.
  //   let message = '';
  //   if (this.hardwareService.getPendingPhotoCount() > 0) {
  //     message = ConfirmMessage.InspectionSessionLogoutPhotoUploadInProgressWarning;
  //   } else {
  //     message = ConfirmMessage.InspectionSessionLogoutMessage;
  //   }
  //   this.dialogManager.showConfirmCancelDialog(message).subscribe((response) => {
  //     if (response) {
  //       // Removing the local storage keys.
  //       localStorage.clear();

  //       this.authenticationService.signOut$().pipe(take(1)).subscribe();
  //     }
  //   });
  // }

  private setRoutes(): void {
    this.userService.userRoleChanged$.pipe(takeUntil(this.unsubscriber$)).subscribe((isRoleChanged: boolean) => {
      if (isRoleChanged) {
        const routes: XpoShellRoute[] = [];
        for (const labelKey of Object.keys(RouteLabels)) {
          // Broadcast Message tab is available for Managers
          if (
            this.userService.isCurrentUserManager ||
            labelKey !== Object.keys(RouteLabels)[Object.values(RouteLabels).indexOf(RouteLabels.BROADCAST_MESSAGES)]
          ) {
            routes.push(this.buildRoute(labelKey));
          }
        }
        this.routesSubject.next(routes);
      }
    });
  }

  private buildRoute(labelName: string): XpoShellRoute {
    return {
      label: RouteLabels[labelName],
      path: `/${RouterUriComponents[labelName]}`,
      queryParamsHandling: 'merge'
    };
  }

  private iconsInit() {
    this.iconRegistry.addSvgIcon(
      'xpo-logo',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/xpo_logo.svg')
    );
    this.iconRegistry.addSvgIcon(
      'gear-icon',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/gear_icon.svg')
    );
    this.iconRegistry.addSvgIcon(
      'refresh-icon',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/wni_control-refresh-2x.svg')
    );
    this.iconRegistry.addSvgIcon(
      'back-button-icon',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/wni_control-back-2x.svg')
    );
    this.iconRegistry.addSvgIcon(
      'item-680-icon',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/icon-xpo-680.svg')
    );
    this.iconRegistry.addSvgIcon(
      'action-button-icon',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/wni_control-action-2x.svg')
    );
    this.iconRegistry.addSvgIcon(
      'trash-icon',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/wni_control-trash-2x.svg')
    );
    this.iconRegistry.addSvgIcon(
      'photo-icon',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/ic_photo_camera_24px.svg')
    );
    this.iconRegistry.addSvgIcon(
      'back-arrow-icon',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/wni_back-arrow.svg')
    );
    this.iconRegistry.addSvgIcon(
      'forward-arrow-icon',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/wni_forward-arrow.svg')
    );
    this.iconRegistry.addSvgIcon(
      IndicatorName.ACCURACY,
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/wni_accuracy.svg')
    );
    this.iconRegistry.addSvgIcon(
      IndicatorName.FOOD,
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/wni_food.svg')
    );
    this.iconRegistry.addSvgIcon(
      IndicatorName.FREEZABLE,
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/wni_freezable.svg')
    );
    this.iconRegistry.addSvgIcon(
      IndicatorName.GUARANTEED,
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/wni_guaranteed.svg')
    );
    this.iconRegistry.addSvgIcon(
      IndicatorName.HAZMAT,
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/wni_hazmat.svg')
    );
    this.iconRegistry.addSvgIcon(
      IndicatorName.HEADLOAD,
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/wni_headload.svg')
    );
    this.iconRegistry.addSvgIcon(
      IndicatorName.POISON,
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/wni_poison.svg')
    );
    this.iconRegistry.addSvgIcon(
      'document-type-BLAT',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/wni_thumb-blat-3x.svg')
    );
    this.iconRegistry.addSvgIcon(
      'document-type-BL',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/wni_thumb-bol-3x.svg')
    );
    this.iconRegistry.addSvgIcon(
      'document-type-CUST',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/wni_thumb-customs-3x.svg')
    );
    this.iconRegistry.addSvgIcon(
      'document-type-WRFO',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/wni_thumb-inspphotos-3x.svg')
    );
    this.iconRegistry.addSvgIcon(
      'document-type-NCIC',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/wni_thumb-nmfcinspcert-3x.svg')
    );
    this.iconRegistry.addSvgIcon(
      'document-type-WI',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/wni_thumb-wghtcorrcert-3x.svg')
    );
    this.iconRegistry.addSvgIcon(
      'document-type-DMNR',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/wni_thumb-dim-photo-3x.svg')
    );
    this.iconRegistry.addSvgIcon(
      'yellow-clock',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/wni_thumb-clock-yellow-1x.svg')
    );
    this.iconRegistry.addSvgIcon(
      'upload-clock',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/wni_thumb-clock-1x.svg')
    );
    this.iconRegistry.addSvgIcon(
      'upload-cloud',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/wni_thumb-cloud-1x.svg')
    );
    this.iconRegistry.addSvgIcon(
      'filter',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/wni_filter.svg')
    );
    this.iconRegistry.addSvgIcon(
      'torch-on',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/cam-light-on-tablet.svg')
    );
    this.iconRegistry.addSvgIcon(
      'torch-off',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/cam-light-off-tablet.svg')
    );
  }
}
