import { InspectionShipment } from '@xpo-ltl/sdk-inspections';
import { InspectionState } from '../enums/inspection-state.enum';
import { ListName } from '../enums/list-name.enum';
import { SourceOfRecommendation } from '../enums/source-of-recommendation.enum';
import { AppConstantsService } from '../services/app-constants.service';
import { DateUtils } from './date-utils';
import { LocationBreakdown, LocationUtil } from './location-utils';
import { ProNumber } from './pronumber';

export class PlanningListItem {
  rank: string;
  eta: Date;
  locationWithInspectionPriority: string;
  location: string;
  breakDoor: number;
  loadDoor: number;
  proNumber: string;
  shipperName: string;
  originSic: string;
  consigneeName: string;
  destSic: string;
  totalPieceCnt: number;
  motorizedPieceCnt: number;
  loosePieceCnt: number;
  totalGrossWeight: number;
  totalGrossVolume: number;
  density: number;
  fak: string[];
  nmfc: string[];
  nmfcClass: string[];
  commodity: string[];
  customerInstructions: string[] = [];
  removedSinceRefresh: boolean;
  addedSinceRefresh: boolean;
  transactionDate: Date;
  priorityShipment = false; // Priority shipment is those that will be highlighted in Orange
  trailerStatus: string;
  trailerNumber: string;
  locationPriority: number;

  // required to update cust instructions
  shipperMadCd: string;
  consigneeMadCd: string;
  bil2MadCd: string;

  constructor(inspectionShipment: InspectionShipment) {
    if (!inspectionShipment) {
      return;
    }

    this.rank = inspectionShipment.rankOfRecommendation ? inspectionShipment.rankOfRecommendation.toString() : '';
    if (inspectionShipment.sourceOfRecommendation?.trim().length > 0) {
      this.rank += `-${SourceOfRecommendation[inspectionShipment.sourceOfRecommendation]}`;
    }

    if (inspectionShipment.etaTmst) {
      this.eta = new Date(
        new Date(inspectionShipment.etaTmst).toLocaleString(DateUtils.locale, { timeZone: DateUtils.timezone })
      );
    }
    const locationBreakdown: LocationBreakdown = LocationUtil.buildLocationBreakdown(
      inspectionShipment.shipmentLocation
    );
    this.locationWithInspectionPriority = inspectionShipment.shipmentLocation;
    this.location = locationBreakdown?.shipmentLocation;
    this.locationPriority = locationBreakdown?.locationPriority;
    this.trailerNumber = inspectionShipment?.trailerNbr;
    this.trailerStatus = locationBreakdown?.trailerStatus;

    !isNaN(+inspectionShipment.breakDoor)
      ? (this.breakDoor = +inspectionShipment.breakDoor)
      : (this.breakDoor = locationBreakdown.breakDoorNumber);
    !isNaN(+inspectionShipment.loadDoor) ? (this.loadDoor = +inspectionShipment.loadDoor) : (this.loadDoor = undefined);

    this.proNumber = inspectionShipment.shipmentId
      ? new ProNumber(inspectionShipment.shipmentId.proNumber).formatProNumber()
      : '';
    this.shipperName = inspectionShipment.shipperAcctId ? inspectionShipment.shipperAcctId.acctName : '';
    this.originSic = inspectionShipment.originSic;
    this.consigneeName = inspectionShipment.consigneeAcctId ? inspectionShipment.consigneeAcctId.acctName : '';
    this.destSic = inspectionShipment.destSic;
    this.totalPieceCnt = inspectionShipment.totPieceCnt;
    this.motorizedPieceCnt = inspectionShipment.motorizedPiecesCnt;
    this.loosePieceCnt = inspectionShipment.loosePiecesCnt;
    this.totalGrossWeight = inspectionShipment.totGrossWeight;
    this.totalGrossVolume = inspectionShipment.totGrossVolume;
    this.removedSinceRefresh = inspectionShipment.removedSinceRefresh;
    this.addedSinceRefresh = inspectionShipment.addedSinceRefresh;
    this.transactionDate = inspectionShipment.lastUpdateDateTime;

    this.shipperMadCd = inspectionShipment.shipperAcctId.acctMadCd;
    this.consigneeMadCd = inspectionShipment.consigneeAcctId.acctMadCd;
    this.bil2MadCd = inspectionShipment.bil2AcctId.acctMadCd;

    let density = 0;
    if (
      inspectionShipment.totGrossWeight &&
      inspectionShipment.totGrossVolume &&
      inspectionShipment.totGrossVolume > 0
    ) {
      density = AppConstantsService.calculateDensity(
        inspectionShipment.totGrossWeight,
        inspectionShipment.totGrossVolume
      );
    }
    this.density = density;
    this.fak = inspectionShipment.agreementFakText;

    this.nmfc = new Array<string>();
    this.nmfcClass = new Array<string>();
    this.commodity = new Array<string>();

    if (inspectionShipment.commodity) {
      inspectionShipment.commodity.forEach((commodityItem, index) => {
        if (commodityItem.nmfcItemCd) {
          this.nmfc.push(commodityItem.nmfcItemCd);
        } else {
          this.nmfc.push('');
        }
        if (commodityItem.nmfcClass) {
          this.nmfcClass.push(PlanningListItem.fixClass(commodityItem.nmfcClass));
        } else {
          this.nmfcClass.push('');
        }
        if (commodityItem.desc) {
          this.commodity.push(commodityItem.desc);
        } else {
          this.commodity.push('');
        }
      });
    }

    // Is this a priority Shipment?  We check all States even though not all matter here to Match the Inspection Lists
    // If you change here, you probably need to change the same method in the InspectionListItem class
    if (inspectionShipment.shipmentLocation) {
      const locationNumber = inspectionShipment.shipmentLocation.toString().substring(0, 1);
      const inspectionState = inspectionShipment.inspectionStatusCd;
      if (locationNumber >= '1' && locationNumber <= '4') {
        if (
          !inspectionState ||
          inspectionState === InspectionState.RECOMMENDED ||
          inspectionState === InspectionState.FLAGGED ||
          inspectionState === InspectionState.IN_PROGRESS ||
          inspectionState === InspectionState.DISMISSED ||
          inspectionState === InspectionState.NO_STATUS
        ) {
          this.priorityShipment = true;
        }
      }
    }
  }

  public static fixClass(value: string): string {
    if (!value) {
      return '';
    } else if (value === '925') {
      return '92.5';
    } else if (value === '775') {
      return '77.5';
    } else {
      return value;
    }
  }

  public static sortList(planningListItems: PlanningListItem[], listName: ListName) {
    if (!planningListItems) {
      return;
    }

    planningListItems.sort((shipment1, shipment2) => {
      const location1: string = shipment1.locationWithInspectionPriority
        ? shipment1.locationWithInspectionPriority
        : '';
      const location2: string = shipment2.locationWithInspectionPriority
        ? shipment2.locationWithInspectionPriority
        : '';

      if (location1 === location2) {
        const shipper1: string = shipment1.shipperName ? shipment1.shipperName : '';
        const shipper2: string = shipment2.shipperName ? shipment2.shipperName : '';
        return shipper1.localeCompare(shipper2);
      } else {
        return location1.localeCompare(location2);
      }
    });
  }

  public static mapInspectionShipments(inspectionShipments: InspectionShipment[]): PlanningListItem[] {
    if (inspectionShipments) {
      return inspectionShipments.map((shipment) => new PlanningListItem(shipment));
    }
    return [];
  }
}
