import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { XpoAuthenticationGuard } from '@xpo/ngx-auth';

import { RouterUriComponents } from '../../enums/router-uri-components.enum';
import { RoleCheckGuard } from '../../guards/role-check-guard';
import { SequentialGuard } from '../../guards/sequential.guard';
import { ShipmentDetailsGuard } from '../../guards/shipment-details.guard';
import { UnreadMessagesGuard } from '../../guards/unread-messages.guard';
import { UserProfileGuard } from '../../guards/user-profile-guard';
import { ShipmentDetailsComponent } from './shipment-details.component';

const routes: Routes = [
  {
    path: `${RouterUriComponents.SHIPMENT_DETAILS_PAGE}/:pro`,
    component: ShipmentDetailsComponent,
    canActivate: [SequentialGuard],
    data: {
      sequentialGuards: [
        XpoAuthenticationGuard,
        RoleCheckGuard,
        UserProfileGuard,
        UnreadMessagesGuard,
        ShipmentDetailsGuard
      ]
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ShipmentDetailsRoutingModule {}
