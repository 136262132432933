import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { InspectionShipment } from '@xpo-ltl/sdk-inspections';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { ComparatorUtils } from '../../classes/comparator-utils';
import { DateUtils } from '../../classes/date-utils';
import { Photo } from '../../classes/photo';
import { PhotoId } from '../../classes/photo-id';
import { ProNumber } from '../../classes/pronumber';
import { ConfigManagerProperties } from '../../enums/config-manager-properties.enum';
import { DocTypes } from '../../enums/doc-type.enum';
import { DocumentListTitles } from '../../enums/document-list-titles.enum';
import { DocumentManagementService } from '../../services/document-management-service';
import { PhotoGalleryService } from '../photo-gallery/photo-gallery.service';
import { DocumentListImpl } from './document-list-impl';

@Component({
  selector: 'app-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentListComponent implements OnChanges {
  constructor(
    private configManagerService: ConfigManagerService,
    private documentManagement: DocumentManagementService,
    private photoGalleryService: PhotoGalleryService
  ) {}

  @Input()
  public shipmentDetails: InspectionShipment;

  @Input()
  public docTypes: DocTypes[];

  @Input()
  public title: DocumentListTitles;

  @Input()
  public photoIds: PhotoId[];

  public readonly DocTypes = DocTypes;
  public readonly DocumentListTitles = DocumentListTitles;
  public proNumber: ProNumber;
  public isExpanded = false;
  public showTrailerPhotoAppLink = false;
  private documentPhotosSubject = new BehaviorSubject<Photo[]>([]);
  public documentPhotos$ = this.documentPhotosSubject.asObservable();

  private archivedDocumentsGalleryData: DocumentListImpl;
  private lastProNumberLoaded: string;

  ngOnChanges() {
    this.archivedDocumentsGalleryData = new DocumentListImpl(this.documentManagement);
    if (this.shipmentDetails?.shipmentId) {
      const proNumber = this.shipmentDetails.shipmentId.proNumber;

      if (this.lastProNumberLoaded !== proNumber) {
        this.proNumber = new ProNumber(proNumber);
      }
    }
    this.isExpanded = false;
    this.showTrailerPhotoAppLink = this.title === DocumentListTitles.OPERATIONS_PHOTOS;

    // clear the subject because it has previous PRO photos if we search PRO on the Shipment Detail page
    this.documentPhotosSubject.next(<Photo[]>[]);
  }

  showPhotoGallery(currentPhoto: Photo) {
    this.photoGalleryService
      .showPhotoGalleryDialog(
        this.archivedDocumentsGalleryData,
        this.docTypes,
        currentPhoto,
        this.documentPhotosSubject.getValue()
      )
      .pipe(take(1))
      .subscribe();
  }

  loadDocumentsOnExpanded(): void {
    this.photoIds?.forEach((photoId: PhotoId) => {
      const foundPhoto: Photo = this.documentPhotosSubject
        .getValue()
        .find((photo: Photo) => photo.id.id === photoId.id);
      if (!foundPhoto?.isLoaded) {
        this.documentManagement
          .getShipmentImagedDocuments(photoId)
          .pipe(take(1))
          .subscribe((photo: Photo) => {
            this.documentPhotosSubject.next(
              this.documentPhotosSubject
                .getValue()
                .concat([photo])
                .sort((photo1, photo2) => ComparatorUtils.stringNumberCompare(photo1.id?.id, photo2.id?.id))
            );
          });
      }
    });
  }

  openTrailerPhotoApp() {
    let imageviewerAuthAppRootUri = this.configManagerService.getSetting<string>(
      ConfigManagerProperties.imageviewerAuthAppRootUri
    );
    if (imageviewerAuthAppRootUri && imageviewerAuthAppRootUri[imageviewerAuthAppRootUri.length - 1] !== '/') {
      imageviewerAuthAppRootUri += '/';
    }
    const trailerPhotoAppBaseUrl = `${imageviewerAuthAppRootUri}image-search`;
    const proNumberParam = `searchKey=${this.lastProNumberLoaded}`;
    const today = new Date();
    const yesterday = DateUtils.getDayBefore(today);
    const beginDateParam = `beginDate=${DateUtils.getFormatDate(yesterday, 'MM-DD-YYYY')}`;
    const endDateParam = `endDate=${DateUtils.getFormatDate(today, 'MM-DD-YYYY')}`;
    const url = `${trailerPhotoAppBaseUrl}?${proNumberParam}&${beginDateParam}&${endDateParam}`;
    window.open(url);
  }
}
