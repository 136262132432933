<div class="shipment-details">
  <app-shipment-details-toolbar></app-shipment-details-toolbar>
  <app-overview [inspectionShipmentDetails]="inspectionShipmentDetails"></app-overview>
  <app-location-movement
    [shipmentDetails]="shipmentDetails"
    [shipmentLocationDetails]="shipmentLocationDetails"
  ></app-location-movement>
  <app-customer-instructions [inspectionShipmentDetails]="inspectionShipmentDetails"></app-customer-instructions>
  <app-bill-details [shipmentDetails]="shipmentDetails"></app-bill-details>
  <app-pricing [shipmentDetails]="shipmentDetails"></app-pricing>
  <app-previous-inspection [inspectionShipmentDetails]="inspectionShipmentDetails"></app-previous-inspection>
  <app-document-list
    [shipmentDetails]="shipmentDetails"
    [title]="DocumentListTitles.DOCUMENT"
    [docTypes]="documentDocTypes"
    [photoIds]="documentPhotoIds$ | async"
  ></app-document-list>
  <app-document-list
    [shipmentDetails]="shipmentDetails"
    [title]="DocumentListTitles.OPERATIONS_PHOTOS"
    [docTypes]="operationsPhotosDocTypes"
    [photoIds]="operationPhotoIds$ | async"
  ></app-document-list>
  <app-document-list
    [shipmentDetails]="shipmentDetails"
    [title]="DocumentListTitles.INSPECTIONS_PHOTOS"
    [docTypes]="inspectionsPhotosDocTypes"
    [photoIds]="inspectionPhotoIds$ | async"
  ></app-document-list>
</div>
