import { Component, OnDestroy, OnInit } from '@angular/core';
import { AcctId } from '@xpo-ltl/sdk-common';
import {
  CustomerGuideline,
  GetInspectionShipmentDetailsResp,
  ListCustomerGuidelinesQuery
} from '@xpo-ltl/sdk-inspections';
import { EMPTY, Subject, Subscription } from 'rxjs';
import { catchError, take, takeUntil } from 'rxjs/operators';
import { CustomerGuidelines } from '../../../classes/customer-guidelines';
import { ResponseValidation } from '../../../classes/ResponseValidation';
import { DialogAction } from '../../../enums/dialog-actions.enum';
import { ErrorMessageActions } from '../../../enums/error-message-actions.enum';
import { AccountService } from '../../../services/account.service';
import { AppConstantsService } from '../../../services/app-constants.service';
import { ErrorHandlingService } from '../../../services/error-handling.service';
import { CustomerGuidelinesService } from '../services/customer-guidelines.service';

@Component({
  selector: 'app-customer-guidelines-form',
  templateUrl: './customer-guidelines-form.component.html',
  styleUrls: ['./customer-guidelines-form.component.scss']
})
export class CustomerGuidelinesFormComponent implements OnInit, OnDestroy {
  private subject$ = new Subject();
  private observable = this.subject$.asObservable();
  private subscription = new Subscription();
  private unsubscriber$: Subject<any> = new Subject();

  madCodeIsValid: ResponseValidation;
  account: AcctId;

  validForm: boolean;
  customerGuidelineInformation: CustomerGuideline;
  showError: Boolean;
  errorValidations: string;
  existingGuideline: boolean;

  madCode: string;

  constructor(
    public customerGuidelinesService: CustomerGuidelinesService,
    private accountService: AccountService,
    private appConstants: AppConstantsService,
    private errorHandling: ErrorHandlingService
  ) {
    this.madCode = this.customerGuidelinesService.getMadCode();
  }

  ngOnInit() {
    this.madCodeIsValid = new ResponseValidation(true, '');

    this.subscription = this.observable.pipe().subscribe(() => {
      this.searchMadCode();
    });
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

  setAccountName(accountName: string): void {
    this.customerGuidelinesService.setAccountName(accountName);
  }

  getAccountName(): string {
    return this.customerGuidelinesService.getAccountName();
  }

  setMadCode(madCode: string): void {
    this.customerGuidelinesService.setMadCode(madCode);
  }

  getMadCode(): string {
    return this.customerGuidelinesService.getMadCode();
  }

  setGuideline(guideline: string): void {
    this.customerGuidelinesService.setGuideline(guideline);
  }

  getGuideline(): string {
    return this.customerGuidelinesService.getGuideline();
  }

  getMadCodeIsValid(): boolean {
    return this.customerGuidelinesService.getMadCodeIsValid();
  }

  setAccountId(accountId: string): void {
    this.customerGuidelinesService.setAccountId(accountId);
  }

  getAccountId(): string {
    return this.customerGuidelinesService.getAccountId();
  }

  searchMadCode(): void {
    this.setMadCode(AccountService.formatMadCodeAccount(this.madCode.trim()));
    this.madCodeIsValid = this.accountService.getAccount(this.madCode.trim());

    if (this.madCodeIsValid.getIsValid()) {
      this.madCodeIsValid
        .getResponse()
        .pipe(
          take(1),
          catchError((error) => {
            this.madCodeIsValid.setIsValid(false);
            this.madCodeIsValid.setMessage('Account not found');
            this.madCodeIsValid.setResponse(undefined);
            this.customerGuidelinesService.setMadCodeIsValid(false);
            this.setAccountId('');
            this.setAccountName('');
            this.customerGuidelinesService.setGuideline('');
            this.customerGuidelinesService.setCustomerHasGuideline(false);

            const errorMessage: string = this.errorHandling.buildDisplayErrorMessage(
              ErrorMessageActions.GETTING,
              `Customer account`
            );
            console.error(this.errorHandling.buildResponseErrorMessage(errorMessage, error));

            return EMPTY;
          })
        )
        .subscribe((acct) => {
          this.account = acct;
          this.setAccountName(this.account.acctName);
          this.setAccountId(this.account.acctInstId);
          this.validateAccount();
          this.getGuidelineInformation(this.getAccountId());
        });
    } else {
      this.account = undefined;
      this.setAccountId('');
      this.setAccountName('');
    }
  }

  getGuidelineInformation(customerId: string): void {
    const query = new ListCustomerGuidelinesQuery();
    query.acctInstId = customerId;
    this.appConstants.inspectionShipmentDetails$
      .pipe(
        takeUntil(this.unsubscriber$),
        catchError((error) => {
          this.errorHandling.handleResponseError(error, ErrorMessageActions.GETTING, `Customer guidelines`);

          return EMPTY;
        })
      )
      .subscribe((shipmentDetails: GetInspectionShipmentDetailsResp) => {
        if (shipmentDetails?.currentInspectionDetails?.custSpecificInspGuidelines?.length > 0) {
          this.customerGuidelinesService.setCustomerGuideline(
            shipmentDetails.currentInspectionDetails.custSpecificInspGuidelines[0]
          );
          this.customerGuidelinesService.setCustomerHasGuideline(true);
          this.customerGuidelinesService.setAction(DialogAction.EDIT);
        } else {
          this.customerGuidelinesService.setCustomerGuideline(this.buildCustomerGuideline());
          this.customerGuidelinesService.setCustomerHasGuideline(false);
          this.madCodeIsValid.setIsValid(true);
          this.customerGuidelinesService.setMadCodeIsValid(true);
          this.customerGuidelinesService.setGuideline('');
          this.customerGuidelinesService.setAction(DialogAction.CREATE);
        }
      });
  }

  private buildCustomerGuideline(): CustomerGuidelines {
    const customerGuidelineNew = new CustomerGuidelines();
    customerGuidelineNew.acctInstId = this.getAccountId();
    customerGuidelineNew.acctName = this.getAccountName();
    customerGuidelineNew.acctMadCd = this.getMadCode();
    customerGuidelineNew.guideline = '';
    return customerGuidelineNew;
  }

  madCodeChanged() {
    this.subject$.next();
  }

  validateAccount(): void {
    if (this.account) {
      this.customerGuidelinesService.setMadCodeIsValid(this.madCodeIsValid.getIsValid());
    }
  }

  validateInputMadCode() {
    if (!this.madCode) {
      this.madCodeIsValid.setMessage('The MAD Code cannot be empty');
    } else if (this.madCode.length !== 11) {
      this.madCodeIsValid.setMessage('MAD Code must be 11 characters');
    }
  }
}
