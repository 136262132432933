import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Injectable()
export class ErrorHandlingService {
  private snackBarDuration = 5000;

  constructor(private snackBar: MatSnackBar) {}

  public buildResponseErrorMessage(message: string, error: any): string {
    if (error?.error?.message) {
      message = `${message} ${error.error.message}`;
    } else if (error?.message) {
      message = `${message}  ${error.message}`;
    } else if (error?.constructor?.name === 'ProgressEvent') {
      message = `${message} Communication Error!`;
    } else if (error) {
      message = `${message} ${error}`;
    }

    return message;
  }

  public buildGeneralErrorMessage(message: string, error: any = null): string {
    if (error?.error?.message) {
      message = `${message} ${error.error.message}`;
    } else if (error?.error) {
      message = `${message} ${error.error}`;
    } else if (error?.message) {
      message = `${message} ${error.message}`;
    } else if (error) {
      message = `${message} ${error}`;
    }

    return message;
  }

  public buildDisplayErrorMessage(action: string, sourceName: string): string {
    return `Error ${action} ${sourceName}!`;
  }

  public showErrorMessage(message: string, reason?: string): void {
    if (reason) {
      this.snackBar.open(`${message}: ${reason}`, 'OK', { duration: this.snackBarDuration });
    } else {
      this.snackBar.open(`${message}`, 'OK', { duration: this.snackBarDuration });
    }
  }

  public handleResponseError(error: any, action: string, sourceName: string, isMoreInfoError: boolean = false): void {
    const errorLocation: string = this.buildDisplayErrorMessage(action, sourceName);
    let snackBarMessage: string;

    if (isMoreInfoError) {
      snackBarMessage = this.buildResponseErrorMessage(errorLocation, error?.error?.moreInfo?.[0]?.message);
    } else {
      snackBarMessage = this.buildResponseErrorMessage(errorLocation, error);
    }

    this.showErrorMessage(snackBarMessage);
    console.error(this.buildResponseErrorMessage(errorLocation, error));
  }
}
