import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { CustomerGuidelineDialogComponent } from '../../dialogs/customer-guideline-dialog/customer-guideline-dialog.component';
import { DialogAction } from '../../enums/dialog-actions.enum';
import { RouteLabels } from '../../enums/router-uri-components.enum';
import { GridSettingsService } from '../../services/grid-settings/grid-settings.service';
import { ListCustomerGuidelinesComponent } from './list-customer-guidelines/list-customer-guidelines.component';
import { CustomerGuidelinesService } from './services/customer-guidelines.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-customer-guidelines',
  templateUrl: './customer-guidelines.component.html',
  styleUrls: ['./customer-guidelines.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomerGuidelinesComponent implements OnInit, OnDestroy {
  public selectedListName = undefined;
  public selectedCount = 0;
  public columnsSorted: boolean;
  public columnsFiltered: boolean;

  @ViewChild(ListCustomerGuidelinesComponent, { static: true })
  public customerGuidelinesList: ListCustomerGuidelinesComponent;

  public toolbarTitle: string;
  private unsubscriber$: Subject<any> = new Subject();

  constructor(
    public router: Router,
    private gridSettings: GridSettingsService,
    private changeDetector: ChangeDetectorRef,
    public customerGuidelinesService: CustomerGuidelinesService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.selectedListName = RouteLabels.CUSTOMER_GUIDELINES;
    this.columnsFiltered = this.gridSettings.hasFilterGridSettings(this.selectedListName);
    this.columnsSorted = this.gridSettings.hasSortGridSettings(this.selectedListName);

    // NOTE: This timeout is to force the table to resize correctly when rendered (after becoming visible).
    setTimeout(() => {
      this.resetRowHeights();
    });
  }

  ngOnDestroy() {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

  public selectedCountChanged(count: number) {
    this.selectedCount = count;
    this.toolbarTitle = count + ' Item(s) selected from ' + this.selectedListName + ' List!';
  }

  public gridSettingsChanged() {
    this.columnsFiltered = this.gridSettings.hasFilterGridSettings(this.selectedListName);
    this.columnsSorted = this.gridSettings.hasSortGridSettings(this.selectedListName);
    this.changeDetector.detectChanges();
  }

  public clearGridFilters() {
    this.gridSettings.clearFilterGridSettings(this.selectedListName, this.customerGuidelinesList.gridOptions);
  }

  public clearGridSorts() {
    this.gridSettings.clearSortGridSettings(this.selectedListName, this.customerGuidelinesList.gridOptions);
  }

  public clearGridSortAndFilters() {
    this.clearGridSorts();
    this.clearGridFilters();
  }

  public resetGridSettings() {
    this.gridSettings.resetGridSettings(this.selectedListName, this.customerGuidelinesList.gridOptions);
  }

  public loadGridSettings() {
    this.gridSettings.loadListGridSettings(this.selectedListName, this.customerGuidelinesList.gridOptions);
  }

  public saveGridSettings() {
    const list = this.getSelectedList();
    if (list?.gridOptions) {
      this.gridSettings.saveListGridSettings(this.selectedListName);
    }
  }

  public getSelectedList(): any {
    return this.customerGuidelinesList;
  }

  public resetRowHeights() {
    const list = this.getSelectedList();
    if (list && list.gridOptions && list.gridOptions.api) {
      list.gridOptions.api.resetRowHeights();
    }
  }

  public refreshList() {
    this.customerGuidelinesList.getCustomerGuidelines();
  }

  public onAddCustomerGuidelineClicked(data: Object) {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.autoFocus = true;
    matDialogConfig.disableClose = false;
    matDialogConfig.minWidth = '90%';
    matDialogConfig.minHeight = '90%';
    matDialogConfig.data = {
      title: 'Add Customer Guideline',
      action: DialogAction.CREATE,
      guideline: data
    };
    this.customerGuidelinesService.setCustomerHasGuideline(false);
    const matDialogRef = this.dialog.open(CustomerGuidelineDialogComponent, matDialogConfig);
    matDialogRef.afterClosed().subscribe((reload) => {
      if (reload) {
        this.customerGuidelinesList.clearList();
        this.customerGuidelinesList.getCustomerGuidelines();
      }
      this.customerGuidelinesService.clear();
    });

    matDialogRef.backdropClick().subscribe(() => {
      this.customerGuidelinesService.clear();
    });
  }
}
