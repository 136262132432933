import { PhotoGalleryService } from './photo-gallery.service';
import { Photo } from '../../classes/photo';
import { PhotoId } from '../../classes/photo-id';

export class PhotoGalleryData {
  public serviceRef: PhotoGalleryService;
  public currentPhotoId: PhotoId;

  public photoCount = 0;
  public photoNumber = 0;
  public currentPhoto: Photo;
  public previousPhotoId: PhotoId;
  public nextPhotoId: PhotoId;
}
