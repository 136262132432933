import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { GetInspectionShipmentDetailsResp, GetShipmentLocationDetailsResp } from '@xpo-ltl/sdk-inspections';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, take } from 'rxjs/internal/operators';
import { ProNumber } from '../classes/pronumber';
import { AppConstantsService } from '../services/app-constants.service';
import { AppNavigationService } from '../services/app-navigation.service';
import { ShipmentDetailsService } from '../services/shipment-details.service';

@Injectable()
export class ShipmentDetailsGuard implements CanActivate {
  constructor(
    private shipmentDetailsService: ShipmentDetailsService,
    private appConstants: AppConstantsService,
    private appNavigation: AppNavigationService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    const pro = new ProNumber(route.params['pro']);
    let sicCd: string;
    if (this.appConstants?.inspectionContext) {
      sicCd = this.appConstants.inspectionContext?.inspectionSic;
    }

    if (!pro.isValid() || !sicCd) {
      this.appConstants.setInspectionShipmentDetails(null); // make sure shipmentDetails are set to null
      this.appConstants.setShipmentLocationDetails(null);
      this.appNavigation.navigateToList();
      return false;
    }

    return this.shipmentDetailsService.getInspectionShipmentDetails(pro).pipe(
      distinctUntilChanged(),
      map(
        (getInspectionShipmentDetailsResp: GetInspectionShipmentDetailsResp) => {
          let parentPro: ProNumber;
          if (getInspectionShipmentDetailsResp?.shipmentDetails?.shipmentId?.proNumber) {
            parentPro = new ProNumber(getInspectionShipmentDetailsResp.shipmentDetails.shipmentId.proNumber);
          }
          this.appConstants.setInspectionShipmentDetails(getInspectionShipmentDetailsResp);

          this.shipmentDetailsService
            .getShipmentLocationDetails(parentPro)
            .pipe(take(1))
            .subscribe((response: GetShipmentLocationDetailsResp) => {
              this.appConstants.setShipmentLocationDetails(response);
            });

          return true;
        },
        () => {
          // We need to navigate on an error back to the list page, in case we started from the shipment details already
          this.appNavigation.navigateToList(); // Navigate back to list
          return false;
        }
      )
    );
  }
}
