<mat-dialog class="pendingPhotosWrapper">
  <h2 md-dialog-title>Please Wait.... Uploading Photos to DMS</h2>
  <mat-dialog-content class="confirm-cancel-container_content">
    <div>
      Waiting for {{ hardwareService.pendingPhotoCount$ | async }} more photo(s) to be uploaded to DMS <br />
      <br />
      Please don't refresh or close your browser!
    </div>
  </mat-dialog-content>
</mat-dialog>
