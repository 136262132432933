import { Injectable } from '@angular/core';
import { PieceDimensions } from '@xpo-ltl/sdk-inspections';
import { Comment } from '@xpo-ltl/sdk-common';
import { AppStorageService } from './app-storage.service';
import { ProNumber } from '../classes/pronumber';
import { DmsOpCode } from '../enums/dms-op-code.enum';

@Injectable()
export class InspectionLocalStorageService {
  private readonly INSPECTION_NOTES = 'InspectionNotes-';
  private readonly INSPECTION_DIMENSIONS = 'InspectionDimensions-';
  private readonly INSPECTION_WEIGHT = 'InspectionWeight-';
  private readonly ROUTING_HISTORY = 'RoutingHistory';

  constructor(private storageService: AppStorageService) {}

  clearInspectionData(proNumber: ProNumber, deletePhotosFlag: boolean = false) {
    if (proNumber) {
      localStorage.removeItem(this.INSPECTION_NOTES + proNumber.formatProNumber());
      localStorage.removeItem(this.INSPECTION_DIMENSIONS + proNumber.formatProNumber());
      localStorage.removeItem(this.INSPECTION_WEIGHT + proNumber.formatProNumber());
      if (deletePhotosFlag) {
        this.storageService.deletePhotosByProAndStatusCd(proNumber, DmsOpCode.NEWLY_ADDED_PHOTO).subscribe();
      }
    }
  }

  setInspectionNotes(proNumber: ProNumber, inspectionNotes: Comment) {
    if (proNumber) {
      localStorage.setItem(this.INSPECTION_NOTES + proNumber.formatProNumber(), JSON.stringify(inspectionNotes));
    }
  }

  setInspectionDimensions(proNumber: ProNumber, inspectionDimensions: PieceDimensions[]) {
    if (proNumber) {
      localStorage.setItem(
        this.INSPECTION_DIMENSIONS + proNumber.formatProNumber(),
        JSON.stringify(inspectionDimensions)
      );
    }
  }

  setInspectionWeight(proNumber: ProNumber, inspectionWeight: number) {
    if (proNumber) {
      localStorage.setItem(this.INSPECTION_WEIGHT + proNumber.formatProNumber(), inspectionWeight.toString());
    }
  }

  setRoutingHistory(histories: string[]) {
    localStorage.setItem(this.ROUTING_HISTORY, histories?.toString());
  }

  getInspectionNotes(proNumber: ProNumber): Comment {
    let comment: Comment;
    if (proNumber) {
      const json = localStorage.getItem(this.INSPECTION_NOTES + proNumber.formatProNumber());
      if (json) {
        comment = JSON.parse(json);
      }
    }
    return comment;
  }

  getInspectionDimensions(proNumber: ProNumber): PieceDimensions[] {
    let pieceDimensions;
    if (proNumber) {
      const json = localStorage.getItem(this.INSPECTION_DIMENSIONS + proNumber.formatProNumber());
      if (json) {
        pieceDimensions = JSON.parse(json);
      }
    }
    return pieceDimensions;
  }

  getInspectionWeight(proNumber: ProNumber): number {
    let weight;

    if (proNumber) {
      weight = localStorage.getItem(this.INSPECTION_WEIGHT + proNumber.formatProNumber());
    }

    return weight;
  }

  getRoutingHistory(): string[] {
    const routingHistory: string = localStorage.getItem(this.ROUTING_HISTORY);
    let routingHistories: string[] = [];
    if (routingHistory?.trim().length > 0) {
      routingHistories = routingHistory.split(',');
    }

    return routingHistories;
  }
}
