export enum ConfigManagerProperties {
  accessEmployeeIds = 'accessEmployeeIds',
  accessJobRoleCodes = 'accessJobRoleCodes',
  accessRoles = 'accessRoles',
  apiUrl = 'apiUrl',
  appMetadataEndpoint = 'appMetadataEndpoint',
  appName = 'appName',
  bolApiEndpoint = 'bolApiEndpoint',
  buildVersion = 'buildVersion',
  csShipmentListRegionUrl = 'csShipmentListRegionUrl',
  elasticSearchApiEndpoint = 'elasticSearchApiEndpoint',
  errorToastDuration = 'errorToastDuration',
  feedbackSubject = 'feedbackSubject',
  feedbackToAddress = 'feedbackToAddress',
  freightFlowApiEndpoint = 'freightFlowApiEndpoint',
  freightmovementApiEndpoint = 'freightmovementApiEndpoint',
  imageviewerAuthAppRootUri = 'imageviewerAuthAppRootUri',
  infrastructureApiEndpoint = 'infrastructureApiEndpoint',
  invoiceApiEndpoint = 'invoiceApiEndpoint',
  item680note2bVersion = 'item680note2bVersion',
  locationApiEndpoint = 'locationApiEndpoint',
  locationV2ApiEndpoint = 'locationV2ApiEndpoint',
  loggedInUserRoot = 'loggedInUserRoot',
  loggingApiEndpoint = 'loggingApiEndpoint',
  membershipApiEndpoint = 'membershipApiEndpoint',
  photoCleanupStrategyDays = 'photoCleanupStrategyDays',
  pricingApiEndpoint = 'pricingApiEndpoint',
  production = 'production',
  recommendedRulesEndpoint = 'recommendedRulesEndpoint',
  region = 'region',
  releaseNotesLink = 'releaseNotesLink',
  scopeOptions = 'scopeOptions',
  secretToken = 'secretToken',
  shipmentApiEndpoint = 'shipmentApiEndpoint',
  shipmentV2ApiEndpoint = 'shipmentV2ApiEndpoint',
  successToastDuration = 'successToastDuration',
  userContextPath = 'userContextPath'
}
