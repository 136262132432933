import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InspectionShipment, UpdateInspectionStatusResp } from '@xpo-ltl/sdk-inspections';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { ProNumber } from '../../../../classes/pronumber';
import { ConfirmMessage, buildInspectedNotCorrectedWarningMessage } from '../../../../enums/confirm-message.enum';
import { ErrorMessageActions } from '../../../../enums/error-message-actions.enum';
import { InspectionState } from '../../../../enums/inspection-state.enum';
import { AppConstantsService } from '../../../../services/app-constants.service';
import { AppNavigationService } from '../../../../services/app-navigation.service';
import { ErrorHandlingService } from '../../../../services/error-handling.service';
import { InspectionLocalStorageService } from '../../../../services/inspection-local-storage.service';
import { ShipmentDetailsService } from '../../../../services/shipment-details.service';
import { XpoDialogManagerService } from '../../../../services/xpo-dialog-manager.service';

@Component({
  selector: 'app-shipment-details-toolbar',
  templateUrl: './shipment-details-toolbar.component.html',
  styleUrls: ['./shipment-details-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShipmentDetailsToolbarComponent implements OnInit, OnDestroy {
  constructor(
    private shipmentDetailsService: ShipmentDetailsService,
    public appConstants: AppConstantsService,
    public router: Router,
    private route: ActivatedRoute,
    public dialog: XpoDialogManagerService,
    private changeDetection: ChangeDetectorRef,
    private appNavigation: AppNavigationService,
    private inspectionStorageService: InspectionLocalStorageService,
    private errorHandlingService: ErrorHandlingService
  ) {}

  public proNumber: string;

  private subscription: Subscription;

  public isRevoked = false;

  ngOnInit() {
    this.proNumber = '';
    this.subscription = this.appConstants.inspectionShipmentDetails$.subscribe((inspectionShipmentDetails) => {
      if (inspectionShipmentDetails?.shipmentDetails) {
        const shipmentDetails: InspectionShipment = inspectionShipmentDetails?.shipmentDetails;
        if (shipmentDetails.shipmentId?.proNumber?.trim().length > 0) {
          this.proNumber = new ProNumber(shipmentDetails.shipmentId.proNumber).formatProNumber();
        } else {
          this.errorHandlingService.showErrorMessage(
            this.errorHandlingService.buildDisplayErrorMessage(ErrorMessageActions.GETTING, 'PRO number')
          );
        }
        this.isRevoked = shipmentDetails?.removedSinceRefresh;
        this.changeDetection.detectChanges();
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public backButtonClicked() {
    this.appNavigation.navigateToList();
  }

  public inspectShipment() {
    this.appNavigation.navigateToInspection(new ProNumber(this.proNumber));
  }

  public editInspection() {
    const proNumbers = [new ProNumber(this.proNumber)];
    this.shipmentDetailsService
      .changeStatus(proNumbers, InspectionState.EDITING, false)
      .pipe(take(1))
      .subscribe((resp: UpdateInspectionStatusResp) => {
        this.appNavigation.navigateToInspection(new ProNumber(this.proNumber));
      });
  }

  public markInspectedNotCorrected() {
    const currentState: InspectionState = this.appConstants.inspectionState;
    const message: string = buildInspectedNotCorrectedWarningMessage(currentState);

    this.dialog
      .showConfirmCancelDialog(message)
      .pipe(take(1))
      .subscribe((response) => {
        if (response) {
          this.changeStatus(new ProNumber(this.proNumber), InspectionState.INSPECTED_NOT_CORRECTED, true, true);
        }
      });
  }

  public sendToRecommendedList() {
    this.changeStatus(new ProNumber(this.proNumber), InspectionState.RECOMMENDED);
  }

  public sendToFlaggedList() {
    this.changeStatus(new ProNumber(this.proNumber), InspectionState.FLAGGED);
  }

  public dismissShipment() {
    this.changeStatus(new ProNumber(this.proNumber), InspectionState.DISMISSED);
  }

  public markCorrected() {
    this.dialog
      .showConfirmCancelDialog(ConfirmMessage.InspectedCorrected)
      .pipe(take(1))
      .subscribe((response) => {
        if (response) {
          this.changeStatus(new ProNumber(this.proNumber), InspectionState.CORRECTION_SUBMITTED);
        }
      });
  }

  private changeStatus(
    proNumber: ProNumber,
    inspectionState: InspectionState,
    warningAcceptedInd: boolean = false,
    deleteInspectionData: boolean = false
  ) {
    const proNumbers = [proNumber];
    this.shipmentDetailsService
      .changeStatus(proNumbers, inspectionState, warningAcceptedInd)
      .pipe(take(1))
      .subscribe((resp: UpdateInspectionStatusResp) => {
        let reloadList = true;
        if (resp && resp.validationErrors && resp.validationErrors.length > 0) {
          reloadList = false;
          const validationError = resp.validationErrors[0];
          if (validationError && validationError.message) {
            // update the shipment (in case it changed). This will also update the status
            this.shipmentDetailsService.getInspectionShipmentDetails(proNumber).pipe(take(1)).subscribe();
            if (validationError.message.startsWith('Confirmation Required')) {
              this.showConfirmDialog(proNumber, inspectionState);
            } else {
              this.dialog.showStatusChangeDialog(resp);
            }
          }
        }
        if (deleteInspectionData) {
          this.inspectionStorageService.clearInspectionData(proNumber, true);
        }
        if (reloadList) {
          this.appConstants.changedProNbrs.add(proNumber.formatProNumber());
          this.appNavigation.navigateToList();
        }
      });
  }

  // showConfirmationDialog is only called for In-Progress inspections, warning the user
  // that it will delete the inspection (and associated data).
  // May have to change later if other types of statuses could call this.
  private showConfirmDialog(proNumber: ProNumber, inspectionState: InspectionState) {
    this.dialog
      .showConfirmCancelDialog(
        ConfirmMessage.InspectionWillBeDeletedWarning,
        InspectionState.IN_PROGRESS, // Note: Not actually used in the Warning Message
        inspectionState // Note: Not actually used in the Warning Message
      )
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          this.changeStatus(proNumber, inspectionState, true, true);
        }
      });
  }

  public keepShipment() {
    this.shipmentDetailsService
      .manuallyAddShipment(new ProNumber(this.proNumber))
      .pipe(take(1))
      .subscribe(() => {
        // assume the revoke happened and just update the isRevoked variable
        this.isRevoked = false;
        this.changeDetection.detectChanges();
      });
  }
}
