import { UpdateInspectionStatusResp } from '@xpo-ltl/sdk-inspections';
import { DataValidationError } from '@xpo-ltl/sdk-common';
import { ProNumber } from './pronumber';
import { ConfirmMessage } from '../enums/confirm-message.enum';
import { InspectionState, toInspectionStateDisplayName } from '../enums/inspection-state.enum';

export class ChangeStatusErrorData {
  public errorMap = new Array<string>();

  constructor(response: UpdateInspectionStatusResp = null) {
    if (response) {
      response.validationErrors.forEach(error => {
        const message = ChangeStatusErrorData.buildMessage(error);
        if (message) {
          this.add(message, new ProNumber(error.fieldValue).formatProNumber());
        }
      });
    }
  }

  private static buildChangeStatusMessage(errorMessage: string, confirmMessage: ConfirmMessage): string {
    let fromState = 'Unknown';
    let toState = 'Unknown';
    let state = 'Unknown';
    if (errorMessage) {
      const split = errorMessage.split("' to '");
      if (split && split.length === 2) {
        const fromStateStartPos = split[0].lastIndexOf("'");
        const toStateEndPos = split[1].indexOf("'");
        if (fromStateStartPos >= 0) {
          fromState = split[0].substring(fromStateStartPos + 1);
        }
        if (toStateEndPos >= 0) {
          toState = split[1].substring(0, toStateEndPos);
        }
      } else {
        // this is for single state messages
        const startPos = errorMessage.indexOf("'");
        if (startPos >= 0) {
          const endPos = errorMessage.indexOf("'", startPos + 1);
          if (endPos > startPos) {
            state = errorMessage.substring(startPos + 1, endPos);
          }
        }
      }
    }

    if (confirmMessage === ConfirmMessage.ShipmentsNotMovedWarning) {
      if (fromState === InspectionState.INSPECTED) {
        confirmMessage = ConfirmMessage.InspectionAlreadySubmitted;
      }
    }
    let message = <string>confirmMessage;
    message = message.toString().replace('{fromState}', toInspectionStateDisplayName(<InspectionState>fromState));
    message = message.toString().replace('{toState}', toInspectionStateDisplayName(<InspectionState>toState));
    message = message.toString().replace('{state}', toInspectionStateDisplayName(<InspectionState>state));

    return message;
  }

  public static buildMessage(validationError: DataValidationError): string {
    let message: string;
    if (validationError && validationError.message) {
      if (validationError.message.indexOf('Error changing state') >= 0) {
        message = ChangeStatusErrorData.buildChangeStatusMessage(
          validationError.message,
          ConfirmMessage.ShipmentsNotMovedWarning
        );
      } else if (validationError.message.indexOf('Confirmation Required') >= 0) {
        message = ChangeStatusErrorData.buildChangeStatusMessage(
          validationError.message,
          ConfirmMessage.ShipmentsNotMovedWarning
        );
      } else if (validationError.message.indexOf('Inspection already in') >= 0) {
        message = ChangeStatusErrorData.buildChangeStatusMessage(
          validationError.message,
          ConfirmMessage.ShipmentsAlreadyInStatusWarning
        );
      } else {
        message = validationError.message;
      }
    }

    return message;
  }

  public add(header: string, detail: string) {
    let detailArray = this.errorMap[header];
    if (!detailArray) {
      detailArray = new Array<string>();
      this.errorMap[header] = detailArray;
    }
    detailArray.push(detail);
  }
}
