import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { XpoPipesModule } from '../../pipes/xpo-pipes.module';
import { MaterialModule } from '../../material.module';
import { DocumentListComponent } from './document-list.component';

const components = [DocumentListComponent];

@NgModule({
    declarations: components,
    imports: [MaterialModule, XpoPipesModule],
    exports: components,
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DocumentListModule {}
