import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { GetInspectionDetailsResp, InspectionShipment } from '@xpo-ltl/sdk-inspections';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/internal/operators';
import { ProNumber } from '../classes/pronumber';
import { InspectionLocalStorageService } from '../services/inspection-local-storage.service';
import { ShipmentDetailsService } from '../services/shipment-details.service';

@Injectable()
export class InspectionDetailsResolver implements Resolve<InspectionShipment> {
  constructor(
    private shipmentDetailsService: ShipmentDetailsService,
    private inspectionLocalStorage: InspectionLocalStorageService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<InspectionShipment> | Promise<InspectionShipment> | InspectionShipment {
    const pro = route.params['pro'];
    if (!pro) {
      return null;
    }

    return this.shipmentDetailsService.getInspectionDetails(pro).pipe(
      take(1),
      map(
        (inspectionDetailsResp: GetInspectionDetailsResp) => {
          if (
            !inspectionDetailsResp ||
            !inspectionDetailsResp.inspectionDetails ||
            inspectionDetailsResp.inspectionDetails.length === 0
          ) {
            return null;
          }

          const inspectionShipment = inspectionDetailsResp.inspectionDetails[0];
          const pieceDimensions = this.inspectionLocalStorage.getInspectionDimensions(new ProNumber(pro));
          if (pieceDimensions) {
            inspectionShipment.inspectorPieceDimensions = pieceDimensions;
          }

          const inspectionNotes = this.inspectionLocalStorage.getInspectionNotes(new ProNumber(pro));
          if (inspectionNotes) {
            inspectionShipment.inspectionNote = inspectionNotes;
          }

          return inspectionShipment;
        },
        () => {
          return null;
        }
      )
    );
  }
}
