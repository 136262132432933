export enum ListName {
  Flagged = 'Flagged',
  Recommended = 'Recommended',
  Inspected = 'Inspected',
  Completed = 'Completed',
  Dismissed = 'Dismissed',
  Lookup = 'Lookup',
  AddPros = 'AddPros',
  RecommendationRules = 'Recommendation Rules',
  CustomerGuidelines = 'Customer Guidelines',
  BroadcastMessages = 'Broadcast Messages',
  PickupRequests = 'Pickup Requests'
}
