import { InspectionState, toInspectionStateDisplayName } from './inspection-state.enum';

export enum ConfirmMessage {
  InspectionSubmitMessage = 'Are you sure you want to submit this Inspection?',
  InspectedNotCorrected = 'Are you sure you want to submit as Inspected Not Corrected?',
  InspectionSubmitError = 'Error Inspecting Shipment! Please try again.',
  InspectedCorrected = 'Are you sure you want to submit as Corrected?',
  InspectionWillBeDeletedWarning = 'This Shipment has an inspection {inEditOrProgress}. Any dimensions, notes and photos not submitted from the inspection page will be deleted.<br><br>Are you sure you want to continue?',
  ShipmentsNotMovedWarning = 'Shipment could not be moved from {fromState} to {toState}.',
  ShipmentsAlreadyInStatusWarning = 'Shipment already in {state} status.',
  InspectionAlreadySubmitted = 'Inspection has already been submitted. Please complete existing Inspection.',
  InspectionSessionLogoutMessage = 'You are about to log off from Inspections application.<br><br>Do you wish to continue?',
  InspectionSessionLogoutPhotoUploadInProgressWarning = 'You are about to log off from the Inspections application and you have photos pending upload.' +
    '<br><br>If you log off the upload process will stop and any remaining photos will not be uploaded until you log back into the application.' +
    '<br><br>Do you wish to continue?'
}

export function buildInspectedNotCorrectedWarningMessage(currentState: string | InspectionState): string {
  switch (currentState) {
    case InspectionState.IN_PROGRESS:
    case InspectionState.EDITING:
      return buildInspectionWillBeDeletedWarning(currentState);
    case InspectionState.RECOMMENDED:
    case InspectionState.FLAGGED:
    case InspectionState.INSPECTED:
    case InspectionState.DISMISSED:
      return ConfirmMessage.InspectedNotCorrected;
    default:
      return '';
  }
}

export function buildInspectionWillBeDeletedWarning(inEditOrProgressState: string | InspectionState): string {
  const warningMessage: string = ConfirmMessage.InspectionWillBeDeletedWarning.toString().replace(
    '{inEditOrProgress}',
    toInspectionStateDisplayName(<InspectionState>inEditOrProgressState)
  );
  return warningMessage;
}

export function buildShipmentsNotMovedWarning(
  fromState: string | InspectionState,
  toState: string | InspectionState
): string {
  const warningMessage: string = ConfirmMessage.ShipmentsNotMovedWarning.toString()
    .replace('{fromState}', toInspectionStateDisplayName(<InspectionState>fromState))
    .replace('{toState}', toInspectionStateDisplayName(<InspectionState>toState));
  return warningMessage;
}

export function buildShipmentsAlreadyInStatusWarning(currentState: string | InspectionState): string {
  const warningMessage: string = ConfirmMessage.ShipmentsAlreadyInStatusWarning.toString().replace(
    '{state}',
    toInspectionStateDisplayName(<InspectionState>currentState)
  );
  return warningMessage;
}
