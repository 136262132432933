import { Pipe, PipeTransform } from '@angular/core';
import { DateUtils } from '../classes/date-utils';
import { AppConstantsService } from '../services/app-constants.service';

@Pipe({
  name: 'timeFormatPipe'
})
export class TimeFormatPipe implements PipeTransform {
  transform(eta: number): string {
    if (eta) {
      const current_dateTime = new Date(
        new Date(eta).toLocaleString(DateUtils.locale, { timeZone: DateUtils.timezone })
      );
      return AppConstantsService.getFormattedDateTime(current_dateTime);
    } else {
      return '';
    }
  }
}
