import { Component, Input } from '@angular/core';
import { GetInspectionShipmentDetailsResp } from '@xpo-ltl/sdk-inspections';

@Component({
  selector: 'app-customer-instructions',
  templateUrl: './customer-instructions.component.html',
  styleUrls: ['./customer-instructions.component.scss']
})
export class CustomerInstructionsComponent {
  constructor() {}

  @Input()
  inspectionShipmentDetails: GetInspectionShipmentDetailsResp;

  isExpanded = true;
}
