import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecommendationsRulesComponent } from './recommendation-rules.component';
import { ListRecommendedRulesComponent } from './list-recommended-rules/list-recommended-rules.component';
import { MaterialModule } from '../../material.module';
import { AgGridModule } from 'ag-grid-angular';
import { RuleFilterComponent } from './rule-filter/rule-filter.component';
import { RecommendedRulesFiltersComponent } from './recommended-rules-filters/recommended-rules-filters.component';

@NgModule({
    declarations: [
        RecommendationsRulesComponent,
        ListRecommendedRulesComponent,
        RuleFilterComponent,
        RecommendedRulesFiltersComponent
    ],
    imports: [CommonModule, MaterialModule, AgGridModule.withComponents([])],
    exports: [
        RecommendationsRulesComponent,
        ListRecommendedRulesComponent,
        RuleFilterComponent,
        RecommendedRulesFiltersComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class RecommendationRulesModule {}
