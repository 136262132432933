import { Injectable } from '@angular/core';
import { DataOptions } from '@xpo-ltl/data-api';
import {
  CreateArchiveDmsDocumentPath,
  CreateArchiveDmsDocumentResp,
  CreateArchiveDmsDocumentRqst,
  CreateBroadcastMessageResp,
  CreateBroadcastMessageRqst,
  CreateCustomerGuidelineResp,
  CreateCustomerGuidelineRqst,
  CreateRecommendationRuleResp,
  CreateRecommendationRuleRqst,
  CustomerGuideline,
  DeleteCustomerGuidelinePath,
  DeleteRecommendationRulePath,
  EnrichedCustomerGuideline,
  InspectionsApiService,
  ListCustomerGuidelinesQuery,
  ListCustomerGuidelinesResp,
  ListRecommendationRulesBySicPath,
  ListRecommendationRulesBySicResp,
  BroadcastMessage,
  UpdateCustomerGuidelinePath,
  UpdateCustomerGuidelineResp,
  UpdateCustomerGuidelineRqst,
  UpdateRecommendationRulePath,
  UpdateRecommendationRuleResp,
  UpdateRecommendationRuleRqst,
  ListBroadcastMessagesResp,
  ListBroadcastMessagesQuery,
  ListUserBroadcastMessagesPath,
  ListUserBroadcastMessagesResp
} from '@xpo-ltl/sdk-inspections';
import { EMPTY, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CustomerGuidelines } from '../../classes/customer-guidelines';
import { CustomerRecommendedRule } from '../../classes/customer-recommended-rule';
import { Photo } from '../../classes/photo';
import { ErrorMessageActions } from '../../enums/error-message-actions.enum';
import { RequestValidator } from '../../validators/request.validator';
import { ErrorHandlingService } from '../error-handling.service';

@Injectable({ providedIn: 'root' })
export class InspectionsApiWrapperService {
  private readonly CUSTOMER_GUIDELINES: string = 'Customer Guidelines';
  private readonly RECOMMENDATION_RULES: string = 'Recommendation Rules';
  private readonly ARCHIVE_DMS_DOCUMENT: string = 'Archive DMS Document';
  private readonly BROADCAST_MESSAGE: string = 'Broadcast Message';

  constructor(private errorHandling: ErrorHandlingService, private inspectionsApiService: InspectionsApiService) {}

  createCustomerGuideline(aCustomerGuideline: CustomerGuideline): Observable<CreateCustomerGuidelineResp> {
    RequestValidator.validateObjectNotUndefinedOrEmpty(aCustomerGuideline, 'Customer Guideline');

    const createCustomerGuidelineRqst = new CreateCustomerGuidelineRqst();
    createCustomerGuidelineRqst.customerGuideline = aCustomerGuideline;

    return this.inspectionsApiService.createCustomerGuideline(createCustomerGuidelineRqst).pipe(
      catchError((error) => {
        this.errorHandling.handleResponseError(error, ErrorMessageActions.CREATING, this.CUSTOMER_GUIDELINES);

        return EMPTY;
      })
    );
  }

  listCustomerGuidelines(): Observable<CustomerGuidelines[]> {
    return this.inspectionsApiService.listCustomerGuidelines(new ListCustomerGuidelinesQuery()).pipe(
      map((resp: ListCustomerGuidelinesResp) =>
        resp.customerGuideline?.map((item: EnrichedCustomerGuideline) => {
          const itemView: CustomerGuidelines = new CustomerGuidelines();
          itemView.setData(item);
          return itemView;
        })
      ),
      catchError((error) => {
        this.errorHandling.handleResponseError(error, ErrorMessageActions.GETTING, this.CUSTOMER_GUIDELINES);

        return EMPTY;
      })
    );
  }

  getCustomerGuidelineByCustomerId(customerId: string): Observable<EnrichedCustomerGuideline> {
    RequestValidator.validateStringNotNullOrEmpty(customerId, 'Customer ID');

    const query = new ListCustomerGuidelinesQuery();
    query.acctInstId = customerId;

    return this.inspectionsApiService.listCustomerGuidelines(query).pipe(
      map((response: ListCustomerGuidelinesResp) => {
        if (response?.customerGuideline?.length > 0) {
          return response.customerGuideline[0];
        }
      }),
      catchError((error) => {
        this.errorHandling.handleResponseError(
          error,
          ErrorMessageActions.GETTING,
          `${this.CUSTOMER_GUIDELINES} By Customer ID`
        );

        return EMPTY;
      })
    );
  }

  updateCustomerGuideline(
    aCustomerGuideline: CustomerGuideline,
    aAcctInstId: string
  ): Observable<UpdateCustomerGuidelineResp> {
    RequestValidator.validateObjectNotUndefinedOrEmpty(aCustomerGuideline, 'Customer Guideline');
    RequestValidator.validateStringNotNullOrEmpty(aAcctInstId, 'Account Instance ID');

    const request = new UpdateCustomerGuidelineRqst();
    const path = new UpdateCustomerGuidelinePath();
    request.customerGuideline = aCustomerGuideline;
    path.acctInstId = aAcctInstId;

    return this.inspectionsApiService.updateCustomerGuideline(request, path).pipe(
      catchError((error) => {
        this.errorHandling.handleResponseError(error, ErrorMessageActions.UPDATING, this.CUSTOMER_GUIDELINES);

        return EMPTY;
      })
    );
  }

  deleteCustomerGuideline(aAcctInstId: string): Observable<void> {
    RequestValidator.validateStringNotNullOrEmpty(aAcctInstId, 'Account Instance ID');

    const deleteCustomerGuidelinePath = new DeleteCustomerGuidelinePath();
    deleteCustomerGuidelinePath.acctInstId = aAcctInstId;

    return this.inspectionsApiService.deleteCustomerGuideline(deleteCustomerGuidelinePath).pipe(
      catchError((error) => {
        this.errorHandling.handleResponseError(error, ErrorMessageActions.DELETING, this.CUSTOMER_GUIDELINES);

        return EMPTY;
      })
    );
  }

  listRecommendationRulesBySic(inspectionSic: string): Observable<CustomerRecommendedRule[]> {
    RequestValidator.validateStringNotNullOrEmpty(inspectionSic, 'Inspection SIC');

    const path: ListRecommendationRulesBySicPath = new ListRecommendationRulesBySicPath();
    path.sicCd = inspectionSic;

    return this.inspectionsApiService.listRecommendationRulesBySic(path).pipe(
      map((resp: ListRecommendationRulesBySicResp) =>
        resp.recommendationRules.map((rule: CustomerRecommendedRule) => {
          const newRuleToShow: CustomerRecommendedRule = new CustomerRecommendedRule();

          newRuleToShow.map(rule);
          return newRuleToShow;
        })
      ),
      catchError((error) => {
        this.errorHandling.handleResponseError(
          error,
          ErrorMessageActions.LOADING,
          `List ${this.RECOMMENDATION_RULES} By SIC`
        );

        return EMPTY;
      })
    );
  }

  createRecommendationRule(
    aRecommendationRule: CustomerRecommendedRule,
    options?: DataOptions
  ): Observable<CreateRecommendationRuleResp> {
    RequestValidator.validateObjectNotUndefinedOrEmpty(aRecommendationRule, 'Recommendation Rule');

    const isMoreInfoError = true;
    const createRecommendationRuleRqst = new CreateRecommendationRuleRqst();
    createRecommendationRuleRqst.recommendationRule = aRecommendationRule;

    return this.inspectionsApiService.createRecommendationRule(createRecommendationRuleRqst, options).pipe(
      catchError((error) => {
        this.errorHandling.handleResponseError(
          error,
          ErrorMessageActions.CREATING,
          this.RECOMMENDATION_RULES,
          isMoreInfoError
        );

        return EMPTY;
      })
    );
  }

  updateRecommendationRule(
    ruleInstId: string,
    aRecommendationRule: CustomerRecommendedRule,
    options?: DataOptions
  ): Observable<UpdateRecommendationRuleResp> {
    RequestValidator.validateStringNotNullOrEmpty(ruleInstId, 'Recommendation Rule ID');
    RequestValidator.validateObjectNotUndefinedOrEmpty(aRecommendationRule, 'Recommendation Rule');

    const request = new UpdateRecommendationRuleRqst();
    const path = new UpdateRecommendationRulePath();
    path.customerRcmndRuleId = ruleInstId;
    request.recommendationRule = aRecommendationRule;

    return this.inspectionsApiService.updateRecommendationRule(request, path, options).pipe(
      catchError((error) => {
        this.errorHandling.handleResponseError(error, ErrorMessageActions.UPDATING, this.RECOMMENDATION_RULES);

        return EMPTY;
      })
    );
  }

  deleteRecommendationRule(aCustomerRcmndRuleId: number): Observable<void> {
    RequestValidator.validateNumberIsPositiveOrZeroInteger(aCustomerRcmndRuleId, 'Recommendation Rule ID number');

    const path = new DeleteRecommendationRulePath();
    path.customerRcmndRuleId = aCustomerRcmndRuleId;

    return this.inspectionsApiService.deleteRecommendationRule(path).pipe(
      catchError((error) => {
        this.errorHandling.handleResponseError(error, ErrorMessageActions.DELETING, this.RECOMMENDATION_RULES);

        return EMPTY;
      })
    );
  }

  createArchiveDmsDocument(photo: Photo, aInspectionSic: string): Observable<CreateArchiveDmsDocumentResp> {
    RequestValidator.validateStringNotNullOrEmpty(aInspectionSic, 'Inspection Sic');
    RequestValidator.validateObjectNotUndefinedOrEmpty(photo, 'Photo');

    const path: CreateArchiveDmsDocumentPath = new CreateArchiveDmsDocumentPath();
    const request: CreateArchiveDmsDocumentRqst = new CreateArchiveDmsDocumentRqst();
    path.proNumber = photo.id?.proNumber?.formatProNumber();
    path.inspectionSic = aInspectionSic;
    request.filename = photo.fileName;
    request.fileContents = photo.getBase64data();
    request.contentType = photo.contentType;
    if (request.filename?.trim().length === 0) {
      request.filename = photo.id?.id;
    }

    return this.inspectionsApiService.createArchiveDmsDocument(request, path, { loadingOverlayEnabled: false }).pipe(
      catchError((error) => {
        this.errorHandling.handleResponseError(error, ErrorMessageActions.CREATING, this.ARCHIVE_DMS_DOCUMENT);

        return EMPTY;
      })
    );
  }

  createBroadcastMessage(aBroadcastMessage: BroadcastMessage): Observable<CreateBroadcastMessageResp> {
    RequestValidator.validateObjectNotUndefinedOrEmpty(aBroadcastMessage, 'Broadcast Message');

    const request = new CreateBroadcastMessageRqst();
    request.broadcastMessage = aBroadcastMessage;

    return this.inspectionsApiService.createBroadcastMessage(request).pipe(
      catchError((error) => {
        this.errorHandling.handleResponseError(error, ErrorMessageActions.CREATING, this.BROADCAST_MESSAGE);

        return EMPTY;
      })
    );
  }

  listBroadcastMessages(query: ListBroadcastMessagesQuery): Observable<ListBroadcastMessagesResp> {
    return this.inspectionsApiService.listBroadcastMessages(query);
  }

  listUserBroadcastMessages(path: ListUserBroadcastMessagesPath): Observable<ListUserBroadcastMessagesResp> {
    return this.inspectionsApiService.listUserBroadcastMessages(path);
  }
}
