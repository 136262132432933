import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { InspectionShipment } from '@xpo-ltl/sdk-inspections';
import { ProNumber } from '../../../../classes/pronumber';
import { AppConstantsService } from '../../../../services/app-constants.service';
import { XpoDialogManagerService } from '../../../../services/xpo-dialog-manager.service';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PricingComponent implements OnChanges {
  @Input()
  shipmentDetails: InspectionShipment;

  appliedRulesetUrl: string;
  isExpanded = false;

  constructor(
    public dialog: MatDialog,
    public xpoDialog: XpoDialogManagerService,
    public appConstantsService: AppConstantsService
  ) {}

  ngOnChanges() {
    this.appliedRulesetUrl = undefined;
    if (this.shipmentDetails) {
      const proNumber = new ProNumber(this.shipmentDetails?.shipmentId?.proNumber).formatProNumber();
      const pickupDate = this.shipmentDetails?.shipmentId?.pickupDate;
      const appliedRulesetNbr = this.shipmentDetails?.appliedRulesetNbr;
      const appliedAgreementId = this.shipmentDetails?.appliedAgreementId;
      if (proNumber && pickupDate && appliedRulesetNbr && appliedAgreementId) {
        const queryParams = [];
        queryParams.push('proId=' + proNumber);
        queryParams.push('shipmentPkupDt=' + pickupDate);
        queryParams.push('rsSeq=' + appliedRulesetNbr);
        queryParams.push('docId=' + appliedAgreementId);
        queryParams.push('popUp=Y');
        this.appliedRulesetUrl = this.appConstantsService.getPricingAppEndpoint(['initRulesetView.do'], queryParams);
      }
    }
    this.isExpanded = false;
  }

  public viewRulesets() {
    if (this.appliedRulesetUrl) {
      this.xpoDialog.openExternalWindow(this.appliedRulesetUrl, 'PricingRuleSet');
      // this.popupCenter(this.appliedRulesetUrl, 'PricingRuleSet', 800, 900, 100);
      // IFrame not working cross protocol https to http, so opening up a window for now
      /*
      const data = new IFrameDisplayData(
        'Applied Ruleset',
        this.appliedRulesetUrl,
        'Unable to Retrieve Rule Set'
      );

      const dialogRef = this.dialog.open(IFrameDisplayComponent, {
        data: data
      });
      */
    }
  }
}
