import { Injectable } from '@angular/core';
import { isEmpty as _isEmpty } from 'lodash';
import { ProNumber } from '../classes/pronumber';

@Injectable()
export class RequestValidator {
  static readonly ERROR_CANNOT_BE_EMPTY = 'cannot be empty.';
  static readonly ERROR_SHOULD_BE_POSITIVE_INT = 'should be a positive integer.';
  static readonly PRO_NBR = 'PRO Number';
  static readonly INVALID = 'Invalid';

  constructor() {}

  public static validateStringNotNullOrEmpty(requestString: string, fieldName: string): void {
    if (!requestString || requestString.trim().length === 0) {
      throw new Error(`${fieldName} ${RequestValidator.ERROR_CANNOT_BE_EMPTY}`);
    }
  }

  public static validateObjectNotUndefinedOrEmpty(requestObject: Object, fieldName: string): void {
    if (
      !requestObject ||
      Object.keys(requestObject).length === 0 ||
      Object.values(requestObject).every((value) => value === undefined)
    ) {
      throw new Error(`${fieldName} ${RequestValidator.ERROR_CANNOT_BE_EMPTY}`);
    }
  }

  public static validateProNumber(requestProNumber: ProNumber): void {
    this.validateObjectNotUndefinedOrEmpty(requestProNumber, `${RequestValidator.PRO_NBR}`);
    if (!requestProNumber?.isValid()) {
      throw new Error(`${RequestValidator.INVALID} ${RequestValidator.PRO_NBR}.`);
    }
  }

  public static validateArrayOrSingleObjectNotEmpty(requestObject: Object | Array<Object>, fieldName: string): void {
    if (_isEmpty(requestObject)) {
      throw new Error(`${fieldName} ${RequestValidator.ERROR_CANNOT_BE_EMPTY}`);
    }
  }

  public static validateNumberIsPositiveOrZeroInteger(num: number, fieldName: string): void {
    if (!num || !Number.isInteger(num) || num <= 0) {
      throw new Error(`${fieldName} ${RequestValidator.ERROR_SHOULD_BE_POSITIVE_INT}`);
    }
  }
}
