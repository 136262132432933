import { PhotoGalleryDataIF } from '../../../photo-gallery/photo-gallery-data-if';
import { Observable } from 'rxjs';
import { Photo } from '../../../../classes/photo';
import { ProNumber } from '../../../../classes/pronumber';
import { HardwareService } from '../../../../services/hardware/hardware-service';
import { PhotoId } from '../../../../classes/photo-id';

export class PhotoGalleryDataImpl implements PhotoGalleryDataIF {
  constructor(private hardwareService: HardwareService) {}

  public canDeletePhoto(): boolean {
    return true;
  }

  public loadPhoto(photoId: PhotoId): Observable<Photo> {
    return this.hardwareService.getImage(photoId);
  }

  public deletePhoto(photoId: PhotoId): Observable<boolean> {
    return this.hardwareService.deletePhoto(photoId);
  }

  public listPhotoIds(proNumber: ProNumber): Observable<PhotoId[]> {
    return this.hardwareService.listPhotoIds(proNumber);
  }
}
