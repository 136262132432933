import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { InspectionShipment } from '@xpo-ltl/sdk-inspections';
import { Observable, Subject, of } from 'rxjs';
import { startWith, switchMap, take, takeUntil, tap } from 'rxjs/internal/operators';
import { ConfirmCancelData } from '../../../../classes/confirm-cancel-data';
import { Photo } from '../../../../classes/photo';
import { PhotoId } from '../../../../classes/photo-id';
import { ProNumber } from '../../../../classes/pronumber';
import { ConfirmCancelComponent } from '../../../../dialogs/confirm-cancel/confirm-cancel.component';
import { InspectionState } from '../../../../enums/inspection-state.enum';
import { HardwareService } from '../../../../services/hardware/hardware-service';

@Component({
  selector: 'ig-image-gallery',
  templateUrl: './photos.component.html',
  styleUrls: ['./photos.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ImageGalleryComponent implements OnInit, OnDestroy {
  static PHOTO_NUMBER_LIMIT: number = 20;

  constructor(
    public hardwareService: HardwareService,
    private changeDetector: ChangeDetectorRef,
    private matDialog: MatDialog
  ) {}

  @Input()
  inspectionDetails: InspectionShipment;

  @Output()
  numberOfPhotosChange = new EventEmitter<number>();

  public photos$: Observable<Photo[]> = of([]);
  public selectedFiles = null;

  private numberOfPhotos: number = 0;
  private unsubscriber$: Subject<any> = new Subject();
  private PHOTO_LIMIT_EXCEEDED_MESSAGE: string = `You have reached photo limit. Only ${ImageGalleryComponent.PHOTO_NUMBER_LIMIT} photos per inspection are allowed.`;

  ngOnInit() {
    this.photos$ = this.getThumbnails(new ProNumber(this.inspectionDetails?.shipmentId?.proNumber));
  }

  ngOnDestroy() {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

  public launchCamera(): void {
    this.hardwareService.launchCamera(
      new ProNumber(this.inspectionDetails.shipmentId.proNumber),
      <InspectionState>this.inspectionDetails.inspectionStatusCd
    );
  }

  public showPhotoGallery(photoId: PhotoId): void {
    this.hardwareService
      .showPhotoGallery(new ProNumber(this.inspectionDetails.shipmentId.proNumber), photoId)
      .pipe(take(1))
      .subscribe(() => {
        this.getThumbnails(new ProNumber(this.inspectionDetails.shipmentId.proNumber));
      });
  }

  public deletePhoto(photo: Photo): void {
    const data = new ConfirmCancelData('Confirm Action', 'Delete Photo?', 'No', 'Yes');
    const dialogRef = this.matDialog.open(ConfirmCancelComponent, {
      data: data
    });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((resp) => {
        if (resp) {
          this.hardwareService
            .deletePhoto(photo.id)
            .pipe(take(1))
            .subscribe(() => {
              this.hardwareService.photosUpdated();
            });
        }
      });
  }

  private getThumbnails(proNumber: ProNumber): Observable<Photo[]> {
    return this.hardwareService.photosUpdated$.pipe(
      startWith(this.hardwareService.photosUpdated()),
      switchMap(() => {
        return this.hardwareService.listThumbnails(proNumber).pipe(
          take(1),
          tap((photos: Photo[]) => {
            if (photos) {
              this.numberOfPhotos = photos.length;
              this.numberOfPhotosChange.emit(this.numberOfPhotos);
            }
            this.changeDetector.detectChanges();
          })
        );
      })
    );
  }

  public pictureTaken(event): void {
    const files: File[] = Array.from(event.target.files);

    if (files.length + this.numberOfPhotos > ImageGalleryComponent.PHOTO_NUMBER_LIMIT) {
      alert(this.PHOTO_LIMIT_EXCEEDED_MESSAGE);
    } else {
      files.forEach((file) => {
        if (this.isValidFilename(file.name)) {
          const reader = new FileReader();

          reader.onload = () => {
            const filename = file.name;
            const base64 = reader.result.toString();
            this.hardwareService
              .addPhoto(new ProNumber(this.inspectionDetails.shipmentId.proNumber), base64, filename)
              .pipe(takeUntil(this.unsubscriber$))
              .subscribe();
          };
          reader.readAsDataURL(new Blob([file]));
        } else {
          alert('Invalid filename/format for file: ' + file.name.toString());
        }
      });
    }
  }

  private isValidFilename(fileName: string): boolean {
    if (!fileName) {
      return false;
    }

    const dotIdx = fileName.lastIndexOf('.');
    if (dotIdx < 0) {
      return false;
    }

    const ext = fileName.substring(dotIdx).toUpperCase();
    if (ext === '.JPG' || ext === '.JPEG' || /*ext === '.BMP' || */ ext === '.PNG' || ext === '.GIF') {
      return true;
    } else {
      return false;
    }
  }
}
