import { Injectable } from '@angular/core';
import {
  ListOperationalServiceCentersQuery,
  ListOperationalServiceCentersResp,
  LocationApiService,
  ServiceCenter
} from '@xpo-ltl-2.0/sdk-location';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { ErrorMessageActions } from '../../enums/error-message-actions.enum';
import { ErrorHandlingService } from '../error-handling.service';

@Injectable({ providedIn: 'root' })
export class LocationApiWrapperService {
  private _isEverySicLocationLoaded = false;

  private sicLocationsSubject = new BehaviorSubject(<ServiceCenter[]>[]);
  sicLocations$ = this.sicLocationsSubject.asObservable();

  constructor(private locationService: LocationApiService, private errorHandling: ErrorHandlingService) {}

  get isEverySicLocationLoaded(): boolean {
    return this._isEverySicLocationLoaded;
  }

  get sicLocations(): ServiceCenter[] {
    return this.sicLocationsSubject.value;
  }

  listOperationalServiceCenters(): Observable<ServiceCenter[]> {
    const que = new ListOperationalServiceCentersQuery();
    que.excludeSatellitesInd = false;

    return this.locationService.listOperationalServiceCenters(que).pipe(
      take(1),
      map((resp: ListOperationalServiceCentersResp) => {
        if (resp) {
          this._isEverySicLocationLoaded = true;
          this.sicLocationsSubject.next(resp.serviceCenters);
          return resp.serviceCenters;
        } else {
          throw new Error(`Received SIC locations as: ${JSON.stringify(resp)}`);
        }
      }),
      catchError((error) => {
        this.errorHandling.handleResponseError(error, ErrorMessageActions.GETTING, `Every SIC location`);

        return EMPTY;
      })
    );
  }
}
