import { Injectable, TemplateRef } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UpdateInspectionStatusResp } from '@xpo-ltl/sdk-inspections';
import { clone, defaults } from 'lodash';
import { Observable, of } from 'rxjs';
import { ChangeStatusErrorData } from '../classes/change-status-error-data';
import { ConfirmCancelData } from '../classes/confirm-cancel-data';
import { NotAuthorizedComponent } from '../components/not-authorized/not-authorized.component';
import { AlertWrapperComponent } from '../dialogs/alert-wrapper/alert-wrapper.component';
import { ConfirmCancelComponent } from '../dialogs/confirm-cancel/confirm-cancel.component';
import { StatusChangeDialogComponent } from '../dialogs/status-change-dialog/status-change-dialog.component';
import { InspectionState, toInspectionStateDisplayName } from '../enums/inspection-state.enum';
import { AlertDialogData } from '../interfaces/alert-dialog-data.interface';

@Injectable()
export class XpoDialogManagerService {
  private configDefaults: MatDialogConfig;

  // When using the openExternalWindow from the Inspection App (.net) the popup will not come to the top/have focus if already opened
  // Therefore, close the window if it already exists by keeping a reference to the last one opened.
  // But, we want different windows open for different functions, such as Previous Corrrections and Pricing windows
  // So keep a map of the window references by name (title)
  private externalWindowLastUrlByNameMap = new Map<string, any>();

  constructor(private matDialog: MatDialog) {
    this.configDefaults = {
      width: '650px'
    };

    // this.initializeWatchers();
  }

  public alert(alertOptions: AlertDialogData, configOverrides: MatDialogConfig = {}): MatDialogRef<any> {
    const defaultsWithData = defaults(configOverrides, clone(this.configDefaults));
    defaultsWithData.data = alertOptions;

    return this.matDialog.open(AlertWrapperComponent, defaultsWithData);
  }

  public open(dialogComponent: TemplateRef<any>, configOverrides: MatDialogConfig = {}): MatDialogRef<any> {
    const dialogConfig = defaults(configOverrides, clone(this.configDefaults));

    return this.matDialog.open(dialogComponent, dialogConfig);
  }

  public showStatusChangeDialog(updateInspectionStatusResp: UpdateInspectionStatusResp) {
    if (
      updateInspectionStatusResp &&
      updateInspectionStatusResp.validationErrors &&
      updateInspectionStatusResp.validationErrors.length > 0
    ) {
      const changeStateErrorData = new ChangeStatusErrorData(updateInspectionStatusResp);
      if (changeStateErrorData.errorMap && Object.keys(changeStateErrorData.errorMap).length > 0) {
        const dialogRef = this.matDialog.open(StatusChangeDialogComponent, {
          data: {
            changeStateErrorData: changeStateErrorData
          }
        });
      }
    }
  }

  public showConfirmCancelDialog(
    message: string,
    fromState: InspectionState = null,
    toState: InspectionState = null
  ): Observable<any | undefined> {
    if (fromState) {
      message = message.toString().replace('{fromState}', toInspectionStateDisplayName(fromState));
    }

    if (toState) {
      message = message.toString().replace('{toState}', toInspectionStateDisplayName(toState));
    }

    const data = new ConfirmCancelData('Confirm Action', message, 'No', 'Yes');
    const dialogRef = this.matDialog.open(ConfirmCancelComponent, {
      data: data
    });
    return dialogRef.afterClosed();
  }

  public openExternalWindow(url: string, title: string) {
    const padding = 5;
    let height = window.innerHeight * 0.9;
    let width = window.innerWidth * 0.9;

    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

    const windowWidth = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : screen.width;
    const windowHeight = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : screen.height;

    if (height > windowHeight - padding * 2) {
      height = windowHeight - padding * 2;
    }

    if (width > windowWidth - padding * 2) {
      width = windowWidth - padding * 2;
    }

    const systemZoom = windowWidth / window.screen.availWidth;
    const left = (windowWidth - width) / 2 / systemZoom + dualScreenLeft;
    const top = (windowHeight - height) / 2 / systemZoom + dualScreenTop;

    // close previous window (if exists) for this name(title)
    const lastWindowReference = this.externalWindowLastUrlByNameMap.get(title);
    if (lastWindowReference) {
      lastWindowReference.close();
    }

    const newWindow = window.open(
      url,
      title,
      'status=no,location=no,toolbar=no,scrollbars=yes, width=' +
        width / systemZoom +
        ', height=' +
        height / systemZoom +
        ', top=' +
        top +
        ', left=' +
        left
    );

    if (newWindow) {
      // make sure newWindow exists before setting
      this.externalWindowLastUrlByNameMap.set(title, newWindow);
      // Puts focus on the newWindow
      if (window.focus) {
        newWindow.focus();
      }
    }
  }

  public showNotAuthorizedDialog() {
    this.alert(
      {
        titleText: 'No Authorization',
        contentComponent: NotAuthorizedComponent,
        injectedData: of({ isSuccess: false }),
        hideDismissButton: true
      },
      {
        disableClose: true,
        width: '500px'
      }
    );
  }
}
