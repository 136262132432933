<div class="document-list">
  <mat-expansion-panel [(expanded)]="isExpanded" (opened)="loadDocumentsOnExpanded()">
    <mat-expansion-panel-header>
      <mat-panel-title class="section-label">{{ title }} ({{ photoIds?.length }})</mat-panel-title>
    </mat-expansion-panel-header>
    <div class="document-list__container">
      <div *ngIf="!photoIds || photoIds.length === 0; else showPhotos" fxLayoutAlign="start center">
        No {{ title }} Found
      </div>
      <ng-template #showPhotos>
        <div fxLayout="row wrap" fxLayoutGap="10px">
          <div *ngFor="let photo of documentPhotos$ | async" fxLayout="column">
            <div fxFlex *ngIf="photo.isLoaded; else showIcons" class="document-list__container-photos">
              <div *ngIf="title === DocumentListTitles.OPERATIONS_PHOTOS || title === DocumentListTitles.DOCUMENT">
                <div *ngIf="photo?.id.parentProNumber">
                  <!--display child PRO for PLT shipments-->
                  <div class="label">{{ photo?.id.proNumber.formatProNumber() }}</div>
                </div>
                <div class="label">{{ photo?.id.imageType | imageTypePipe }}</div>
              </div>
              <img [src]="photo.getBase64data(true)" (click)="showPhotoGallery(photo)" />
            </div>
            <ng-template #showIcons>
              <div fxFlex>
                <mat-icon
                  class="document-list__documentIcon"
                  svgIcon="{{ photo.svgIconValue }}"
                  (click)="showPhotoGallery(photo.id)"
                ></mat-icon>
              </div>
            </ng-template>
          </div>
        </div>
        <div *ngIf="showTrailerPhotoAppLink">
          <button (click)="openTrailerPhotoApp()" mat-button color="primary" class="document-list__trailer-photo-link">
            TRAILER PHOTOS
          </button>
        </div>
      </ng-template>
      <div></div>
    </div>
  </mat-expansion-panel>
</div>
