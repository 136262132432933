export enum ColumnType {
  TIMESTAMP = 'Timestamp',
  CUSTOMER_INSTRUCTIONS = 'CustomerInstructions',
  NMFC = 'NMFC',
  COMMODITY_DESCRIPTION = 'CommodityDescription',
  NMFC_CLASS = 'NMFCClass',
  FAK = 'FAK',
  STATUS = 'Status',
  PARTY_ROLE = 'PartyRole',
  PAYMENT_TYPE = 'PaymentType',
  NUMBER = 'Number'
}
