import { Injectable } from '@angular/core';
import { DataOptions } from '@xpo-ltl/data-api';
import { AcctId, CustomerFunctionCd } from '@xpo-ltl/sdk-common';
import { CreateRecommendationRuleResp } from '@xpo-ltl/sdk-inspections';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { take } from 'rxjs/internal/operators';
import { ResponseValidation } from '../../../classes/ResponseValidation';
import { CustomerRecommendedRule } from '../../../classes/customer-recommended-rule';
import { AppConstantsService } from '../../../services/app-constants.service';
import { InspectionsApiWrapperService } from '../../../services/inspections/inspections.service';
import { RuleFilter } from '../classes/rule-filter';
import { PartyRole } from '../enums/party_role.enum';
import { RetryStrategy } from './../../../operators/retry-strategy';

@Injectable()
export class RecommendedRulesService {
  static DEFAULT_PROCESSING_MESSAGE = 'Processing. Please Wait...';
  private DEFAULT_DATA_OPTIONS = <DataOptions>{
    loadingOverlayEnabled: true,
    loadingOverlayMessage: RecommendedRulesService.DEFAULT_PROCESSING_MESSAGE,
    toastOnError: true
  };

  // Default Retry count
  private static DEFAULT_RETRY_COUNT = 3;

  // Initial 15 second timeout for any standard GET (Single Object) response
  private static DEFAULT_GET_TIMEOUT = 15000; // This will go up to 2 mins based on DEFAULT_RETRY_COUNT or 4...

  // Wait no longer than 60 seconds for any UPDATE (PUT, POST) response
  private static DEFAULT_UPDATE_TIMEOUT = 60000;
  private static DEFAULT_UPDATE_RETRY_COUNT = 0; // Don't retry on Updates

  private static DEFAULT_RETRY_DELAY = 1000; // retry every second on (non-timeout) errors
  private lastRefreshDateSubject = new BehaviorSubject(<string>'');
  public lastRefreshDate$ = this.lastRefreshDateSubject.asObservable();
  private customerRecommendedRule: CustomerRecommendedRule;
  private ruleFilters: Array<RuleFilter>;
  private recommendationRuleFilters: Array<RuleFilter>;
  private ruleFiltersSubject: BehaviorSubject<Array<RuleFilter>>;
  private formChangedEventSubject: Subject<void>;

  private account: AcctId;
  private partyRole: string;

  constructor(
    private constants: AppConstantsService,
    private inspectionsService: InspectionsApiWrapperService,
    private retryStrategy: RetryStrategy
  ) {
    this.initializeAttributes();
  }

  private initializeAttributes(): void {
    this.account = new AcctId();
    this.partyRole = '';
    this.recommendationRuleFilters = new Array<RuleFilter>();
    this.customerRecommendedRule = new CustomerRecommendedRule();
    this.initializeFilters();
  }

  private initializeFilters(): void {
    this.ruleFilters = new Array<RuleFilter>();

    this.ruleFilters.push(new RuleFilter('Payment Type', 'payment_type', 'options', ['Prepaid', 'Collect']));

    this.ruleFilters.push(new RuleFilter('Consignee', 'consignee', 'MADCode'));

    this.refreshFilters();
  }

  public getAccount(): AcctId {
    if (!this.account) {
      this.account = new AcctId();
    }

    return this.account;
  }

  public setAccount(account: AcctId) {
    this.account = account;
  }

  public getRuleRecommended(): CustomerRecommendedRule {
    return this.customerRecommendedRule;
  }

  public getAccountName(): string {
    return this.getAccount().acctName;
  }

  public getMadCode() {
    return this.getAccount().acctMadCd;
  }

  /**
   *
   * @param madCode
   */
  public setMadCode(madCode: string) {
    this.getAccount().acctMadCd = madCode;
  }

  public isAccountValid(): boolean {
    let response = false;
    const accountValidation = this.validateAccountPartyRole();

    if (this.getAccount().acctInstId && accountValidation.getIsValid()) {
      response = true;
    }

    return response;
  }

  public clear() {
    this.initializeAttributes();
  }

  public getRecommendation() {
    return this.customerRecommendedRule.typeCd;
  }

  /**
   *
   * @param recommendation
   */
  public setRecommendation(recommendation: string) {
    this.customerRecommendedRule.typeCd = recommendation;
  }

  /**
   *
   * @param party
   */
  public setParty(party: string) {
    this.partyRole = party.toUpperCase();
  }

  public getParty(): string {
    return this.partyRole;
  }

  public getFormChangedEventSubject(): Subject<void> {
    if (!this.formChangedEventSubject) {
      this.formChangedEventSubject = new Subject<void>();
    }

    return this.formChangedEventSubject;
  }

  public getFormChangedEventSubjectAsObservable(): Observable<void> {
    return this.getFormChangedEventSubject().asObservable();
  }

  public triggerFilterValidations(): void {
    this.getFormChangedEventSubject().next();
  }

  /**
   *
   * @param acct
   */
  public validateAccountPartyRole(): ResponseValidation {
    const validationResponse = new ResponseValidation(true, '');
    const isAccountPickupAndDelivery = this.getAccount().acctPartyRole === CustomerFunctionCd.PICKUP_OR_DELIVERY;
    const isPartyBillTo = this.getParty() === PartyRole.BillTo;

    if (!isPartyBillTo && !isAccountPickupAndDelivery) {
      validationResponse.setIsValid(false);
      validationResponse.setMessage('Account code should be P&D location');
    } else if (isPartyBillTo && isAccountPickupAndDelivery) {
      validationResponse.setIsValid(false);
      validationResponse.setMessage('Account code should be Bill To location');
    }

    return validationResponse;
  }

  // Before asking the backend for the account information checks that the account is a valid consignee
  // different than the man rule's mad code and that main rule account's party is shipper
  public validateFilterMadCodeAccount(madCode: string): ResponseValidation {
    const response = new ResponseValidation(true, '');

    if (this.getParty() !== PartyRole.Shipper) {
      response.setIsValid(false);
      response.setMessage(`You cannot specify a consignee account unless the rule's main account is a shipper`);
    } else if (madCode && madCode === this.getMadCode()) {
      response.setIsValid(false);
      response.setMessage('Shipper and Consignee MAD codes must be different');
    }

    return response;
  }

  // After getting the account form the service it checks that the account is pickup and delivery
  public validateConsigneeAccountPartyRole(acct: AcctId): ResponseValidation {
    const validationResponse = new ResponseValidation(true, '');
    if (this.getParty() && acct.acctPartyRole !== CustomerFunctionCd.PICKUP_OR_DELIVERY) {
      validationResponse.setIsValid(false);
      validationResponse.setMessage('Consignee account code should be pickup delivery location');
    }
    return validationResponse;
  }

  private setPaymentTypeFilterForEditDialog(): void {
    const paymentTypeFilter = this.getFilterByName('payment_type');

    const newRuleFilter = new RuleFilter(
      paymentTypeFilter.getDisplayName(),
      paymentTypeFilter.getName(),
      paymentTypeFilter.getType(),
      paymentTypeFilter.getOptions()
    );

    newRuleFilter.setValue(this.customerRecommendedRule.inspectionChargeCd);
    newRuleFilter.setIsValid(true);
    this.addRuleFilter(newRuleFilter);
  }

  private setConsigneeFilterForEditDialog(): void {
    const consigneeFilter = this.getFilterByName(PartyRole.Consignee.toLowerCase());

    const newRuleFilter = new RuleFilter(
      consigneeFilter.getDisplayName(),
      consigneeFilter.getName(),
      consigneeFilter.getType()
    );

    // Get the account that is acting as consignee
    newRuleFilter.setValue(
      this.customerRecommendedRule.customerAccounts.find((account) => account.acctPartyRole === PartyRole.Consignee)
    );

    newRuleFilter.setIsValid(true);
    this.addRuleFilter(newRuleFilter);
  }

  private getCustomerRecommendedRuleForEditDialog(rule: any): CustomerRecommendedRule {
    const customerRecommendedRule = new CustomerRecommendedRule();

    customerRecommendedRule.ruleInstId = rule.ruleInstId;
    customerRecommendedRule.typeCd = rule.typeCd;
    customerRecommendedRule.ruleRemarks = rule.ruleRemarks;
    customerRecommendedRule.inspectionSicCd = rule.inspectionSicCd;
    customerRecommendedRule.inspectionChargeCd = rule.inspectionChargeCd;
    customerRecommendedRule.customerAccounts = rule.customerAccounts;
    customerRecommendedRule.auditInfo = rule.auditInfo;

    return customerRecommendedRule;
  }

  /**
   *
   * @param rule
   */
  public setRuleRecommended(rule: any) {
    this.customerRecommendedRule = this.getCustomerRecommendedRuleForEditDialog(rule);

    // We set the payment type filter if the rule has an specific cahrdeToCd value
    if (this.customerRecommendedRule.inspectionChargeCd !== 'ANY') {
      this.setPaymentTypeFilterForEditDialog();
    }

    // If the rule has more than one account, it means it has consignee filter
    if (this.customerRecommendedRule.customerAccounts && this.customerRecommendedRule.customerAccounts.length === 1) {
      // Get the first element in accounts array
      this.setAccount(this.customerRecommendedRule.customerAccounts.filter((account) => account).shift());
    } else if (this.customerRecommendedRule.customerAccounts.length > 1) {
      // Get the account that is not acting as consignee
      this.setAccount(
        this.customerRecommendedRule.customerAccounts.find((account) => account.acctPartyRole !== PartyRole.Consignee)
      );

      this.setConsigneeFilterForEditDialog();
    }

    this.setParty(this.getAccount().acctPartyRole);

    if (this.getAccount().acctPartyRole === PartyRole.BillTo) {
      this.getAccount().acctPartyRole = CustomerFunctionCd.BILL_TO;
    } else {
      this.getAccount().acctPartyRole = CustomerFunctionCd.PICKUP_OR_DELIVERY;
    }

    this.setRecommendation(this.customerRecommendedRule.typeCd);
  }

  /**
   * Sets the main account
   * @param accountInfo
   */
  public addAccountToRule(accountInfo: AcctId): void {
    this.customerRecommendedRule.customerAccounts.push(accountInfo);
  }

  public isFormValid(): boolean {
    let response = false;

    if (this.isAccountValid() && this.getRecommendation() && this.getParty()) {
      response = true;
    }

    return response;
  }

  public areFiltersValid(): boolean {
    let response = false;

    const invalidFilter = this.recommendationRuleFilters.some((filter: RuleFilter) => {
      return !filter.getIsValid();
    });

    if (!invalidFilter) {
      response = true;
    }

    return response;
  }

  public getFilters(): Array<RuleFilter> {
    return this.ruleFilters;
  }

  private getRuleFiltersSubject(): BehaviorSubject<Array<RuleFilter>> {
    if (!this.ruleFiltersSubject) {
      this.ruleFiltersSubject = new BehaviorSubject<Array<RuleFilter>>(this.getFilters());
    }

    return this.ruleFiltersSubject;
  }

  public getFiltersObservable(): Observable<Array<RuleFilter>> {
    return this.getRuleFiltersSubject().asObservable();
  }

  public refreshFilters(): void {
    this.getRuleFiltersSubject().next(this.getFilters());
  }

  public getRecommendationRuleFilters(): Array<RuleFilter> {
    return this.recommendationRuleFilters;
  }

  /**
   *
   * @param name
   */
  public getFilterByName(name: string): RuleFilter {
    return this.ruleFilters.find((filter: RuleFilter) => {
      return filter.getName() === name;
    });
  }

  public isLastFilterAvailable(): boolean {
    const availableFilters = this.getCurrentAvailableFilters();

    return availableFilters && availableFilters.length === 1;
  }

  private getCurrentAvailableFilters(): Array<RuleFilter> {
    return this.ruleFilters.filter((filter: RuleFilter) => {
      return filter.getStatus() === 'available';
    });
  }

  public addLastFilterAvailable(): void {
    const availableFilters = this.getCurrentAvailableFilters();

    if (availableFilters && availableFilters.length === 1) {
      // We get the available filter (should be the last one available at this point)
      const availableFilter = this.getFilterByName(availableFilters[0].getName());
      availableFilter.setStatus('selected');

      // Then we create a new filter (copiyng)
      const newRuleFilter = new RuleFilter(
        availableFilter.getDisplayName(),
        availableFilter.getName(),
        availableFilter.getType(),
        availableFilter.getOptions()
      );

      // We add the new filter to the rule
      this.addRuleFilter(newRuleFilter);

      // Then we refresh the filters, so no filters are available now
      this.refreshFilters();
    }
  }

  /**
   *
   * @param filter
   */
  public addRuleFilter(filter: RuleFilter): void {
    this.recommendationRuleFilters.push(filter);
  }

  /**
   *
   * @param filter
   */
  public removeRuleFilter(filter: RuleFilter): void {
    const index = this.recommendationRuleFilters.findIndex((currentFilter: RuleFilter) => {
      return currentFilter.getName() === filter.getName();
    });

    if (index > -1) {
      this.recommendationRuleFilters.splice(index, 1);
    }
  }

  public canAddRuleFilter(): boolean {
    let response = true;
    const addedFiltersLength = this.recommendationRuleFilters.length;

    const enabledFilters = this.ruleFilters.filter((filter: RuleFilter) => {
      return filter.getStatus() !== 'disabled';
    });

    const filtersLength = enabledFilters.length;

    if (addedFiltersLength && !(addedFiltersLength < filtersLength)) {
      response = false;
    }

    return response;
  }

  private evaluateFilters() {
    this.customerRecommendedRule.inspectionChargeCd = 'ANY';

    this.recommendationRuleFilters.forEach((filter) => {
      if (filter.getName() === 'payment_type' && filter.getValue()) {
        this.customerRecommendedRule.inspectionChargeCd = filter.getValue().toUpperCase();
      } else if (filter.getName() === PartyRole.Consignee.toLowerCase() && filter.getValue()) {
        filter.getValue().acctPartyRole = PartyRole.Consignee;
        this.addAccountToRule(filter.getValue());
      }
    });
  }

  public disableFilters(): void {
    // We will not allow the user to use consignee filter if the party is not shipper
    if (this.getParty() === PartyRole.Shipper) {
      this.enableConsigneeFilter();
    } else {
      this.disableConsigneeFilter();
    }
  }

  private disableConsigneeFilter(): void {
    const filter = this.getFilterByName(PartyRole.Consignee.toLowerCase());

    if (filter.getStatus() === 'available') {
      filter.setStatus('disabled');
    }

    this.refreshFilters();
  }

  private enableConsigneeFilter(): void {
    const filter = this.getFilterByName(PartyRole.Consignee.toLowerCase());

    if (filter.getStatus() === 'disabled') {
      filter.setStatus('available');
    }

    this.refreshFilters();
  }

  public getRecommendedRules(): Observable<CustomerRecommendedRule[]> {
    this.lastRefreshDateSubject.next(AppConstantsService.getCurrentFormattedDateTime());

    return this.inspectionsService.listRecommendationRulesBySic(this.constants.inspectionContext?.inspectionSic);
  }

  private prepareRuleBeforeSave(): void {
    // We set the current SIC
    this.customerRecommendedRule.inspectionSicCd = this.constants.inspectionContext?.inspectionSic;

    // We copy the account object to avoid passing it by reference
    // Then we set account's party
    const account = JSON.parse(JSON.stringify(this.getAccount()));
    account.acctPartyRole = this.getParty();

    // And we reset customer accounts list
    this.customerRecommendedRule.customerAccounts = new Array<AcctId>();

    // After that we add the account to the rule's account list
    this.addAccountToRule(account);

    // Finally we update the rule based on the filters
    this.evaluateFilters();
  }

  public createRecommendedRule(): Observable<CreateRecommendationRuleResp> {
    this.prepareRuleBeforeSave();

    return this.inspectionsService
      .createRecommendationRule(this.customerRecommendedRule, this.DEFAULT_DATA_OPTIONS)
      .pipe(
        this.retryStrategy.retryStrategy(
          RecommendedRulesService.DEFAULT_UPDATE_TIMEOUT,
          RecommendedRulesService.DEFAULT_RETRY_DELAY,
          RecommendedRulesService.DEFAULT_UPDATE_RETRY_COUNT,
          RecommendedRulesService.DEFAULT_PROCESSING_MESSAGE,
          'CREATE-RECOMMENDATION-RULE'
        ),
        take(1)
      );
  }

  public updateRecommendedRule() {
    this.prepareRuleBeforeSave();

    return this.inspectionsService
      .updateRecommendationRule(
        this.customerRecommendedRule?.ruleInstId?.toString(),
        this.customerRecommendedRule,
        this.DEFAULT_DATA_OPTIONS
      )
      .pipe(
        this.retryStrategy.retryStrategy(
          RecommendedRulesService.DEFAULT_UPDATE_TIMEOUT,
          RecommendedRulesService.DEFAULT_RETRY_DELAY,
          RecommendedRulesService.DEFAULT_UPDATE_RETRY_COUNT,
          RecommendedRulesService.DEFAULT_PROCESSING_MESSAGE,
          'UPDATE-RECOMMENDATION-RULE'
        ),
        take(1)
      );
  }

  public deleteRecommendedRule(): Observable<void> {
    return this.inspectionsService.deleteRecommendationRule(this.customerRecommendedRule?.ruleInstId);
  }
}
