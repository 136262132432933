import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import {
  GetInspectionShipmentDetailsResp,
  GetShipmentLocationDetailsResp,
  InspectionCustGuidelines,
  InspectionShipment
} from '@xpo-ltl/sdk-inspections';
import { BehaviorSubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { PhotoId } from '../../classes/photo-id';
import { ProNumber } from '../../classes/pronumber';
import { DocTypes } from '../../enums/doc-type.enum';
import { DocumentListTitles } from '../../enums/document-list-titles.enum';
import { AlertDialogData } from '../../interfaces/alert-dialog-data.interface';
import { AppConstantsService } from '../../services/app-constants.service';
import { DocumentManagementService } from '../../services/document-management-service';
import { XpoDialogManagerService } from '../../services/xpo-dialog-manager.service';
import { RequestValidator } from '../../validators/request.validator';
import { CustomerGuidelines } from './../../classes/customer-guidelines';
import { CustomerInstructionsDialogComponent } from './../../dialogs/customer-instructions-dialog/customer-instructions-dialog.component';

@Component({
  selector: 'app-shipment-details',
  templateUrl: './shipment-details.component.html',
  styleUrls: ['./shipment-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShipmentDetailsComponent implements OnInit, OnDestroy {
  static readonly OPERATIONS_PHOTOS_DOC_TYPES: DocTypes[] = [DocTypes.TRAILER_PHOTO, DocTypes.PICKUP_DIMENSIONER_PHOTO];
  static readonly INSPECTIONS_PHOTOS_DOC_TYPES: DocTypes[] = [DocTypes.INSPECTION_PHOTO];
  static readonly DOCUMENT_DOC_TYPES: DocTypes[] = [
    DocTypes.BILL_OF_LANDING,
    DocTypes.BILL_OF_LANDING_ATTACHMENT,
    DocTypes.CUSTOMS,
    DocTypes.INSPECTION_CERTIFICATE,
    DocTypes.REWEIGH_CERTIFICATE
  ];

  constructor(
    public route: ActivatedRoute,
    public constants: AppConstantsService,
    private changeDetection: ChangeDetectorRef,
    private matDialog: MatDialog,
    private dialogManager: XpoDialogManagerService,
    private documentManagement: DocumentManagementService
  ) {}
  DocumentListTitles = DocumentListTitles;
  shipmentLocationDetails: GetShipmentLocationDetailsResp;
  inspectionShipmentDetails: GetInspectionShipmentDetailsResp;
  shipmentDetails: InspectionShipment;

  private inspectionPhotoIdsSubject = new BehaviorSubject<PhotoId[]>([]);
  inspectionPhotoIds$ = this.inspectionPhotoIdsSubject.asObservable();
  private operationPhotoIdsSubject = new BehaviorSubject<PhotoId[]>([]);
  operationPhotoIds$ = this.operationPhotoIdsSubject.asObservable();
  private documentPhotoIdsSubject = new BehaviorSubject<PhotoId[]>([]);
  documentPhotoIds$ = this.documentPhotoIdsSubject.asObservable();

  private unsubscriber$: Subject<any> = new Subject();
  private lastLoadedProNumber: string;

  ngOnInit() {
    this.getShipmentDetails();
    this.getShipmentLocationDetails();
  }

  ngOnDestroy() {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

  get documentDocTypes(): DocTypes[] {
    return ShipmentDetailsComponent.DOCUMENT_DOC_TYPES;
  }
  get inspectionsPhotosDocTypes(): DocTypes[] {
    return ShipmentDetailsComponent.INSPECTIONS_PHOTOS_DOC_TYPES;
  }
  get operationsPhotosDocTypes(): DocTypes[] {
    return ShipmentDetailsComponent.OPERATIONS_PHOTOS_DOC_TYPES;
  }

  private getShipmentDetails(): void {
    this.constants.inspectionShipmentDetails$
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((inspectionShipmentDetails: GetInspectionShipmentDetailsResp) => {
        if (inspectionShipmentDetails) {
          inspectionShipmentDetails.previousInspectionDetails?.reverse();
          this.inspectionShipmentDetails = inspectionShipmentDetails;
          this.shipmentDetails = this.inspectionShipmentDetails?.shipmentDetails;

          // If we get a valid response with a pronumber, check to see if it's different from the Last Pro Number Loaded. If different, then check to see if there are customer guidlines
          // and if so, show them in a dialog box. The reason for the last proNumber check is to prevent the dialog box from popping up again when the guards run and load the
          // shipment details before the next navigation (typically to the inspection screen)
          const currentlyLoadedProNumber: string = this.shipmentDetails?.shipmentId?.proNumber;
          if (currentlyLoadedProNumber?.trim().length > 0) {
            if (this.lastLoadedProNumber !== currentlyLoadedProNumber) {
              this.lastLoadedProNumber = currentlyLoadedProNumber;

              this.loadPhotoIds(new ProNumber(this.lastLoadedProNumber));

              if (this.inspectionShipmentDetails?.currentInspectionDetails?.custSpecificInspGuidelines?.length > 0) {
                const guideLineMessage: CustomerGuidelines[] = [];
                this.inspectionShipmentDetails.currentInspectionDetails.custSpecificInspGuidelines.forEach(
                  (guideline: InspectionCustGuidelines) => {
                    if (guideline?.customerGuidelines?.note?.trim().length > 0) {
                      const guidelineMessageItem: CustomerGuidelines = new CustomerGuidelines();

                      if (guideline.customerId) {
                        guidelineMessageItem.acctName = guideline.customerId.acctName;
                        guidelineMessageItem.guideline = guideline.customerGuidelines.note;

                        guideLineMessage.push(guidelineMessageItem);
                      }
                    }
                  }
                );

                setTimeout(() => {
                  this.showCustomerGuidelinesDialog(guideLineMessage);
                });
              }
            }
            if (this.shipmentDetails.ratedPalletPricingInd) {
              this.showPalletPricingRatedShipmentDialog();
            }
          }
        }
      });
  }

  private getShipmentLocationDetails(): void {
    this.constants.shipmentLocationDetails$
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((response: GetShipmentLocationDetailsResp) => {
        this.shipmentLocationDetails = response;
        this.changeDetection.detectChanges();
      });
  }

  private showCustomerGuidelinesDialog(message: CustomerGuidelines[]) {
    if (message.length > 0) {
      const dialogConfig: MatDialogConfig = <MatDialogConfig>{
        data: { message: message }
      };
      this.matDialog.open(CustomerInstructionsDialogComponent, dialogConfig);
    }
  }

  private showPalletPricingRatedShipmentDialog() {
    const alertDialogData: AlertDialogData = <AlertDialogData>{
      titleText: 'Shipment Notification',
      contentText: 'Pallet Pricing applies to this shipment'
    };

    const matDialogData: MatDialogConfig = {
      width: '400'
    };
    this.dialogManager.alert(alertDialogData, matDialogData);
  }

  private loadPhotoIds(proNumber: ProNumber): void {
    RequestValidator.validateProNumber(proNumber);

    // need to reset photo Ids in case user search other PRO while opening Shipment Detail page
    this.resetPhotoIdsSubject(this.inspectionPhotoIdsSubject);
    this.resetPhotoIdsSubject(this.operationPhotoIdsSubject);
    this.resetPhotoIdsSubject(this.documentPhotoIdsSubject);

    this.documentManagement
      .listDocumentIds(proNumber)
      .pipe(take(1))
      .subscribe((photoIds: PhotoId[]) => {
        photoIds.forEach((photoId: PhotoId) => {
          const imageType: DocTypes = photoId.imageType as DocTypes;

          if (Object.values(ShipmentDetailsComponent.INSPECTIONS_PHOTOS_DOC_TYPES).includes(imageType)) {
            this.updatePhotoIdsSubject(this.inspectionPhotoIdsSubject, photoId);
          } else if (Object.values(ShipmentDetailsComponent.OPERATIONS_PHOTOS_DOC_TYPES).includes(imageType)) {
            this.updatePhotoIdsSubject(this.operationPhotoIdsSubject, photoId);
          } else if (Object.values(ShipmentDetailsComponent.DOCUMENT_DOC_TYPES).includes(imageType)) {
            this.updatePhotoIdsSubject(this.documentPhotoIdsSubject, photoId);
          } else {
            // do nothing, there are more doc types but we're only interested in Inspections related docs
          }
        });
      });
  }

  private resetPhotoIdsSubject(subject: BehaviorSubject<PhotoId[]>): void {
    subject.next(<PhotoId[]>[]);
  }

  private updatePhotoIdsSubject(subject: BehaviorSubject<PhotoId[]>, photoId: PhotoId): void {
    subject.next(subject.getValue().concat([photoId]));
  }
}
