import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { RouterUriComponents } from '../../enums/router-uri-components.enum';
import { ChangeInspectionStatusGuard } from '../../guards/change-inspection-status.guard';
import { RoleCheckGuard } from '../../guards/role-check-guard';
import { SequentialGuard } from '../../guards/sequential.guard';
import { ShipmentDetailsGuard } from '../../guards/shipment-details.guard';
import { UnreadMessagesGuard } from '../../guards/unread-messages.guard';
import { UserProfileGuard } from '../../guards/user-profile-guard';
import { InspectionDetailsResolver } from '../../resolvers/inspection-details.resolver';
import { InspectShipmentComponent } from './inspect-shipment.component';

const routes: Routes = [
  {
    path: `${RouterUriComponents.INSPECT_SHIPMENT_PAGE}/:pro`,
    component: InspectShipmentComponent,
    canActivate: [SequentialGuard],
    data: {
      sequentialGuards: [
        // TODO: for SSO
        // XpoAuthenticationGuard,
        RoleCheckGuard,
        UserProfileGuard,
        ShipmentDetailsGuard,
        ChangeInspectionStatusGuard,
        UnreadMessagesGuard
      ]
    },
    resolve: {
      inspectionDetails: InspectionDetailsResolver
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InspectShipmentRoutingModule {}
