// Original code from: https://umbracofreelancer.uk/blog/post/create-thumbnails-using-javascript/

import { SecurityContext } from '@angular/core';
import { DomSanitizer, SafeUrl, ɵDomSanitizerImpl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { RequestValidator } from '../validators/request.validator';
import { Photo } from './photo';
import { PhotoId } from './photo-id';

export class PhotoUtils {
  private static thumbnailMaxWidth = 300;
  private static thumbnailMaxHeight = 200;

  public static createThumbnail(imageBytes: string): Observable<string> {
    return new Observable((observer) => {
      const image = new Image();
      image.onload = () => {
        let canvas, ctx, thumbnailScale, thumbnailWidth, thumbnailHeight;
        // create an off-screen canvas
        canvas = document.createElement('canvas');
        ctx = canvas.getContext('2d');

        // Calculate the size of the thumbnail, to best fit within max/width (cropspadding)
        thumbnailScale =
          image.width / image.height > this.thumbnailMaxWidth / this.thumbnailMaxHeight
            ? this.thumbnailMaxWidth / image.width
            : this.thumbnailMaxHeight / image.height;
        thumbnailWidth = image.width * thumbnailScale;
        thumbnailHeight = image.height * thumbnailScale;

        // set its dimension to target size
        canvas.width = thumbnailWidth;
        canvas.height = thumbnailHeight;

        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        // draw source image into the off-screen canvas:
        ctx.drawImage(image, 0, 0, thumbnailWidth, thumbnailHeight);

        observer.next(canvas.toDataURL('image/jpeg', 70));
        observer.complete();
      };

      image.onerror = () => {
        // just use the imageBytes on a conversion issue
        observer.next(imageBytes);
        observer.complete();
      };

      image.src = imageBytes;
    });
  }

  public static resizePhoto(imageBytes: string, maxWidth: number, maxHeight: number, quality = 70): Observable<string> {
    return new Observable((observer) => {
      const image = new Image();
      image.onload = () => {
        let canvas, ctx, newScale, newWidth, newHeight;
        // create an off-screen canvas
        canvas = document.createElement('canvas');
        ctx = canvas.getContext('2d');

        // Calculate the size of the thumbnail, to best fit within max/width (cropspadding)
        newScale =
          image.width / image.height > maxWidth / maxHeight ? maxWidth / image.width : maxHeight / image.height;
        newWidth = image.width * newScale;
        newHeight = image.height * newScale;

        // set its dimension to target size
        canvas.width = newWidth;
        canvas.height = newHeight;

        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        // draw source image into the off-screen canvas:
        ctx.drawImage(image, 0, 0, newWidth, newHeight);

        observer.next(canvas.toDataURL('image/jpeg', quality));
        observer.complete();
      };

      image.onerror = () => {
        // just use the imageBytes on a conversion issue
        observer.next(imageBytes);
        observer.complete();
      };

      image.src = imageBytes;
    });
  }

  /**
   * Returns the photosize in KiloBytes.
   * @param base64String base64 stream representing the photobytes
   */
  public static calculatePhotoSize(base64String): number {
    let padding; // base64 encodes three bytes to four characters. Sometimes, padding is added in the form of one or two ‘=’ characters.
    let inKiloBytes = 0;
    if (base64String.endsWith('==')) {
      padding = 2;
    } else if (base64String.endsWith('=')) {
      padding = 1;
    } else {
      padding = 0;
    }
    // divided by 1000 as we are using base 10 convention
    return (inKiloBytes = ((base64String.length / 4) * 3 - padding) / 1000);
  }

  public static toPhotoArrayFromPhotoIdArray(photoIds: PhotoId[]): Photo[] {
    const photos: Photo[] = [];
    photoIds?.forEach((photoId: PhotoId) => {
      photos.push(new Photo(photoId));
    });

    return photos;
  }
}
