import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent {
  @Input()
  hidden = false;

  @Input()
  showSelectDeselectAll = false;

  @Input()
  showBackButton: boolean;
  @Output()
  backButtonClicked = new EventEmitter<boolean>();

  @Output()
  selectAllButtonClicked = new EventEmitter<boolean>();

  @Output()
  deselectAllButtonClicked = new EventEmitter<boolean>();

  @Input()
  title: string;

  @Input()
  actionButton1Label: string;
  @Input()
  actionButton1IsMain = true;
  @Input()
  actionButton1Show = true;
  @Output()
  actionButton1Clicked = new EventEmitter<boolean>();

  @Input()
  actionButton2Label: string;
  @Input()
  actionButton2IsMain = true;
  @Input()
  actionButton2Show = true;
  @Output()
  actionButton2Clicked = new EventEmitter<boolean>();

  @Input()
  actionButton3Label: string;
  @Input()
  actionButton3IsMain = true;
  @Input()
  actionButton3Show = true;
  @Output()
  actionButton3Clicked = new EventEmitter<boolean>();

  @Input()
  actionButton4Label: string;
  @Input()
  actionButton4IsMain = true;
  @Input()
  actionButton4Show = true;
  @Output()
  actionButton4Clicked = new EventEmitter<boolean>();

  @Input()
  actionButton5Label: string;
  @Input()
  actionButton5IsMain = true;
  @Input()
  actionButton5Show = true;
  @Output()
  actionButton5Clicked = new EventEmitter<boolean>();

  @Input()
  actionButton6Label: string;
  @Input()
  actionButton6IsMain = true;
  @Input()
  actionButton6Show = true;
  @Output()
  actionButton6Clicked = new EventEmitter<boolean>();

  constructor() {}

  public selectAllButtonClick() {
    this.selectAllButtonClicked.emit(true);
  }

  public deselectAllButtonClick() {
    this.deselectAllButtonClicked.emit(true);
  }

  public backButtonClick() {
    this.backButtonClicked.emit(true);
  }

  public button1Click() {
    this.actionButton1Clicked.emit(true);
  }

  public button2Click() {
    this.actionButton2Clicked.emit(true);
  }

  public button3Click() {
    this.actionButton3Clicked.emit(true);
  }

  public button4Click() {
    this.actionButton4Clicked.emit(true);
  }

  public button5Click() {
    this.actionButton5Clicked.emit(true);
  }

  public button6Click() {
    this.actionButton6Clicked.emit(true);
  }
}
