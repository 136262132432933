import { Component, ChangeDetectionStrategy, Input, OnChanges } from '@angular/core';
import { NameAddressSection } from '../../../../enums/name-address-section.enum';
import { GetInspectionShipmentDetailsResp, InspectionShipment } from '@xpo-ltl/sdk-inspections';

@Component({
  selector: 'app-bill-details',
  templateUrl: './bill-details.component.html',
  styleUrls: ['./bill-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BillDetailsComponent implements OnChanges {
  constructor() {}

  @Input()
  shipmentDetails: InspectionShipment;

  NameAddressSection = NameAddressSection;

  shipperSectionExpanded = false;
  consigneeSectionExpanded = false;
  billtoSectionExpanded = false;

  isExpanded = false;

  ngOnChanges() {
    this.shipperSectionExpanded = false;
    this.consigneeSectionExpanded = false;
    this.billtoSectionExpanded = false;
    this.isExpanded = false;
  }

  expandCollapse(section: NameAddressSection) {
    if (section === NameAddressSection.Shipper) {
      this.shipperSectionExpanded = !this.shipperSectionExpanded;
    } else if (section === NameAddressSection.Consignee) {
      this.consigneeSectionExpanded = !this.consigneeSectionExpanded;
    } else {
      this.billtoSectionExpanded = !this.billtoSectionExpanded;
    }
  }
}
