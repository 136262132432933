<div class="customer-instructions">
  <mat-expansion-panel [(expanded)]="isExpanded">
    <mat-expansion-panel-header>
      <mat-panel-title class="section-label"> Customer Instructions </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="customer-instructions__table">
      <div *ngIf="inspectionShipmentDetails?.currentInspectionDetails">
        <div
          *ngFor="
            let instruction of inspectionShipmentDetails.currentInspectionDetails.custSpecificInspGuidelines;
            let i = index
          "
          class="customer-instructions__table-row"
        >
          <div class="label">{{ instruction?.customerId?.acctName }}</div>
          <div class="value">{{ instruction?.customerGuidelines?.note }}</div>
        </div>
      </div>
      <div *ngIf="!inspectionShipmentDetails?.shipmentDetails">
        There are no guidelines associated to this shipment.
      </div>
    </div>
  </mat-expansion-panel>
</div>
