import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { WebcamModule } from 'ngx-webcam2';

import { CameraComponent } from '../components/camera/camera.component';
import { CustomerGuidelinesFormComponent } from '../components/customer-guidelines/customer-guidelines-form/customer-guidelines-form.component';
import { CustomerGuidelinesModule } from '../components/customer-guidelines/customer-guidelines.module';
import { RecommendationRulesModule } from '../components/recommendation-rules/recommendation-rules.module';
import { RecommendedRulesFormComponent } from '../components/recommendation-rules/recommended-rules-form/recommended-rules-form.component';
import { RecommendedRulesSummaryComponent } from '../components/recommendation-rules/recommended-rules-summary/recommended-rules-summary.component';
import { WebCameraComponent } from '../components/web-camera/web-camera.component';
import { MaterialModule } from '../material.module';
import { XpoPipesModule } from '../pipes/xpo-pipes.module';
import { InspectionsDialogsService } from '../services/inspections-dialogs.service';
import { AddProComponent } from './add-pro/add-pro.component';
import { BroadcastMessageDialogComponent } from './broadcast-message-dialog/broadcast-message-dialog.component';
import { CameraDialogComponent } from './camera-dialog/camera-dialog.component';
import { ChangeLocationComponent } from './change-location/change-location.component';
import { ConfirmCancelComponent } from './confirm-cancel/confirm-cancel.component';
import { CustomerGuidelineDialogComponent } from './customer-guideline-dialog/customer-guideline-dialog.component';
import { CustomerInstructionsDialogComponent } from './customer-instructions-dialog/customer-instructions-dialog.component';
import { DevOnlyComponent } from './dev-only/dev-only.component';
import { IFrameDisplayComponent } from './iframe-display/iframe-display.component';
import { NotesDialogComponent } from './notes-dialog/notes-dialog.component';
import { RecommendationRuleDialogComponent } from './recommendation-rule-dialog/recommendation-rule-dialog.component';
import { SettingsComponent } from './settings/settings.component';
import { StatusChangeDialogComponent } from './status-change-dialog/status-change-dialog.component';
import { ToolsMenuComponent } from './tools-menu/tools-menu.component';
import { UnreadBroadcastMessageDialogComponent } from './unread-broadcast-message-dialog/unread-broadcast-message-dialog.component';
import { UnreadBroadcastMessageItemComponent } from './unread-broadcast-message-dialog/unread-broadcast-message-item/unread-broadcast-message-item.component';

const components = [
  ToolsMenuComponent,
  AddProComponent,
  ConfirmCancelComponent,
  ChangeLocationComponent,
  StatusChangeDialogComponent,
  SettingsComponent,
  RecommendationRuleDialogComponent,
  IFrameDisplayComponent,
  RecommendedRulesFormComponent,
  RecommendedRulesSummaryComponent,
  CustomerGuidelineDialogComponent,
  CustomerGuidelinesFormComponent,
  CustomerInstructionsDialogComponent,
  NotesDialogComponent,
  CameraDialogComponent,
  CameraComponent,
  BroadcastMessageDialogComponent,
  UnreadBroadcastMessageDialogComponent,
  UnreadBroadcastMessageItemComponent,
  DevOnlyComponent,
  WebCameraComponent
];
@NgModule({
    declarations: components,
    imports: [MaterialModule, XpoPipesModule, RecommendationRulesModule, CustomerGuidelinesModule, WebcamModule],
    providers: [InspectionsDialogsService],
    exports: components,
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DialogsModule {}
