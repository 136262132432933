import { Injectable } from '@angular/core';
import { DataOptions } from '@xpo-ltl/data-api';
import { AcctId, Comment, ShiftCd } from '@xpo-ltl/sdk-common';
import {
  FreightMovementApiService,
  GetTrailerLoadedShipmentsByDoorPath,
  GetTrailerLoadedShipmentsByDoorQuery,
  GetTrailerLoadedShipmentsByDoorResp,
  GetTrailerLoadedShipmentsPath,
  GetTrailerLoadedShipmentsQuery,
  GetTrailerLoadedShipmentsResp
} from '@xpo-ltl/sdk-freightmovement';
import {
  CreateInspectionRqst,
  EnrichedCustomerGuideline,
  GetInspectionDetailsPath,
  GetInspectionDetailsResp,
  GetInspectionShipmentDetailsPath,
  GetInspectionShipmentDetailsResp,
  GetInspectionStatusHashPath,
  GetInspectionStatusHashResp,
  GetInspectionStatusPath,
  GetInspectionStatusResp,
  GetLastRefreshRecommendationsInfoPath,
  GetLastRefreshRecommendationsInfoResp,
  GetShipmentLocationDetailsPath,
  GetShipmentLocationDetailsQuery,
  GetShipmentLocationDetailsResp,
  InspectionCustGuidelines,
  InspectionsApiService,
  InspectionShipment,
  InspectionStatusHash,
  ListInspectionShipmentDetailsPath,
  ListInspectionShipmentDetailsQuery,
  ListInspectionShipmentsPath,
  ListInspectionShipmentsQuery,
  ListInspectionShipmentsResp,
  ListPickupRequestsForInspectionPath,
  ListPickupRequestsForInspectionResp,
  ListPlannedShipmentsPath,
  ListPlannedShipmentsQuery,
  ListPlannedShipmentsResp,
  RefreshRecommendationsRqst,
  UpdateInspectionStatusResp,
  UpdateInspectionStatusRqst
} from '@xpo-ltl/sdk-inspections';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/internal/operators';
import { distinctUntilChanged, take } from 'rxjs/operators';
import { CustomerGuidelines } from '../classes/customer-guidelines';
import { InspectionListItem } from '../classes/inspection-list-item';
import { PlanningListItem } from '../classes/planning-list-item';
import { ProNumber } from '../classes/pronumber';
import { ErrorMessageActions } from '../enums/error-message-actions.enum';
import { InspectionState } from '../enums/inspection-state.enum';
import { ListName } from '../enums/list-name.enum';
import { RetryStrategy } from '../operators/retry-strategy';
import { RequestValidator } from '../validators/request.validator';
import { AppConstantsService } from './app-constants.service';
import { ErrorHandlingService } from './error-handling.service';

@Injectable()
export class ShipmentDetailsService {
  private static DEFAULT_DATA_OPTIONS = <DataOptions>{
    loadingOverlayEnabled: false,
    toastOnError: false
  };

  private static DEFAULT_LOADING_MESSAGE = 'Loading. Please Wait...';
  private static DEFAULT_PROCESSING_MESSAGE = 'Processing. Please Wait...';

  // Default Retry count
  private static DEFAULT_RETRY_COUNT = 4;

  // Initial 15 second timeout for any standard GET (Single Object) response
  private static DEFAULT_GET_TIMEOUT = 15000; // This will go up to 2 mins based on DEFAULT_RETRY_COUNT or 4...

  // Initial 30 second timeout for any standard GET (LIST) response
  private static DEFAULT_LIST_TIMEOUT = 30000; // This will go up to 4 mins based on DEFAULT_RETRY_COUNT or 4...

  // Wait no longer than 60 seconds for any UPDATE (PUT, POST) response
  private static DEFAULT_UPDATE_TIMEOUT = 60000;
  private static DEFAULT_UPDATE_RETRY_COUNT = 0; // Don't retry on Updates

  // Wait no longer than 5 mins for Refresh Recommendations
  private static DEFAULT_REFRESH_RECOMMENDATIONS_TIMEOUT = 300000;

  private static DEFAULT_RETRY_DELAY = 1000; // retry every second on (non-timeout) errors

  private lastRefreshDateSubject = new BehaviorSubject(<string>'');
  public lastRefreshDate$ = this.lastRefreshDateSubject.asObservable();

  private recommendedListDataSubject = new BehaviorSubject(<PlanningListItem[]>[]);
  public recommendedListData$ = this.recommendedListDataSubject.asObservable();

  private flaggedListDataSubject = new BehaviorSubject(<PlanningListItem[]>[]);
  public flaggedListData$ = this.flaggedListDataSubject.asObservable();

  private dismissedListDataSubject = new BehaviorSubject(<PlanningListItem[]>[]);
  public dismissedListData$ = this.dismissedListDataSubject.asObservable();

  private inspectedListDataSubject = new BehaviorSubject(<InspectionListItem[]>[]);
  public inspectedListData$ = this.inspectedListDataSubject.asObservable();

  private completedListDataSubject = new BehaviorSubject(<InspectionListItem[]>[]);
  public completedListData$ = this.completedListDataSubject.asObservable();

  // What is the date range for each list...
  // Default the Complete and Dismissed to 1 Day
  private listDateRangeMap: Map<ListName, string> = new Map([
    [ListName.Completed, '1'],
    [ListName.Dismissed, '1']
  ]);

  // This map is used to determine if the list needs to be refreshed or not
  // Just before the call to refresh the lists, we will call the getStatusHashCodes operation
  // If the returned inspectionStateHashCode is different than what is stored in this map
  // The refresh the lists corresponding to the Inspection State(s) that changed
  private inspectionStateHashCodeMap = new Map<InspectionState, string>();

  constructor(
    private constants: AppConstantsService,
    private inspectionsApiService: InspectionsApiService,
    private freightMovementService: FreightMovementApiService,
    private errorHandling: ErrorHandlingService,
    private retryStrategy: RetryStrategy
  ) {
    this.constants.inspectionContext$.pipe(distinctUntilChanged()).subscribe(() => {
      this.loadAllLists(true); // force refresh on SIC Change (really shouldn't matter)
    });
  }

  private getChangedListsByHashCode(newInspectionStateHashCodeValues: Map<InspectionState, string>): Set<ListName> {
    const changedLists = new Set<ListName>();
    // return all Lists if Map Parameter doesn't exist
    if (!newInspectionStateHashCodeValues) {
      changedLists.add(ListName.Recommended);
      changedLists.add(ListName.Flagged);
      changedLists.add(ListName.Dismissed);
      changedLists.add(ListName.Inspected);
      changedLists.add(ListName.Completed);
      return changedLists;
    }
    // check each status one by one
    if (
      this.isHashCodeDifferent(
        newInspectionStateHashCodeValues.get(InspectionState.RECOMMENDED),
        this.inspectionStateHashCodeMap.get(InspectionState.RECOMMENDED)
      )
    ) {
      changedLists.add(ListName.Recommended);
    }
    if (
      this.isHashCodeDifferent(
        newInspectionStateHashCodeValues.get(InspectionState.FLAGGED),
        this.inspectionStateHashCodeMap.get(InspectionState.FLAGGED)
      )
    ) {
      changedLists.add(ListName.Flagged);
    }
    if (
      this.isHashCodeDifferent(
        newInspectionStateHashCodeValues.get(InspectionState.DISMISSED),
        this.inspectionStateHashCodeMap.get(InspectionState.DISMISSED)
      )
    ) {
      changedLists.add(ListName.Dismissed);
    }
    if (
      this.isHashCodeDifferent(
        newInspectionStateHashCodeValues.get(InspectionState.INSPECTED),
        this.inspectionStateHashCodeMap.get(InspectionState.INSPECTED)
      ) ||
      this.isHashCodeDifferent(
        newInspectionStateHashCodeValues.get(InspectionState.IN_PROGRESS),
        this.inspectionStateHashCodeMap.get(InspectionState.IN_PROGRESS)
      ) ||
      this.isHashCodeDifferent(
        newInspectionStateHashCodeValues.get(InspectionState.EDITING),
        this.inspectionStateHashCodeMap.get(InspectionState.EDITING)
      )
    ) {
      changedLists.add(ListName.Inspected);
    }
    if (
      this.isHashCodeDifferent(
        newInspectionStateHashCodeValues.get(InspectionState.INSPECTED_NOT_CORRECTED),
        this.inspectionStateHashCodeMap.get(InspectionState.INSPECTED_NOT_CORRECTED)
      ) ||
      this.isHashCodeDifferent(
        newInspectionStateHashCodeValues.get(InspectionState.CORRECTION_SUBMITTED),
        this.inspectionStateHashCodeMap.get(InspectionState.CORRECTION_SUBMITTED)
      )
    ) {
      changedLists.add(ListName.Completed);
    }

    return changedLists;
  }

  private isHashCodeDifferent(hashCode1: string, hashCode2: string): boolean {
    let value1 = '';
    let value2 = '';
    if (hashCode1 != null) {
      value1 = hashCode1;
    }
    if (hashCode2 != null) {
      value2 = hashCode2;
    }
    return value1 !== value2;
  }

  public getInspectionDetailMap(proNbrs: string[]): Observable<Map<string, InspectionShipment>> {
    const pathParam = new ListInspectionShipmentDetailsPath();
    const queryParam = new ListInspectionShipmentDetailsQuery();

    RequestValidator.validateStringNotNullOrEmpty(this.constants?.inspectionContext?.inspectionSic, 'Inspection Sic');
    RequestValidator.validateStringNotNullOrEmpty(this.constants?.inspectionContext?.shiftCd, 'Shift Code');

    pathParam.inspectionSic = this.constants.inspectionContext?.inspectionSic;
    queryParam.shiftCd = this.constants.inspectionContext?.shiftCd;
    queryParam.proNbr = proNbrs;

    return this.inspectionsApiService
      .listInspectionShipmentDetails(pathParam, queryParam, ShipmentDetailsService.DEFAULT_DATA_OPTIONS)
      .pipe(
        map((resp) => this.convertArrayToMap(resp.shipmentDetails)),
        this.retryStrategy.retryStrategy(
          ShipmentDetailsService.DEFAULT_LIST_TIMEOUT,
          ShipmentDetailsService.DEFAULT_RETRY_DELAY,
          ShipmentDetailsService.DEFAULT_RETRY_COUNT,
          ShipmentDetailsService.DEFAULT_LOADING_MESSAGE,
          'INSPECTION-DETAIL-MAP'
        ),
        take(1),
        catchError((error) => {
          this.errorHandling.handleResponseError(error, ErrorMessageActions.GETTING, `Trailer/Load Door Shipments`);

          return EMPTY;
        })
      );
  }

  public listInspectionDetail(proNbrs: string[], showOnToastError = true): Observable<InspectionShipment[]> {
    const pathParam = new ListInspectionShipmentDetailsPath();
    const queryParam = new ListInspectionShipmentDetailsQuery();

    RequestValidator.validateStringNotNullOrEmpty(this.constants?.inspectionContext?.inspectionSic, 'Inspection Sic');
    RequestValidator.validateStringNotNullOrEmpty(this.constants?.inspectionContext?.shiftCd, 'Shift Code');

    pathParam.inspectionSic = this.constants.inspectionContext?.inspectionSic;
    queryParam.shiftCd = this.constants.inspectionContext?.shiftCd;
    queryParam.proNbr = proNbrs;

    return this.inspectionsApiService
      .listInspectionShipmentDetails(pathParam, queryParam, ShipmentDetailsService.DEFAULT_DATA_OPTIONS)
      .pipe(
        this.retryStrategy.retryStrategy(
          ShipmentDetailsService.DEFAULT_LIST_TIMEOUT,
          ShipmentDetailsService.DEFAULT_RETRY_DELAY,
          ShipmentDetailsService.DEFAULT_RETRY_COUNT,
          ShipmentDetailsService.DEFAULT_LOADING_MESSAGE,
          'LIST-INSPECTION-DETAIL'
        ),
        take(1),
        map((resp) => resp.shipmentDetails),
        catchError((error) => {
          this.errorHandling.handleResponseError(error, ErrorMessageActions.GETTING, `Inspection shipment details`);

          return EMPTY;
        })
      );
  }

  listPickupRequestsForInspection(): Observable<ListPickupRequestsForInspectionResp> {
    RequestValidator.validateStringNotNullOrEmpty(this.constants?.inspectionContext?.inspectionSic, 'Inspection Sic');
    RequestValidator.validateStringNotNullOrEmpty(this.constants?.inspectionContext?.shiftCd, 'Shift Code');

    const pathParam = new ListPickupRequestsForInspectionPath();
    pathParam.shiftCd = this.constants.inspectionContext.shiftCd as ShiftCd;
    pathParam.sicCd = this.constants.inspectionContext.inspectionSic;

    return this.inspectionsApiService.listPickupRequestsForInspection(pathParam).pipe(
      catchError((error) => {
        this.errorHandling.handleResponseError(error, ErrorMessageActions.GETTING, 'Pickup Requests');

        return EMPTY;
      })
    );
  }

  private convertArrayToMap(arr: InspectionShipment[]): Map<string, InspectionShipment> {
    const detailMap = new Map<string, InspectionShipment>();
    arr.forEach((detail) => detailMap.set(detail.shipmentId.proNumber, detail));
    return detailMap;
  }

  public changeStatus(
    proNumber: ProNumber | Array<ProNumber>,
    inspectionState: InspectionState,
    warningAcceptedInd: boolean = false
  ): Observable<UpdateInspectionStatusResp> {
    RequestValidator.validateObjectNotUndefinedOrEmpty(this.constants?.inspectionContext, 'Inspection Context');

    const request: UpdateInspectionStatusRqst = new UpdateInspectionStatusRqst();
    request.proNbr = new Array<string>();
    if (Array.isArray(proNumber)) {
      if (proNumber?.length > 0) {
        proNumber?.forEach((pro) => {
          RequestValidator.validateObjectNotUndefinedOrEmpty(pro, RequestValidator.PRO_NBR);
          RequestValidator.validateProNumber(pro);
          request.proNbr.push(pro.formatProNumber());
        });
      } else {
        throw new Error(`${RequestValidator.PRO_NBR} ${RequestValidator.ERROR_CANNOT_BE_EMPTY}`);
      }
    } else {
      RequestValidator.validateObjectNotUndefinedOrEmpty(proNumber, RequestValidator.PRO_NBR);
      RequestValidator.validateProNumber(proNumber);
      request.proNbr.push(proNumber.formatProNumber());
    }
    request.statusCd = <string>inspectionState;
    request.inspectionContext = this.constants.inspectionContext;
    request.warningAcceptedInd = warningAcceptedInd;
    request.manuallyAddInd = false;

    return this.inspectionsApiService.updateInspectionStatus(request, ShipmentDetailsService.DEFAULT_DATA_OPTIONS).pipe(
      this.retryStrategy.retryStrategy(
        ShipmentDetailsService.DEFAULT_UPDATE_TIMEOUT,
        ShipmentDetailsService.DEFAULT_RETRY_DELAY,
        ShipmentDetailsService.DEFAULT_UPDATE_RETRY_COUNT,
        ShipmentDetailsService.DEFAULT_PROCESSING_MESSAGE,
        'UPDATE-INSPECTION-STATUS'
      ),
      take(1),
      catchError((error) => {
        this.errorHandling.handleResponseError(error, ErrorMessageActions.UPDATING, `status`);

        return EMPTY;
      })
    );
  }

  public manuallyAddShipment(proNumber: ProNumber): Observable<boolean> {
    RequestValidator.validateObjectNotUndefinedOrEmpty(this.constants?.inspectionContext, 'Inspection Context');
    RequestValidator.validateObjectNotUndefinedOrEmpty(proNumber, RequestValidator.PRO_NBR);
    RequestValidator.validateProNumber(proNumber);

    const request: UpdateInspectionStatusRqst = new UpdateInspectionStatusRqst();
    request.proNbr = [proNumber.formatProNumber()];
    request.statusCd = null; // null Status code, means don't change existing status
    request.inspectionContext = this.constants.inspectionContext;
    request.warningAcceptedInd = false;
    request.manuallyAddInd = true;

    return this.inspectionsApiService.updateInspectionStatus(request, ShipmentDetailsService.DEFAULT_DATA_OPTIONS).pipe(
      this.retryStrategy.retryStrategy(
        ShipmentDetailsService.DEFAULT_UPDATE_TIMEOUT,
        ShipmentDetailsService.DEFAULT_RETRY_DELAY,
        ShipmentDetailsService.DEFAULT_UPDATE_RETRY_COUNT,
        ShipmentDetailsService.DEFAULT_PROCESSING_MESSAGE,
        'UPDATE-INSPECTION-STATUS'
      ),
      take(1),
      map(
        (response: UpdateInspectionStatusResp) => {
          if (!response) {
            throw new Error('Unknown Error: UpdateInspectionStatusResp is empty.');
          }
          if (response?.validationErrors?.length > 0) {
            throw new Error(`Validation Error: ${response.validationErrors[0].message}`);
          }

          return true;
        },
        (error) => {
          throw error;
        }
      ),
      catchError((error) => {
        this.errorHandling.handleResponseError(error, `manually ${ErrorMessageActions.ADDING}`, `shipment`);

        return EMPTY;
      })
    );
  }

  public getInspectionStatus(proNumber: ProNumber): Observable<GetInspectionStatusResp> {
    RequestValidator.validateStringNotNullOrEmpty(this.constants?.inspectionContext?.inspectionSic, 'Inspection Sic');
    RequestValidator.validateObjectNotUndefinedOrEmpty(proNumber, RequestValidator.PRO_NBR);
    RequestValidator.validateProNumber(proNumber);

    const request: GetInspectionStatusPath = new GetInspectionStatusPath();
    request.proNumber = proNumber.formatProNumber();
    request.inspectionSic = this.constants.inspectionContext?.inspectionSic;

    return this.inspectionsApiService.getInspectionStatus(request, ShipmentDetailsService.DEFAULT_DATA_OPTIONS).pipe(
      this.retryStrategy.retryStrategy(
        ShipmentDetailsService.DEFAULT_GET_TIMEOUT,
        ShipmentDetailsService.DEFAULT_RETRY_DELAY,
        ShipmentDetailsService.DEFAULT_RETRY_COUNT,
        ShipmentDetailsService.DEFAULT_LOADING_MESSAGE,
        'GET-INSPECTION-STATUS'
      ),
      take(1),
      catchError((error) => {
        this.errorHandling.handleResponseError(error, ErrorMessageActions.GETTING, `Inspection status`);

        return EMPTY;
      })
    );
  }

  public refreshRecommendations(): Observable<void> {
    const request: RefreshRecommendationsRqst = new RefreshRecommendationsRqst();
    request.inspectionContext = this.constants.inspectionContext;

    return this.inspectionsApiService.refreshRecommendations(request, ShipmentDetailsService.DEFAULT_DATA_OPTIONS).pipe(
      this.retryStrategy.retryStrategy(
        ShipmentDetailsService.DEFAULT_REFRESH_RECOMMENDATIONS_TIMEOUT,
        ShipmentDetailsService.DEFAULT_RETRY_DELAY,
        0,
        'Refreshing Recommendations. Please Wait...',
        'REFRESH-RECOMMENDATIONS'
      ), // Don't ever retry
      catchError((error) => {
        this.errorHandling.handleResponseError(error, ErrorMessageActions.REFRESHING, `Recommendations`);

        return EMPTY;
      })
    );
  }

  public loadAllLists(forceRefreshAllLists: boolean = false): void {
    // Don't load anything if we don't have an inspection context yet
    if (!this.constants.inspectionContext?.inspectionSic) {
      return;
    }

    this.getInspectionStatusHash()
      .pipe(
        take(1),
        catchError((error) => {
          // if error, just update all lists
          this.lastRefreshDateSubject.next(AppConstantsService.getCurrentFormattedDateTime());
          this.getRecommendedShipments();
          this.getFlaggedShipments();
          this.getInspectedShipments();
          this.getCompletedShipments();
          this.getDismissedShipments();

          this.errorHandling.handleResponseError(error, ErrorMessageActions.GETTING, `Inspection status hash`);

          return EMPTY;
        })
      )
      .subscribe((inspectionStatusHashCodes: Map<InspectionState, string>) => {
        let changedLists: Set<ListName>;
        if (forceRefreshAllLists) {
          // update the lastRefreshDate if we're forcing a full refresh...
          this.lastRefreshDateSubject.next(AppConstantsService.getCurrentFormattedDateTime());
          changedLists = this.getChangedListsByHashCode(null); // Passing in null will refresh all lists
        } else {
          changedLists = this.getChangedListsByHashCode(inspectionStatusHashCodes);
        }
        // Set inspectionStateHashCode Map to new one
        this.inspectionStateHashCodeMap = inspectionStatusHashCodes;

        // Per change WI-2371 - https://jira.xpo.com/browse/WI-2371
        // For now, we decided to refresh the Recommended and Flagged Lists
        // everytime instead of waiting for a List Data change
        // if (changedLists.has(ListName.Recommended)) {
        this.getRecommendedShipments();
        // }
        // if (changedLists.has(ListName.Flagged)) {
        this.getFlaggedShipments();
        // }
        if (changedLists.has(ListName.Inspected)) {
          this.getInspectedShipments();
        }
        if (changedLists.has(ListName.Completed)) {
          this.getCompletedShipments();
        }
        if (changedLists.has(ListName.Dismissed)) {
          this.getDismissedShipments();
        }
      });
  }

  private getInspectionStatusHash(): Observable<Map<InspectionState, string>> {
    if (
      !this.constants?.inspectionContext?.inspectionSic ||
      this.constants?.inspectionContext?.inspectionSic?.trim().length === 0
    ) {
      return; // Just complete.. no next value(Note from Matthew-Havlovick)
    }

    const pathParams: GetInspectionStatusHashPath = new GetInspectionStatusHashPath();
    pathParams.inspectionSic = this.constants.inspectionContext?.inspectionSic;

    return this.inspectionsApiService
      .getInspectionStatusHash(pathParams, ShipmentDetailsService.DEFAULT_DATA_OPTIONS)
      .pipe(
        this.retryStrategy.retryStrategy(
          ShipmentDetailsService.DEFAULT_GET_TIMEOUT,
          0, // don't retry
          0, // don't retry
          ShipmentDetailsService.DEFAULT_LOADING_MESSAGE,
          'GET-INSPECTION-STATUS-HASH-CODES'
        ),
        take(1),
        map((response: GetInspectionStatusHashResp) => {
          if (!response) {
            return; // Just complete.. no next value(Note from Matthew-Havlovick)
          }
          const inspectionStatusHashCodes = new Map<InspectionState, string>();
          response.inspectionStatusHashes?.forEach((value: InspectionStatusHash) => {
            inspectionStatusHashCodes.set(<InspectionState>value.inspectionStatusMessage, value.hashCode);
          });
          return inspectionStatusHashCodes;
        }),
        catchError((error) => {
          this.errorHandling.handleResponseError(error, ErrorMessageActions.GETTING, `Inspection status hash`);

          return EMPTY;
        })
      );
  }

  private getRecommendedShipments(): void {
    this.getPlannedShipments(InspectionState.RECOMMENDED)
      .pipe(
        take(1),
        catchError((error) => {
          this.errorHandling.handleResponseError(error, ErrorMessageActions.GETTING, `Recommended shipments`);

          return EMPTY;
        })
      )
      .subscribe((response) => {
        if (response.plannedShipments) {
          const rowData = PlanningListItem.mapInspectionShipments(response.plannedShipments);
          PlanningListItem.sortList(rowData, ListName.Recommended);
          this.recommendedListDataSubject.next(rowData);
        }
      });
  }

  private setCustomerGuidelinesForShipments(
    planningListItems: PlanningListItem[],
    customerGuidelines: CustomerGuidelines[]
  ): PlanningListItem[] {
    planningListItems?.forEach((planningListItem) => {
      const madCodes = this.getPlanningListItemMadCodes(planningListItem);
      // if there's no madcodes on shipment, set default message.
      if (madCodes.length === 0) {
        planningListItem.customerInstructions.push('None');
      } else {
        if (customerGuidelines) {
          madCodes.forEach((madCode) => {
            const guidelineItem = customerGuidelines.find((item) => item.acctMadCd === madCode);
            if (guidelineItem) {
              planningListItem.customerInstructions.push(guidelineItem.guideline);
            }
          });
        }
        // if no customerGuidelines matches shipment's MADCodes, set default message.
        if (planningListItem.customerInstructions.length === 0) {
          planningListItem.customerInstructions.push('None');
        }
      }
    });
    return planningListItems;
  }

  private getFlaggedShipments(): void {
    this.getPlannedShipments(InspectionState.FLAGGED)
      .pipe(
        take(1),
        catchError((error) => {
          this.errorHandling.handleResponseError(error, ErrorMessageActions.GETTING, `Flagged shipments`);

          return EMPTY;
        })
      )
      .subscribe((response) => {
        if (response) {
          const rowData = PlanningListItem.mapInspectionShipments(response.plannedShipments);
          PlanningListItem.sortList(rowData, ListName.Flagged);
          this.flaggedListDataSubject.next(rowData);
        }
      });
  }

  private getDismissedShipments(): void {
    const dateRangeValue = this.listDateRangeMap.get(ListName.Dismissed);
    let fromDate: number;
    if (dateRangeValue) {
      fromDate = new Date().getTime() - +dateRangeValue * 24 * 60 * 60 * 1000; // turn days into fromDate now - (days*ms per day)
    }
    this.getPlannedShipments(InspectionState.DISMISSED, fromDate)
      .pipe(
        take(1),
        catchError((error) => {
          this.errorHandling.handleResponseError(error, ErrorMessageActions.GETTING, `Dismissed shipments`);

          return EMPTY;
        })
      )
      .subscribe((response) => {
        if (response) {
          const rowData = PlanningListItem.mapInspectionShipments(response.plannedShipments);
          PlanningListItem.sortList(rowData, ListName.Dismissed);
          this.dismissedListDataSubject.next(rowData);
        }
      });
  }

  private getPlannedShipments(
    statusCodes: InspectionState | Array<InspectionState>,
    fromDate: number = null
  ): Observable<ListPlannedShipmentsResp> {
    RequestValidator.validateArrayOrSingleObjectNotEmpty(statusCodes, 'Status Code');
    RequestValidator.validateStringNotNullOrEmpty(this.constants?.inspectionContext?.inspectionSic, 'Inspection Sic');
    RequestValidator.validateStringNotNullOrEmpty(this.constants?.inspectionContext?.shiftCd, 'Shift Code');

    const codes = Array.isArray(statusCodes) ? statusCodes : [statusCodes];
    const pathParam: ListPlannedShipmentsPath = new ListPlannedShipmentsPath();
    const queryParams = new ListPlannedShipmentsQuery();
    pathParam.inspectionSic = this.constants.inspectionContext?.inspectionSic;
    queryParams.shiftCd = this.constants.inspectionContext?.shiftCd;
    queryParams.plannedShipmentStatusCds = <string[]>codes;

    if (fromDate) {
      queryParams.fromDt = new Date(fromDate);
    }

    const hasRecommendedStatus: boolean =
      queryParams.plannedShipmentStatusCds.indexOf(InspectionState.RECOMMENDED) > -1;

    return this.inspectionsApiService
      .listPlannedShipments(pathParam, queryParams, ShipmentDetailsService.DEFAULT_DATA_OPTIONS)
      .pipe(
        this.retryStrategy.retryStrategy(
          ShipmentDetailsService.DEFAULT_LIST_TIMEOUT,
          ShipmentDetailsService.DEFAULT_RETRY_DELAY,
          ShipmentDetailsService.DEFAULT_RETRY_COUNT,
          ShipmentDetailsService.DEFAULT_LOADING_MESSAGE,
          'GET-PLANNED-SHIPMENTS-' + codes // Need to append codes so the stats update correctly
        ),
        take(1),
        catchError((error) => {
          this.errorHandling.handleResponseError(error, ErrorMessageActions.GETTING, `Planned shipments`);

          return EMPTY;
        }),
        finalize(() => {
          if (hasRecommendedStatus) {
            this.updateLastRefreshRecommendationsDate();
          }
        })
      );
  }

  public updateLastRefreshRecommendationsDate(): void {
    RequestValidator.validateStringNotNullOrEmpty(this.constants?.inspectionContext?.inspectionSic, 'Inspection Sic');

    const request: GetLastRefreshRecommendationsInfoPath = new GetLastRefreshRecommendationsInfoPath();
    request.inspectionSic = this.constants.inspectionContext?.inspectionSic;
    this.inspectionsApiService
      .getLastRefreshRecommendationsInfo(request, ShipmentDetailsService.DEFAULT_DATA_OPTIONS)
      .pipe(
        this.retryStrategy.retryStrategy(
          ShipmentDetailsService.DEFAULT_GET_TIMEOUT,
          ShipmentDetailsService.DEFAULT_RETRY_DELAY,
          ShipmentDetailsService.DEFAULT_RETRY_COUNT,
          null,
          'GET-LAST-REFRESH-RECOMMENDATIONS-INFO'
        ),
        take(1),
        catchError((error) => {
          this.errorHandling.handleResponseError(
            error,
            ErrorMessageActions.GETTING,
            `Last Refresh Recommendations Time`
          );

          return EMPTY;
        })
      )
      .subscribe((response: GetLastRefreshRecommendationsInfoResp) => {
        if (response) {
          this.constants.setLastRefreshRecommendationsDate(response.lastRefreshRecommendationsTmst);
        }
      });
  }

  private getInspectedShipments(): void {
    this.listInspectedShipments([InspectionState.INSPECTED, InspectionState.IN_PROGRESS, InspectionState.EDITING])
      .pipe(
        take(1),
        catchError((error) => {
          this.errorHandling.handleResponseError(error, ErrorMessageActions.GETTING, `Inspected shipments`);

          return EMPTY;
        })
      )
      .subscribe((response: ListInspectionShipmentsResp) => {
        if (response) {
          const rowData = InspectionListItem.mapInspectionShipments(response.inspectionShipments);
          InspectionListItem.sortList(rowData, ListName.Inspected);
          this.inspectedListDataSubject.next(rowData);
        }
      });
  }

  private getCompletedShipments(): void {
    const dateRangeValue = this.listDateRangeMap.get(ListName.Completed);
    let fromDate: number;
    if (dateRangeValue) {
      fromDate = new Date().getTime() - +dateRangeValue * 24 * 60 * 60 * 1000; // turn days into fromDate now - (days*ms per day)
    }
    this.listInspectedShipments(
      [InspectionState.CORRECTION_SUBMITTED, InspectionState.INSPECTED_NOT_CORRECTED],
      fromDate
    )
      .pipe(
        take(1),
        catchError((error) => {
          this.errorHandling.handleResponseError(error, ErrorMessageActions.GETTING, `Completed shipments`);

          return EMPTY;
        })
      )
      .subscribe((response: ListInspectionShipmentsResp) => {
        if (response) {
          const rowData = InspectionListItem.mapInspectionShipments(response.inspectionShipments);
          InspectionListItem.sortList(rowData, ListName.Completed);
          this.completedListDataSubject.next(rowData);
        }
      });
  }

  private listInspectedShipments(
    statusCodes: InspectionState | Array<InspectionState>,
    fromDate: number = null
  ): Observable<ListInspectionShipmentsResp> {
    RequestValidator.validateArrayOrSingleObjectNotEmpty(statusCodes, 'Status Code');
    RequestValidator.validateStringNotNullOrEmpty(this.constants?.inspectionContext?.inspectionSic, 'Inspection Sic');
    RequestValidator.validateStringNotNullOrEmpty(this.constants?.inspectionContext?.shiftCd, 'Shift Code');

    const codes: InspectionState[] = Array.isArray(statusCodes) ? statusCodes : [statusCodes];
    const pathParam: ListInspectionShipmentsPath = new ListInspectionShipmentsPath();
    const queryParams: ListInspectionShipmentsQuery = new ListInspectionShipmentsQuery();
    pathParam.inspectionSic = this.constants.inspectionContext?.inspectionSic;
    queryParams.inspectionStatusCodes = <string[]>codes;
    queryParams.shiftCd = this.constants.inspectionContext?.shiftCd;
    if (fromDate) {
      queryParams.fromDt = new Date(fromDate);
    }

    return this.inspectionsApiService
      .listInspectionShipments(pathParam, queryParams, ShipmentDetailsService.DEFAULT_DATA_OPTIONS)
      .pipe(
        this.retryStrategy.retryStrategy(
          ShipmentDetailsService.DEFAULT_LIST_TIMEOUT,
          ShipmentDetailsService.DEFAULT_RETRY_DELAY,
          ShipmentDetailsService.DEFAULT_RETRY_COUNT,
          ShipmentDetailsService.DEFAULT_LOADING_MESSAGE,
          'GET-INSPECTED-SHIPMENTS-' + codes // Need to append codes so the stats update correctly
        ),
        take(1),
        catchError((error) => {
          this.errorHandling.handleResponseError(error, ErrorMessageActions.LOADING, `Inspection shipments`);

          return EMPTY;
        })
      );
  }

  public getInspectionShipmentDetails(proNumber: ProNumber): Observable<GetInspectionShipmentDetailsResp> {
    RequestValidator.validateStringNotNullOrEmpty(this.constants?.inspectionContext?.inspectionSic, 'Inspection Sic');
    RequestValidator.validateObjectNotUndefinedOrEmpty(proNumber, RequestValidator.PRO_NBR);
    RequestValidator.validateProNumber(proNumber);

    const pathParam: GetInspectionShipmentDetailsPath = new GetInspectionShipmentDetailsPath();
    pathParam.inspectionSic = this.constants.inspectionContext?.inspectionSic;
    pathParam.proNumber = proNumber.formatProNumber();

    return this.inspectionsApiService
      .getInspectionShipmentDetails(pathParam, ShipmentDetailsService.DEFAULT_DATA_OPTIONS)
      .pipe(
        take(1),
        this.retryStrategy.retryStrategy(
          ShipmentDetailsService.DEFAULT_GET_TIMEOUT,
          ShipmentDetailsService.DEFAULT_RETRY_DELAY,
          ShipmentDetailsService.DEFAULT_RETRY_COUNT,
          ShipmentDetailsService.DEFAULT_LOADING_MESSAGE,
          'GET-INSPECTION-SHIPMENT-DETAILS'
        ),
        catchError((error) => {
          this.errorHandling.handleResponseError(error, ErrorMessageActions.GETTING, `shipment details`);

          return EMPTY;
        })
      );
  }

  public getShipmentLocationDetails(proNumber: ProNumber): Observable<GetShipmentLocationDetailsResp> {
    RequestValidator.validateStringNotNullOrEmpty(this.constants?.inspectionContext?.inspectionSic, 'Inspection Sic');
    RequestValidator.validateStringNotNullOrEmpty(this.constants?.inspectionContext?.shiftCd, 'Shift Code');
    RequestValidator.validateObjectNotUndefinedOrEmpty(proNumber, RequestValidator.PRO_NBR);
    RequestValidator.validateProNumber(proNumber);

    const pathParam: GetShipmentLocationDetailsPath = new GetShipmentLocationDetailsPath();
    const queryParams = new GetShipmentLocationDetailsQuery();
    pathParam.inspectionSic = this.constants.inspectionContext?.inspectionSic;
    pathParam.proNumber = proNumber.formatProNumber();
    queryParams.shiftCd = this.constants.inspectionContext?.shiftCd;

    return this.inspectionsApiService
      .getShipmentLocationDetails(pathParam, queryParams, ShipmentDetailsService.DEFAULT_DATA_OPTIONS)
      .pipe(
        this.retryStrategy.retryStrategy(
          ShipmentDetailsService.DEFAULT_GET_TIMEOUT,
          ShipmentDetailsService.DEFAULT_RETRY_DELAY,
          ShipmentDetailsService.DEFAULT_RETRY_COUNT,
          ShipmentDetailsService.DEFAULT_LOADING_MESSAGE,
          'GET-SHIPMENT-LOCATION-DETAILS'
        ),
        take(1),
        catchError((error) => {
          this.errorHandling.handleResponseError(error, ErrorMessageActions.GETTING, `shipment location details`);

          return EMPTY;
        })
      );
  }

  public createInspection(inspectionShipment: InspectionShipment): Observable<void> {
    const request = new CreateInspectionRqst();
    request.inspection = inspectionShipment;
    request.inspection.inspectionContext = this.constants.inspectionContext;

    return this.inspectionsApiService.createInspection(request, ShipmentDetailsService.DEFAULT_DATA_OPTIONS).pipe(
      this.retryStrategy.retryStrategy(
        ShipmentDetailsService.DEFAULT_UPDATE_TIMEOUT,
        ShipmentDetailsService.DEFAULT_RETRY_DELAY,
        ShipmentDetailsService.DEFAULT_UPDATE_RETRY_COUNT,
        ShipmentDetailsService.DEFAULT_PROCESSING_MESSAGE,
        'CREATE-INSPECTION'
      ),
      take(1),
      catchError((error) => {
        this.errorHandling.handleResponseError(error, ErrorMessageActions.CREATING, `Inspection`);

        return EMPTY;
      })
    );
  }

  public getInspectionDetails(proNumber: string): Observable<GetInspectionDetailsResp> {
    RequestValidator.validateStringNotNullOrEmpty(this.constants?.inspectionContext?.inspectionSic, 'Inspection Sic');
    RequestValidator.validateObjectNotUndefinedOrEmpty(new ProNumber(proNumber), RequestValidator.PRO_NBR);
    RequestValidator.validateProNumber(new ProNumber(proNumber));

    const pathParam: GetInspectionDetailsPath = new GetInspectionDetailsPath();
    pathParam.inspectionSic = this.constants.inspectionContext?.inspectionSic;
    pathParam.proNumber = new ProNumber(proNumber).formatProNumber();

    return this.inspectionsApiService.getInspectionDetails(pathParam, ShipmentDetailsService.DEFAULT_DATA_OPTIONS).pipe(
      this.retryStrategy.retryStrategy(
        ShipmentDetailsService.DEFAULT_GET_TIMEOUT,
        ShipmentDetailsService.DEFAULT_RETRY_DELAY,
        ShipmentDetailsService.DEFAULT_RETRY_COUNT,
        ShipmentDetailsService.DEFAULT_LOADING_MESSAGE,
        'GET-INSPECTION-DETAILS'
      ),
      take(1),
      catchError((error) => {
        this.errorHandling.handleResponseError(error, ErrorMessageActions.GETTING, `Inspection details`);

        return EMPTY;
      })
    );
  }

  public getTrailerLoadedShipments(trailerId: string): Observable<GetTrailerLoadedShipmentsResp> {
    const pathParam = new GetTrailerLoadedShipmentsPath();
    const queryParam = new GetTrailerLoadedShipmentsQuery();
    pathParam.equipmentPrefixSuffix = trailerId;
    queryParam.planSic = this.constants.inspectionContext?.inspectionSic;
    queryParam.planShiftCd = this.constants.inspectionContext?.shiftCd[0]; // Legacy API only takes the first letter

    return this.freightMovementService
      .getTrailerLoadedShipments(pathParam, queryParam, ShipmentDetailsService.DEFAULT_DATA_OPTIONS)
      .pipe(
        this.retryStrategy.retryStrategy(
          ShipmentDetailsService.DEFAULT_LIST_TIMEOUT,
          ShipmentDetailsService.DEFAULT_RETRY_DELAY,
          ShipmentDetailsService.DEFAULT_RETRY_COUNT,
          ShipmentDetailsService.DEFAULT_LOADING_MESSAGE,
          'GET-TRAILER-LOADED-SHIPMENTS'
        ),
        take(1)
      );
  }

  public getTrailerLoadedShipmentsByDoor(doorId: string): Observable<GetTrailerLoadedShipmentsByDoorResp> {
    const pathParam = new GetTrailerLoadedShipmentsByDoorPath();
    const queryParam = new GetTrailerLoadedShipmentsByDoorQuery();
    pathParam.loadDoor = Number(doorId);
    queryParam.planSic = this.constants.inspectionContext?.inspectionSic;
    queryParam.planShiftCd = this.constants.inspectionContext?.shiftCd[0]; // Legacy API only takes the first letter

    return this.freightMovementService
      .getTrailerLoadedShipmentsByDoor(pathParam, queryParam, ShipmentDetailsService.DEFAULT_DATA_OPTIONS)
      .pipe(
        this.retryStrategy.retryStrategy(
          ShipmentDetailsService.DEFAULT_LIST_TIMEOUT,
          ShipmentDetailsService.DEFAULT_RETRY_DELAY,
          ShipmentDetailsService.DEFAULT_RETRY_COUNT,
          ShipmentDetailsService.DEFAULT_LOADING_MESSAGE,
          'GET-TRAILER-LOADED-SHIPMENTS-BY-DOOR'
        ),
        take(1)
      );
  }

  public getListDateRange(listName: ListName): string {
    return this.listDateRangeMap.get(listName);
  }

  // Update list range and force refresh of list
  public setListDateRange(listName: ListName, dateRangeValue: string) {
    this.listDateRangeMap.set(listName, dateRangeValue);
    if (listName === ListName.Dismissed) {
      this.getDismissedShipments();
    } else if (listName === ListName.Completed) {
      this.getCompletedShipments();
    }
  }

  private getPlanningListItemMadCodes(planningList: PlanningListItem): string[] {
    const madCodes: string[] = [];
    madCodes.push(planningList.shipperMadCd, planningList.consigneeMadCd, planningList.bil2MadCd);
    return madCodes.filter(Boolean);
  }

  // For reference customer guidelines returns different response type than expected in ShipmentDetails customer Instructions.
  // For future, either update shipmentdetails or customer guidelines to handle the same class
  // For even more distant future, update shipmentdetails service call to retrieve the correct customer guidelines data.
  private mapEnrichedCustGuidelineToInspectionCustGuideline(
    enrichedCustGuideline: EnrichedCustomerGuideline
  ): InspectionCustGuidelines {
    if (enrichedCustGuideline) {
      const inspCustGuideline = new InspectionCustGuidelines();

      const accId = new AcctId();
      accId.acctInstId = enrichedCustGuideline.customer?.acctInstId;
      accId.acctMadCd = enrichedCustGuideline.customer?.acctMadCd;
      accId.acctName = enrichedCustGuideline.customer?.acctName;

      const comment = new Comment();
      comment.note = enrichedCustGuideline.guideline;

      inspCustGuideline.customerId = accId;
      inspCustGuideline.customerGuidelines = comment;

      return inspCustGuideline;
    }
  }
}
