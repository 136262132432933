<mat-card>
  <div *ngIf="customerGuidelinesService.isManagerActionsAvailable$ | async">
    <p>Fill out all the information bellow. All fields are required.</p>
  </div>
  <br />
  <div class="form-guideline">
    <div>
      <div class="label">
        <label>MAD Code </label>
      </div>

      <mat-form-field floatLabel="never" class="mad-code-input">
        <input
          matInput
          (focusout)="validateInputMadCode()"
          [(ngModel)]="madCode"
          (ngModelChange)="searchMadCode()"
          maxlength="11"
          placeholder="Enter MAD Code"
          [readonly]="!(customerGuidelinesService.isManagerActionsAvailable$ | async)"
        />
        <mat-hint>{{ getAccountName() }}</mat-hint>
      </mat-form-field>
      <div *ngIf="!this.madCodeIsValid.getIsValid()" class="mat-error">{{ this.madCodeIsValid.getMessage() }}</div>
    </div>
  </div>
  <br /><br />
  <div class="guideline">
    <div>
      <div class="label">
        <label>Guideline </label>
      </div>
      <mat-form-field class="guideline__input">
        <textarea
          matInput
          [disabled]="!getMadCodeIsValid()"
          [ngModel]="getGuideline()"
          (ngModelChange)="setGuideline($event)"
          maxlength="4000"
          cdkTextareaAutosize
          #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="2"
          cdkAutosizeMaxRows="100"
          [readonly]="!(customerGuidelinesService.isManagerActionsAvailable$ | async)"
        >
        </textarea>
      </mat-form-field>
    </div>
  </div>
</mat-card>
