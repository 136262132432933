<mat-dialog-actions>
  <div class="content-left">
    <h2 mat-dialog-title>{{ title }}</h2>
  </div>
  <div class="content-right">
    <button mat-button (click)="clear()" *ngIf="!isFormValidated && isManagerActionsAvailable">Clear Form</button>
    <button mat-button (click)="close()" *ngIf="!isManagerActionsAvailable">Close</button>
  </div>
</mat-dialog-actions>
<br />
<mat-divider></mat-divider>
<mat-dialog-content>
  <div *ngIf="!isFormValidated">
    <app-customer-guidelines-form></app-customer-guidelines-form>
  </div>

  <div *ngIf="isFormValidated && isManagerActionsAvailable">
    <br />
    <br />
    <div class="warning">
      <p>{{ getWarningFromAction() }}</p>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions *ngIf="isManagerActionsAvailable">
  <div class="content-left">
    <button
      mat-flat-button
      color="warn"
      (click)="deleteGuideline()"
      [disabled]="!this.customerGuidelinesService.getCustomerHasGuideline()"
      *ngIf="!isFormValidated"
    >
      Delete
    </button>
  </div>
  <div class="content-right">
    <button mat-button (click)="close()" *ngIf="!isFormValidated">Cancel</button>
    <button mat-button (click)="backFormScreen()" *ngIf="isFormValidated">No</button>
    <button mat-button color="primary" (click)="confirmDialog()" [disabled]="!validForm()" *ngIf="!isFormValidated">
      Save
    </button>
    <button mat-button color="primary" (click)="performCustomerGuidelineOperation()" *ngIf="isFormValidated">
      Yes
    </button>
  </div>
</mat-dialog-actions>
