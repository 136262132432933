import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { GetInspectionShipmentDetailsResp } from '@xpo-ltl/sdk-inspections';
import { ProNumber } from '../../../../classes/pronumber';
import { ConfigManagerProperties } from '../../../../enums/config-manager-properties.enum';
import { InspectionState } from '../../../../enums/inspection-state.enum';
import { AppConstantsService } from '../../../../services/app-constants.service';
import { XpoDialogManagerService } from '../../../../services/xpo-dialog-manager.service';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OverviewComponent implements OnChanges {
  @Input()
  inspectionShipmentDetails: GetInspectionShipmentDetailsResp;

  constructor(
    public constants: AppConstantsService,
    private xpoDialog: XpoDialogManagerService,
    private configManagerService: ConfigManagerService
  ) {}

  showPreviousCorrection = false;
  isExpanded = true;

  ngOnChanges() {
    this.showPreviousCorrection = this.shouldShowPreviousCorrection();
    this.isExpanded = true;
  }

  private shouldShowPreviousCorrection(): boolean {
    if (this.inspectionShipmentDetails?.previousInspectionDetails) {
      for (let idx = 0; idx < this.inspectionShipmentDetails.previousInspectionDetails.length; idx++) {
        if (
          this.inspectionShipmentDetails.previousInspectionDetails[idx].inspectionStatusCd ===
          InspectionState.CORRECTION_SUBMITTED
        ) {
          return true;
        }
      }
    }
    return false;
  }

  viewPreviousCorrection() {
    if (this.inspectionShipmentDetails?.shipmentDetails) {
      const proNumber = new ProNumber(
        this.inspectionShipmentDetails.shipmentDetails.shipmentId.proNumber
      ).formatProNumber();
      const queryParams = [];
      queryParams.push('proNumber=' + proNumber);
      const previousCorrectionUrl = this.constants.getCorrectionAppEndpoint(['shipments/show.do'], queryParams);
      this.xpoDialog.openExternalWindow(previousCorrectionUrl, 'PreviousCorrection');
    }
  }

  openNmftaSite(nmfc: string): void {
    const nmftaSearchUrl = this.constants.getNmftaSearchUrl(nmfc);
    this.xpoDialog.openExternalWindow(nmftaSearchUrl, 'NMFTA Search');
  }

  openShipmentDetailsManagementApp(mouseEvent: MouseEvent): void {
    mouseEvent.stopPropagation();
    if (this.inspectionShipmentDetails?.shipmentDetails?.shipmentId?.shipmentInstId) {
      const shipmentId = this.inspectionShipmentDetails.shipmentDetails.shipmentId.shipmentInstId;
      let csShipmentListRegionUrl = this.configManagerService.getSetting<string>(
        ConfigManagerProperties.csShipmentListRegionUrl
      );
      if (csShipmentListRegionUrl && csShipmentListRegionUrl[csShipmentListRegionUrl.length - 1] !== '/') {
        csShipmentListRegionUrl += '/';
      }
      const shipmentManagementAppBaseUrl = `${csShipmentListRegionUrl}shipment-details/${shipmentId}`;
      window.open(shipmentManagementAppBaseUrl);
    }
  }
}
