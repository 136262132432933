import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { TrailerDetail } from '../model/trailer-detail.model';
import { AppConstantsService } from '../../../services/app-constants.service';
import {
  GetTrailerLoadedShipmentsByDoorPath,
  GetTrailerLoadedShipmentsByDoorQuery,
  GetTrailerLoadedShipmentsByDoorResp,
  GetTrailerLoadedShipmentsPath,
  GetTrailerLoadedShipmentsQuery,
  GetTrailerLoadedShipmentsResp
} from '@xpo-ltl/sdk-freightmovement';
import { catchError, map } from 'rxjs/operators';
import { FormatValidationService } from '@xpo-ltl/common-services';
import { AppNavigationService } from '../../../services/app-navigation.service';
import { ErrorHandlingService } from '../../../services/error-handling.service';
import { ShipmentDetailsService } from '../../../services/shipment-details.service';

@Injectable()
export class TrailerDetailResolver implements Resolve<TrailerDetail> {
  constructor(
    private validationService: FormatValidationService,
    private errorHandling: ErrorHandlingService,
    private constantsService: AppConstantsService,
    private shipmentDetailsService: ShipmentDetailsService,
    private appNavigation: AppNavigationService
  ) {}

  private static getShipmentTotalWeight(
    resp: GetTrailerLoadedShipmentsResp | GetTrailerLoadedShipmentsByDoorResp
  ): number {
    const sumFn = (x, y) => x + y;
    return resp.loadedShipments.map(shipment => shipment.totWeight.weight).reduce(sumFn);
  }

  private static getHeadLoadCounts(resp: GetTrailerLoadedShipmentsResp | GetTrailerLoadedShipmentsByDoorResp): number {
    return resp.loadedShipments.filter(shipment => shipment.headloadInd === true).length;
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<TrailerDetail> | Observable<never> {
    const input = route.paramMap.get('trailer');
    let trailerId;
    let doorId;
    if (this.validationService.isValidTrailerNumber(input)) {
      trailerId = input;
    } else if (this.validationService.isValidDoorNumber(input)) {
      doorId = input;
    }
    if (trailerId) {
      return this.shipmentDetailsService.getTrailerLoadedShipments(trailerId).pipe(
        map((resp: GetTrailerLoadedShipmentsResp) => {
          return <TrailerDetail>{
            loadedTrailer: resp.loadedTrailer,
            loadedShipments: resp.loadedShipments,
            proCounts: resp.loadedShipments ? resp.loadedShipments.length : 0,
            totalWeight:
              resp.loadedShipments && resp.loadedShipments.length > 0
                ? TrailerDetailResolver.getShipmentTotalWeight(resp)
                : 0,
            headLoadCounts:
              resp.loadedShipments && resp.loadedShipments.length > 0
                ? TrailerDetailResolver.getHeadLoadCounts(resp)
                : 0
          };
        }),
        catchError(() => {
          this.appNavigation.clearLookupValue();
          this.errorHandling.showErrorMessage(`Trailer: ${trailerId} Not Found!`);
          return throwError(`Trailer: ${trailerId} Not Found!`);
        })
      );
    } else if (doorId) {
      return this.shipmentDetailsService.getTrailerLoadedShipmentsByDoor(doorId).pipe(
        map((resp: GetTrailerLoadedShipmentsByDoorResp) => {
          return <TrailerDetail>{
            loadedTrailer: resp.loadedTrailer,
            loadedShipments: resp.loadedShipments,
            proCounts: resp.loadedShipments ? resp.loadedShipments.length : 0,
            totalWeight:
              resp.loadedShipments && resp.loadedShipments.length > 0
                ? TrailerDetailResolver.getShipmentTotalWeight(resp)
                : 0,
            headLoadCounts:
              resp.loadedShipments && resp.loadedShipments.length > 0
                ? TrailerDetailResolver.getHeadLoadCounts(resp)
                : 0
          };
        }),
        catchError(() => {
          this.appNavigation.clearLookupValue();
          this.errorHandling.showErrorMessage(`No Trailer Found at the Door #${doorId}`);
          return throwError(`No Trailer Found at the Door #${doorId}`);
        })
      );
    }
  }
}
