import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { HardwareService } from '../../services/hardware/hardware-service';

@Component({
  selector: 'app-not-authorized',
  templateUrl: './not-authorized.component.html',
  styleUrls: ['./not-authorized.component.scss']
})
export class NotAuthorizedComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<NotAuthorizedComponent>, public hardwareService: HardwareService) {}
  ngOnInit() {}

  public close(): void {
    this.hardwareService.exitApplication();
    this.dialogRef.close();
  }
}
