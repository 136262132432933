<div class="notes">
  <mat-expansion-panel expanded="true">
    <mat-expansion-panel-header>
      <mat-panel-title class="section-label"> Add Notes </mat-panel-title>
    </mat-expansion-panel-header>

    <mat-form-field class="notes__input">
      <textarea
        matInput
        placeholder="Add notes"
        maxlength="1000"
        [(ngModel)]="inspectionDetails.inspectionNote.note"
        (ngModelChange)="notesChanged()"
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="2"
        cdkAutosizeMaxRows="100"
      ></textarea>
    </mat-form-field>
    <!-- LEI-376: disabled until it can be updated to match the revised rule -->
    <!-- <div class="buttons-menu-calculators">
      <a (click)="showCalculator()">
        <mat-icon id="icon-680-item" svgIcon="item-680-icon"></mat-icon>
        <br />
        Item 680 Note 2b
      </a>
    </div> -->
  </mat-expansion-panel>
</div>
