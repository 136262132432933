<div fxLayout="column" fxFlex="" fxFlexAlign="stretch start" class="list-shipments">
  <div *ngIf="selectedCount >= 0" fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%">
    <h2 class="customer-guidelines-title">Customer Guidelines</h2>
    <div fxLayoutAlign="start left">
      <div>
        <div (click)="refreshList()">
          <mat-icon class="list-shipments__refresh-icon" svgIcon="refresh-icon" style="width: 16px"></mat-icon>
        </div>
      </div>
    </div>
    <div class="refreshTime" fxLayout="row" fxLayoutAlign="start left" fxFlex="flex">
      {{ customerGuidelinesList?.lastRefreshDate$ | async }}
    </div>
    <div fxLayout="row" fxLayoutAlign="end center" fxFlex="flex" style="margin-right: 10px">
      <button
        *ngIf="customerGuidelinesService.isManagerActionsAvailable$ | async"
        mat-button
        color="primary"
        (click)="onAddCustomerGuidelineClicked()"
      >
        Add Customer Guideline
      </button>

      <button class="icon-button" mat-button [matMenuTriggerFor]="menu">
        <mat-icon svgIcon="gear-icon"></mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button (click)="saveGridSettings()" mat-menu-item>Save Column Settings</button>
        <button (click)="loadGridSettings()" mat-menu-item>Load Column Settings</button>
        <button (click)="resetGridSettings()" mat-menu-item>Reset Column Settings</button>
      </mat-menu>
    </div>
  </div>

  <div>
    <app-toolbar
      [hidden]="selectedCount == 0"
      [showBackButton]="false"
      [title]="toolbarTitle"
      [showSelectDeselectAll]="true"
    >
    </app-toolbar>

    <ig-list-customer-guidelines
      (listChanged)="refreshList()"
      (selectedCountChanged)="selectedCountChanged($event)"
      (gridSettingsChanged)="gridSettingsChanged()"
    ></ig-list-customer-guidelines>
  </div>
</div>
