import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ColDef, GridOptions } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import { ComparatorUtils } from '../../../classes/comparator-utils';
import { ProNumber } from '../../../classes/pronumber';
import { InspectionState } from '../../../enums/inspection-state.enum';
import { ListName } from '../../../enums/list-name.enum';
import { AppNavigationService } from '../../../services/app-navigation.service';
import { GridSettingsService } from '../../../services/grid-settings/grid-settings.service';
import { HardwareService } from '../../../services/hardware/hardware-service';
import { ShipmentDetailsService } from '../../../services/shipment-details.service';

@Component({
  selector: 'app-list-inspections',
  templateUrl: './list-inspected-inspections.component.html',
  styleUrls: ['./list-inspected-inspections.component.scss']
})
export class ListInspectionsComponent implements OnInit, OnDestroy {
  public gridOptions: GridOptions;
  public rowData = [];
  public inProgressCount = 0;
  public totalCount = 0;
  height: any;
  width: any;

  @Output()
  selectedCountChanged = new EventEmitter<number>();

  @Output()
  listChanged = new EventEmitter<boolean>();

  @Output()
  gridSettingsChanged = new EventEmitter<boolean>();

  constructor(
    private shipmentDetailsService: ShipmentDetailsService,
    private gridSettings: GridSettingsService,
    private appNavigation: AppNavigationService
  ) {
    this.gridOptions = <GridOptions>{};

    this.gridOptions.defaultColDef = <ColDef>{
      resizable: true,
      sortable: true,
      filter: 'agSetColumnFilter',
      menuTabs: ['filterMenuTab'],
      filterParams: {
        comparator: (a: string, b: string) => {
          return ComparatorUtils.stringAndNumberCompare(a, b);
        }
      }
    };

    this.gridOptions.columnDefs = this.gridSettings.buildDefaultInspectedListColumnDefs();

    this.gridOptions.suppressMenuHide = HardwareService.isTouchScreenCapable();
    this.gridOptions.suppressRowClickSelection = true;
    this.gridOptions.onCellDoubleClicked = this.onCellDoubleClicked.bind(this);
    this.gridOptions.onColumnMoved = this.columnsChanged.bind(this);
    this.gridOptions.onColumnPinned = this.columnsChanged.bind(this);
    this.gridOptions.onColumnResized = this.columnsChanged.bind(this);
    this.gridOptions.onFilterChanged = this.columnsChanged.bind(this);
    this.gridOptions.onColumnVisible = this.columnsChanged.bind(this);
    this.gridOptions.onSortChanged = this.columnsChanged.bind(this);
    this.gridOptions.onRowDataChanged = this.rowDataChanged.bind(this);
    this.gridOptions.icons = GridSettingsService.buildGridIcons();
    this.gridOptions.enableCellTextSelection = true;
    this.gridOptions.ensureDomOrder = true;
    this.gridOptions.onGridReady = () => {
      this.setHeightAndWidth();
    };
  }

  private subscription = new Subscription();

  public ngOnInit() {
    this.subscription.add(
      this.shipmentDetailsService.inspectedListData$.subscribe((value) => {
        if (value) {
          this.rowData = value;
          this.totalCount = value.length;
          let inProgressCount = 0;
          value.forEach((inspectionShipment) => {
            if (
              inspectionShipment.inspectionStatusCd === <string>InspectionState.IN_PROGRESS ||
              inspectionShipment.inspectionStatusCd === <string>InspectionState.EDITING
            ) {
              inProgressCount++;
            }
          });
          this.inProgressCount = inProgressCount;
        }
      })
    );
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private rowDataChanged($event) {
    this.gridSettings.restoreGridState(ListName.Inspected, this.gridOptions);
    this.setHeightAndWidth();
  }

  private columnsChanged($event) {
    this.gridSettings.storeGridState(ListName.Inspected, this.gridOptions);
    this.gridSettingsChanged.emit(true);
  }

  onResize($event) {
    this.setHeightAndWidth();
  }

  setHeightAndWidth() {
    this.width = document.getElementsByClassName('app-container-content')[0].clientWidth;
    this.height = document.getElementsByClassName('app-container-content')[0].clientHeight - 50;
  }

  private onCellDoubleClicked($event) {
    this.appNavigation.navigateToShipmentDetails(new ProNumber($event.data.proNumber));
  }
}
