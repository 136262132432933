import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { DocumentInfo } from '@xpo-ltl/sdk-inspections';
import { EMPTY, interval, Observable } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/internal/operators';
import { Photo } from '../classes/photo';
import { PhotoId } from '../classes/photo-id';
import { ErrorMessageActions } from '../enums/error-message-actions.enum';
import { DevTestingService } from './dev-testing.service';
import { DocumentManagementService } from './document-management-service';
import { ErrorHandlingService } from './error-handling.service';
import { HardwareService } from './hardware/hardware-service';

@Injectable()
export class PhotoUploadService {
  private readonly UPLOAD_INTERVAL = 1000;
  private readonly SNACKBAR_DURATION = 5000;
  private readonly LOCAL_PHOTO = 'local photo';
  private readonly PHOTO_TO_DMS = 'photo to DMS';

  private lastSendStatusDateTime: Date;
  private photoIdErrorMap = new Map<string, string>();
  private photoIdErrorTimestampMap = new Map<string, Date>();

  constructor(
    private hardwareService: HardwareService,
    private documentManagement: DocumentManagementService,
    private errorHandlingService: ErrorHandlingService,
    private snackBar: MatSnackBar,
    private devTestingService: DevTestingService
  ) {
    // check every second if there are photos to upload to DMS
    interval(this.UPLOAD_INTERVAL).subscribe(() => {
      this.processFileUploads();
    });
  }

  private processFileUploads(): void {
    // if we are in the middle of a send, then just return
    // if we have waited longer than 5 minutes, assume something failed/hung and clear the sendStatusDateTime
    if (this.lastSendStatusDateTime) {
      const elapsedTime = new Date().getTime() - this.lastSendStatusDateTime.getTime();
      if (elapsedTime < 300000) {
        return;
      } else {
        console.log('Still processing for ' + elapsedTime + '; continue!');
      }
    }

    this.setLastSendStatusDateTime(new Date());
    this.hardwareService.updatePendingPhotoCount();
    let processingPhoto: Photo;

    this.getNextPhotoToUpload()
      .pipe(
        take(1),
        switchMap((photo: Photo) => {
          if (!photo) {
            // no photo to process, set the lastSendStatusDateTime to null;
            this.setLastSendStatusDateTime(null);
            return EMPTY;
          } else {
            processingPhoto = photo; // saving this photo since we're using it in subscribe()

            return this.documentManagement.createArchiveDmsDocument(processingPhoto).pipe(
              take(1),
              catchError((error) => {
                this.setErrorOnMaps(processingPhoto, error);
                this.setLastSendStatusDateTime(null);

                return EMPTY;
              })
            );
          }
        }),
        catchError((error) => {
          this.errorHandlingService.handleResponseError(error, ErrorMessageActions.SAVING, this.PHOTO_TO_DMS);
          this.setLastSendStatusDateTime(null);
          return EMPTY;
        })
      )
      .subscribe((documentInfo: DocumentInfo) => {
        if (documentInfo?.documentUrl) {
          // if successful, delete the photo from the Getac (or Browser)
          this.deletePhotoFromHardware(processingPhoto);
        } else {
          this.errorHandlingService.handleResponseError(
            `Error saving photo to DMS! No DocumentInfo Returned!`,
            ErrorMessageActions.SAVING,
            this.PHOTO_TO_DMS
          );
          this.setErrorOnMaps(processingPhoto, 'No DocumentInfo Returned!');
          this.setLastSendStatusDateTime(null);
        }
      });
  }

  private getNextPhotoToUpload(): Observable<Photo> {
    return this.hardwareService.getPendingPhotoIdsToUpload().pipe(
      take(1),
      switchMap((photoIds: PhotoId[]) => {
        let photoIdToUpload: PhotoId;
        let oldestErrorTimestamp = Number.MAX_VALUE;
        if (photoIds?.length > 0) {
          // first process all those that don't have errors
          for (let idx = 0; idx < photoIds.length; idx++) {
            const photoId: PhotoId = photoIds[idx];
            const errorTimestamp: Date = this.photoIdErrorTimestampMap.get(photoId.id);
            if (!errorTimestamp) {
              // once we find one without an error.. just use it
              photoIdToUpload = photoId;
              break;
            } else {
              // we had an error. Keep the oldest error to use in case we don't have a non Error photoId to process
              if (errorTimestamp.getTime() < oldestErrorTimestamp) {
                oldestErrorTimestamp = errorTimestamp.getTime();
                photoIdToUpload = photoId;
              }
            }
          }
        }

        if (!photoIdToUpload) {
          // No archived-photos to process
          this.setLastSendStatusDateTime(null);
          return EMPTY;
        } else {
          return this.hardwareService.getImage(photoIdToUpload).pipe(take(1));
        }
      })
    );
  }

  private setLastSendStatusDateTime(date: Date) {
    this.lastSendStatusDateTime = date;
  }

  private setErrorOnMaps(photo: Photo, errorMessage: string) {
    if (photo) {
      this.photoIdErrorMap.set(photo.id.id, errorMessage);
      this.photoIdErrorTimestampMap.set(photo.id.id, new Date());
    }
  }

  private deletePhotoFromHardware(photo: Photo): void {
    if (photo) {
      this.photoIdErrorTimestampMap.delete(photo.id.id); // delete even if doesn't exist
      this.photoIdErrorMap.delete(photo.id.id); // delete even if doesn't exist
      this.hardwareService
        .deletePhoto(photo.id)
        .pipe(
          take(1),
          catchError((error) => {
            this.errorHandlingService.handleResponseError(error, ErrorMessageActions.DELETING, this.LOCAL_PHOTO);
            this.setLastSendStatusDateTime(null);
            return EMPTY;
          })
        )
        .subscribe((isDeleted: boolean) => {
          this.setLastSendStatusDateTime(null);
          if (!isDeleted) {
            this.errorHandlingService.handleResponseError(
              `Hardware service delete photo returned ${isDeleted}`,
              ErrorMessageActions.DELETING,
              this.LOCAL_PHOTO
            );
          }
        });
    }
  }
}
