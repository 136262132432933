import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import { Observable, Subscription } from 'rxjs';

import { InjectedDialogComponent } from '../../../../interfaces/injected-dialog-component.interface';
import { HardwareService } from '../../../../services/hardware/hardware-service';

@Component({
  selector: 'app-pending-photos',
  templateUrl: './pending-photos.component.html',
  styleUrls: ['./pending-photos.component.scss']
})
export class PendingPhotosComponent implements OnInit, OnDestroy, InjectedDialogComponent {
  public dataObservable: Observable<any>;

  private subscriptions = new Subscription();

  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    public hardwareService: HardwareService
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.hardwareService.pendingPhotoCount$.subscribe(count => {
        if (!count || count === 0) {
          this.dialogRef.close();
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
