import { CommonModule } from '@angular/common';
import { YesNoPipe } from './yes-no.pipe';
import { ShiftDisplayEnumStringPipe } from './app.shift-code.pipe';
import { NgModule } from '@angular/core';
import { NameAddressPipe } from './name-address.pipe';
import { InspectionStatusCodePipe } from './inspection-status-code.pipe';
import { ImageTypePipe } from './image-type.pipe';
import { InvertedYesNoPipe } from './inverted-yes-no.pipe';
import { ChildProPipe } from './childPro.pipe';
import { TimeFormatPipe } from './timeFormat.pipe';
import { DialogActionPipe } from './dialogAction.pipe';

const pipes = [
  ShiftDisplayEnumStringPipe,
  YesNoPipe,
  NameAddressPipe,
  InspectionStatusCodePipe,
  ImageTypePipe,
  InvertedYesNoPipe,
  ChildProPipe,
  TimeFormatPipe,
  DialogActionPipe
];

@NgModule({
  imports: [CommonModule],
  declarations: pipes,
  providers: pipes,
  exports: pipes
})
export class XpoPipesModule {}
