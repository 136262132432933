export interface ProNumberPattern {
  description: string;
  regex: RegExp;
  normalizer(proNumber: string): string;
}

export class ProNumber {
  private readonly proNumber: string;

  PARENT_PRO_PATTERNS = [
    {
      description: '9_DIGIT',
      regex: /^[1-9][0-9]{8}$/,
      normalizer: (proNumber: string): string => {
        return proNumber;
      }
    },
    {
      description: '9_DIGIT_HYPHEN_BLUE_PRO',
      regex: /^[1-9][0-9]{2}-[0-9]{6}$/,
      normalizer: (proNumber: string): string => {
        return proNumber.substring(0, 3) + proNumber.substring(4, 10);
      }
    },
    {
      description: '10_DIGIT_HYPHEN_BLUE_PRO',
      regex: /^0[1-9][0-9]{2}-[0-9]{6}$/,
      normalizer: (proNumber: string): string => {
        return proNumber.substring(1, 4) + proNumber.substring(5, 11);
      }
    },
    {
      description: '11_DIGIT_BLUE_PRO',
      regex: /^0[1-9][0-9]{2}0[0-9]{6}$/,
      normalizer: (proNumber: string): string => {
        return proNumber.substring(1, 4) + proNumber.substring(5, 11);
      }
    }
  ];

  CHILD_PRO_PATTERNS = [
    {
      description: '10_DIGIT_YELLOW_PRO',
      regex: /^[1-9][0-9]{2}[1-9][0-9]{6}$/,
      normalizer: (proNumber: string): string => {
        return proNumber;
      }
    },
    {
      description: '11_DIGIT_YELLOW_PRO',
      regex: /^0[1-9][0-9]{2}[1-9][0-9]{6}$/,
      normalizer: (proNumber: string): string => {
        return proNumber.substring(1, 11);
      }
    },
    {
      description: '10_DIGIT_HYPHEN_YELLOW_PRO',
      regex: /^[1-9][0-9]{2}[1-9]-[0-9]{6}$/,
      normalizer: (proNumber: string): string => {
        return proNumber.substring(0, 4) + proNumber.substring(5, 11);
      }
    }
  ];

  constructor(proNumber: string) {
    if (proNumber) {
      proNumber = proNumber.trim();
      const normalizer = this.getProNumberNormalizer(proNumber);
      if (normalizer) {
        const normalizedPro = normalizer(proNumber);
        if (normalizedPro) {
          this.proNumber = normalizedPro;
        }
      }
    }
  }

  public static isValid(proNumber: string): boolean {
    if (proNumber) {
      proNumber = proNumber.trim();
      const REGEXES = {
        REGEX_9_DIGIT: /^[0-9]{9}$/,
        REGEX_11_DIGIT: /^[0-9]{11}$/,
        REGEX_9_DIGIT_HYPHEN: /^[0-9]{3}[-][0-9]{6}$/,
        REGEX_10_DIGIT: /^[0-9]{10}$/,
        REGEX_10_DIGIT_HYPHEN: /^[0-9]{4}[-][0-9]{6}$/
      };
      const composeRegex = (...regexes) => new RegExp(regexes.map(r => r.source).join('|'));

      const PRO_NUMBER_REGEX = composeRegex(
        REGEXES.REGEX_9_DIGIT,
        REGEXES.REGEX_11_DIGIT,
        REGEXES.REGEX_9_DIGIT_HYPHEN,
        REGEXES.REGEX_10_DIGIT,
        REGEXES.REGEX_10_DIGIT_HYPHEN
      );

      return PRO_NUMBER_REGEX.test(proNumber);
    } else {
      return false;
    }
  }

  getProNumberNormalizer(proNumber: string) {
    if (!ProNumber.isValid(proNumber)) {
      return undefined;
    }

    return this.isParentPro(proNumber) ? this.getParentProNormalizer(proNumber) : this.getChildProNormalizer(proNumber);
  }

  isParentPro(proNumber: string): boolean {
    const PRO_NUMBER_REGEX = this.composeRegexes(this.PARENT_PRO_PATTERNS);
    return PRO_NUMBER_REGEX.test(proNumber);
  }

  isChildPro(proNumber: string): boolean {
    const PRO_NUMBER_REGEX = this.composeRegexes(this.CHILD_PRO_PATTERNS);
    return PRO_NUMBER_REGEX.test(proNumber);
  }

  composeRegexes(patterns: ProNumberPattern[]): RegExp {
    const regexes = patterns.map(pattern => pattern.regex);
    return new RegExp(regexes.map(r => r.source).join('|'));
  }

  getParentProNormalizer(proNumber: string): (proNumber: string) => string {
    const matchedPattern = this.PARENT_PRO_PATTERNS.find(pattern => pattern.regex.test(proNumber));

    return matchedPattern ? matchedPattern.normalizer : undefined;
  }

  getChildProNormalizer(proNumber: string) {
    const matchedPattern = this.CHILD_PRO_PATTERNS.find(pattern => pattern.regex.test(proNumber));

    return matchedPattern ? matchedPattern.normalizer : undefined;
  }

  get9DigitHyphenBluePro(proNumber: string): string {
    proNumber = proNumber.trim();
    if (!proNumber || proNumber.length !== 9) {
      return undefined;
    }
    return proNumber.substring(0, 3) + '-' + proNumber.substring(3, 9);
  }

  get10DigitHyphenYellowPro(proNumber: string): string {
    if (!proNumber || proNumber.length !== 10) {
      return undefined;
    }
    return proNumber.substring(0, 4) + '-' + proNumber.substring(4, 10);
  }

  public isValid(): boolean {
    return ProNumber.isValid(this.proNumber);
  }

  public formatProNumber(): string {
    return this.isParentPro(this.proNumber)
      ? this.get9DigitHyphenBluePro(this.proNumber)
      : this.isChildPro(this.proNumber)
      ? this.get10DigitHyphenYellowPro(this.proNumber)
      : undefined;
  }

  public getRawProNumber(): string {
    return this.proNumber;
  }

  public equals(proNumber: ProNumber): boolean {
    if (proNumber && proNumber.proNumber === this.proNumber) {
      return true;
    } else {
      return false;
    }
  }
}
