export enum DialogAction {
  CREATE = 'Create',
  EDIT = 'Edit',
  DELETE = 'Delete',
  BROADCAST = 'Broadcast',
  VIEW = 'View'
}

export function toDialogActionPastTense(dialogAction: DialogAction): string {
  switch (dialogAction) {
    case DialogAction.CREATE:
      return 'created';
    case DialogAction.EDIT:
      return 'updated';
    case DialogAction.DELETE:
      return 'deleted';
    case DialogAction.BROADCAST:
      return 'sent';
  }
}
