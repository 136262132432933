import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { RuleFilter } from '../classes/rule-filter';
import { RecommendedRulesService } from '../services/recommended-rules.service';
import { ResponseValidation } from '../../../classes/ResponseValidation';
import { TypeFilters } from '../enums/type_filters.enum';
import { AccountService } from '../../../services/account.service';
import { Subscription, Subject } from 'rxjs';
import { AcctId } from '@xpo-ltl/sdk-common';
import { debounceTime } from 'rxjs/operators';
@Component({
  selector: 'app-rule-filter',
  templateUrl: './rule-filter.component.html',
  styleUrls: ['./rule-filter.component.scss']
})
export class RuleFilterComponent implements OnInit, OnDestroy {
  @Input() filter: RuleFilter;
  public filters: Array<RuleFilter>;
  public filtersSubscription: Subscription;
  public filterValidation: ResponseValidation;
  public validationSubscription: Subscription;

  private debounceSubject$ = new Subject();
  private debounceObservable = this.debounceSubject$.asObservable();
  private subscription = new Subscription();

  constructor(private recommendedRulesService: RecommendedRulesService, private accountService: AccountService) {}

  private getConsigneeFilterValue(): AcctId {
    if (!this.filter.getValue()) {
      this.filter.setValue(new AcctId());
    }

    return this.filter.getValue();
  }

  /**
   *
   * @param madCode
   */
  public setMadCode(madCode: string): void {
    const filterValue = this.getConsigneeFilterValue();
    filterValue.acctMadCd = madCode;
  }

  public getMadCode(): string {
    let response = '';

    if (this.filter.getValue() && this.filter.getValue().acctMadCd) {
      response = this.filter.getValue().acctMadCd;
    }

    return response;
  }

  public getAccountName(): string {
    let response = '';

    if (this.filter.getValue() && this.filter.getValue().acctName) {
      response = this.filter.getValue().acctName;
    }

    return response;
  }

  ngOnInit() {
    this.filterValidation = new ResponseValidation(true, '');
    this.getAvailableFilters();
    this.subscribeToValidationEvent();

    if (this.filter.getValue()) {
      if (this.filter.getValue().acctName) {
        this.filter.setIsValid(true);
      }

      // Update filters in the service asynchronously
      setTimeout(() => {
        this.updateFilterAndTriggerRefreshFilters(this.filter.getName());
      });
    }

    this.subscription = this.debounceObservable.pipe(debounceTime(1000)).subscribe(() => {
      this.validateFilter();
    });
  }

  public getAvailableFilters(): void {
    this.filtersSubscription = this.recommendedRulesService
      .getFiltersObservable()
      .subscribe((filters: Array<RuleFilter>) => {
        this.filters = filters.filter((filter: RuleFilter) => {
          // We check if the filter is available
          const isFilterAvailable = filter.getStatus() === 'available';
          // Or if the filter is the one already selected
          const isCurrentFilter = this.filter.getName() === filter.getName();

          // Then we return the available filters + the current selected filter (if any)
          return isFilterAvailable || isCurrentFilter;
        });
      });
  }

  public subscribeToValidationEvent(): void {
    this.validationSubscription = this.recommendedRulesService
      .getFormChangedEventSubjectAsObservable()
      .subscribe(() => {
        this.validateFilter();
      });
  }

  public madCodeChanged() {
    this.debounceSubject$.next();
  }

  ngOnDestroy() {
    if (this.filtersSubscription) {
      this.filtersSubscription.unsubscribe();
    }

    if (this.validationSubscription) {
      this.validationSubscription.unsubscribe();
    }

    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  // Filter validation depending on the filter type
  public validateFilter() {
    switch (this.filter.getName()) {
      case TypeFilters.PaymentType:
        this.validatePaymentTypeFilter();
        break;
      case TypeFilters.Consignee:
        this.validateConsigneeFilter();
        break;
      default:
        this.filter.setIsValid(false);
        this.filterValidation.setIsValid(false);
        break;
    }
  }

  public validatePaymentTypeFilter(): void {
    if (this.filter.getValue()) {
      this.filter.setIsValid(true);
      this.filterValidation.setIsValid(true);
    } else {
      this.filterValidation.setIsValid(false);
    }
  }

  public validateConsigneeFilter(): void {
    const madCode = AccountService.formatMadCodeAccount(this.getMadCode());
    // We check the mad code against the rule's main account
    this.filterValidation = this.recommendedRulesService.validateFilterMadCodeAccount(madCode);

    // If the mad code is valid, we get the account details
    if (this.filterValidation.getIsValid()) {
      this.getAccountDetails(madCode);
    } else {
      // Otherwise we set the filter's state to not valid
      this.resetFilterStatus();
      this.setMadCode(madCode);
    }
  }

  private validateAcctFilter(acctToValidate: AcctId): void {
    // check if the consignee filter account is pickup and delivery
    this.filterValidation = this.recommendedRulesService.validateConsigneeAccountPartyRole(acctToValidate);
    if (this.filterValidation.getIsValid()) {
      this.filter.setValue(acctToValidate);
      this.filter.setIsValid(true);
    } else {
      this.resetFilterStatus();
      this.setMadCode(acctToValidate.acctMadCd);
    }
  }

  private getAccountDetails(madCode: string) {
    const accountValidation = this.accountService.getAccount(madCode);
    if (accountValidation.getIsValid()) {
      accountValidation.getResponse().subscribe(
        (account: AcctId) => {
          this.validateAcctFilter(account);
        },
        () => {
          this.resetFilterStatus();
          this.setMadCode(madCode);
          this.filterValidation.setMessage('Account not found');
          this.filterValidation.setResponse(undefined);
          this.filterValidation.setIsValid(false);
        }
      );
    } else {
      this.resetFilterStatus();
      this.setMadCode(madCode);
      this.filterValidation.setIsValid(accountValidation.getIsValid());
      this.filterValidation.setMessage(accountValidation.getMessage());
    }
  }

  public selectFilter(filterName: string): void {
    // Should deselect any other filter it had before
    this.deselectFilter(this.filter.getName());

    // We get the filter from the service
    const filter = this.recommendedRulesService.getFilterByName(filterName);

    if (filter) {
      // If the filter exists we update the component's filter
      this.filter.setName(filter.getName());
      this.filter.setType(filter.getType());
      this.filter.setOptions(filter.getOptions());
      this.filter.setDisplayName(filter.getDisplayName());

      this.updateFilterAndTriggerRefreshFilters(filter.getName());
    }

    // Since we changed the filter, we reset its validations state
    this.resetFilterStatus();
  }

  private updateFilterAndTriggerRefreshFilters(filterName: string): void {
    const filter = this.recommendedRulesService.getFilterByName(filterName);

    if (filter) {
      // We update the filter's status
      filter.setStatus('selected');

      // Finally we refresh the filters in the service
      this.recommendedRulesService.refreshFilters();
    }
  }

  private deselectFilter(filterName: string): void {
    const filter = this.recommendedRulesService.getFilterByName(filterName);

    if (filter) {
      filter.setStatus('available');
      filter.setValue(undefined);

      // We check if there are filters to disable (because of the one we removed)
      this.recommendedRulesService.disableFilters();
    }

    // Since we are deselecting the filter, we remove its value and set
    // isValid to false.
    if (this.filter.getValue()) {
      this.resetFilterStatus();
    }
  }

  public removeFilter(): void {
    this.deselectFilter(this.filter.getName());

    this.recommendedRulesService.removeRuleFilter(this.filter);
    this.recommendedRulesService.refreshFilters();
  }

  public displayFilterErrorMessageByFilterType(): string {
    let response: string;

    switch (this.filter.getName()) {
      case TypeFilters.PaymentType:
        response = `No ${this.filter.getDisplayName()} selected`;
        break;
      default:
        response = this.filterValidation.getMessage();
        break;
    }

    return response;
  }

  private resetFilterStatus(): void {
    this.filter.setValue(undefined);
    this.filter.setIsValid(false);
  }
}
