import { Injectable } from '@angular/core';
import { MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { BeaconStatusCd } from '@xpo-ltl/sdk-common';
import { Observable } from 'rxjs';
import { BroadcastMessage } from '../classes/broadcast-messages/broadcast-message';
import { BroadcastMessageDialogComponent } from '../dialogs/broadcast-message-dialog/broadcast-message-dialog.component';
import { UnreadBroadcastMessageDialogComponent } from '../dialogs/unread-broadcast-message-dialog/unread-broadcast-message-dialog.component';
import { DialogAction } from '../enums/dialog-actions.enum';
import { XpoDialogManagerService } from './xpo-dialog-manager.service';

@Injectable()
export class InspectionsDialogsService {
  constructor(private dialogManager: XpoDialogManagerService) {}

  openUnreadBroadcastMessagesDialog(unreadMessages: BroadcastMessage[], employeeId: string): Observable<any> {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.autoFocus = true;
    matDialogConfig.disableClose = true;
    matDialogConfig.minWidth = '400px';
    matDialogConfig.minHeight = '300px';
    matDialogConfig.width = '600px';
    matDialogConfig.data = {
      title: 'Inspection Messages',
      unreadMessages,
      employeeId
    };
    const matDialogRef = this.dialogManager.open(<any>UnreadBroadcastMessageDialogComponent, matDialogConfig);
    return matDialogRef.afterClosed();
  }

  openNewBroadcastMessageDialog(): Observable<BroadcastMessage> {
    return this.openBroadcastMessageDialog(`${DialogAction.CREATE} Message`, DialogAction.CREATE, null);
  }

  openEditOrViewBroadcastMessageDialog(broadcastMessage: BroadcastMessage): Observable<BroadcastMessage> {
    if (broadcastMessage.messageStatus === BeaconStatusCd.NEW) {
      return this.openBroadcastMessageDialog(`${DialogAction.EDIT} Message`, DialogAction.EDIT, broadcastMessage);
    } else {
      return this.openBroadcastMessageDialog(`${DialogAction.VIEW} Message`, DialogAction.VIEW, broadcastMessage);
    }
  }

  private openBroadcastMessageDialog(
    title: string,
    action: DialogAction,
    broadcastMessage: BroadcastMessage
  ): Observable<BroadcastMessage> {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.autoFocus = true;
    matDialogConfig.disableClose = false;
    matDialogConfig.minWidth = '400px';
    matDialogConfig.minHeight = '270px';
    matDialogConfig.width = '600px';
    matDialogConfig.data = {
      title,
      action,
      broadcastMessage: broadcastMessage
    };
    const matDialogRef = this.dialogManager.open(<any>BroadcastMessageDialogComponent, matDialogConfig);
    return matDialogRef.afterClosed();
  }
}
