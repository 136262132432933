import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ProNumber } from '../classes/pronumber';
import { ListName } from '../enums/list-name.enum';
import { ListType } from '../enums/list-type.enum';
import { getRouterUriComponentsFromListType, RouterUriComponents } from '../enums/router-uri-components.enum';
import { AppRouteHistoryService } from './app-route-history.service';
import { ShipmentDetailsService } from './shipment-details.service';

@Injectable()
export class AppNavigationService {
  private static HISTORY_LIMIT = 5;
  // This needs to be an observable as the ListPage updates the Tab Accordingly
  private listShipmentsTabSubject = new BehaviorSubject(ListName.Recommended);
  listShipmentsTab$ = this.listShipmentsTabSubject.asObservable();

  private listType = ListType.Shipment;
  private lookupValue: string;

  setListShipmentsTab(listShipmentsTab: ListName) {
    if (listShipmentsTab) {
      this.listShipmentsTabSubject.next(listShipmentsTab);
      this.router.navigate([RouterUriComponents.LIST_SHIPMENTS, listShipmentsTab]);
    }
  }

  constructor(
    private router: Router,
    private routeHistoryService: AppRouteHistoryService,
    private shipmentService: ShipmentDetailsService
  ) {}

  clearLookupValue() {
    this.lookupValue = null;
  }

  /**
   * MSH 2/7/2019
   * If a list type is passed in, then just go to that list
   * If a list type is a lookup and the lookupInput is passed in, then save it
   * if it's not passed in, then use what's saved
   * if Lookup is requested, but no new lookup value, nor is any saved, then
   * go to the Shipment List
   * Any other Lists, make sure to clear the Lookup Value
   *
   * @param listType
   * @param lookupInput
   * @returns
   */
  navigateToList(listType: ListType = null, lookupInput: string = null) {
    if (listType) {
      this.listType = listType;
    }

    if (this.listType === ListType.Lookup) {
      if (lookupInput) {
        this.lookupValue = lookupInput;
      }
      if (!this.lookupValue) {
        this.listType = ListType.Shipment;
      }
    } else {
      this.lookupValue = null;
    }

    const routerUriComponents: string = getRouterUriComponentsFromListType(this.listType);

    if (this.isBackToPrevPageEvent(routerUriComponents, this.lookupValue)) {
      const navigateUrl = this.routeHistoryService.popPrevUrl();
      this.router.navigate([navigateUrl]);
      return;
    }

    if (this.listType === ListType.Lookup) {
      this.router.navigate([routerUriComponents, this.lookupValue]);
    } else if (this.listType === ListType.Shipment || this.listType === null) {
      // Note: If we're already on the Shipment List, this will cause it to refresh (as expected) when called
      // If we're not on the shipment list, calling refresh all Lists will not cause the refresh to be called twice
      // as the ListPage doesn't subscribe until after it's own refresh.
      this.shipmentService.loadAllLists();

      this.router.navigate([routerUriComponents, this.listShipmentsTabSubject.getValue()]);
    } else {
      this.router.navigate([routerUriComponents]);
    }
  }

  navigateToShipmentDetails(proNumber: ProNumber): void {
    const pro = proNumber.formatProNumber();
    this.router.navigate([RouterUriComponents.SHIPMENT_DETAILS_PAGE, pro]);
  }

  navigateToInspection(proNumber: ProNumber): void {
    const pro = proNumber.formatProNumber();
    this.router.navigate([RouterUriComponents.INSPECT_SHIPMENT_PAGE, pro]);
  }

  private isBackToPrevPageEvent(routerUriComponents: string, lookupValue: string): boolean {
    if (this.routeHistoryService.getRouteHistory()?.length > 1) {
      if (lookupValue?.trim().length > 0) {
        // if there is history and lookup value has value, check if its a back to previous page event
        const prevRouteInfo: string[] = this.routeHistoryService.getPrevUrl()?.split('/');
        const prevRouteComponent: string = prevRouteInfo?.[0];
        const prevLookupValue: string = prevRouteInfo?.[1];

        return routerUriComponents === prevRouteComponent && prevLookupValue === lookupValue;
      } else {
        // if lookupValue has no value, its a back arrow click event
        return true;
      }
    } else {
      // if there is no history, not back to previous page event
      return false;
    }
  }
}
