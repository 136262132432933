import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig
} from '@angular/material/legacy-dialog';
import { AcctId } from '@xpo-ltl/sdk-common';
import { CustomerRecommendedRule } from '@xpo-ltl/sdk-inspections';
import { ColDef, GridOptions } from 'ag-grid-community';
import { take, takeUntil } from 'rxjs/operators';
import { ComparatorUtils } from '../../../classes/comparator-utils';
import { RecommendationRuleDialogComponent } from '../../../dialogs/recommendation-rule-dialog/recommendation-rule-dialog.component';
import { DialogAction } from '../../../enums/dialog-actions.enum';
import { ListName } from '../../../enums/list-name.enum';
import { GridSettingsService } from '../../../services/grid-settings/grid-settings.service';
import { HardwareService } from '../../../services/hardware/hardware-service';
import { PartyRole } from '../enums/party_role.enum';
import { RecommendedRulesService } from '../services/recommended-rules.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-list-recommended-rules',
  templateUrl: './list-recommended-rules.component.html',
  styleUrls: ['./list-recommended-rules.component.scss']
})
export class ListRecommendedRulesComponent implements OnInit, OnDestroy {
  public gridOptions: GridOptions;
  public rowData = [];
  public totalCount = 0;
  public height: number;
  public width: number;

  private unsubscriber$: Subject<any> = new Subject();

  @Output()
  selectedCountChanged = new EventEmitter<number>();

  @Output()
  listChanged = new EventEmitter<boolean>();

  @Output()
  gridSettingsChanged = new EventEmitter<boolean>();

  constructor(
    private recommendedRulesService: RecommendedRulesService,
    private gridSettings: GridSettingsService,
    private dialog: MatDialog
  ) {
    this.gridOptions = <GridOptions>{};

    this.gridOptions.defaultColDef = <ColDef>{
      resizable: true,
      sortable: true,
      filter: 'agSetColumnFilter',
      menuTabs: ['filterMenuTab'],
      filterParams: {
        comparator: (a: string, b: string) => {
          return ComparatorUtils.stringAndNumberCompare(a, b);
        }
      }
    };

    this.gridOptions.columnDefs = this.gridSettings.buildDefaultRecommendedRulesListColumnDefs();
    this.gridOptions.rowSelection = 'single';
    this.gridOptions.suppressMenuHide = HardwareService.isTouchScreenCapable();
    this.gridOptions.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.gridOptions.onCellDoubleClicked = this.onCellDoubleClicked.bind(this);
    this.gridOptions.onColumnMoved = this.columnsChanged.bind(this);
    this.gridOptions.onColumnPinned = this.columnsChanged.bind(this);
    this.gridOptions.onColumnResized = this.columnsChanged.bind(this);
    this.gridOptions.onFilterChanged = this.columnsChanged.bind(this);
    this.gridOptions.onColumnVisible = this.columnsChanged.bind(this);
    this.gridOptions.onSortChanged = this.columnsChanged.bind(this);
    this.gridOptions.onRowDataChanged = this.rowDataChanged.bind(this);
    this.gridOptions.icons = GridSettingsService.buildGridIcons();
    this.gridOptions.enableCellTextSelection = true;
    this.gridOptions.ensureDomOrder = true;
    this.gridOptions.onGridReady = () => {
      this.setHeightAndWidth();
    };
  }

  public ngOnInit() {
    this.getInformation();
  }

  public getInformation(): void {
    this.recommendedRulesService
      .getRecommendedRules()
      .pipe(take(1))
      .subscribe((rules: CustomerRecommendedRule[]) => {
        rules.sort((rule1, rule2) => {
          const account1: AcctId = this.findAccount(rule1.customerAccounts);
          const account2: AcctId = this.findAccount(rule2.customerAccounts);
          return ComparatorUtils.madStringCompare(account1?.acctMadCd, account2?.acctMadCd);
        });
        this.rowData = rules;
        this.totalCount = rules.length;
      });
  }

  private findAccount(accounts: AcctId[]): AcctId {
    let account: AcctId;
    if (accounts?.length === 1) {
      account = accounts[0];
    } else if (accounts?.length > 1) {
      // If the rule has more than one account, it means it has consignee filter
      account = this.getNotConsigneeAccount(accounts);
    } else {
      account = new AcctId();
    }

    return account;
  }

  private getNotConsigneeAccount(accounts: AcctId[]): AcctId {
    // Get the account that is not acting as consignee
    return accounts?.find((account) => account.acctPartyRole !== PartyRole.Consignee);
  }

  public ngOnDestroy() {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

  private rowDataChanged($event): void {
    this.gridSettings.restoreGridState(ListName.RecommendationRules, this.gridOptions);
    this.setHeightAndWidth();
  }

  private columnsChanged($event): void {
    this.gridSettings.storeGridState(ListName.RecommendationRules, this.gridOptions);
    this.gridSettingsChanged.emit(true);
  }

  onResize($event): void {
    this.setHeightAndWidth();
  }

  setHeightAndWidth(): void {
    this.width = document.getElementsByClassName('app-container-content')[0].clientWidth;
    this.height = document.getElementsByClassName('app-container-content')[0].clientHeight - 50;
  }

  private onSelectionChanged(): void {
    if (this.gridOptions.api) {
      const selectedRows = this.gridOptions.api.getSelectedRows();
      if (selectedRows) {
        this.selectedCountChanged.emit(selectedRows.length);
      }
    }
  }

  public clearList(): void {
    this.rowData = [];
    this.totalCount = 0;
  }

  private openRecommendationRuleDialog(data: Object): void {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.autoFocus = true;
    matDialogConfig.disableClose = false;
    matDialogConfig.minWidth = '60%';
    matDialogConfig.minHeight = '60%';
    matDialogConfig.width = '70%';
    matDialogConfig.data = {
      title: 'Edit Recommendation Rule',
      action: DialogAction.EDIT,
      rule: data
    };
    const matDialogRef = this.dialog.open(RecommendationRuleDialogComponent, matDialogConfig);
    matDialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((reload) => {
        if (reload) {
          this.clearList();
          this.getInformation();
        }
        this.recommendedRulesService.clear();
      });

    matDialogRef
      .backdropClick()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(() => {
        this.recommendedRulesService.clear();
      });
  }

  private onCellDoubleClicked($event): void {
    this.unselectAllRows();
    this.openRecommendationRuleDialog($event.data);
  }

  private unselectAllRows(): void {
    if (this.gridOptions.api) {
      this.gridOptions.api.deselectAll();
      this.onSelectionChanged();
    }
  }
}
