import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { GetInspectionStatusResp, UpdateInspectionStatusResp } from '@xpo-ltl/sdk-inspections';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/internal/operators';
import { ProNumber } from '../classes/pronumber';
import { InspectionState } from '../enums/inspection-state.enum';
import { AppConstantsService } from '../services/app-constants.service';
import { ErrorHandlingService } from '../services/error-handling.service';
import { ShipmentDetailsService } from '../services/shipment-details.service';
import { XpoDialogManagerService } from '../services/xpo-dialog-manager.service';

@Injectable()
export class ChangeInspectionStatusGuard implements CanActivate {
  constructor(
    private shipmentDetailsService: ShipmentDetailsService,
    private dialog: XpoDialogManagerService,
    private constants: AppConstantsService,
    private errorHandling: ErrorHandlingService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    const pro: ProNumber = new ProNumber(route.params['pro']);

    if (!pro) {
      return false;
    }

    return this.shipmentDetailsService.getInspectionStatus(pro).pipe(
      take(1),
      switchMap((statusResp: GetInspectionStatusResp) => {
        if (!statusResp) {
          return of(false);
        }
        if (
          statusResp.inspectionStatusMessage === InspectionState.IN_PROGRESS ||
          statusResp.inspectionStatusMessage === InspectionState.EDITING
        ) {
          return of(true);
        }
        return this.shipmentDetailsService.changeStatus(pro, InspectionState.IN_PROGRESS).pipe(
          take(1),
          switchMap((changeStatusResp: UpdateInspectionStatusResp) => {
            let validChangeStatus = false;
            if (changeStatusResp) {
              if (
                (!changeStatusResp.validationErrors || changeStatusResp.validationErrors.length === 0) &&
                changeStatusResp.messageValidations &&
                changeStatusResp.messageValidations.length > 0
              ) {
                this.constants.setInspectionState(InspectionState.IN_PROGRESS);
                validChangeStatus = true;
              }

              if (validChangeStatus) {
                return of(true); // the only valid case
              } else {
                if (changeStatusResp.validationErrors) {
                  this.dialog.showStatusChangeDialog(changeStatusResp);
                }
                return of(false);
              }
            }
          })
        );
      })
    );
  }
}
