import { ProNumber } from './pronumber';

export class PhotoId {
  private _proNumber: ProNumber;
  // Photo ID is a unique Id for the photo.
  // If from DMS, this will be the dmsDocTimestamp, otherwise a guid
  private _id: string;
  private _imageType: string; // This is Document Class
  private _parentProNumber?: ProNumber;

  // from: https://stackoverflow.com/questions/26501688/a-typescript-guid-class
  // We don't need a real GUID, nor does it really need to be for our purposes,
  // so this is good enough
  private static getGeneratedPseudoGuid(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0;
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  constructor(proNumber: ProNumber, imageType: string, photoId: string = null) {
    this._proNumber = proNumber;
    this._imageType = imageType;
    if (photoId) {
      this._id = photoId;
    } else {
      this._id = PhotoId.getGeneratedPseudoGuid();
    }
  }

  get id(): string {
    return this._id;
  }

  get proNumber(): ProNumber {
    return this._proNumber;
  }

  get imageType(): string {
    return this._imageType;
  }

  get parentProNumber(): ProNumber {
    return this._parentProNumber;
  }

  set parentProNumber(parentPro: ProNumber) {
    this._parentProNumber = parentPro;
  }
}
