import { EventEmitter, Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/internal/operators';
import { Photo } from '../../classes/photo';
import { PhotoId } from '../../classes/photo-id';
import { PhotoUtils } from '../../classes/photo-utils';
import { PhotoGalleryData } from './photo-gallery-data';
import { PhotoGalleryDataIF } from './photo-gallery-data-if';
import { PhotoGalleryComponent } from './photo-gallery.component';

@Injectable({
  providedIn: 'root'
})
export class PhotoGalleryService {
  constructor(private matDialog: MatDialog) {}

  private photoGalleryData: PhotoGalleryData;
  private dialogRef: any;
  private docTypes: string[];
  public onClearThumbnail = new EventEmitter();
  private dataInterface: PhotoGalleryDataIF;
  private photos: Photo[];

  private photoGalleryChangedSubject = new Subject();
  public photoGalleryChanged$ = this.photoGalleryChangedSubject.asObservable();

  public showPhotoGalleryDialog(
    dataInterface: PhotoGalleryDataIF,
    docTypes: string[],
    currentPhoto: Photo | PhotoId,
    photos?: Photo[]
  ): Observable<any | undefined> {
    this.dataInterface = dataInterface;
    this.photoGalleryData = new PhotoGalleryData();
    this.photoGalleryData.serviceRef = this;

    if (currentPhoto instanceof Photo) {
      this.photoGalleryData.currentPhoto = currentPhoto as Photo;
      this.photoGalleryData.currentPhotoId = (currentPhoto as Photo).id;
    } else {
      this.photoGalleryData.currentPhoto = new Photo(currentPhoto as PhotoId);
      this.photoGalleryData.currentPhotoId = currentPhoto as PhotoId;
    }
    this.docTypes = docTypes;
    this.photos = photos;
    this.loadPhoto(this.photoGalleryData.currentPhotoId);

    this.dialogRef = this.matDialog.open(PhotoGalleryComponent, {
      data: this.photoGalleryData,
      // height: '100vh',
      // width: '100vw',
      maxWidth: '100vw',
      // maxHeight: '100vh',
      panelClass: 'photoGalleryPanel'
    });
    return this.dialogRef.afterClosed();
  }

  public canDeletePhoto() {
    return this.dataInterface.canDeletePhoto();
  }

  public nextPhoto() {
    if (this.photoGalleryData.nextPhotoId) {
      this.loadPhoto(this.photoGalleryData.nextPhotoId);
    }
  }

  public previousPhoto() {
    if (this.photoGalleryData.previousPhotoId) {
      this.loadPhoto(this.photoGalleryData.previousPhotoId);
    }
  }

  public loadPhoto(currentPhotoId: PhotoId): void {
    if (!this.photos) {
      // load photos before submitting inspection, which is stored in hardware
      this.loadFromHardware(currentPhotoId);
    } else {
      // load photos that retrieved from DMS
      this.setPreviousCurrentNextPhotos(currentPhotoId);
    }
  }

  private loadFromHardware(photoId: PhotoId): void {
    this.dataInterface
      .listPhotoIds(photoId.proNumber, this.docTypes)
      .pipe(take(1))
      .subscribe((photoIds: PhotoId[]) => {
        this.photos = PhotoUtils.toPhotoArrayFromPhotoIdArray(photoIds);
        this.setPreviousCurrentNextPhotos(photoId);
      });
  }

  private setPreviousCurrentNextPhotos(aCurrentPhotoId: PhotoId): void {
    if (!this.photos) {
      this.dialogRef.close();
    } else {
      this.photoGalleryData.photoCount = this.photos.length;
      for (let i = 0; i < this.photos?.length; i++) {
        const currentPhoto: Photo = this.photos[i];
        if (currentPhoto.id.id === aCurrentPhotoId.id) {
          if (i > 0) {
            this.photoGalleryData.previousPhotoId = this.photos[i - 1].id;
          } else {
            this.photoGalleryData.previousPhotoId = undefined;
          }
          if (i < this.photos.length - 1) {
            this.photoGalleryData.nextPhotoId = this.photos[i + 1].id;
          } else {
            this.photoGalleryData.nextPhotoId = undefined;
          }
          this.photoGalleryData.photoNumber = i + 1;
          this.photoGalleryData.currentPhotoId = currentPhoto.id;
          this.photoGalleryData.currentPhoto = currentPhoto;
          break;
        }
      }
      if (!this.photoGalleryData.currentPhoto.contentType) {
        this.dataInterface
          .loadPhoto(aCurrentPhotoId)
          .pipe(take(1))
          .subscribe((aCurrentPhoto: Photo) => {
            // we could run into a problem where the user spams left and right before the image is loaded.
            // if so, only update the current photo if the currentPhotoId matches the photo returned in the call
            if (this.photoGalleryData.currentPhotoId.id === aCurrentPhotoId.id) {
              this.photoGalleryData.currentPhoto = aCurrentPhoto;
            }
          });
      }
      this.photoGalleryChangedSubject.next();
    }
  }

  public close(): void {
    this.dialogRef.close();
  }

  public deletePhoto(): void {
    const deletePhotoId: PhotoId = this.photoGalleryData.currentPhoto.id;
    this.photos = this.photos.filter((aPhoto: Photo) => aPhoto.id.id !== deletePhotoId.id);

    this.dataInterface
      .deletePhoto(deletePhotoId)
      .pipe(take(1))
      .subscribe(() => {
        if (this.photoGalleryData.nextPhotoId) {
          this.nextPhoto();
        } else if (this.photoGalleryData.previousPhotoId) {
          this.previousPhoto();
        } else {
          // no more archived-photos, close the dialog box
          this.onDeleteLastPhoto();
          this.dialogRef.close();
        }
        this.onDeleteLastPhoto();
      });
  }

  public onDeleteLastPhoto(): void {
    this.onClearThumbnail.emit(null);
  }
}
