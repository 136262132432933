<div fxLayout="column" fxFlex="" fxFlexAlign="stretch start" class="list-shipments">
  <div *ngIf="selectedCount >= 0" fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%">
    <h2 class="recommendation-rules-title">Recommendation Rules</h2>
    <div fxLayoutAlign="start left">
      <div>
        <div (click)="refreshList()">
          <mat-icon class="list-shipments__refresh-icon" svgIcon="refresh-icon" style="width: 16px;"></mat-icon>
        </div>
      </div>
    </div>
    <div class="refreshTime" fxLayout="row" fxLayoutAlign="start left" fxFlex="flex">
      {{ recommendedRulesService.lastRefreshDate$ | async }}
    </div>
    <div fxLayout="row" fxLayoutAlign="end center" fxFlex="flex" style="margin-right: 10px;">
      <button mat-button color="primary" (click)="openRecommendationRuleDialog()">
        Add Recommendation Rule
      </button>

      <button class="icon-button" mat-button [matMenuTriggerFor]="columnMenu" *ngIf="columnsFiltered || columnsSorted">
        <mat-icon svgIcon="filter" class="filterIconRed" *ngIf="columnsFiltered || columnsSorted"></mat-icon>
      </button>

      <mat-menu #columnMenu="matMenu">
        <button (click)="clearGridFilters()" mat-menu-item *ngIf="columnsFiltered">
          Clear Filters
        </button>
        <button (click)="clearGridSorts()" mat-menu-item *ngIf="columnsSorted">
          Clear Sorting
        </button>
        <button (click)="clearGridSortAndFilters()" mat-menu-item *ngIf="columnsFiltered && columnsSorted">
          Clear Both
        </button>
      </mat-menu>

      <button class="icon-button" mat-button [matMenuTriggerFor]="menu">
        <mat-icon svgIcon="gear-icon"></mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button (click)="saveGridSettings()" mat-menu-item>
          Save Column Settings
        </button>
        <button (click)="loadGridSettings()" mat-menu-item>
          Load Column Settings
        </button>
        <button (click)="resetGridSettings()" mat-menu-item>
          Reset Column Settings
        </button>
      </mat-menu>
    </div>
  </div>

  <div>
    <app-toolbar
      [hidden]="selectedCount == 0"
      [showBackButton]="false"
      [title]="toolbarTitle"
      [showSelectDeselectAll]="true"
      (selectAllButtonClicked)="selectAllRows()"
      (deselectAllButtonClicked)="deselectAllRows()"
      [actionButton1Label]="'EDIT'"
      [actionButton2Label]="'DELETE'"
    >
    </app-toolbar>

    <app-list-recommended-rules
      (listChanged)="refreshList()"
      (gridSettingsChanged)="gridSettingsChanged()"
    ></app-list-recommended-rules>
  </div>
</div>
