import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';

import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/internal/operators';
import { GeneralPreferences } from '../classes/general-preferences';
import { SicShiftPreference } from '../classes/sic-shift-preference';
import { ChangeLocationComponent } from '../dialogs/change-location/change-location.component';
import { AppConstantsService } from '../services/app-constants.service';
import { HardwareService } from '../services/hardware/hardware-service';
import { UserPreferencesService } from '../services/user-preferences.service';
import { UserService } from '../services/user.service';
import { XpoDialogManagerService } from '../services/xpo-dialog-manager.service';

@Injectable()
export class UserProfileGuard implements CanActivate {
  constructor(
    private appConstantsService: AppConstantsService,
    private dialogManager: XpoDialogManagerService,
    private hardwareService: HardwareService,
    private userPreferenceService: UserPreferencesService,
    private userService: UserService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    // Load the user settings
    if (!this.appConstantsService.isGeneralUserPreferencesLoaded) {
      this.loadGeneralUserPreferences();
    }

    if (this.appConstantsService.sicCode && this.appConstantsService.shiftCode) {
      return true;
    } else {
      return this.userPreferenceService.loadSicShiftUserPreference().pipe(
        take(1),
        map((preference: SicShiftPreference) => {
          if (preference?.sic && preference?.shift) {
            this.appConstantsService.setInspectionContext(
              preference.sic,
              preference.shift,
              this.userService.currentUserFirstName,
              this.userService.currentUserLastName,
              this.userService.currentUserEmployeeId
            );
            return true;
          } else {
            this.showSicShiftUserPreferenceSetupDialog();
          }
        })
      );
    }
  }

  private showSicShiftUserPreferenceSetupDialog() {
    this.dialogManager.alert(
      {
        titleText: 'Please setup your Home SIC and Shift',
        contentComponent: ChangeLocationComponent,
        injectedData: of({ isSuccess: false }),
        hideDismissButton: true
      },
      {
        disableClose: true,
        width: '600'
      }
    );
  }

  private loadGeneralUserPreferences() {
    this.appConstantsService.isGeneralUserPreferencesLoaded = true;
    this.userPreferenceService
      .loadGeneralUserPreferences()
      .pipe(take(1))
      .subscribe((response: string) => {
        if (response) {
          // We parse the value of general user preferences
          const preference = JSON.parse(response);

          if (this.hardwareService.canSetZoomLevel() && preference.getacZoomlevel) {
            // We set the getac zoom level preference
            const zoomLevel = GeneralPreferences.getZoomLevelValue(preference.getacZoomlevel);
            this.hardwareService.setZoomLevel(zoomLevel);
          }
        }
      });
  }
}
