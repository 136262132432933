import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ColDef, GridOptions } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { ProNumber } from '../../../classes/pronumber';
import { InspectionState } from '../../../enums/inspection-state.enum';
import { ListName } from '../../../enums/list-name.enum';
import { AppNavigationService } from '../../../services/app-navigation.service';
import { GridSettingsService } from '../../../services/grid-settings/grid-settings.service';
import { HardwareService } from '../../../services/hardware/hardware-service';
import { ShipmentDetailsService } from '../../../services/shipment-details.service';
import { XpoDialogManagerService } from '../../../services/xpo-dialog-manager.service';
import { ComparatorUtils } from '../../../classes/comparator-utils';

@Component({
  selector: 'app-list-planning-shipments',
  templateUrl: './list-recommended-shipments.component.html',
  styleUrls: ['./list-recommended-shipments.component.scss']
})
export class ListPlanningShipmentsComponent implements OnInit, OnDestroy {
  public gridOptions: GridOptions;
  public rowData = [];
  public totalCount = 0;
  height: any;
  width: any;

  @Output()
  selectedCountChanged = new EventEmitter<number>();

  @Output()
  listChanged = new EventEmitter<boolean>();

  @Output()
  gridSettingsChanged = new EventEmitter<boolean>();

  constructor(
    private shipmentDetailsService: ShipmentDetailsService,
    private gridSettings: GridSettingsService,
    private dialogManager: XpoDialogManagerService,
    private appNavigation: AppNavigationService
  ) {
    this.gridOptions = <GridOptions>{};

    this.gridOptions.defaultColDef = <ColDef>{
      resizable: true,
      sortable: true,
      filter: 'agSetColumnFilter',
      menuTabs: ['filterMenuTab'],
      filterParams: {
        comparator: (a: string, b: string) => {
          return ComparatorUtils.stringAndNumberCompare(a, b);
        }
      }
    };

    this.gridOptions.columnDefs = this.gridSettings.buildDefaultRecommendedListColumnDefs();

    this.gridOptions.rowSelection = 'multiple';
    this.gridOptions.suppressMenuHide = HardwareService.isTouchScreenCapable();
    this.gridOptions.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.gridOptions.onCellDoubleClicked = this.onCellDoubleClicked.bind(this);
    this.gridOptions.onColumnMoved = this.columnsChanged.bind(this);
    this.gridOptions.onColumnPinned = this.columnsChanged.bind(this);
    this.gridOptions.onColumnResized = this.columnsChanged.bind(this);
    this.gridOptions.onFilterChanged = this.columnsChanged.bind(this);
    this.gridOptions.onColumnVisible = this.columnsChanged.bind(this);
    this.gridOptions.onSortChanged = this.columnsChanged.bind(this);
    this.gridOptions.onRowDataChanged = this.rowDataChanged.bind(this);
    this.gridOptions.icons = GridSettingsService.buildGridIcons();
    this.gridOptions.enableCellTextSelection = true;
    this.gridOptions.ensureDomOrder = true;
    this.gridOptions.onGridReady = () => {
      this.setHeightAndWidth();
    };
  }

  private subscription = new Subscription();

  public ngOnInit() {
    this.subscription.add(
      this.shipmentDetailsService.recommendedListData$.subscribe((value) => {
        if (value) {
          this.unselectAllRows();
          this.rowData = value;
          this.totalCount = value.length;
        }
      })
    );
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private rowDataChanged($event) {
    this.gridSettings.restoreGridState(ListName.Recommended, this.gridOptions);
    this.setHeightAndWidth();
  }

  private columnsChanged($event) {
    this.gridSettings.storeGridState(ListName.Recommended, this.gridOptions);
    this.gridSettingsChanged.emit(true);
  }

  onResize($event) {
    this.setHeightAndWidth();
  }

  setHeightAndWidth() {
    this.width = document.getElementsByClassName('app-container-content')[0].clientWidth;
    this.height = document.getElementsByClassName('app-container-content')[0].clientHeight - 50;
  }

  private onSelectionChanged() {
    if (this.gridOptions.api) {
      const selectedRows = this.gridOptions.api.getSelectedRows();
      if (selectedRows) {
        this.selectedCountChanged.emit(selectedRows.length);
      }
    }
  }

  private onCellDoubleClicked($event) {
    this.unselectAllRows();
    this.appNavigation.navigateToShipmentDetails(new ProNumber($event.data.proNumber));
  }

  public moveShipmentsToInspectionList() {
    const proNumbers = this.getSelectedShipments();
    this.shipmentDetailsService
      .changeStatus(proNumbers, InspectionState.FLAGGED)
      .pipe(take(1))
      .subscribe((response) => {
        this.dialogManager.showStatusChangeDialog(response);
        this.listChanged.emit(true);
      });
  }

  public dismissShipments() {
    const proNumbers = this.getSelectedShipments();
    this.shipmentDetailsService
      .changeStatus(proNumbers, InspectionState.DISMISSED)
      .pipe(take(1))
      .subscribe((response) => {
        this.dialogManager.showStatusChangeDialog(response);
        this.listChanged.emit(true);
      });
  }

  private getSelectedShipments(): ProNumber[] {
    const selectedShipments: ProNumber[] = [];
    if (this.gridOptions.api) {
      const selectedRows = this.gridOptions.api.getSelectedRows();
      if (selectedRows && selectedRows.length > 0) {
        selectedRows.forEach((row) => {
          selectedShipments.push(new ProNumber(row.proNumber));
        });
        this.unselectAllRows();
      }
    }
    return selectedShipments;
  }

  private unselectAllRows() {
    if (this.gridOptions.api) {
      this.gridOptions.api.deselectAll();
      this.onSelectionChanged();
    }
  }
}
