import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { takeUntil } from 'rxjs/operators';
import { CustomerGuidelinesFormComponent } from '../../components/customer-guidelines/customer-guidelines-form/customer-guidelines-form.component';
import { CustomerGuidelinesService } from '../../components/customer-guidelines/services/customer-guidelines.service';
import { DialogAction, toDialogActionPastTense } from '../../enums/dialog-actions.enum';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-customer-guideline-dialog',
  templateUrl: './customer-guideline-dialog.component.html',
  styleUrls: ['./customer-guideline-dialog.component.scss']
})
export class CustomerGuidelineDialogComponent implements OnInit, OnDestroy {
  @ViewChild(CustomerGuidelinesFormComponent, { static: false })
  public customerGuidelinesForm: CustomerGuidelinesFormComponent;

  isFormValidated: boolean;
  action: string;
  title: string;
  isManagerActionsAvailable: boolean;

  private unsubscriber$: Subject<any> = new Subject();

  constructor(
    private dialogRef: MatDialogRef<CustomerGuidelineDialogComponent>,
    private customerGuidelinesService: CustomerGuidelinesService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.customerGuidelinesService.isManagerActionsAvailable$
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((isManagerActionsAvailable: boolean) => {
        this.isManagerActionsAvailable = isManagerActionsAvailable;
      });
    if (data) {
      this.action = data.action;
      const customerGuidelineId: number = data.guideline?.customerGuidelineId;
      if (customerGuidelineId) {
        if (this.isManagerActionsAvailable) {
          this.title = `${data.title} ID: ${customerGuidelineId}`;
        } else {
          this.title = `Customer Guideline ID: ${customerGuidelineId}`;
        }
      } else {
        this.title = `${data.title}`;
      }

      this.customerGuidelinesService.setAction(this.action);
    }

    if (this.action === DialogAction.EDIT) {
      // We copy the guideline object to avoid passing it by reference
      const customerGuideline = JSON.parse(JSON.stringify(data.guideline));

      this.customerGuidelinesService.setCustomerGuideline(customerGuideline);
    }

    this.isFormValidated = false;
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

  public validForm(): boolean {
    if (
      this.customerGuidelinesService.getMadCodeIsValid() &&
      this.customerGuidelinesService.getCustomerGuideline().guideline !== undefined &&
      this.customerGuidelinesService
        .getCustomerGuideline()
        .guideline.localeCompare(this.customerGuidelinesService.getGuideline()) !== 0 &&
      this.customerGuidelinesService.getGuideline().length >= 10 &&
      this.customerGuidelinesService.getGuideline().length <= 4000
    ) {
      return true;
    } else {
      return false;
    }
  }

  public confirmDialog() {
    if (
      this.customerGuidelinesService.getMadCodeIsValid() &&
      this.customerGuidelinesService.getCustomerGuideline().guideline !== undefined &&
      this.customerGuidelinesService
        .getCustomerGuideline()
        .guideline.localeCompare(this.customerGuidelinesService.getGuideline()) !== 0 &&
      this.customerGuidelinesService.getGuideline().length >= 10 &&
      this.customerGuidelinesService.getGuideline().length <= 4000
    ) {
      this.isFormValidated = true;
    } else {
      this.isFormValidated = false;
    }
  }

  public performCustomerGuidelineOperation() {
    let action: any;
    let showMessage = '';
    if (this.customerGuidelinesService.getAction() === DialogAction.EDIT) {
      action = this.customerGuidelinesService.updateCustomerGuideline();
    } else if (this.customerGuidelinesService.getAction() === DialogAction.DELETE) {
      action = this.customerGuidelinesService.deleteCustomerGuideline();
    } else {
      action = this.customerGuidelinesService.createCustomerGuideline();
    }

    showMessage = `The guideline ${toDialogActionPastTense(this.action as DialogAction)} successfully`;
    action.pipe(takeUntil(this.unsubscriber$)).subscribe(
      () => {
        this.snackBar.open(showMessage, 'Close', {
          duration: 3000
        });
        this.close(true);
      },
      () => {
        this.backFormScreen();
      }
    );
  }

  public deleteGuideline() {
    this.customerGuidelinesService.setAction(DialogAction.DELETE);
    this.isFormValidated = this.customerGuidelinesService.getMadCodeIsValid();
  }

  public backFormScreen() {
    this.isFormValidated = false;

    if (this.customerGuidelinesService.getAction() === DialogAction.DELETE) {
      this.customerGuidelinesService.setAction(DialogAction.EDIT);
    }
  }

  public cancel(): void {
    this.close(false);
  }

  public close(reload): void {
    this.customerGuidelinesService.clear();
    this.dialogRef.close(reload);
  }

  public clear(): void {
    this.customerGuidelinesService.clear();
    this.customerGuidelinesService.setCustomerHasGuideline(false);
    this.customerGuidelinesForm.madCodeIsValid.setIsValid(true);
  }

  public getAction(): string {
    return this.customerGuidelinesService.getAction();
  }

  public getWarningFromAction(): string {
    return `Are you sure you want to ${this.getAction()} this Customer Guideline?`;
  }
}
