import { Injectable } from '@angular/core';
import { NotificationService } from '@xpo-ltl/data-api';
import { Observable, Observer, of } from 'rxjs';
import { delay, timeout } from 'rxjs/internal/operators';
import { RetryStats } from './retry-stats';

@Injectable()
export class RetryStrategy {
  constructor(private notificationService: NotificationService) {}

  public retryStrategy = (
    defaultTimeoutValue: number,
    delayOnError: number,
    retryCount: number,
    overlayMessage: string,
    strategyKey: string = null
  ) => {
    let timeoutValue = defaultTimeoutValue;
    let retryStats: RetryStats = null;
    if (strategyKey) {
      retryStats = RetryStats.loadRetryStats(strategyKey);
      timeoutValue = retryStats.evaluateTimeoutValue(defaultTimeoutValue);
    }

    return (source: Observable<any>) => {
      return new Observable<any>((observer: any) => {
        if (overlayMessage) {
          this.notificationService.showOverlayMessage(overlayMessage);
          this._retryStrategy(source, observer, timeoutValue, delayOnError, retryCount, true, retryStats);
        } else {
          this._retryStrategy(source, observer, timeoutValue, delayOnError, retryCount, false, retryStats);
        }
      });
    };
    // tslint:disable-next-line
  };

  private _retryStrategy(
    source: Observable<any>,
    observer: Observer<any>,
    timeoutValue: number,
    delayOnError: number,
    retryCount: number,
    removeOverlay: boolean,
    retryStats: RetryStats,
    lastError: any = null
  ) {
    if (retryCount < 0) {
      if (removeOverlay) {
        this.notificationService.hideOverlayMessage();
      }
      if (retryStats) {
        // if we truly timed out, something is really wrong.. Reset stats to make sure we don't get stuck always timing out
        retryStats.clearRetryStats();
      }
      observer.error(lastError);
    } else {
      const startTime = new Date().getTime();
      source.pipe(timeout(timeoutValue)).subscribe(
        (next: any) => {
          // only store key on success
          if (retryStats) {
            const elapsedTime = new Date().getTime() - startTime;
            retryStats.addTransactionTime(elapsedTime);
            retryStats.saveRetryStats();
          }
          observer.next(next);
        },
        error => {
          // If a 400 series error, just return, don't retry
          if (error && error.code && error.code.toString().startsWith('4')) {
            if (removeOverlay) {
              this.notificationService.hideOverlayMessage();
            }
            observer.error(error);
            return;
          }

          // if timeout.. then update timeout and call again, otherwise delay then call again
          let delayValue = 0;

          if (error && error.name === 'TimeoutError') {
            timeoutValue = timeoutValue * 2;
          } else {
            delayValue = delayOnError;
          }
          of(true)
            .pipe(delay(delayValue))
            .subscribe(() => {
              return this._retryStrategy(
                source,
                observer,
                timeoutValue,
                delayOnError,
                retryCount - 1,
                removeOverlay,
                retryStats,
                error
              );
            });
        },
        () => {
          if (removeOverlay) {
            this.notificationService.hideOverlayMessage();
          }
          observer.complete();
        }
      );
    }
  }
}
