import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InputNumberModule } from '@deividfortuna/input-number';
import {
  CustomerApiService as CustomerV2ApiService,
  SdkCustomerModule as SdkCustomerV2Module
} from '@xpo-ltl-2.0/sdk-customer';
import { LocationApiService as LocationV2ApiService, SdkLocationModule } from '@xpo-ltl-2.0/sdk-location';
import { ConditioningService, FormatValidationService } from '@xpo-ltl/common-services';
import { ConfigManagerModule, CONFIG_MANAGER_SERVICE_CONFIGURATION } from '@xpo-ltl/config-manager';
import { DataApiService, NotificationService } from '@xpo-ltl/data-api';
import { SupportedAppEnum, XpoMaintAppConfig, XpoMaintenanceModule } from '@xpo-ltl/maint-ui';
import { XpoLtlAuthConfigLoaderService, XpoLtlAuthModule } from '@xpo-ltl/ngx-auth';
import { XpoLtlAuthUiModule } from '@xpo-ltl/ngx-auth-ui';

import { SdkAppMetadataModule } from '@xpo-ltl/sdk-appmetadata';
import { BeaconApiService } from '@xpo-ltl/sdk-beacon';
import { DocumentManagementApiService } from '@xpo-ltl/sdk-documentmanagement';
import { FreightMovementApiService } from '@xpo-ltl/sdk-freightmovement';
import { HumanResourceApiService } from '@xpo-ltl/sdk-humanresource';
import { InspectionsApiService, SdkInspectionsModule } from '@xpo-ltl/sdk-inspections';
import { XpoAuthConfig, XPO_AUTH_CONFIG } from '@xpo/ngx-auth';
import { AgGridModule } from 'ag-grid-angular';
import { environment } from '../environments/environment';
import { AppDialogsModule } from './app-dialogs.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AddProResolverService } from './components/add-pro-detail/service/add-pro-resolver.service';
import { AppFooterComponent } from './components/app-footer/app-footer.component';
import { CameraService } from './components/camera/camera.service';
import { CustomerGuidelinesService } from './components/customer-guidelines/services/customer-guidelines.service';
import { InsContextHeaderComponent } from './components/ins-context-header/ins-context-header.component';
import { InspectShipmentModule } from './components/inspect-shipment/inspect-shipment.module';
import { ListCompleteInspectionsComponent } from './components/list-shipments/list-completed-inspections/list-completed-inspections.component';
import { ListDismissedShipmentsComponent } from './components/list-shipments/list-dismissed-shipments/list-dismissed-shipments.component';
import { ListFlaggedShipmentsComponent } from './components/list-shipments/list-flagged-shipments/list-flagged-shipments.component';
import { ListInspectionsComponent } from './components/list-shipments/list-inspected-inspections/list-inspected-inspections.component';
import { ListPlanningShipmentsComponent } from './components/list-shipments/list-recommended-shipments/list-recommended-shipments.component';
import { ListShipmentsComponent } from './components/list-shipments/list-shipments.component';
import { ManifestSearchColumnComponent } from './components/list-shipments/manifest-search-column/manifest-search-column.component';
import { NmfcColumnComponent } from './components/list-shipments/nmfc-column/nmfc-column.component';
import { NotAuthorizedComponent } from './components/not-authorized/not-authorized.component';
import { NotificationComponent } from './components/notification/notification.component';
import { PhotoGalleryService } from './components/photo-gallery/photo-gallery.service';
import { RecommendedRulesService } from './components/recommendation-rules/services/recommended-rules.service';
import { ShipmentDetailsModule } from './components/shipment-details/shipment-details.module';
import { ToolbarModule } from './components/toolbar/toolbar.module';
import { Item680CalculatorModule } from './components/tools/item680calculator/item680calculator.module';
import { Item680ValidationsService } from './components/tools/item680calculator/services/item680-validations.service';
import { TrailerDetailResolver } from './components/trailer-detail/resolver/trailer-detail.resolver';
import { DialogsModule } from './dialogs/dialogs.module';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { InputValidationModule } from './input-validation/input-validation.module';
import { MaterialModule } from './material.module';
import { RetryStrategy } from './operators/retry-strategy';
import { XpoPipesModule } from './pipes/xpo-pipes.module';
import { AccountService } from './services/account.service';
import { AppConstantsService } from './services/app-constants.service';
import { AppNavigationService } from './services/app-navigation.service';
import { AppStorageService } from './services/app-storage.service';
import { DocumentManagementService } from './services/document-management-service';
import { EmailService } from './services/email/email.service';
import { ErrorHandlingService } from './services/error-handling.service';
import { GridSettingsService } from './services/grid-settings/grid-settings.service';
import { HardwareService } from './services/hardware/hardware-service';
import { HardwareServiceFactory } from './services/hardware/hardware-service-factory';
import { InspectionLocalStorageService } from './services/inspection-local-storage.service';
import { PhotoUploadService } from './services/photo-upload.service';
import { ShipmentDetailsService } from './services/shipment-details.service';
import { RequestValidator } from './validators/request.validator';
import { XpoIconModule } from '@xpo-ltl/ngx-ltl-core/icon';
import { XpoAppSwitcherPopoverModule } from '@xpo-ltl/ngx-ltl-core/app-switcher-popover';
import { XpoDialogModule } from '@xpo-ltl/ngx-ltl-core/dialog';
import { XpoFeedbackModule } from '@xpo-ltl/ngx-ltl-core/feedback';
import { XpoSnackBarModule } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { XpoFooterModule } from '@xpo-ltl/ngx-ltl-core/footer';
import { XpoButtonModule } from '@xpo-ltl/ngx-ltl-core/button';
import { XpoSlideToggleModule } from '@xpo-ltl/ngx-ltl-core/toggle';
import { XpoShellModule } from '@xpo-ltl/ngx-ltl-core/shell';
import { XpoAccountPopoverModule } from '@xpo-ltl/ngx-ltl-core/account-popover';
import { XpoContextualHeaderModule } from '@xpo-ltl/ngx-ltl-core/contextual-header';
import { XpoPageNotFoundRoutingModule } from '@xpo-ltl/ngx-ltl-core/error-page';

export function authConfigFactory(configService: XpoLtlAuthConfigLoaderService): XpoAuthConfig {
  configService.initSettings('./assets/config.json');

  return new XpoAuthConfig({
    appCode: configService.appCode,
    scopes: configService.scopes,
    isProd: configService.isProd,
    applicationRootUri: configService.appRootUri
  });
}

const sdkModules: any[] = [SdkAppMetadataModule, SdkLocationModule, SdkInspectionsModule, SdkCustomerV2Module];

const ltlCoreModules = [
  XpoAccountPopoverModule,
  XpoAppSwitcherPopoverModule,
  XpoDialogModule,
  XpoFeedbackModule,
  XpoShellModule,
  XpoSnackBarModule,
  XpoIconModule,
  XpoFooterModule,
  XpoContextualHeaderModule,
  XpoButtonModule,
  XpoSlideToggleModule,
  XpoLtlAuthUiModule
];

@NgModule({
    declarations: [
        AppComponent,
        InsContextHeaderComponent,
        AppFooterComponent,
        NotAuthorizedComponent,
        NotificationComponent,
        ListShipmentsComponent,
        ListPlanningShipmentsComponent,
        ListFlaggedShipmentsComponent,
        ListDismissedShipmentsComponent,
        ListInspectionsComponent,
        NmfcColumnComponent,
        ListCompleteInspectionsComponent,
        ClickOutsideDirective,
        ManifestSearchColumnComponent
    ],
    imports: [
        AngularFireModule.initializeApp(environment.firebase),
        BrowserModule,
        BrowserAnimationsModule,
        MaterialModule,
        ConfigManagerModule,
        DialogsModule,
        XpoPipesModule,
        XpoMaintenanceModule.initialize(<XpoMaintAppConfig>{ appName: SupportedAppEnum.IG }),
        ...ltlCoreModules,
        ...sdkModules,
        AppDialogsModule,
        AgGridModule.withComponents([]),
        ToolbarModule,
        InputNumberModule,
        InputValidationModule,
        Item680CalculatorModule,
        ShipmentDetailsModule,
        InspectShipmentModule,
        XpoLtlAuthModule.forRoot({
            provide: XPO_AUTH_CONFIG,
            useFactory: authConfigFactory,
            deps: [XpoLtlAuthConfigLoaderService]
        }),
        AppRoutingModule,
        XpoPageNotFoundRoutingModule // KEEP LAST!!!
    ],
    providers: [
        {
            provide: CONFIG_MANAGER_SERVICE_CONFIGURATION,
            useValue: { configFileUrl: './assets/config.json' }
        },
        {
            provide: HardwareService,
            useFactory: (photoGalleryService: PhotoGalleryService, appStorageService: AppStorageService, cameraService: CameraService, constantsService: AppConstantsService) => HardwareServiceFactory(photoGalleryService, appStorageService, cameraService, constantsService),
            deps: [PhotoGalleryService, AppStorageService, CameraService, AppConstantsService]
        },
        AppConstantsService,
        GridSettingsService,
        DataApiService,
        NotificationService,
        CustomerV2ApiService,
        FreightMovementApiService,
        LocationV2ApiService,
        ErrorHandlingService,
        FormatValidationService,
        ConditioningService,
        ShipmentDetailsService,
        DocumentManagementApiService,
        DocumentManagementService,
        InspectionLocalStorageService,
        PhotoUploadService,
        RetryStrategy,
        AppStorageService,
        TrailerDetailResolver,
        AddProResolverService,
        AppNavigationService,
        Item680ValidationsService,
        RecommendedRulesService,
        CustomerGuidelinesService,
        InspectionsApiService,
        BeaconApiService,
        HumanResourceApiService,
        AccountService,
        CameraService,
        RequestValidator,
        EmailService
    ],
    bootstrap: [AppComponent],
    exports: []
})
export class AppModule {}
