import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Item680ValidationsService } from './services/item680-validations.service';
import { Subject, Observable } from 'rxjs';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Item680AppStorageService } from './services/item680-app-storage.service';
import { ProNumber } from '../../../classes/pronumber';
import { InspectionLocalStorageService } from '../../../services/inspection-local-storage.service';

@Component({
  selector: 'app-item680calculator',
  templateUrl: './item680calculator.component.html',
  styleUrls: ['./item680calculator.component.scss']
})
export class Item680CalculatorComponent implements OnInit, OnDestroy {
  public eventsSubject: Subject<string> = new Subject<string>();
  private pro: string;

  constructor(
    protected calculatorService: Item680ValidationsService,
    private location: Location,
    private route: ActivatedRoute,
    private item680StorageService: Item680AppStorageService,
    private inspectionStorageService: InspectionLocalStorageService
  ) {}

  ngOnInit() {
    this.pro = this.route.snapshot.paramMap.get('pro');

    if (this.pro) {
      this.calculatorService.setPro(this.pro);
      this.loadCalculatorData(new ProNumber(this.pro));
    } else {
      this.calculatorService.setPro(undefined);
    }
  }

  ngOnDestroy() {
    this.clearForm();
  }

  submitForm() {
    this.calculatorService.setItem680CalculatorSubmitted(true);
    this.calculatorService.generateItem680Comments();
    this.emitEventToChild('submit');
  }

  public clearForm(): void {
    this.calculatorService.resetAllValues();
    this.emitEventToChild('reset');
  }

  public navigateBack(): void {
    this.location.back();
  }

  emitEventToChild($event: any) {
    this.eventsSubject.next($event);
  }

  private loadCalculatorData(proNumber: ProNumber): void {
    const data = this.item680StorageService.getItem6802bData(proNumber);

    if (data) {
      this.calculatorService.setCalculatorData(data);
    } else {
      const dimensions = this.inspectionStorageService.getInspectionDimensions(proNumber);
      this.calculatorService.setOverallDimensions(dimensions);
      const weight = this.inspectionStorageService.getInspectionWeight(proNumber);
      this.calculatorService.setShipmentWeight(weight);
    }

    this.calculatorService.calculateShipmentProperties();
  }
}
