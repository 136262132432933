<div class="inspect-shipment toolbar-inspect-fix">
  <app-toolbar
    [showBackButton]="true"
    (backButtonClicked)="backButtonClicked()"
    [title]="proNumber.formatProNumber() + ' - ' + status"
    [actionButton1Label]="'SUBMIT'"
    (actionButton1Clicked)="submitInspection()"
    [actionButton1IsMain]="true"
    [actionButton2Label]="'INSPECTED NOT CORRECTED'"
    (actionButton2Clicked)="inspectedNotCorrected()"
    [actionButton2IsMain]="true"
  ></app-toolbar>
  <app-dimensions [(inspectionDetails)]="inspectionDetails" [dimensionErrors]="dimensionErrors"></app-dimensions>
  <app-notes [(inspectionDetails)]="inspectionDetails"></app-notes>
  <app-document-list
    [shipmentDetails]="shipmentDetails"
    [title]="DocumentListTitles.PHOTOS"
    [docTypes]="photoDocTypes"
  ></app-document-list>
  <ig-image-gallery
    [(inspectionDetails)]="inspectionDetails"
    (numberOfPhotosChange)="setNumberOfPhotos($event)"
  ></ig-image-gallery>
</div>
