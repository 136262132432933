import { Pipe, PipeTransform } from '@angular/core';
import { DocTypes } from '../enums/doc-type.enum';

@Pipe({
  name: 'imageTypePipe'
})
export class ImageTypePipe implements PipeTransform {
  transform(imageType: string): string {
    if (!imageType) {
      return '';
    }

    switch (imageType) {
      case DocTypes.BILL_OF_LANDING:
        return 'Bill Of Lading';
      case DocTypes.BILL_OF_LANDING_ATTACHMENT:
        return 'Bill Of Lading Attachment';
      case DocTypes.CUSTOMS:
        return 'Customs Document';
      case DocTypes.INSPECTION_CERTIFICATE:
        return 'NMFC Inspection Certificate';
      case DocTypes.REWEIGH_CERTIFICATE:
        return 'WI Inspection Certificate';
      case DocTypes.INSPECTION_PHOTO:
        return 'WI Inspection Photo';
      case DocTypes.TRAILER_PHOTO:
        return 'Trailer Photo';
      case DocTypes.DIMENSION_PHOTO:
        return 'In Motion Dimensioner Photo';
      case DocTypes.PICKUP_DIMENSIONER_PHOTO:
        return 'Dimensioner Photo';
      default:
        return '';
    }
  }
}
