import { ShiftCd } from '@xpo-ltl/sdk-common';

export enum ShiftCode {
  Inbound = 'Inbound',
  Outbound = 'Outbound',
  NightFAC = 'NightFAC',
  DayReship = 'DayReship'
}

export const ShiftCodeDisplayMap = new Map<ShiftCode, string>([
  [ShiftCode.Outbound, 'Outbound'],
  [ShiftCode.Inbound, 'Inbound'],
  [ShiftCode.NightFAC, 'Night FAC'],
  [ShiftCode.DayReship, 'Day Reship']
]);

export function toShiftCode(shiftCodeValue: string): ShiftCode {
  if (shiftCodeValue) {
    for (const key in ShiftCode) {
      if (ShiftCode[key] === shiftCodeValue) {
        return ShiftCode[key.toString()];
      }
    }
  }
  return null;
}

export function toShiftCd(shiftCode: ShiftCode): ShiftCd {
  return ShiftCodeDisplayMap.get(shiftCode) as ShiftCd;
}
