import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { ListName } from '../enums/list-name.enum';
import { RouterUriComponents } from '../enums/router-uri-components.enum';
import { RequestValidator } from '../validators/request.validator';
import { InspectionLocalStorageService } from './inspection-local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AppRouteHistoryService {
  private static HISTORY_LIMIT = 20;
  private static NOT_SAVING_HISTORY_LIST = [
    'auth',
    ListName.Recommended,
    ListName.Flagged,
    ListName.Inspected,
    ListName.Completed,
    ListName.Dismissed,
    RouterUriComponents.INSPECT_SHIPMENT_PAGE,
    RouterUriComponents.RECOMMENDATION_RULES,
    RouterUriComponents.PICKUP_REQUESTS,
    RouterUriComponents.CUSTOMER_GUIDELINES,
    RouterUriComponents.BROADCAST_MESSAGES
  ];

  constructor(private router: Router, private localStorageService: InspectionLocalStorageService) {}

  listenRoutingEvt(): void {
    this.router.events
      .pipe(
        distinctUntilChanged(),
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe(({ url }: NavigationEnd) => {
        this.handleUrl(url);
      });
  }

  getPrevUrl(): string {
    const histories: string[] = this.localStorageService.getRoutingHistory();

    return histories?.[histories?.length - 2]; // the last history is the current page
  }

  getRouteHistory(): string[] {
    return this.localStorageService.getRoutingHistory();
  }

  popPrevUrl(): string {
    const histories: string[] = this.localStorageService.getRoutingHistory();

    histories.pop(); // the last history is the current page so we can discard it
    const prevUrl: string = histories.pop();
    this.localStorageService.setRoutingHistory(histories);

    return prevUrl;
  }

  resetRouteHistory(): void {
    this.localStorageService.setRoutingHistory([]);
  }

  /**
   * store the url if the history array is less than maximum length
   * if the array is maximum length, then remove the oldest url
   * @param url
   */
  private addNavigatedHistory(url: string): void {
    const histories: string[] = this.localStorageService.getRoutingHistory();

    if (histories.length === AppRouteHistoryService.HISTORY_LIMIT) {
      histories.shift();
    }
    histories.push(url);
    this.localStorageService.setRoutingHistory(histories);
  }

  /**
   * handle url to store or reset the history
   * @param url
   */
  private handleUrl(url: string): void {
    RequestValidator.validateStringNotNullOrEmpty(url, 'url');
    url[0] === '/' ? (url = url.substring(1)) : (url = url); // remove first '/' if its included so we can call Router.navigate([])
    const histories: string[] = this.localStorageService.getRoutingHistory();

    if (!AppRouteHistoryService.NOT_SAVING_HISTORY_LIST.some((item) => url.includes(item.toString()))) {
      if (url === RouterUriComponents.LIST_SHIPMENTS) {
        // if url is 'list-shipments', then reset the history
        this.resetRouteHistory();
      } else if (
        histories?.[histories?.length - 1]?.startsWith(RouterUriComponents.INSPECT_SHIPMENT_PAGE) &&
        histories?.length >= 2 &&
        histories?.[histories?.length - 2] === url
      ) {
        // if the current page is inspect-shipment/ page and url is same as the previous page, this event came from back arrow button. pop the history
        histories.pop();
        this.localStorageService.setRoutingHistory(histories);
      } else if (histories?.[histories?.length - 1] !== url) {
        // if url is with PRO such as 'list-shipments/123-456789' and not the same as the last history, then add to the history
        // its possible to get the same url as the last history if user refresh the app
        this.addNavigatedHistory(url);
      }
    } else {
      // if url is in NOT_SAVING_HISTORY_LIST, reset the history
      this.resetRouteHistory();
    }
  }
}
