<mat-dialog class="photoGallery">
  <mat-dialog-content>
    <div fxLayout="column" fxLayoutAlign="space-between stretch" class="photoGallery__content">
      <div fxLayout="row" fxLayoutAlign="space-between stretch" fxFlex="10%">
        <div fxFlex="150px">
          <button mat-button (click)="close()">
            <mat-icon svgIcon="back-button-icon" class="photoGallery__icon"></mat-icon>
          </button>
        </div>
        <div fxFlex="fxFlex" fxLayout="column" fxLayoutAlign="space-evenly stretch" style="text-align: center">
          <div>
            <span class="photoGallery__label">
              PRO {{ data?.currentPhotoId?.proNumber?.formatProNumber() }} -
              {{ data?.currentPhotoId?.imageType | imageTypePipe }}</span
            >
          </div>
          <div>
            <span class="photoGallery__label">{{ data?.photoNumber }} of {{ data?.photoCount }}</span>
          </div>
        </div>
        <div fxFlex="150px">
          <button mat-button (click)="deletePhoto()" *ngIf="canDeletePhoto()">
            <mat-icon svgIcon="trash-icon" class="photoGallery__icon"></mat-icon>
          </button>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="space-evenly between" fxFlex="fxFlex">
        <div fxFlex="100px" fxLayoutAlign="center center" class="photoGallery__leftArrow">
          <button mat-button (click)="previousPhoto()" *ngIf="data?.previousPhotoId">
            <mat-icon svgIcon="back-arrow-icon" class="photoGallery__icon"></mat-icon>
          </button>
        </div>

        <div fxFlex="fxFlex" fxLayout="column" fxLayoutAlign="center center">
          <div *ngIf="data?.currentPhoto?.isLoaded">
            <img [src]="data.currentPhoto.getBase64data(true)" class="photoGallery__image" />
          </div>
        </div>

        <div fxFlex="100px" fxLayoutAlign="center center" class="photoGallery__rightArrow">
          <button mat-button (click)="nextPhoto()" *ngIf="data?.nextPhotoId">
            <mat-icon svgIcon="forward-arrow-icon" class="photoGallery__icon"></mat-icon>
          </button>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</mat-dialog>
