export enum ErrorMessageActions {
  CREATING = 'creating',
  UPDATING = 'updating',
  DELETING = 'deleting',
  GETTING = 'getting',
  SENDING = 'sending',
  CANCELING = 'canceling',
  RESPONDING = 'responding',
  ADDING = 'adding',
  REFRESHING = 'refreshing',
  LOADING = 'loading',
  SAVING = 'saving',
  TAKING = 'taking'
}
