import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertWrapperComponent } from './dialogs/alert-wrapper/alert-wrapper.component';
import { ConfirmCancelComponent } from './dialogs/confirm-cancel/confirm-cancel.component';
import { XpoDialogManagerService } from './services/xpo-dialog-manager.service';
import { MaterialModule } from './material.module';
import { DialogTemplateInjectorDirective } from './dialogs/dialog-template-injector.directive';
import { IFrameDisplayComponent } from './dialogs/iframe-display/iframe-display.component';
import { StatusChangeDialogComponent } from './dialogs/status-change-dialog/status-change-dialog.component';

@NgModule({
    imports: [CommonModule, MaterialModule],
    declarations: [DialogTemplateInjectorDirective, AlertWrapperComponent],
    providers: [XpoDialogManagerService],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppDialogsModule {}
