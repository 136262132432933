import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import { CustomerGuidelines } from '../../classes/customer-guidelines';

@Component({
  selector: 'app-customer-instructions-dialog',
  templateUrl: './customer-instructions-dialog.component.html'
})
export class CustomerInstructionsDialogComponent implements OnInit {
  guidelines: CustomerGuidelines[];

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<CustomerInstructionsDialogComponent>
  ) {
    if (data) {
      this.guidelines = data.message;
    }
  }
  ngOnInit() {}
}
