<div class="location-movement">
  <mat-expansion-panel [(expanded)]="isExpanded">
    <mat-expansion-panel-header>
      <span>
        <mat-panel-title class="section-label"> Location & Movement </mat-panel-title>
      </span>

      <span
        *ngIf="!showChildPro && shipmentDetails?.inspectionHandlingUnitExemptionInd"
        class="warning-for-exemptionPLT"
      >
        This PRO is PLT but has a Child PRO Exemption, there are no associated Child PROs
      </span>
    </mat-expansion-panel-header>

    <div fxLayout="row" fxLayoutAlign="start stretch">
      <div fxLayout="column" fxFlex="20">
        <div class="label">Origin SIC</div>
        <div class="value">
          {{ shipmentLocationDetails?.shipmentDetail?.originSic }}
        </div>
      </div>
      <div fxLayout="column" fxFlex="20">
        <div class="label">Destination SIC</div>
        <div class="value">
          {{ shipmentLocationDetails?.shipmentDetail?.destSic }}
        </div>
      </div>
      <div *ngIf="!showChildPro" fxLayout="column" fxFlex="20">
        <div class="label">Trailer Status</div>
        <div class="value">
          {{ shipmentLocations[0]?.locationBreakdown?.trailerStatus }}
        </div>
      </div>
      <div *ngIf="!showChildPro" fxLayout="column" fxFlex="20">
        <div class="label">Location</div>
        <div class="value">
          {{ shipmentLocations[0]?.locationBreakdown?.shipmentLocation }}
        </div>
      </div>
      <div *ngIf="!showChildPro" fxLayout="column" fxFlex="20">
        <div class="label">Trailer Number</div>
        <div class="manifest-search-column">
          <a [routerLink]="[routerLink, shipmentLocations[0]?.locationBreakdown?.trailerNumber]">{{
            shipmentLocations[0]?.locationBreakdown?.trailerNumber
          }}</a>
        </div>
      </div>
      <div fxLayout="column" fxFlex="20">
        <div class="label">ETA</div>
        <div class="value">
          {{ shipmentLocationDetails?.shipmentDetail?.etaTmst | timeFormatPipe }}
        </div>
      </div>
      <div *ngIf="!showChildPro" fxLayout="column" fxFlex="20">
        <div class="label">Break Door</div>
        <div class="manifest-search-column">
          <a [routerLink]="[routerLink, shipmentLocationDetails?.shipmentDetail?.breakDoor]">{{
            shipmentLocationDetails?.shipmentDetail?.breakDoor
          }}</a>
        </div>
      </div>
      <div fxLayout="column" fxFlex="20">
        <div class="label">Load Door</div>
        <div class="manifest-search-column">
          <a [routerLink]="[routerLink, shipmentLocationDetails?.shipmentDetail?.loadDoor]">{{
            shipmentLocationDetails?.shipmentDetail?.loadDoor
          }}</a>
        </div>
      </div>
    </div>
    <div *ngIf="showChildPro" fxLayout="row" fxLayoutAlign="start stretch">
      <div fxLayout="column" fxFlex="20">
        <div class="label">Child PRO</div>
        <div *ngFor="let shipmentLocation of shipmentLocations" class="childPro-value">
          {{ shipmentLocation?.pltProNumber | childProPipe }}
        </div>
      </div>
      <div fxLayout="column" fxFlex="20">
        <div class="label">Trailer Status</div>
        <div *ngFor="let shipmentLocation of shipmentLocations" class="childPro-value">
          {{ shipmentLocation?.locationBreakdown?.trailerStatus }}
        </div>
      </div>
      <div fxLayout="column" fxFlex="20">
        <div class="label">Location</div>
        <div *ngFor="let shipmentLocation of shipmentLocations" class="childPro-value">
          {{ shipmentLocation?.locationBreakdown?.shipmentLocation }}
        </div>
      </div>
      <div fxLayout="column" fxFlex="20">
        <div class="label">Break Door</div>
        <div *ngFor="let shipmentLocation of shipmentLocations" class="childPro-value">
          <div class="manifest-search-column">
            <a [routerLink]="[routerLink, shipmentLocation?.locationBreakdown?.breakDoorNumber]">{{
              shipmentLocation?.locationBreakdown?.breakDoorNumber
            }}</a>
          </div>
        </div>
      </div>
      <div fxLayout="column" fxFlex="20">
        <div class="label">Trailer Number</div>
        <div *ngFor="let shipmentLocation of shipmentLocations" class="childPro-value">
          <div class="manifest-search-column">
            <a [routerLink]="[routerLink, shipmentLocation?.locationBreakdown?.trailerNumber]">{{
              shipmentLocation?.locationBreakdown?.trailerNumber
            }}</a>
          </div>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
</div>
