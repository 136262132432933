import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AppConstantsService } from '../../../services/app-constants.service';
import { ShipmentDetailsService } from '../../../services/shipment-details.service';
import { AddPro } from '../model/add-pro.model';
import { AppNavigationService } from '../../../services/app-navigation.service';
import { ListType } from '../../../enums/list-type.enum';
import { map } from 'rxjs/internal/operators';
import { InspectionShipment } from '@xpo-ltl/sdk-inspections';

@Injectable()
export class AddProResolverService implements Resolve<Observable<AddPro[]>> {
  constructor(
    private constantsService: AppConstantsService,
    private shipmentDetailsService: ShipmentDetailsService,
    private appNavigation: AppNavigationService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<AddPro[]> {
    if (!this.constantsService.proNbrInputs) {
      this.appNavigation.navigateToList(ListType.Shipment);
      return null;
    } else {
      return this.shipmentDetailsService
        .listInspectionDetail(this.constantsService.proNbrInputs)
        .pipe(map((shipments: InspectionShipment[]) => AddPro.mapInspectionShipments(shipments)));
    }
  }
}
