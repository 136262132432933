import { Injectable } from '@angular/core';
import { CreateCustomerGuidelineResp, CustomerGuideline, UpdateCustomerGuidelineResp } from '@xpo-ltl/sdk-inspections';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomerGuidelines } from '../../../classes/customer-guidelines';
import { InspectionsApiWrapperService } from '../../../services/inspections/inspections.service';
import { UserService } from '../../../services/user.service';

@Injectable()
export class CustomerGuidelinesService {
  private customerGuideline = new CustomerGuidelines();
  private madCode: string;
  private accountName: string;
  private madCodeIsValid: boolean;
  private guideline: string;
  private customerHasGuideline: boolean;
  private accountId: string;
  private action: string;

  public isManagerActionsAvailable$: Observable<boolean>;

  constructor(private inspectionsService: InspectionsApiWrapperService, private userService: UserService) {
    this.initializeAttributes();
  }

  private initializeAttributes(): void {
    this.madCodeIsValid = false;
    this.madCode = '';
    this.accountName = '';
    this.guideline = '';
    this.customerGuideline = new CustomerGuidelines();

    this.isManagerActionsAvailable$ = this.userService.userRoleChanged$.pipe(
      map((isRoleChanged: boolean) => {
        if (isRoleChanged) {
          return this.userService.isManagerActionsAvailable();
        } else {
          return false;
        }
      })
    );
  }

  public clear() {
    this.initializeAttributes();
  }

  public validateForm(): boolean {
    return true;
  }

  public getAccountName(): string {
    return this.accountName;
  }

  public setAccountName(accountName: string): void {
    this.accountName = accountName;
  }

  public getMadCode() {
    return this.madCode;
  }

  public setMadCode(madCode: string) {
    this.madCode = madCode;
  }

  public getMadCodeIsValid(): boolean {
    return this.madCodeIsValid;
  }

  public setMadCodeIsValid(madCodeIsValid: boolean): void {
    this.madCodeIsValid = madCodeIsValid;
  }

  public getGuideline() {
    return this.guideline;
  }

  public setGuideline(guidelineUpdated: string) {
    this.guideline = guidelineUpdated;
  }

  public getAccountId() {
    return this.accountId;
  }

  public setAccountId(accountInstId: string) {
    this.accountId = accountInstId;
  }

  public getAction() {
    return this.action;
  }

  public setAction(action: string) {
    this.action = action;
  }

  public setCustomerGuideline(guideline: any) {
    this.customerGuideline = guideline;
    this.madCode = this.customerGuideline.acctMadCd;
    this.accountName = this.customerGuideline.acctName;
    this.guideline = this.customerGuideline.guideline;
    this.setMadCodeIsValid(true);
  }

  public getCustomerGuideline(): CustomerGuidelines {
    return this.customerGuideline;
  }

  public getCustomerHasGuideline(): boolean {
    return this.customerHasGuideline;
  }

  public setCustomerHasGuideline(hasGuideline: boolean): void {
    this.customerHasGuideline = hasGuideline;
  }

  public getCustomerGuidelines(): Observable<CustomerGuidelines[]> {
    return this.inspectionsService.listCustomerGuidelines();
  }

  public updateCustomerGuideline(): Observable<UpdateCustomerGuidelineResp> {
    return this.inspectionsService.updateCustomerGuideline(
      this.mapCustomerGuideline(),
      this.customerGuideline?.acctInstId
    );
  }

  private mapCustomerGuideline() {
    const guidelineEntity = new CustomerGuideline();
    guidelineEntity.customerGuidelineId = this.customerGuideline.customerGuidelineId;
    guidelineEntity.customerId = Number(this.customerGuideline.acctInstId);
    guidelineEntity.guideline = this.guideline;
    return guidelineEntity;
  }

  public deleteCustomerGuideline(): Observable<void> {
    return this.inspectionsService.deleteCustomerGuideline(this.customerGuideline?.acctInstId);
  }

  public createCustomerGuideline(): Observable<CreateCustomerGuidelineResp> {
    return this.inspectionsService.createCustomerGuideline(this.mapCustomerGuideline());
  }
}
