import { Injectable } from '@angular/core';
import { BroadcastMessage } from '../classes/broadcast-messages/broadcast-message';
import { AppConfigManagerService } from './app-config-manager.service';
import { AppConstantsService } from './app-constants.service';

/**
 * ONLY USED IN TEST ENVIRONMENT
 * This service will have methods/properties that will help testing such as
 *  - mock/use dummy API responses
 *  - change user roles to switch app version between Managers and Inspectors and more
 *
 * unread-broadcast-message-dialog.component.ts also has remove/restore ItemInstanceId()
 * as dev only methods
 */
@Injectable({
  providedIn: 'root'
})
export class DevTestingService {
  private static readonly indexNumberOfRemovedItemInstanceId: number = 0;

  // storing boolean string. using it in toggle [checked] value in dev-only.component.html
  isManagerRolesChecked: boolean;

  // remove ItemInstanceId from Beacon API response
  removedItemInstanceId: number;

  constructor(private configManagerService: AppConfigManagerService, private constantsService: AppConstantsService) {}

  /**
   * remove or restore ItemInstanceId from Beacon API response to test
   * if Inspectors still can enter the app without ItemInstanceId(LEI-1173)
   *
   * @param isItemInstanceIdRemoved
   * @param unreadMessages
   */
  removeOrRestoreBeaconItemInstanceId(
    isItemInstanceIdRemoved: boolean,
    unreadMessages: BroadcastMessage[]
  ): BroadcastMessage[] {
    if (unreadMessages?.length > 0) {
      if (isItemInstanceIdRemoved) {
        // remove Instance Id
        this.removedItemInstanceId = unreadMessages[DevTestingService.indexNumberOfRemovedItemInstanceId].itemInstId;
        unreadMessages[DevTestingService.indexNumberOfRemovedItemInstanceId].itemInstId = undefined; // no need to remove it from all the messages
      } else {
        // restore Instance Id
        unreadMessages[DevTestingService.indexNumberOfRemovedItemInstanceId].itemInstId = this.removedItemInstanceId;
      }
    }
    return unreadMessages;
  }
}
