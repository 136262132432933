import { Injectable } from '@angular/core';
import {
  BeaconApiService,
  UpdateItemStatusResp,
  UpdateItemStatusRqst,
  UpdateUnattachedBeaconPath,
  UpdateUnattachedBeaconResp,
  UpdateUnattachedBeaconRqst
} from '@xpo-ltl/sdk-beacon';
import {
  CreateBroadcastMessageResp,
  ListBroadcastMessagesQuery,
  ListBroadcastMessagesResp,
  ListUserBroadcastMessagesPath,
  ListUserBroadcastMessagesResp
} from '@xpo-ltl/sdk-inspections';
import { EMPTY, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BroadcastMessage } from '../../classes/broadcast-messages/broadcast-message';
import { ErrorMessageActions } from '../../enums/error-message-actions.enum';
import { ErrorHandlingService } from '../error-handling.service';
import { InspectionsApiWrapperService } from '../inspections/inspections.service';

@Injectable({
  providedIn: 'root'
})
export class BroadcastMessagesService {
  private _isMissingItemInstanceId = false;

  private readonly SOURCE_NAME: string = 'Broadcast Messages';

  constructor(
    private beaconApiService: BeaconApiService,
    private inspectionsService: InspectionsApiWrapperService,
    private errorHandling: ErrorHandlingService
  ) {}

  set isMissingItemInstanceId(isMissing: boolean) {
    this._isMissingItemInstanceId = isMissing;
  }

  get isMissingItemInstanceId(): boolean {
    return this._isMissingItemInstanceId;
  }

  // #region Message Administration

  /**
   * Return all the messages
   */
  getBroadcastMessagesList(): Observable<BroadcastMessage[]> {
    const query: ListBroadcastMessagesQuery = new ListBroadcastMessagesQuery();

    return this.inspectionsService.listBroadcastMessages(query).pipe(
      map((resp: ListBroadcastMessagesResp) => {
        let broadcastMessages: BroadcastMessage[] = [];
        if (resp?.broadcastMessages?.length > 0) {
          broadcastMessages = resp.broadcastMessages.map((broadcastMessage) => {
            return new BroadcastMessage(broadcastMessage);
          });
        }
        return broadcastMessages;
      }),
      catchError((error) => {
        this.errorHandling.handleResponseError(error, ErrorMessageActions.GETTING, this.SOURCE_NAME);

        throw new Error(error); // throw error so component can handle error accordingly
      })
    );
  }

  /**
   * Create a new message
   * @param message
   */
  createBroadcastMessage(message: BroadcastMessage): Observable<BroadcastMessage> {
    return this.inspectionsService.createBroadcastMessage(message.getCreateMessage()).pipe(
      map((response: CreateBroadcastMessageResp) => {
        if (response?.broadcastMessage) {
          message.messageId = response.broadcastMessage.messageId;
          return message;
        } else {
          throw new Error('Response with no broadcastMessage');
        }
      }),
      catchError((error) => {
        this.errorHandling.handleResponseError(error, ErrorMessageActions.CREATING, this.SOURCE_NAME);

        return EMPTY;
      })
    );
  }

  /**
   * Update an existing message
   * @param message
   */
  updateBroadcastMessage(message: BroadcastMessage): Observable<BroadcastMessage> {
    const beacon = message.getUpdateBeacon();
    const request = new UpdateUnattachedBeaconRqst();
    request.beacon = beacon;
    const pathParams = new UpdateUnattachedBeaconPath();
    pathParams.beaconTypeCd = beacon.typeCd;
    pathParams.messageId = beacon.messageId;

    return this.beaconApiService.updateUnattachedBeacon(request, pathParams).pipe(
      map((response: UpdateUnattachedBeaconResp) => {
        if (response?.messageId) {
          return message;
        } else {
          throw new Error('Response with no messageId');
        }
      }),
      catchError((error) => {
        this.errorHandling.handleResponseError(error, ErrorMessageActions.UPDATING, this.SOURCE_NAME);

        return EMPTY;
      })
    );
  }

  /**
   * Send the message to all the user inspectors
   * @param broadcastMessage
   */
  sendBroadcastMessage(broadcastMessage: BroadcastMessage): Observable<boolean> {
    return this.inspectionsService.createBroadcastMessage(broadcastMessage.getSendMessage()).pipe(
      map((response: CreateBroadcastMessageResp) => {
        if (response?.broadcastMessage) {
          return true;
        }
        return false;
      }),
      catchError((error) => {
        this.errorHandling.handleResponseError(error, ErrorMessageActions.SENDING, this.SOURCE_NAME);

        return EMPTY;
      })
    );
  }

  /**
   * Cancel a message
   * @param message
   */
  cancelMessage(message: BroadcastMessage): Observable<boolean> {
    const beacon = message.getCancelBeacon();
    const request = new UpdateUnattachedBeaconRqst();
    request.beacon = beacon;
    const pathParams = new UpdateUnattachedBeaconPath();
    pathParams.beaconTypeCd = beacon.typeCd;
    pathParams.messageId = message.messageId;

    return this.beaconApiService.updateUnattachedBeacon(request, pathParams).pipe(
      map((response: UpdateUnattachedBeaconResp) => {
        if (response?.messageId) {
          return true;
        }
        return false;
      }),
      catchError((error) => {
        this.errorHandling.handleResponseError(error, ErrorMessageActions.CANCELING, this.SOURCE_NAME);

        return EMPTY;
      })
    );
  }

  /**
   * Return all unread message for a user
   * employeeId is the queueName
   * @param employeeId
   */
  getUnreadMessages(employeeId: string): Observable<BroadcastMessage[]> {
    const path: ListUserBroadcastMessagesPath = new ListUserBroadcastMessagesPath();
    path.employeeId = employeeId;

    return this.inspectionsService.listUserBroadcastMessages(path).pipe(
      map((resp: ListUserBroadcastMessagesResp) => {
        let broadcastMessages: BroadcastMessage[] = [];
        if (resp?.broadcastMessages?.length > 0) {
          broadcastMessages = resp.broadcastMessages.map((broadcastMessage) => {
            if (!broadcastMessage.messageId || !broadcastMessage.externalId) {
              const missingPropertyName = !!broadcastMessage.messageId ? 'External ID' : 'Message ID';
              const errorMessage = `${missingPropertyName} is empty`;
              this.errorHandling.handleResponseError(
                errorMessage,
                ErrorMessageActions.GETTING,
                `unread ${this.SOURCE_NAME}`
              );
            }
            return new BroadcastMessage(broadcastMessage);
          });
        }
        return broadcastMessages;
      }),
      catchError((error) => {
        this.errorHandling.handleResponseError(error, ErrorMessageActions.GETTING, `unread ${this.SOURCE_NAME}`);

        return EMPTY;
      })
    );
  }
  // #endregion

  // #region Message Response

  /**
   * Respond a particular message
   * @param messageResponse
   */
  responseMessage(messageResponse: BroadcastMessage): Observable<BroadcastMessage> {
    const request = new UpdateItemStatusRqst();
    request.itemInstId = messageResponse.itemInstId;
    request.itemStatus = messageResponse.response;
    request.context = messageResponse.getContextBeacon();

    return this.beaconApiService.updateItemStatus(request).pipe(
      map((response: UpdateItemStatusResp) => {
        if (response?.item) {
          return messageResponse;
        } else {
          throw new Error('Response with no status changed item');
        }
      }),
      catchError((error) => {
        this.errorHandling.handleResponseError(error, ErrorMessageActions.RESPONDING, this.SOURCE_NAME);

        return EMPTY;
      })
    );
  }

  // #endregion
}
