import { Component, OnDestroy, OnInit, ViewChild, Input } from '@angular/core';
import { RecommendedRulesService } from '../services/recommended-rules.service';
import { AcctId } from '@xpo-ltl/sdk-common';
import { PartyRole } from '../enums/party_role.enum';
import { ResponseValidation } from '../../../classes/ResponseValidation';
import { AccountService } from '../../../services/account.service';
import { Subscription, Subject } from 'rxjs';
@Component({
  selector: 'app-recommended-rules-form',
  templateUrl: './recommended-rules-form.component.html',
  styleUrls: ['./recommended-rules-form.component.scss']
})
export class RecommendedRulesFormComponent implements OnInit, OnDestroy {
  partyRole = PartyRole;
  accountValidation: ResponseValidation;

  private subject$ = new Subject();
  private observable = this.subject$.asObservable();
  private subscription = new Subscription();

  @Input() isNewRule: boolean;

  madCode: string;

  constructor(private recommendedRulesService: RecommendedRulesService, private accountService: AccountService) {
    this.madCode = this.recommendedRulesService.getMadCode();
  }

  public ngOnInit() {
    this.accountValidation = new ResponseValidation(true, '');

    // We check if there are filters to be disabled
    this.recommendedRulesService.disableFilters();

    this.subscription = this.observable.pipe().subscribe(() => {
      this.searchMadCode();
    });
  }

  public getAccountName(): string {
    return this.recommendedRulesService.getAccountName();
  }

  /**
   *
   * @param madCode
   */
  public setMadCode(madCode: string): void {
    this.recommendedRulesService.setMadCode(madCode);
  }

  public getMadCode(): string {
    return this.recommendedRulesService.getMadCode();
  }

  public getRecommendation(): string {
    return this.recommendedRulesService.getRecommendation();
  }

  /**
   *
   * @param recommended
   */
  public setRecommendation(recommended: string): void {
    this.recommendedRulesService.setRecommendation(recommended);
  }

  /**
   *
   * @param partyRole
   */
  public setPartyRole(partyRole: string): void {
    this.recommendedRulesService.setParty(partyRole);
  }

  public getPartyRole(): string {
    return this.recommendedRulesService.getParty();
  }

  public resetAccount(): void {
    // We get the current mad code
    const madCode = this.recommendedRulesService.getMadCode();

    // We empty the account
    this.recommendedRulesService.setAccount(new AcctId());

    // Finally we set the mad code
    this.recommendedRulesService.setMadCode(madCode);
  }

  public madCodeChanged() {
    this.subject$.next();
  }

  public validateInputMadCode() {
    if (!this.madCode) {
      this.accountValidation.setMessage('The MAD Code cannot be empty');
    } else if (this.madCode.length !== 11) {
      this.accountValidation.setMessage('MAD Code must be 11 characters');
    }
  }

  // TODO: rethink this...
  public searchMadCode(): void {
    this.setMadCode(AccountService.formatMadCodeAccount(this.madCode.trim()));
    this.accountValidation = this.accountService.getAccount(this.madCode.trim());

    if (this.accountValidation.getIsValid()) {
      const response = this.accountValidation.getResponse();
      response.subscribe(
        acct => {
          this.recommendedRulesService.setAccount(acct);
          this.validateAccount();
        },
        () => {
          this.accountValidation.setIsValid(false);
          this.accountValidation.setMessage('Account not found');
          this.accountValidation.setResponse(undefined);
          this.resetAccount();
        }
      );
    } else {
      this.resetAccount();
    }

    // Every time we changed the account we validate the filters
    this.recommendedRulesService.triggerFilterValidations();
  }

  public validateAccount(): void {
    if (this.recommendedRulesService.getAccount().acctPartyRole && this.getPartyRole()) {
      this.accountValidation = this.recommendedRulesService.validateAccountPartyRole();
    }
  }

  /**
   *
   * @param partyRole
   */
  public validatePartyRole(partyRole: PartyRole) {
    this.setPartyRole(partyRole);
    this.recommendedRulesService.disableFilters();
    this.validateAccount();

    // Every time we changed the party role we validate the filters
    this.recommendedRulesService.triggerFilterValidations();
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
