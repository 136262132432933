<xpo-contextual-header>
  <xpo-contextual-header-item>
    <mat-form-field>
      <input
        #searchInput
        matInput
        autocomplete="off"
        placeholder="Scan or enter a PRO, Door, or Trailer #"
        [formControl]="lookupInput"
        (keyup.enter)="onLookup()"
        type="text"
        pattern="[a-zA-Z0-9\-]+"
      />
      <mat-icon matSuffix color="primary" (click)="onLookup()">search</mat-icon>
    </mat-form-field>
  </xpo-contextual-header-item>

  <div class="text-display-items">
    <xpo-contextual-header-item *ngIf="!appConfigManagerService.isProduction()" class="dev-only">
      <button class="dev-only" mat-button (click)="onDevOnlyClicked()">Dev Only</button>
    </xpo-contextual-header-item>
    <xpo-contextual-header-item (click)="onSicClicked()" class="text-display-items_SIC">{{
      sic$ | async
    }}</xpo-contextual-header-item>
    <xpo-contextual-header-item (click)="onShiftClicked()" class="text-display-items_Shift">{{
      shift$ | async
    }}</xpo-contextual-header-item>
    <xpo-contextual-header-item>
      Recommendations Last Refreshed: {{ appConstants.lastRefreshRecommendationsDate$ | async }}
    </xpo-contextual-header-item>
  </div>

  <button xpo-action-button [matMenuTriggerFor]="moreIconMenu">
    <mat-icon>more_horiz</mat-icon>
  </button>

  <mat-menu #moreIconMenu>
    <div class="overlay-more-menu">
      <div class="overlay-more-menu_button">
        <button mat-button (click)="onRefreshRecommendationsClicked()">Refresh Recommendations</button>
        <button mat-button (click)="onAddProsClicked()">Add PROs</button>
        <!-- LEI-376: disabled until it can be updated to match the revised rule -->
        <!-- <div class="app-header-sidenav-addpros">
        <button mat-button (click)="openCalculatorsMenu()">Tools</button>
        </div> -->
        <button mat-button (click)="onChangeSicShiftClicked()">Change SIC / Shift</button>
        <div *ngIf="canSetAppSettings()">
          <button mat-button (click)="onSettingsClicked()">Settings</button>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="overlay-more-menu_stored-items">
        <div>Stored photos: {{ photoCount }} ({{ hardwareService.pendingPhotoCount$ | async }} queued)</div>
        <div>Used storage: {{ spaceUsedMB }} MB of 50 MB</div>
      </div>
    </div>
  </mat-menu>
</xpo-contextual-header>
