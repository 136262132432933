import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { FormatValidationService } from '@xpo-ltl/common-services';
import { InspectionContext } from '@xpo-ltl/sdk-inspections';
import { Observable, Subject, of } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { ProNumber } from '../../classes/pronumber';
import { SuccessErrorData } from '../../classes/success-error-data';
import { AddProComponent } from '../../dialogs/add-pro/add-pro.component';
import { ChangeLocationComponent } from '../../dialogs/change-location/change-location.component';
import { DevOnlyComponent } from '../../dialogs/dev-only/dev-only.component';
import { SettingsComponent } from '../../dialogs/settings/settings.component';
import { ListName } from '../../enums/list-name.enum';
import { ListType } from '../../enums/list-type.enum';
import { ShiftCode, toShiftCd } from '../../enums/shift-code.enum';
import { AppConfigManagerService } from '../../services/app-config-manager.service';
import { AppConstantsService } from '../../services/app-constants.service';
import { AppNavigationService } from '../../services/app-navigation.service';
import { AppStorageService, IDBSpace } from '../../services/app-storage.service';
import { ErrorHandlingService } from '../../services/error-handling.service';
import { GridSettingsService } from '../../services/grid-settings/grid-settings.service';
import { HardwareService } from '../../services/hardware/hardware-service';
import { ShipmentDetailsService } from '../../services/shipment-details.service';
import { XpoDialogManagerService } from '../../services/xpo-dialog-manager.service';

@Component({
  selector: 'app-ins-context-header',
  templateUrl: './ins-context-header.component.html',
  styleUrls: ['./ins-context-header.component.scss']
})
export class InsContextHeaderComponent implements OnInit, OnDestroy {
  @ViewChild('searchInput', { static: true })
  searchInput: ElementRef;

  sic$: Observable<string>;
  shift$: Observable<string>;
  lookupInput: UntypedFormControl;
  spaceUsedMB: number;
  photoCount: number;
  scanned: string = '';

  private unsubscriber$: Subject<any> = new Subject();

  constructor(
    public appConstants: AppConstantsService,
    private dialogManager: XpoDialogManagerService,
    private errorHandling: ErrorHandlingService,
    private gridSettings: GridSettingsService,
    private validationService: FormatValidationService,
    private appNavigation: AppNavigationService,
    private shipmentDetailService: ShipmentDetailsService,
    public hardwareService: HardwareService,
    private storageService: AppStorageService,
    private dialog: MatDialog,
    private elementRef: ElementRef,
    public appConfigManagerService: AppConfigManagerService
  ) {
    this.lookupInput = new UntypedFormControl();
    this.sic$ = this.appConstants.inspectionContext$.pipe(map((context: InspectionContext) => context?.inspectionSic));
    this.shift$ = this.appConstants.inspectionContext$.pipe(
      map((context: InspectionContext) => toShiftCd(ShiftCode[context?.shiftCd]))
    );
  }

  private static parseAddPro(proString: string): string[] {
    if (!proString) {
      return undefined;
    }
    const spaceRegex = / /g;
    const proStringNoSpace = proString.replace(spaceRegex, '');
    const delimiterRegex = /[;\n|]/gi;
    const parsedProString = proStringNoSpace.replace(delimiterRegex, ',');
    return parsedProString.split(',').filter((pro) => pro.length > 0);
  }

  private static validateAddPro(pros: string[]): SuccessErrorData {
    const goodPros = [];
    const badPros = [];
    const successError = new SuccessErrorData();
    if (pros) {
      pros.forEach((pro) => {
        if (ProNumber.isValid(pro)) {
          goodPros.push(pro);
          successError.success = goodPros;
        } else {
          badPros.push(pro);
          successError.error = badPros;
        }
      });
    }
    return successError;
  }

  ngOnInit(): void {
    this.storageService
      .getAvailableSpace()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((space: IDBSpace) => {
        this.spaceUsedMB = parseFloat((space.usedKB / 1000).toFixed(2));
      });

    this.storageService
      .getStoredPhotosCount()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((photoCount) => {
        this.photoCount = photoCount;
      });

    // This is from the previous header. Saving this just in case.
    // We check if the current route is the inspection page in order
    // to add the pro as a parameter for the tools menu modal
    // this.router.events.subscribe((event) => {
    //   if (event instanceof NavigationEnd) {
    //     if (event.url && event.url.includes(RouterUriComponents.INSPECT_SHIPMENT_PAGE)) {
    //       this.toolsMenuPro = event.url.split('/').pop();
    //     } else {
    //       this.toolsMenuPro = undefined;
    //     }
    //   }
    // });
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

  @HostListener('document:click', ['$event'])
  public onDocumentClick(event: any) {
    if (this.elementRef?.nativeElement?.contains(event.target)) {
      return;
    }
  }

  @HostListener('keypress', ['$event'])
  public itemScanned(event) {
    const input = event.key;
    if (this.searchInput.nativeElement !== document.activeElement) {
      if (this.elementRef.nativeElement.contains(event.target)) {
        if (input === 'Enter') {
          event.returnValue = '';
          this.onLookup();
        }
        if (input.length === 1) {
          this.scanned += input;
          this.lookupInput.setValue(this.scanned);
        }
      }
    } else {
      this.scanned = this.lookupInput.value;
    }
  }

  onLookup(): void {
    let lookupValue = this.lookupInput.value;
    if (lookupValue) {
      // Trim (primarily for trailer/door lookup, ProNumber already trims)
      lookupValue = lookupValue.trim();
      if (ProNumber.isValid(lookupValue)) {
        this.appConstants.clearChangedProNumbers();
        this.appNavigation.navigateToShipmentDetails(new ProNumber(lookupValue));
      }
      // Lookup for trailer/door
      else if (
        this.validationService.isValidDoorNumber(lookupValue) ||
        this.validationService.isValidTrailerNumber(lookupValue)
      ) {
        this.gridSettings.clearFilterGridSettings(ListName.Lookup);
        this.appConstants.clearChangedProNumbers();
        this.appNavigation.navigateToList(ListType.Lookup, lookupValue);
      } else {
        this.errorHandling.showErrorMessage(`Please enter a valid PRO, Door, or Trailer #`);
      }
    }
    this.lookupInput.reset();
    // need to set timeout to wait a tick
    setTimeout(() => this.searchInput.nativeElement.blur());
  }

  onRefreshRecommendationsClicked(): void {
    this.shipmentDetailService
      .refreshRecommendations()
      .pipe(take(1))
      .subscribe(() => {
        this.appNavigation.navigateToList(ListType.Shipment, null);
      });
  }

  onAddProsClicked() {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.autoFocus = true;
    matDialogConfig.disableClose = true;
    matDialogConfig.minWidth = '28em';
    matDialogConfig.minHeight = '32em';
    matDialogConfig.width = '40em';
    matDialogConfig.height = '36em';

    const matDialogRef = this.dialog.open(AddProComponent, matDialogConfig);
    matDialogRef
      .afterClosed()
      .pipe(
        map((proString: string) => InsContextHeaderComponent.parseAddPro(proString)),
        map((parsedPro: string[]) => InsContextHeaderComponent.validateAddPro(parsedPro))
      )
      .subscribe((data: SuccessErrorData) => {
        if (data?.success?.length > 0) {
          this.gridSettings.clearFilterGridSettings(ListName.AddPros);
          this.appConstants.clearChangedProNumbers();
          this.appConstants.proNbrInputs = data.success;
          this.appNavigation.navigateToList(ListType.AddPros);
        }
        if (data?.error?.length > 0) {
          const joinPro = data.error.join(' | ');
          this.errorHandling.showErrorMessage(`Invalid PROs: ${joinPro}`);
        }
      });
  }

  /**
   * this will be called when user select Change SIC/Shift from the right top corner menu
   */
  onChangeSicShiftClicked(): void {
    this.openChangeLocationDialog();
  }

  /**
   * this will be called when user double click SIC on the header
   */
  onSicClicked() {
    this.openChangeLocationDialog(ChangeLocationComponent.SIC);
  }

  /**
   * this will be called when user double click Shift on the header
   */
  onShiftClicked() {
    this.openChangeLocationDialog(ChangeLocationComponent.SHIFT);
  }

  // ------- using only in test environment
  onDevOnlyClicked(): void {
    this.dialogManager.alert(
      {
        titleText: 'Dev Only Tools',
        contentComponent: DevOnlyComponent,
        injectedData: of({ isSuccess: false }),
        hideDismissButton: true
      },
      {
        disableClose: false,
        width: '600px'
      }
    );
  }

  // This will check if the device can change any value or
  // save settings. We only have one check at the moment
  canSetAppSettings(): boolean {
    return this.hardwareService.canSetZoomLevel();
  }

  onSettingsClicked() {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.autoFocus = true;
    matDialogConfig.disableClose = false;
    matDialogConfig.minWidth = '40%';
    matDialogConfig.minHeight = '40%';
    matDialogConfig.width = '50%';

    this.dialog.open(SettingsComponent, matDialogConfig);
  }

  private openChangeLocationDialog(clickedFrom?: string): void {
    this.dialogManager.alert(
      {
        titleText: 'Change Home SIC and Shift',
        contentComponent: ChangeLocationComponent,
        injectedData: of(clickedFrom),
        hideDismissButton: true
      },
      {
        disableClose: true,
        width: '40em',
        autoFocus: false
      }
    );
  }

  // LEI-376: disabled until it can be updated to match the revised rule
  // public openCalculatorsMenu() {
  //   const matDialogConfig = new MatDialogConfig();
  //   matDialogConfig.autoFocus = true;
  //   matDialogConfig.disableClose = false;
  //   matDialogConfig.minWidth = '60%';
  //   matDialogConfig.minHeight = '60%';
  //   matDialogConfig.width = '80%';
  //   matDialogConfig.height = '80%';
  //   matDialogConfig.data = {
  //     pro: this.toolsMenuPro
  //   };

  //   this.dialog.open(ToolsMenuComponent, matDialogConfig);
  // }
}
