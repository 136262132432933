import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { BroadcastMessage } from '../classes/broadcast-messages/broadcast-message';
import { BroadcastMessagesService } from '../services/broadcast-messages/broadcast-messages.service';
import { InspectionsDialogsService } from '../services/inspections-dialogs.service';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class UnreadMessagesGuard implements CanActivate {
  /**
   * Save if user already saw the messages.
   * Is in memory, because if the user refresh the page should see again the unread messages.
   */
  private messagesAlreadyRead: boolean;

  constructor(
    private broadcastMessagesService: BroadcastMessagesService,
    private inspectionsDialogsService: InspectionsDialogsService,
    private userService: UserService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (this.messagesAlreadyRead) {
      return true;
    }

    const currentUserEmployeeId = this.userService.currentUserEmployeeId;

    return this.userService.isUserLoggedIn$.pipe(
      switchMap(() => {
        return this.broadcastMessagesService.getUnreadMessages(currentUserEmployeeId).pipe(
          switchMap((unreadMessages: BroadcastMessage[]) => {
            // we only display unread message dialog when
            //   - there are unread messages AND
            //   - item instance IDs are not missing.
            if (unreadMessages?.length > 0 && !this.broadcastMessagesService.isMissingItemInstanceId) {
              return this.showUnreadMessages(unreadMessages, currentUserEmployeeId).pipe(
                tap((messagesRead) => this.messagesAlreadyRead === messagesRead)
              );
            } else {
              return of(true);
            }
          })
        );
      })
    );
  }

  private showUnreadMessages(unreadMessages: BroadcastMessage[], employeeId: string): Observable<boolean> {
    return this.inspectionsDialogsService
      .openUnreadBroadcastMessagesDialog(unreadMessages, employeeId)
      .pipe(map((result) => !!result));
  }
}
