import { Injectable } from '@angular/core';
import { HardwareService } from './hardware-service';
import { GetacContainer } from './getac-container';
import { ProNumber } from '../../classes/pronumber';
import { InspectionState } from '../../enums/inspection-state.enum';
import { Observable, of } from 'rxjs';
import { Photo } from '../../classes/photo';
import { PhotoId } from '../../classes/photo-id';
import { take } from 'rxjs/internal/operators';
import { PhotoGalleryService } from '../../components/photo-gallery/photo-gallery.service';
import { DocTypes } from '../../enums/doc-type.enum';
import { DmsOpCode } from '../../enums/dms-op-code.enum';

@Injectable()
export class GetacService extends HardwareService {
  private container: GetacContainer;

  constructor(private photoGalleryService: PhotoGalleryService) {
    super();
    // It's assumed we have a container if we get here
    this.container = window['container'];

    this.container.registerPhotoTakenListener((json) => {
      if (json) {
        const photoObject = JSON.parse(json);
        if (photoObject) {
          this.photosUpdated();
        }
      }
    });
    this.container.registerPhotoGalleryUpdatedListener(() => {
      this.photosUpdated();
    });
  }

  isGetac(): boolean {
    return true;
  }

  public showPendingPhotosDialogBox(): boolean {
    return false;
  }

  exitApplication() {
    this.container.closeApplication();
  }

  addPhoto(proNumber: ProNumber, photoBytes: string, filename: string = null) {
    // Strip off the header if exists
    this.container.insertPhoto(proNumber.formatProNumber(), Photo.getStrippedHeader(photoBytes)).then((value) => {
      // registerPhotoGalleryUpdatedListener doesn't get called when inserting photo's so upload the archived-photos
      this.photosUpdated();
    });
  }

  launchCamera(proNumber: ProNumber, inspectionStatus: InspectionState) {
    this.container.launchCamera(proNumber.formatProNumber(), <string>inspectionStatus);
  }

  listThumbnails(proNumber: ProNumber): Observable<Photo[]> {
    return new Observable<Photo[]>((observer) => {
      this.container.listThumbs(proNumber.formatProNumber(), (json) => {
        const photos = this.buildPhotosFromJson(json);
        const thumbnailPhotos = new Array<Photo>();
        if (photos) {
          console.log(photos);
          photos.forEach((photo) => {
            if (photo.dmsOpCode === DmsOpCode.NEWLY_ADDED_PHOTO) {
              // We only want to show new Photos on Inspection Screen
              console.log('Setting thumbnail Photo');
              console.log(photo);
              thumbnailPhotos.push(photo);
            }
          });
        }
        observer.next(thumbnailPhotos);
        observer.complete();
      });
    });
  }

  getImage(photoId: PhotoId): Observable<Photo> {
    return new Observable<Photo>((observer) => {
      this.container.getPhoto(photoId.id, false, (json) => {
        const photo = this.buildPhotoFromJson(json);
        observer.next(photo);
        observer.complete();
      });
    });
  }

  listPhotoIds(proNumber: ProNumber): Observable<PhotoId[]> {
    return new Observable<PhotoId[]>((observer) => {
      this.container.listPhotos(proNumber.formatProNumber(), false, (json) => {
        const photos = this.buildPhotosFromJson(json);
        const photoIds = new Array<PhotoId>();
        photos.forEach((photo) => {
          if (photo.dmsOpCode === DmsOpCode.NEWLY_ADDED_PHOTO) {
            // We only want to submit New photos to queue up for DMS.
            photoIds.push(photo.id);
          }
        });
        observer.next(photoIds);
        observer.complete();
      });
    });
  }

  deletePhoto(photoId: PhotoId): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      this.container.deletePhoto(photoId.id);
      observer.next(true);
      observer.complete();
    });
  }

  submitInspectionPhotos(proNumber: ProNumber) {
    this.listPhotoIds(proNumber)
      .pipe(take(1))
      .subscribe((photoIds) => {
        if (photoIds) {
          this.setPendingPhotosCount(photoIds.length);
          photoIds.forEach((photoId) => {
            this.container.updateDmsOpCode(photoId.id, DmsOpCode.SUBMITTED_AND_PENDING_PHOTO);
          });
        }
      });
  }

  public updatePendingPhotoCount() {
    this.container.listPhotosNotSentToDms((photosJson) => {
      const photos = this.buildPhotosFromJson(photosJson);
      let photosNotSentCount = 0;
      if (photos) {
        for (let idx = 0; idx < photos.length; idx++) {
          const photo = photos[idx];
          if (photo.dmsOpCode === DmsOpCode.SUBMITTED_AND_PENDING_PHOTO) {
            photosNotSentCount++;
          }
        }
      }
      this.setPendingPhotosCount(photosNotSentCount);
    });
  }

  public getPendingPhotoIdsToUpload(): Observable<PhotoId[]> {
    return new Observable<PhotoId[]>((observer) => {
      this.container.listPendingPhotosMetaData((photosJson) => {
        const photos = this.buildPhotosFromJson(photosJson);
        const photoIdsToUpload = <PhotoId[]>[];
        if (photos) {
          photos.forEach((photo) => {
            photoIdsToUpload.push(photo.id);
          });
        }
        observer.next(photoIdsToUpload);
        observer.complete();
      });
    });
  }

  private buildPhotosFromJson(json: any): Photo[] {
    const photos = new Array<Photo>();
    const photosArray = <any[]>JSON.parse(json);
    photosArray.forEach((photoObject) => {
      const photo = this.buildPhoto(photoObject);
      photos.push(photo);
    });
    return photos;
  }

  private buildPhotoFromJson(json: any): Photo {
    const photoObject = <any>JSON.parse(json);
    return this.buildPhoto(photoObject);
  }

  private buildPhoto(photoObject: any): Photo {
    const proNumber = new ProNumber(photoObject['ProNumber']);
    const photoGuid = photoObject['Id'];
    const photoId = new PhotoId(proNumber, DocTypes.INSPECTION_PHOTO, photoGuid);
    const newPhoto = new Photo(photoId);
    newPhoto.setBase64dataAndContentType(photoObject['PhotoBytes'], Photo.CONTENT_TYPE_PNG); // All archived-photos from Getac are expected as PNG
    newPhoto.setThumbnailBytesAndContentType(photoObject['ThumbnailBytes'], Photo.CONTENT_TYPE_PNG); // All archived-photos from Getac are expected as PNG
    newPhoto.dmsOpCode = photoObject['DmsOpCode'];
    newPhoto.fileName = photoObject['FileName']; // todo: does this exist?
    return newPhoto;
  }

  public showPhotoGallery(proNumber: ProNumber, photoId: PhotoId): Observable<boolean> {
    this.container.showPhotoGallery(proNumber.formatProNumber(), photoId.id);
    return of(true);
  }

  // not implemented in the Getac Version
  // This is specifically for the web version to remove all photos before an inspection
  public removePendingPhotos() {}

  // This is to minimize the app. Getac Version only
  public showDesktop() {
    this.container.showDesktop();
  }

  public canSetZoomLevel(): boolean {
    return typeof this.container.setZoomLevel === 'function';
  }

  public setZoomLevel(zoomLevel: number) {
    this.container.setZoomLevel(zoomLevel);
  }

  public getPhotoCountByPro(proNumber: ProNumber, photoStatus: string) {
    console.log('Action not supported');
  }

  public getStorageSpace() {
    console.log('Action not supported');
  }
}
