export enum ZoomLevel {
  small = 'small',
  medium = 'medium',
  large = 'large'
}

export const ZoomLevelValues = new Map<ZoomLevel, number>([
  [ZoomLevel.small, 0],
  [ZoomLevel.medium, 1.25],
  [ZoomLevel.large, 2.25]
]);
