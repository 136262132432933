import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { XpoDialogManagerService } from '../../services/xpo-dialog-manager.service';

@Component({
  selector: 'app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppFooterComponent implements OnInit {
  constructor(private dialog: MatDialog, private dialogManager: XpoDialogManagerService) {}

  ngOnInit() {}

  cancelClicked() {
    /* Method 1 for displaying a dialog
    const data = new ConfirmCancelData('Header', 'Body', 'Cancel', 'Confirm');
    const dialogRef = this.dialog.open(ConfirmCancelComponent, {
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog closed: ${result}`);
    });
    */

    /* Method 2 for displaying a dialog
    this.dialogManager.alert({
      titleText: 'Simple Dialog',
      contentComponent: AlertComponent,
      dismissalText: 'Done',
      injectedData: of({ isSuccess: false })
    });
    */

    /* Method 3 for displaying a dialog */
    this.dialogManager.alert({
      titleText: 'Something Went Wrong',
      contentText: `We're sorry. Something went wrong while calculating your rate quote. Please try again, or contact the Help Desk at 800-755-2728.`
    });
  }
}
