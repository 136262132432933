import { Injectable } from '@angular/core';
import { ColDef, GridOptions, ICellRendererParams, RowNode, ValueGetterParams } from 'ag-grid-community';
import { take } from 'rxjs/operators';
import { BroadcastMessage } from '../../classes/broadcast-messages/broadcast-message';
import { ComparatorUtils } from '../../classes/comparator-utils';
import { Indicator } from '../../classes/indicator';
import { PickupRequestItem } from '../../classes/pickup-request-item';
import { CustomerGuidelinesCellRendererComponent } from '../../components/customer-guidelines/customer-guidelines-cell-renderer/customer-guidelines-cell-renderer.component';
import {
  CustomManifestSearchParam,
  ManifestSearchColumnComponent
} from '../../components/list-shipments/manifest-search-column/manifest-search-column.component';
import { NmfcColumnComponent } from '../../components/list-shipments/nmfc-column/nmfc-column.component';
import { PickupRequestMarkCellRendererComponent } from '../../components/pickup-requests/cell-renderers/pickup-request-mark-cell-renderer/pickup-request-mark-cell-renderer.component';
import { toPartyRoleDisplayName } from '../../components/recommendation-rules/enums/party_role.enum';
import { IndicatorRendererComponent } from '../../components/trailer-detail/indicator-renderer/indicator-renderer.component';
import { ColumnType } from '../../enums/column-type.enum';
import { IndicatorName } from '../../enums/indicator-name.enum';
import { InspectionState, toInspectionStateDisplayName } from '../../enums/inspection-state.enum';
import { ListName } from '../../enums/list-name.enum';
import { RouterUriComponents } from '../../enums/router-uri-components.enum';
import { AppConstantsService } from '../app-constants.service';
import { UserPreferencesService } from '../user-preferences.service';
import { GridSettingsData } from './grid-settings-data';

/*
  There are two main reasons for this Service:

    1. Saving/Loading/Resetting Grid User Preferences
      a. Column Order
      b. Column Width
      c. Column Pinning
      d. Column Visibility

    2. Storing/Restoring Grid State for the session (not resetting during navigation)
      a. Column Order
      b. Column Width
      c. Column Pinning
      d. Column Visibility
      e. Sorting
      f. Filters

  ag-grid is a little painful when it comes to saving and restoring the grid state.
  The basic idea is that anytime a column changes, we want to save the entire grid state
  by calling storeGridState, which will persist the change to a GridSettingsData object (one per ListName)

  Anytime the data (onRowDataChanged) is updated, we will re-apply/restore the Grid Settings by
  calling the restoreGridState.  We have to call it after the onRowDataChanged in order for the Filters
  to be applied properly.

  Saving and loading User preferences can now just operate on the Grid Settings Data (omitting
  the Column Definition, sorting and filtering). We will have to call the restoreGridState after loading as the load will
  only update the GridSettingsData.

  Observables didn't seem to work very well for this service.
 */

@Injectable()
export class GridSettingsService {
  private gridSettingsMap = new Map<ListName, GridSettingsData>();

  // Map to check to see if the list has been loaded or not... Only load once.
  private listLoadedMap = new Map<ListName, boolean>();

  constructor(private constants: AppConstantsService, private userPreferencesService: UserPreferencesService) {
    this.resetGridSettings(ListName.Recommended);
    this.resetGridSettings(ListName.Flagged);
    this.resetGridSettings(ListName.Inspected);
    this.resetGridSettings(ListName.Completed);
    this.resetGridSettings(ListName.Dismissed);
    this.resetGridSettings(ListName.Lookup);
    this.resetGridSettings(ListName.AddPros);
    this.resetGridSettings(ListName.RecommendationRules);
    this.resetGridSettings(ListName.CustomerGuidelines);
    this.resetGridSettings(ListName.BroadcastMessages);
    this.resetGridSettings(ListName.PickupRequests);
  }

  public static buildGridIcons(): any {
    return {
      checkboxIndeterminate: '<i class="material-icons" style="color: rgba(0, 0, 0, 0.54)">indeterminate_check_box</i>',
      checkboxUnchecked: '<i class="material-icons" style="color: rgba(0, 0, 0, 0.54)">check_box_outline_blank</i>',
      checkboxChecked: '<i class="material-icons" style="color: #0087ea">check_box</i>',
      groupExpanded:
        '<i class="material-icons" style="color: rgba(0, 0, 0, 0.54); margin-top: 16px;">keyboard_arrow_up</i>',
      groupContracted:
        '<i class="material-icons" style="color: rgba(0, 0, 0, 0.54); margin-top: 16px;">keyboard_arrow_down</i>'
    };
  }

  public hasFilterGridSettings(listName: ListName) {
    const gridSettingsData = this.gridSettingsMap.get(listName);
    return !this.isEmpty(gridSettingsData.filterModel);
  }

  public hasSortGridSettings(listName: ListName) {
    const gridSettingsData = this.gridSettingsMap.get(listName);
    return !this.isEmpty(gridSettingsData.sortModel);
  }

  private isEmpty(obj) {
    if (!obj) {
      return true;
    }
    if (Array.isArray(obj) && obj.length === 0) {
      return true;
    }

    for (const key of Object.keys(obj)) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  public clearFilterGridSettings(listName: ListName, gridOptions: GridOptions = null) {
    const gridSettingsData = this.gridSettingsMap.get(listName);
    gridSettingsData.filterModel = null;
    if (gridOptions && gridOptions.api) {
      gridOptions.api.setFilterModel(gridSettingsData.filterModel);
    }
  }

  public clearSortGridSettings(listName: ListName, gridOptions: GridOptions = null) {
    const gridSettingsData = this.gridSettingsMap.get(listName);
    gridSettingsData.sortModel = null;
    if (gridOptions && gridOptions.api) {
      gridOptions.api.setSortModel(gridSettingsData.sortModel);
    }
  }

  public resetGridSettings(listName: ListName, gridOptions: GridOptions = null) {
    this.gridSettingsMap.set(listName, new GridSettingsData()); // just Clear the Grid Settings Data

    if (gridOptions) {
      // if the gridOptions is passed in, the update the Grid State
      this.restoreGridState(listName, gridOptions);
    }
  }

  public storeGridState(listName: ListName, gridOptions: GridOptions) {
    const gridSettings = this.gridSettingsMap.get(listName);
    gridSettings.columnState = gridOptions.columnApi.getColumnState();
    gridSettings.sortModel = gridOptions.api.getSortModel();
    gridSettings.filterModel = gridOptions.api.getFilterModel();
  }

  // Restore grid state is used to re-apply the existing grid state after a data change
  // for sorts, filters, and columnState.  It will also load the users preferences if it's the
  // first time the lists are loaded for the lifetime of the application
  public restoreGridState(listName: ListName, gridOptions: GridOptions) {
    const gridSettings = this.gridSettingsMap.get(listName);
    if (!this.listLoadedMap.get(listName)) {
      // LoadListGridSettings calls this method, restoreGridState, so make sure we set
      // listLoaded to true so this method doesn't get called again
      this.listLoadedMap.set(listName, true);
      this.loadListGridSettings(listName, gridOptions);
    } else {
      if (gridSettings.columnState) {
        gridOptions.columnApi.setColumnState(gridSettings.columnState);
      } else {
        gridOptions.columnApi.resetColumnState();
      }
      gridOptions.api.setSortModel(gridSettings.sortModel);
      gridOptions.api.setFilterModel(gridSettings.filterModel);
    }
  }

  public saveListGridSettings(listName: ListName) {
    // Have to create a copy first of GridSettingsData, otherwise we'll affect the existing
    // Save/Restore functionality
    // We only have to save the ColumnState
    const gridSettingsData = this.gridSettingsMap.get(listName);
    const saveDataGridSettingsData = new GridSettingsData();
    saveDataGridSettingsData.columnState = gridSettingsData.columnState;

    const jsonString = JSON.stringify(saveDataGridSettingsData);

    this.userPreferencesService.saveListUserPreferences(listName, jsonString);
  }

  public loadListGridSettings(listName: ListName, gridOptions: GridOptions) {
    this.userPreferencesService
      .loadListUserPreferences(listName)
      .pipe(take(1))
      .subscribe((response) => {
        if (response) {
          const gridSettings = this.gridSettingsMap.get(listName);
          // Reset Filters and Sorts on Load
          gridSettings.filterModel = null;
          gridSettings.sortModel = null;
          const saveData: GridSettingsData = JSON.parse(response);
          gridSettings.columnState = saveData.columnState;
          if (gridOptions) {
            this.restoreGridState(listName, gridOptions);
          }
        }
      });
  }

  public buildDefaultRecommendedListColumnDefs(): ColDef[] {
    const columnDefs = this.buildDefaultPlannedShipmentListColumnDefs();
    this.applyCustomTypeFunctions(columnDefs);
    return columnDefs;
  }

  public buildDefaultRecommendedRulesListColumnDefs(): ColDef[] {
    const columnDefs = this.buildDefaultRecommendRulesListColumnDefs();
    return columnDefs;
  }

  public buildDefaultCustomerGuidelinesListColumnDefs(): ColDef[] {
    const columnDefs = this.buildDefaultCustomerGuidelinesColumnDefs();
    return columnDefs;
  }

  public buildDefaultPickUpRequestsListColumnDefs(): ColDef[] {
    const columnDefs = this.buildDefaultPickupRequestsColumnDefs();
    this.applyCustomTypeFunctions(columnDefs);
    return columnDefs;
  }

  public buildDefaultDismissedListColumnDefs(): ColDef[] {
    const columnDefs = this.buildDefaultPlannedShipmentListColumnDefs();
    this.applyCustomTypeFunctions(columnDefs);
    return columnDefs;
  }

  public buildDefaultFlaggedListColumnDefs(): ColDef[] {
    const columnDefs = this.buildDefaultPlannedShipmentListColumnDefs();
    this.applyCustomTypeFunctions(columnDefs);
    return columnDefs;
  }

  public buildDefaultAddProsListColumnDefs(): ColDef[] {
    const columnDefs = this.buildAddProColumnDefs();
    this.applyCustomTypeFunctions(columnDefs);
    return columnDefs;
  }

  public buildDefaultLookupListColumnDefs(): ColDef[] {
    const columnDefs = this.buildLookupColumnDefs();
    this.applyCustomTypeFunctions(columnDefs);
    return columnDefs;
  }

  public buildDefaultBroadcastMessagesListColumnDefs(): ColDef[] {
    const columnDefs = this.buildBroadcastMessagesListColumnDefs();
    return columnDefs;
  }

  private applyCustomTypeFunctions(columnState: ColDef[]) {
    columnState.forEach((column) => {
      // Run Cell Style on all columns for New and Strikethrough.
      if (column.field !== 'guideline') {
        column.cellStyle = this.customCellStyles;
      } else {
        column.cellStyle = this.customCellStylesForCustomerGuidelines;
      }
      if (column?.refData?.columnType) {
        switch (column.refData.columnType) {
          case ColumnType.TIMESTAMP:
            column.valueFormatter = this.dateTimeValueFormatter;
            column.comparator = this.dateTimeComparator;
            break;
          case ColumnType.FAK:
          case ColumnType.NMFC:
          case ColumnType.NMFC_CLASS:
          case ColumnType.COMMODITY_DESCRIPTION:
            column.cellRenderer = this.fullArrayCellRenderer;
            column.comparator = (a: string[], b: string[]) => {
              return ComparatorUtils.arrayOfStringAndNumberCompare(a, b);
            };
            column.filterParams = {
              cellRenderer: this.getValueForFilterCellRenderer,
              comparator: (a: string, b: string) => {
                return ComparatorUtils.stringAndNumberCompare(a, b);
              }
            };
            break;
          case ColumnType.CUSTOMER_INSTRUCTIONS:
            column.cellRenderer = this.arrayCellRenderer;
            break;
          case ColumnType.STATUS:
            column.cellRenderer = this.statusCellRenderer;
            column.filterParams = { cellRenderer: this.getValueForFilterCellRenderer };
            break;
          case ColumnType.PARTY_ROLE:
            column.cellRenderer = this.partyRoleCellRenderer;
            column.filterParams = { cellRenderer: this.partyRoleCellRenderer };
            break;
          case ColumnType.PAYMENT_TYPE:
            column.cellRenderer = this.titleCaseRenderer;
            column.filterParams = { cellRenderer: this.titleCaseRenderer };
            break;
          case ColumnType.NUMBER:
            column.cellRenderer = this.getValueForFilterCellRenderer;
            column.filter = 'agNumberColumnFilter';
            column.filterParams = {
              inRangeInclusive: true
            };
        }
      }
    });
  }

  private dateTimeValueFormatter(data: any): string {
    if (!data.value) {
      return '';
    } else {
      return AppConstantsService.getFormattedDateTime(data.value);
    }
  }

  private dateTimeComparator(valueA: any, valueB: any, nodeA?: RowNode, nodeB?: RowNode, isInverted?: boolean): number {
    let c1 = 0;
    let c2 = 0;

    if (valueA) {
      c1 = valueA;
    }
    if (valueB) {
      c2 = valueB;
    }
    return c1 - c2;
  }

  private arrayCellRenderer(params: ICellRendererParams): string {
    if (!params.value || !Array.isArray(params.value) || params.value.length === 0) {
      return '';
    }

    let cellValue = '';
    params.value.forEach((value) => {
      if (value) {
        cellValue += '<div style="line-height: 23px">' + value + '</div>';
      }
    });
    return cellValue;
  }

  private fullArrayCellRenderer(params: ICellRendererParams): string {
    if (!params.value || !Array.isArray(params.value) || params.value.length === 0) {
      return '';
    }

    let cellValue = '';
    params.value.forEach((value) => {
      if (value) {
        cellValue += '<div style="line-height: 23px">' + value + '</div>';
      } else {
        cellValue += '<div style="height: 23px"></div>';
      }
    });
    return cellValue;
  }

  private partyRoleCellRenderer(params: ICellRendererParams): string {
    if (!params.value) {
      return '';
    }
    return toPartyRoleDisplayName(params.value, true);
  }

  private statusCellRenderer(params: ICellRendererParams): string {
    if (!params.value) {
      return '';
    }
    let cellValue = '';
    if (
      params.value === toInspectionStateDisplayName(InspectionState.IN_PROGRESS) ||
      params.value === toInspectionStateDisplayName(InspectionState.EDITING)
    ) {
      cellValue += '<div><div class="inProgressCellStyle centerCell">' + params.value + '<div></div>';
    } else {
      cellValue += '<div class="centerCell" ><div>' + params.value + '<div></div>';
    }
    return cellValue;
  }

  private getValueForFilterCellRenderer(params: ICellRendererParams): string {
    return params.value;
  }

  private customCellStyles(params: any): any {
    const cellStyle = {};
    if (params.data.removedSinceRefresh) {
      cellStyle['text-decoration'] = 'line-through';
    }

    if (params.data.addedSinceRefresh) {
      cellStyle['font-weight'] = 'bold';
    }

    if (params.data.priorityShipment) {
      cellStyle['background-color'] = '#FFE0C2';
    }

    return cellStyle;
  }

  private customCellStylesForCustomerGuidelines(params: any) {
    const cellStyle = {};

    if (params.data.guideline.includes('\n')) {
      cellStyle['white-space'] = 'break-spaces';
      cellStyle['line-height'] = 'normal';
    }

    return cellStyle;
  }

  private titleCaseRenderer(params: ICellRendererParams): string {
    if (!params.value) {
      return '';
    }
    return AppConstantsService.toTitleCase(params.value);
  }

  private buildAddProColumnDefs(): any {
    return [
      { field: 'rank', headerName: 'Rank', width: 160, pinned: 'left' },
      { field: 'proNumber', headerName: 'PRO', width: 150 },
      {
        field: 'inspectionStatus',
        headerName: 'Inspection Status',
        refData: { columnType: ColumnType.STATUS }, // Used for custom CellRenderers and Comparators
        width: 200
      },
      {
        field: 'eta',
        headerName: 'ETA',
        suppressMenu: true,
        refData: { columnType: ColumnType.TIMESTAMP }, // Used for custom CellRenderers and Comparators
        width: 250
      },
      { field: 'location', headerName: 'Location', width: 200 },
      { field: 'breakDoor', headerName: 'Br Dr', width: 110 },
      { field: 'loadDoor', headerName: 'Ld Dr', width: 110 },
      { field: 'shipperName', headerName: 'Shipper', width: 225 },
      { field: 'originSic', headerName: 'Orig', width: 110 },
      { field: 'consigneeName', headerName: 'Consignee', width: 225 },
      { field: 'destSic', headerName: 'Dest', width: 110 },
      {
        field: 'totalPieceCnt',
        headerName: 'Pcs',
        width: 110,
        refData: { columnType: ColumnType.NUMBER } // Used for custom CellRenderers and filterParam
      },
      {
        field: 'motorizedPieceCnt',
        headerName: 'MM',
        width: 110,
        refData: { columnType: ColumnType.NUMBER } // Used for custom CellRenderers and filterParam
      },
      {
        field: 'loosePieceCnt',
        headerName: 'LP',
        width: 110,
        refData: { columnType: ColumnType.NUMBER } // Used for custom CellRenderers and filterParam
      },
      {
        field: 'totalGrossWeight',
        headerName: 'Wgt',
        width: 110,
        refData: { columnType: ColumnType.NUMBER } // Used for custom CellRenderers and filterParam
      },
      {
        field: 'totalGrossVolume',
        headerName: 'CuFt',
        width: 110,
        refData: { columnType: ColumnType.NUMBER } // Used for custom CellRenderers and filterParam
      },
      {
        field: 'density',
        headerName: 'PCF',
        width: 110,
        refData: { columnType: ColumnType.NUMBER } // Used for custom CellRenderers and filterParam
      },
      {
        headerName: 'FAK',
        autoHeight: true,
        field: 'fak',
        refData: { columnType: ColumnType.FAK }, // Used for custom CellRenderers and Comparators
        width: 200
      },
      {
        headerName: 'NMFC',
        autoHeight: true,
        field: 'nmfc',
        refData: { columnType: ColumnType.NMFC }, // Used for custom CellRenderers and Comparators
        width: 115
      },
      {
        headerName: 'Class',
        autoHeight: true,
        field: 'nmfcClass',
        refData: { columnType: ColumnType.NMFC_CLASS }, // Used for custom CellRenderers and Comparators
        width: 150
      },
      {
        headerName: 'Commodity Description',
        width: 265,
        autoHeight: true,
        field: 'commodity',
        refData: { columnType: ColumnType.COMMODITY_DESCRIPTION } // Used for custom CellRenderers and Comparators
      },
      {
        headerName: 'Cust Instr',
        autoHeight: true,
        sortable: false,
        refData: { columnType: ColumnType.CUSTOMER_INSTRUCTIONS }, // Used for custom CellRenderers and Comparators
        field: 'customerInstructions',
        width: 1000
      },
      {
        headerName: 'Transaction Date',
        field: 'transactionDate',
        suppressMenu: true,
        width: 300,
        refData: { columnType: ColumnType.TIMESTAMP }
      }
    ];
  }

  private buildDefaultPlannedShipmentListColumnDefs(): any {
    const columnDefs = [
      { field: 'rank', headerName: 'Rank', width: 76, pinned: 'left' },
      {
        field: 'eta',
        headerName: 'ETA',
        suppressMenu: true,
        refData: { columnType: ColumnType.TIMESTAMP }, // Used for custom CellRenderers and Comparators
        width: 164
      },
      { field: 'locationPriority', headerName: 'Priority', width: 90 },
      { field: 'trailerStatus', headerName: 'Trailer Status', width: 120 },
      { field: 'location', headerName: 'Location', width: 100 },
      {
        field: 'trailerNumber',
        headerName: 'Trailer Number',
        width: 128,
        cellRendererFramework: ManifestSearchColumnComponent,
        cellRendererParams: {
          routerLink: `/${RouterUriComponents.TRAILER_DETAIL_PAGE}`
        } as CustomManifestSearchParam
      },
      {
        field: 'breakDoor',
        headerName: 'Br Dr',
        width: 80,
        cellRendererFramework: ManifestSearchColumnComponent,
        cellRendererParams: {
          routerLink: `/${RouterUriComponents.TRAILER_DETAIL_PAGE}`
        } as CustomManifestSearchParam
      },
      {
        field: 'loadDoor',
        headerName: 'Ld Dr',
        width: 80,
        cellRendererFramework: ManifestSearchColumnComponent,
        cellRendererParams: {
          routerLink: `/${RouterUriComponents.TRAILER_DETAIL_PAGE}`
        } as CustomManifestSearchParam
      },
      { field: 'proNumber', headerName: 'PRO', width: 104 },
      { field: 'shipperName', headerName: 'Shipper', width: 220 },
      { field: 'originSic', headerName: 'Orig', width: 68 },
      { field: 'consigneeName', headerName: 'Consignee', width: 220 },
      { field: 'destSic', headerName: 'Dest', width: 68 },
      {
        field: 'totalPieceCnt',
        headerName: 'Pcs',
        width: 68,
        refData: { columnType: ColumnType.NUMBER } // Used for custom CellRenderers and filterParam
      },
      {
        field: 'motorizedPieceCnt',
        headerName: 'MM',
        width: 68,
        refData: { columnType: ColumnType.NUMBER } // Used for custom CellRenderers and filterParam
      },
      {
        field: 'loosePieceCnt',
        headerName: 'LP',
        width: 68,
        refData: { columnType: ColumnType.NUMBER } // Used for custom CellRenderers and filterParam
      },
      {
        field: 'totalGrossWeight',
        headerName: 'Wgt',
        width: 68,
        refData: { columnType: ColumnType.NUMBER } // Used for custom CellRenderers and filterParam
      },
      {
        field: 'totalGrossVolume',
        headerName: 'CuFt',
        width: 68,
        refData: { columnType: ColumnType.NUMBER } // Used for custom CellRenderers and filterParam
      },
      {
        field: 'density',
        headerName: 'PCF',
        width: 68,
        refData: { columnType: ColumnType.NUMBER } // Used for custom CellRenderers and filterParam
      },
      {
        headerName: 'FAK',
        autoHeight: true,
        field: 'fak',
        refData: { columnType: ColumnType.FAK }, // Used for custom CellRenderers and Comparators
        width: 104
      },
      {
        headerName: 'NMFC',
        autoHeight: true,
        field: 'nmfc',
        width: 112,
        cellRendererFramework: NmfcColumnComponent
      },
      {
        headerName: 'Class',
        autoHeight: true,
        field: 'nmfcClass',
        refData: { columnType: ColumnType.NMFC_CLASS }, // Used for custom CellRenderers and Comparators
        width: 96
      },
      {
        headerName: 'Commodity Description',
        width: 264,
        autoHeight: true,
        field: 'commodity',
        refData: { columnType: ColumnType.COMMODITY_DESCRIPTION }, // Used for custom CellRenderers and Comparators
        filter: 'agTextColumnFilter'
      },
      {
        headerName: 'Transaction Date',
        field: 'transactionDate',
        suppressMenu: true,
        width: 164,
        refData: { columnType: ColumnType.TIMESTAMP }
      }
    ];
    return columnDefs;
  }

  private buildDefaultRecommendRulesListColumnDefs(): any {
    const columnDefs = [
      { field: 'ruleInstId', headerName: 'Rule ID', width: 135 },
      { field: 'typeCd', headerName: 'Recommend', width: 135 },
      { field: 'acctName', headerName: 'Account', width: 200 },
      { field: 'acctMadCd', headerName: 'MAD Code', width: 140 },
      {
        field: 'partyRole',
        headerName: 'Party',
        width: 110,
        refData: { columnType: ColumnType.PARTY_ROLE } // Used for custom CellRenderers and Comparators
      },
      { field: 'consignee', headerName: 'To Consignee Account', width: 200 },
      { field: 'consigneeMadCd', headerName: 'To Consignee MAD Code', width: 200 },
      {
        field: 'inspectionChargeCd',
        headerName: 'Payment Type',
        width: 145,
        refData: { columnType: ColumnType.PAYMENT_TYPE }
      },
      { field: 'createdId', headerName: 'Created By', width: 150 },
      {
        field: 'createdDate',
        headerName: 'Create Date',
        suppressMenu: true,
        width: 180,
        refData: { columnType: ColumnType.TIMESTAMP }
      },
      { field: 'lastUpdatedId', headerName: 'Updated By', width: 150 },
      {
        field: 'updatedDate',
        headerName: 'Update Date',
        suppressMenu: true,
        width: 180,
        refData: { columnType: ColumnType.TIMESTAMP }
      }
    ];
    this.applyCustomTypeFunctions(columnDefs);
    return columnDefs;
  }

  private buildDefaultCustomerGuidelinesColumnDefs(): any {
    const columnDefs = [
      { field: 'customerGuidelineId', headerName: 'Guideline ID', width: 110, minWidth: 50, maxWidth: 150 },
      { field: 'acctMadCd', headerName: 'MAD Code', width: 140, minWidth: 50, maxWidth: 160 },
      { field: 'acctName', headerName: 'Account', width: 250, minWidth: 50, maxWidth: 300 },
      {
        field: 'guideline',
        headerName: 'Guideline',
        minWidth: 600,
        width: 600,
        cellRendererFramework: CustomerGuidelinesCellRendererComponent,
        wrapText: true,
        autoHeight: true
      },
      { field: 'createByName', headerName: 'Created By', width: 180, minWidth: 50, maxWidth: 200 },
      {
        field: 'createdTimestamp',
        headerName: 'Create Date',
        width: 180,
        minWidth: 50,
        maxWidth: 180,
        refData: { columnType: ColumnType.TIMESTAMP }
      },
      { field: 'updateByName', headerName: 'Updated By', width: 180, minWidth: 50, maxWidth: 200 },
      {
        field: 'updatedTimestamp',
        headerName: 'Update Date',
        width: 180,
        minWidth: 50,
        maxWidth: 180,
        refData: { columnType: ColumnType.TIMESTAMP }
      }
    ];
    this.applyCustomTypeFunctions(columnDefs);
    return columnDefs;
  }

  private buildDefaultPickupRequestsColumnDefs(): ColDef[] {
    const columnDefs: ColDef[] = [
      { field: 'shipperName', headerName: 'Shipper Name', width: 200 },
      { field: 'shipperAddress', headerName: 'Shipper Address', width: 300 },
      { field: 'madCode', headerName: 'MAD Code', width: 130 },
      { field: 'proNumber', headerName: 'PRO', width: 130 },
      {
        field: 'pallets',
        headerName: 'Pallets',
        width: 130,
        refData: { columnType: ColumnType.NUMBER } // Used for custom CellRenderers and filterParam
      },
      {
        field: 'pieces',
        headerName: 'Pieces',
        width: 130,
        refData: { columnType: ColumnType.NUMBER } // Used for custom CellRenderers and filterParam
      },
      {
        field: 'weight',
        headerName: 'Weight',
        width: 130,
        refData: { columnType: ColumnType.NUMBER } // Used for custom CellRenderers and filterParam
      },
      {
        field: 'motorMoves',
        headerName: 'MM',
        width: 130,
        refData: { columnType: ColumnType.NUMBER } // Used for custom CellRenderers and filterParam
      },
      { field: 'remarks', headerName: 'Remarks', width: 130 },
      {
        headerName: 'Indicators',
        width: 130,
        cellRendererFramework: PickupRequestMarkCellRendererComponent,
        valueGetter: (params: ValueGetterParams) => {
          return this.getIndicators(params);
        },
        filterValueGetter: (params: ValueGetterParams) => {
          return this.getIndicatorNames(params);
        }
      },
      {
        field: 'cube',
        headerName: 'CuFt',
        width: 130,
        refData: { columnType: ColumnType.NUMBER } // Used for custom CellRenderers and filterParam
      },
      {
        field: 'density',
        headerName: 'PCF',
        width: 130,
        refData: { columnType: ColumnType.NUMBER } // Used for custom CellRenderers and filterParam
      },
      {
        field: 'callNumber',
        headerName: 'Call Number',
        width: 200,
        valueGetter: (param: ValueGetterParams) => {
          const pickupRequest: PickupRequestItem = param.data;
          if (!pickupRequest) {
            return;
          }
          return pickupRequest.getFormattedCallNumber();
        }
      },
      { field: 'pickupStatus', headerName: 'Pickup Status', width: 150 },
      { field: 'inspectionStatus', headerName: 'Inspection Status', width: 180 },
      { field: 'destinationSicCd', headerName: 'Destination', width: 200 }
    ];
    return columnDefs;
  }

  public buildDefaultInspectedListColumnDefs(): any {
    const columnDefs = [
      { headerName: 'PRO', field: 'proNumber', width: 150 },
      {
        headerName: 'Inspection Status',
        field: 'inspectionStatus',
        refData: { columnType: ColumnType.STATUS }, // Used for custom CellRenderers and Comparators
        width: 200
      },
      { field: 'locationPriority', headerName: 'Priority', width: 90 },
      { field: 'trailerStatus', headerName: 'Trailer Status', width: 120 },
      { field: 'location', headerName: 'Location', width: 100 },
      {
        field: 'trailerNumber',
        headerName: 'Trailer Number',
        width: 128,
        cellRendererFramework: ManifestSearchColumnComponent,
        cellRendererParams: {
          routerLink: `/${RouterUriComponents.TRAILER_DETAIL_PAGE}`
        } as CustomManifestSearchParam
      },
      {
        field: 'breakDoor',
        headerName: 'Br Dr',
        width: 80,
        cellRendererFramework: ManifestSearchColumnComponent,
        cellRendererParams: {
          routerLink: `/${RouterUriComponents.TRAILER_DETAIL_PAGE}`
        } as CustomManifestSearchParam
      },
      {
        field: 'loadDoor',
        headerName: 'Ld Dr',
        width: 80,
        cellRendererFramework: ManifestSearchColumnComponent,
        cellRendererParams: {
          routerLink: `/${RouterUriComponents.TRAILER_DETAIL_PAGE}`
        } as CustomManifestSearchParam
      },
      { headerName: 'Bill Status', field: 'billStatus', width: 150 },
      { headerName: 'Shipper', field: 'shipperName', width: 225 },
      { headerName: 'Consignee', field: 'consigneeName', width: 225 },
      { headerName: 'Inspector', field: 'inspectorName', width: 250 },
      {
        headerName: 'Inspection Date',
        field: 'transactionDate',
        suppressMenu: true,
        refData: { columnType: ColumnType.TIMESTAMP }, // Used for custom CellRenderers and Comparators
        width: 300
      }
    ];
    this.applyCustomTypeFunctions(columnDefs);
    return columnDefs;
  }

  public buildDefaultCompletedListColumnDefs(): any {
    const columnDefs = [
      { headerName: 'PRO', field: 'proNumber', width: 150 },
      {
        headerName: 'Inspection Status',
        field: 'inspectionStatus',
        refData: { columnType: ColumnType.STATUS }, // Used for custom CellRenderers and Comparators
        width: 200
      },
      { headerName: 'Bill Status', field: 'billStatus', width: 150 },
      { headerName: 'Shipper', field: 'shipperName', width: 225 },
      { headerName: 'Consignee', field: 'consigneeName', width: 225 },
      { headerName: 'Inspector', field: 'inspectorName', width: 250 },
      {
        headerName: 'Completion Date',
        field: 'transactionDate',
        suppressMenu: true,
        refData: { columnType: ColumnType.TIMESTAMP }, // Used for custom CellRenderers and Comparators
        width: 300
      }
    ];
    this.applyCustomTypeFunctions(columnDefs);
    return columnDefs;
  }

  private buildLookupColumnDefs(): ColDef[] {
    return [
      { headerName: 'PRO', field: 'shipmentId.proNumber', width: 150 },
      {
        headerName: 'Inspection Status',
        field: 'inspectionStatusCd',
        width: 200,
        refData: { columnType: ColumnType.STATUS }
      }, // Used for custom CellRenderers and Comparators },
      { headerName: 'Shipper', field: 'shipperNm', width: 250 },
      {
        headerName: 'Motor Moves',
        field: 'motorizedPiecesCount',
        width: 150,
        refData: { columnType: ColumnType.NUMBER } // Used for custom CellRenderers and filterParam
      },
      {
        headerName: 'Billed Pieces',
        field: 'totPiecesCount',
        width: 150,
        refData: { columnType: ColumnType.NUMBER } // Used for custom CellRenderers and filterParam
      },
      {
        headerName: 'Cube',
        field: 'totGrossVolume',
        width: 150,
        refData: { columnType: ColumnType.NUMBER } // Used for custom CellRenderers and filterParam
      },
      {
        headerName: 'Weight',
        field: 'totWeight.weight',
        width: 150,
        refData: { columnType: ColumnType.NUMBER } // Used for custom CellRenderers and filterParam
      },
      {
        colId: 'indCol',
        headerName: 'Indicators',
        width: 150,
        filterValueGetter: (params: ValueGetterParams) => {
          return this.getIndicatorNames(params);
        },
        cellRendererFramework: IndicatorRendererComponent,
        valueGetter: (params: ValueGetterParams) => {
          return this.getIndicators(params);
        }
      },
      { headerName: 'Dest', field: 'destSic', width: 150 },
      { headerName: 'Load Door', field: 'loadToDoor', width: 150 }
    ];
  }

  private buildBroadcastMessagesListColumnDefs(): ColDef[] {
    const columnDefs: ColDef[] = [
      { field: 'messageId', headerName: 'Message ID', width: 150 },
      { field: 'messageType', headerName: 'Type', width: 150 },
      {
        field: 'messageStatus',
        headerName: 'Status',
        width: 150,
        valueGetter: (params: ValueGetterParams) => {
          if (!params || !params.data) {
            return '';
          }
          const broadcastMessage: BroadcastMessage = params.data;
          return broadcastMessage.getDisplayedStatus();
        }
      },
      { field: 'message', headerName: 'Message', width: 600 },
      { field: 'createdBy', headerName: 'Created By', width: 200 },
      {
        field: 'createdAt',
        headerName: 'Created Date',
        width: 200,
        refData: { columnType: ColumnType.TIMESTAMP }
      },
      { field: 'updatedBy', headerName: 'Updated By', width: 200 },
      {
        field: 'updatedAt',
        headerName: 'Updated Date',
        width: 200,
        refData: { columnType: ColumnType.TIMESTAMP }
      }
    ];
    this.applyCustomTypeFunctions(columnDefs);
    return columnDefs;
  }

  private getIndicators(params: ValueGetterParams): Indicator[] {
    const indicators: Indicator[] = [];

    if (params.data.freezableInd) {
      indicators.push(new Indicator(IndicatorName.FREEZABLE));
    }
    if (params.data.garntdInd) {
      indicators.push(new Indicator(IndicatorName.GUARANTEED));
    }
    if (params.data.hazmatInd) {
      indicators.push(new Indicator(IndicatorName.HAZMAT));
    }
    if (params.data.headloadInd) {
      indicators.push(new Indicator(IndicatorName.HEADLOAD));
    }
    if (params.data.foodInd) {
      indicators.push(new Indicator(IndicatorName.FOOD));
    }
    if (params.data.rrsInd) {
      indicators.push(new Indicator(IndicatorName.RAPID_REMOTE_SERVICE));
    }
    return indicators;
  }

  private getIndicatorNames(params: ValueGetterParams): string {
    let indicatorNames: string[] = [];

    this.getIndicators(params).forEach((indicator: Indicator) => {
      indicatorNames.push(indicator.name);
    });

    return indicatorNames.toString();
  }
}
