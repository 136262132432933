import { SafeResourceUrl, SafeStyle } from '@angular/platform-browser';
import { RequestValidator } from '../validators/request.validator';
import { PhotoId } from './photo-id';

export class Photo {
  static readonly CONTENT_TYPE_PNG = 'image/png';
  static readonly CONTENT_TYPE_JPEG = 'image/jpeg';
  static readonly CONTENT_TYPE_TIFF = 'image/tiff';
  static readonly CONTENT_TYPE_BMP = 'image/bmp';

  private _id: PhotoId; // Photo ID is a unique Id for the photo
  private _base64data: string;
  private _thumbnailBase64data: string;
  private _dmsOpCode: string;
  private _contentType: string = '';
  private _thumbnailContentType: string = '';
  private _fileName: string;
  private _isLoaded = false;
  private _createDate: Date;
  private _sizeKB: number;
  private _imgSrcForHtml: string | SafeResourceUrl;

  static getStrippedHeader(aBase64data: string): string {
    const dataPos = aBase64data.indexOf('data:');
    const pos = aBase64data.indexOf(';base64,');
    if (dataPos === 0 && pos > 0) {
      return aBase64data.substring(pos + 8);
    } else {
      return aBase64data;
    }
  }

  constructor(photoId: PhotoId) {
    RequestValidator.validateObjectNotUndefinedOrEmpty(photoId, 'Photo ID');

    this._id = photoId;
  }

  get id(): PhotoId {
    return this._id;
  }

  set dmsOpCode(dmsOpCode: string) {
    this._dmsOpCode = dmsOpCode;
  }

  get dmsOpCode(): string {
    return this._dmsOpCode;
  }

  set imgSrcForHtml(aImgSrcForHtml: SafeStyle) {
    RequestValidator.validateObjectNotUndefinedOrEmpty(aImgSrcForHtml, 'Image Source');
    this._isLoaded = true;

    this._imgSrcForHtml = aImgSrcForHtml;
  }

  get imgSrcForHtml(): SafeStyle {
    return this._imgSrcForHtml;
  }

  get isLoaded(): boolean {
    return this._isLoaded;
  }

  set fileName(fileName: string) {
    this._fileName = fileName;
  }

  get fileName(): string {
    return this._fileName;
  }

  get contentType(): string {
    return this._contentType;
  }

  get thumbnailContentType(): string {
    if (this._thumbnailContentType) {
      return this._thumbnailContentType;
    } else {
      return this._contentType;
    }
  }

  set createDate(createDate: Date) {
    this._createDate = createDate;
  }

  get createDate(): Date {
    return this._createDate;
  }

  set photoSizeKB(sizeKB: number) {
    this._sizeKB = sizeKB;
  }

  get photoSizeKB(): number {
    return this._sizeKB;
  }

  get svgIconValue(): string {
    return `document-type-${this.id?.imageType}`;
  }

  setBase64dataAndContentType(aBase64data: string, defaultContentType: string = '') {
    this._isLoaded = true; // set as loaded, even if bytes are null
    if (!aBase64data) {
      return;
    }
    const dataPos = aBase64data.indexOf('data:');
    const pos = aBase64data.indexOf(';base64,');
    if (dataPos === 0 && pos > 0) {
      this._contentType = aBase64data.substring(dataPos + 5, pos);
      this._base64data = aBase64data.substring(pos + 8);
    } else {
      this._contentType = defaultContentType;
      this._base64data = aBase64data;
    }
  }

  getBase64data(includeHeader: boolean = false): string {
    if (includeHeader) {
      return this.buildHeader(this._contentType) + this._base64data;
    } else {
      return this._base64data;
    }
  }

  setThumbnailBytesAndContentType(aThumbnailBase64data: string, defaultContentType: string = '') {
    if (!aThumbnailBase64data) {
      return;
    }
    const dataPos = aThumbnailBase64data.indexOf('data:');
    const pos = aThumbnailBase64data.indexOf(';base64,');
    if (dataPos === 0 && pos > 0) {
      this._thumbnailContentType = aThumbnailBase64data.substring(dataPos + 5, pos);
      this._thumbnailBase64data = aThumbnailBase64data.substring(pos + 8);
    } else {
      this._thumbnailContentType = defaultContentType;
      this._thumbnailBase64data = aThumbnailBase64data;
    }
  }

  getThumbnailBytes(includeHeader: boolean = false): string {
    if (includeHeader) {
      if (this._thumbnailBase64data) {
        return this.buildHeader(this._thumbnailContentType) + this._thumbnailBase64data;
      } else {
        return this.buildHeader(this._contentType) + this._base64data;
      }
    } else {
      if (this._thumbnailBase64data) {
        return this._thumbnailBase64data;
      } else {
        return this._base64data;
      }
    }
  }

  private buildHeader(contentType: string): string {
    return 'data:' + contentType + ';base64,';
  }
}
