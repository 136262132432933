import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ImageCapture } from 'image-capture';
import { combineLatest, EMPTY, Observable, Subject } from 'rxjs';
import { catchError, map, take, takeUntil } from 'rxjs/operators';
import { Photo } from '../../classes/photo';
import { PhotoId } from '../../classes/photo-id';
import { ProNumber } from '../../classes/pronumber';
import { CameraDialogComponent } from '../../dialogs/camera-dialog/camera-dialog.component';
import { DmsOpCode } from '../../enums/dms-op-code.enum';
import { ErrorMessageActions } from '../../enums/error-message-actions.enum';
import { AppConstantsService } from '../../services/app-constants.service';
import { IDBSpace } from '../../services/app-storage.service';
import { ErrorHandlingService } from '../../services/error-handling.service';
import { HardwareService } from '../../services/hardware/hardware-service';
import { PhotoGalleryDataImpl } from '../inspect-shipment/components/photos/photos-gallery-data-impl';
import { PhotoGalleryService } from '../photo-gallery/photo-gallery.service';
import { CameraError, CameraState, CameraStatus } from './camera-state';

@Component({
  selector: 'app-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.scss']
})
export class CameraComponent implements OnInit, OnDestroy {
  public flash = 'off';
  public torch = false;
  public lastPhotoId: PhotoId;
  public imageCapture: ImageCapture;
  public pro: ProNumber;
  public cameraState: CameraState = new CameraState();
  public previewImage;
  public errorMessage: string;

  private snapshotSoundTrigger: HTMLAudioElement;
  private cameraCanvas: HTMLMediaElement;
  private mediaStreamTrack;
  private docTypes: string[];
  private unsubscriber$: Subject<any> = new Subject();
  private readonly photoCountLimit: number = this.appConstantsService.inspectionPhotoCountLimit;
  private readonly photoLocalStorageLimit: number = this.appConstantsService.inspectionPhotoLocalStorageLimit;

  constructor(
    private hardwareService: HardwareService,
    private router: Router,
    private dialogRef: MatDialogRef<CameraDialogComponent>,
    private sanitizer: DomSanitizer,
    private photoGallery: PhotoGalleryService,
    private errorHandling: ErrorHandlingService,
    private appConstantsService: AppConstantsService
  ) {}

  ngOnDestroy(): void {
    this.torch = false;
    this.setTorch(this.torch);
    this.mediaStreamTrack?.stop();
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

  ngOnInit(): void {
    this.snapshotSoundTrigger = document.getElementById('camera-shutter') as HTMLMediaElement;
    this.cameraCanvas = document.getElementById('camera-stream') as HTMLMediaElement;
    this.pro = new ProNumber(this.router.url.substring(this.router.url.lastIndexOf('/') + 1)); // TODO: change this

    this.prepareCamera();

    this.photoGallery.onClearThumbnail.subscribe((resp) => {
      this.previewImage = undefined;
      this.prepareCamera();
    });
  }

  onClose(): void {
    this.mediaStreamTrack.stop();
    this.dialogRef.close();
  }

  gotMedia(mediaStream): void {
    this.mediaStreamTrack = mediaStream.getVideoTracks()[0];

    // applyConstraints will fail on PC because its not available for PC
    this.mediaStreamTrack.applyConstraints({
      advanced: [{ torch: false }]
    });

    this.imageCapture = new ImageCapture(this.mediaStreamTrack);
    this.cameraCanvas.srcObject = mediaStream;
  }

  onSnapshot(): void {
    this.showSnapshotDelay();
    if (!this.torch && this.flash === 'flash') {
      this.mediaStreamTrack.applyConstraints({ advanced: [{ torch: true }] });
    } else if (this.torch && this.flash !== 'flash') {
      this.mediaStreamTrack.applyConstraints({ advanced: [{ torch: false }] });
    }
    this.imageCapture
      .takePhoto()
      .then((blob) => {
        this.snapshotSoundTrigger.play();
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64data = reader.result;
          this.savePhoto(base64data)
            .pipe(
              take(1),
              catchError((error) => {
                this.prepareCamera();
                return EMPTY;
              })
            )
            .subscribe((isSucceeded: boolean) => {
              if (isSucceeded) {
                this.previewImage = this.sanitizer.bypassSecurityTrustResourceUrl(base64data.toString());
              }
              this.prepareCamera();
            });
        };
      })
      .catch((error) => {
        this.prepareCamera();
        this.logCameraError('Take Photo error:', error);
        this.errorHandling.handleResponseError(error, ErrorMessageActions.TAKING, `Photo`);
      });
  }

  savePhoto(photoBytes): Observable<boolean> {
    return this.hardwareService.addPhoto(this.pro, photoBytes, null).pipe(
      take(1),
      map((addedPhoto: Photo) => {
        this.lastPhotoId = addedPhoto.id;
        return true;
      }),
      catchError((error) => {
        this.logCameraError('savePhoto() - ', error);
        this.errorHandling.handleResponseError(error, ErrorMessageActions.SAVING, `Photo`);

        return EMPTY;
      })
    );
  }

  showPhotoGallery(): Observable<any> {
    return this.photoGallery.showPhotoGalleryDialog(
      new PhotoGalleryDataImpl(this.hardwareService),
      this.docTypes,
      this.lastPhotoId
    );
  }

  toggleFlash(): void {
    if (this.flash === 'off') {
      this.flash = 'flash';
    } else {
      this.flash = 'off';
    }
  }

  toggleTorch(): void {
    this.torch = !this.torch;
    this.setTorch(this.torch);
  }

  private hideSnapshotDelay(): void {
    const overlay = document.getElementById('camera-delay-overlay');
    if (overlay) {
      overlay.style.display = 'none';
    }
  }

  private initializeCamera(): void {
    // Attempt to Set Default resolution to 2048 x 1536. This is to fix an issue with the Zebra and it seeming to select the
    // Wrong resoution causing a bad aspect ratio/orientation. LEI-318
    navigator.mediaDevices
      .getUserMedia({ video: { facingMode: 'environment', width: 960, height: 720 } })
      .then((mediaStream) => {
        this.cameraState.cameraStatus = CameraStatus.READY;
        this.gotMedia(mediaStream);
        setTimeout(() => {
          // Set to 500ms. we can't set the torch back on or off instantly.
          // We have to wait a bit for it to take affect.
          this.setTorch(this.torch);
          this.hideSnapshotDelay();
        }, 500);
      })
      .catch((error) => {
        this.cameraCanvas.srcObject = null;
        this.mediaStreamTrack.stop();
        this.cameraState.cameraStatus = CameraStatus.ERROR;
        this.logCameraError('getUserMedia() error:', error);
        alert('Initialize Camera Error! ' + error);
      });
  }

  private logCameraError(functionName: string, errorMsg: string): void {
    const error = new CameraError();
    error.errorMessage = errorMsg;
    error.timeStamp = new Date();
    error.stackCall = 'CameraComponent - ' + functionName;

    this.cameraState.errorHistory.push(error);
    console.log('Camera State Updated: ', this.cameraState);
  }

  private prepareCamera(): void {
    this.updateCameraState()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(() => {
        if (this.cameraState.cameraStatus === CameraStatus.ERROR) {
          this.hideSnapshotDelay();
        } else {
          this.initializeCamera();
        }
      });
  }

  /**
   * Setting torch for tablet user.
   * applyConstraints will fail on PC because its not available for PC
   * @param torch
   */
  private setTorch(torch: boolean) {
    if (this.mediaStreamTrack) {
      this.mediaStreamTrack
        .applyConstraints({ advanced: [{ torch: torch }] })
        .then(() => {})
        .catch((error) => {
          console.error('Setting Torch error: ' + error);
        });
    }
  }

  private showSnapshotDelay(): void {
    const overlay = document.getElementById('camera-delay-overlay');
    if (overlay) {
      overlay.style.display = 'block';
    }
  }

  private updateCameraState(): Observable<boolean> {
    this.cameraState.supportedConstraints = navigator.mediaDevices.getSupportedConstraints();
    this.cameraState.cameraStatus = CameraStatus.NOT_READY;

    return combineLatest([
      this.hardwareService.getPhotoCountByPro(this.pro, DmsOpCode.NEWLY_ADDED_PHOTO),
      this.hardwareService.getStorageSpace()
    ]).pipe(
      take(1),
      map(([count, storageSpace]: [number, IDBSpace]) => {
        this.cameraState.photosTaken = count;
        this.cameraState.spaceRemainingKB = storageSpace.availableKB;
        this.validateCameraState(this.cameraState);
        return true;
      }),
      catchError((error) => {
        this.errorHandling.handleResponseError(error, ErrorMessageActions.UPDATING, `Camera State`);

        return EMPTY;
      })
    );
  }

  private validateCameraState(state: CameraState): boolean {
    if (!state) {
      return false;
    }

    if (state.photosTaken === this.photoCountLimit) {
      this.logCameraError('ValidateCameraState() - ', 'Photo count limit reached!');
      this.cameraState.cameraStatus = CameraStatus.ERROR;
      this.errorMessage = `The ${this.photoCountLimit} photo maximum per Inspection has been reached.  If more than ${this.photoCountLimit}  photos are needed, submit the Inspection, edit the Inspection and add up to ${this.photoCountLimit} more photos.`;
      return false;
    }
    // 1 MB offset
    if (state.spaceRemainingKB <= this.photoLocalStorageLimit) {
      this.logCameraError('ValidateCameraState() - ', 'Memory is full!');
      this.cameraState.cameraStatus = CameraStatus.ERROR;
      this.errorMessage =
        'Memory limit for photo storage has been reached. Submit In Progress Inspections with photos or clean up Inspection photos to free up space.';
      return false;
    }
    this.cameraState.cameraStatus = CameraStatus.READY;
    return true;
  }
}
