<div class="pricing">
  <mat-expansion-panel [(expanded)]="isExpanded">
    <mat-expansion-panel-header>
      <mat-panel-title class="section-label"> Pricing </mat-panel-title>
    </mat-expansion-panel-header>

    <div fxLayout="column" fxLayoutAlign="start stretch">
      <div fxLayout="row" fxLayoutAlign="stretch start">
        <div fxLayout="column" fxFlex="25">
          <div class="label">Item 15 Eligibility</div>
          <div class="value">
            {{ shipmentDetails?.item15ExemptInd | invertedYesNoPipe : 'Yes' }}
          </div>
        </div>
        <div fxLayout="column" fxFlex="25">
          <div class="label">ELS Eligibility</div>
          <div class="value">
            {{ shipmentDetails?.elsExemptInd | invertedYesNoPipe : 'Yes' }}
          </div>
        </div>
        <div fxLayout="column" fxFlex="25">
          <div class="label">Lineal Foot Eligibility</div>
          <div class="value">
            {{ shipmentDetails?.linealFootEligibilityInd | yesNoPipe : 'Yes' }}
          </div>
        </div>
        <div fxLayout="column" fxFlex="25">
          <div class="label">Lineal Foot Range</div>
          <div *ngIf="shipmentDetails?.linealFootRanges" class="value">
            {{ shipmentDetails?.linealFootRanges }}
          </div>
          <div *ngIf="!shipmentDetails?.linealFootRanges" class="value">14-99</div>
        </div>
        <div fxLayout="column" fxFlex="25">
          <div class="label">Special lineal foot or capacity rules</div>
          <div class="value">
            {{ shipmentDetails?.specialCapacityRuleInd | yesNoPipe : 'No' }}
          </div>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="start stretch" class="pricing__rulesets" *ngIf="appliedRulesetUrl">
        <button class="pricing__rulesets-button" mat-button color="primary" (click)="viewRulesets()">
          View Applied Rulesets
        </button>
      </div>
    </div>
  </mat-expansion-panel>
</div>
