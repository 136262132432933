import { Component, Inject, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

import { AcctId } from '@xpo-ltl/sdk-common';
import { takeUntil } from 'rxjs/operators';

import { RecommendedRulesFormComponent } from '../../components/recommendation-rules/recommended-rules-form/recommended-rules-form.component';
import { RecommendedRulesService } from '../../components/recommendation-rules/services/recommended-rules.service';
import { DialogAction, toDialogActionPastTense } from '../../enums/dialog-actions.enum';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-recommendation-rule-dialog',
  templateUrl: './recommendation-rule-dialog.component.html',
  styleUrls: ['./recommendation-rule-dialog.component.scss']
})
export class RecommendationRuleDialogComponent implements OnInit, OnDestroy {
  @ViewChild(RecommendedRulesFormComponent, { static: false })
  ruleFilter: RecommendedRulesFormComponent;

  sendInformation: boolean;
  action: string;
  title: string;
  DialogAction = DialogAction;

  private unsubscriber$: Subject<any> = new Subject();

  @Output() isNewRule: boolean;

  constructor(
    private dialogRef: MatDialogRef<RecommendationRuleDialogComponent>,
    private recommendedRulesService: RecommendedRulesService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data) {
      this.action = data.action;
      this.isNewRule = data.isNew;
      if (this.action === DialogAction.CREATE) {
        this.title = `${data.title}`;
      } else {
        this.title = `${data.title} ID: ${data.rule?.ruleInstId}`;
      }
    }

    if (this.action === DialogAction.EDIT) {
      // We copy the rule object to avoid passing it by reference
      const recommendedRule = JSON.parse(JSON.stringify(data.rule));

      this.recommendedRulesService.setRuleRecommended(recommendedRule);
    }

    this.sendInformation = false;
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

  validForm(): boolean {
    return this.recommendedRulesService.isFormValid();
  }

  validFilters(): boolean {
    return this.recommendedRulesService.areFiltersValid();
  }

  confirmDialog() {
    this.recommendedRulesService.triggerFilterValidations();
    if (this.recommendedRulesService.isFormValid() && this.recommendedRulesService.areFiltersValid()) {
      this.sendInformation = true;
    } else {
      this.sendInformation = false;
    }
  }

  performRecommendationRuleOperation() {
    let action: any;
    let showMessage = '';
    if (this.action === DialogAction.EDIT) {
      action = this.recommendedRulesService.updateRecommendedRule();
    } else if (this.action === DialogAction.DELETE) {
      action = this.recommendedRulesService.deleteRecommendedRule();
    } else {
      action = this.recommendedRulesService.createRecommendedRule();
    }

    showMessage = `The rules was ${toDialogActionPastTense(this.action as DialogAction)} successfully`;
    action.pipe(takeUntil(this.unsubscriber$)).subscribe(
      () => {
        this.snackBar.open(showMessage, 'Close', {
          duration: 3000
        });
        this.close(true);
      },
      () => {
        this.backFormScreen();
      }
    );
  }

  deleteRule() {
    this.action = DialogAction.DELETE;
    this.confirmDialog();
  }

  backFormScreen() {
    this.sendInformation = false;

    if (this.action === DialogAction.DELETE) {
      this.action = DialogAction.EDIT;
    }
  }

  cancel(): void {
    this.close(false);
  }

  close(reload): void {
    this.recommendedRulesService.clear();
    this.dialogRef.close(reload);
  }

  clear(): void {
    this.recommendedRulesService.clear();
    this.recommendedRulesService.setRecommendation('');
    this.recommendedRulesService.setAccount(new AcctId());
    this.ruleFilter.madCode = '';
  }
}
