import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { XpoAuthenticationGuard } from '@xpo/ngx-auth';
import { CustomerGuidelinesComponent } from './components/customer-guidelines/customer-guidelines.component';
import { ListShipmentsComponent } from './components/list-shipments/list-shipments.component';
import { NotAuthorizedComponent } from './components/not-authorized/not-authorized.component';
import { RecommendationsRulesComponent } from './components/recommendation-rules/recommendation-rules.component';
import { RouterUriComponents } from './enums/router-uri-components.enum';
import { NotAuthorizedRedirectGuard } from './guards/not-authorized-redirect.guard';
import { RoleCheckGuard } from './guards/role-check-guard';
import { ScopeCheckGuard } from './guards/scope-check-guard';
import { SequentialGuard } from './guards/sequential.guard';
import { UnreadMessagesGuard } from './guards/unread-messages.guard';
import { UserProfileGuard } from './guards/user-profile-guard';

export const RouterDefinitions: Routes = [
  {
    path: RouterUriComponents.NOT_AUTHORIZED_PAGE,
    component: NotAuthorizedComponent,
    canActivate: [NotAuthorizedRedirectGuard]
  },
  {
    path: RouterUriComponents.LIST_SHIPMENTS + '/:tab',
    component: ListShipmentsComponent,
    canActivate: [SequentialGuard],
    data: {
      sequentialGuards: [XpoAuthenticationGuard, RoleCheckGuard, UserProfileGuard, UnreadMessagesGuard]
    }
  },
  {
    path: RouterUriComponents.RECOMMENDATION_RULES,
    component: RecommendationsRulesComponent,
    canActivate: [SequentialGuard],
    data: {
      sequentialGuards: [XpoAuthenticationGuard, RoleCheckGuard, UserProfileGuard, UnreadMessagesGuard]
    }
  },
  {
    path: RouterUriComponents.SHIPMENT_DETAILS_PAGE,
    loadChildren: () =>
      import('./components/shipment-details/shipment-details.module').then((m) => m.ShipmentDetailsModule)
  },
  {
    path: RouterUriComponents.INSPECT_SHIPMENT_PAGE,
    loadChildren: () =>
      import('./components/inspect-shipment/inspect-shipment.module').then((m) => m.InspectShipmentModule)
  },
  {
    path: RouterUriComponents.TRAILER_DETAIL_PAGE,
    canActivate: [SequentialGuard],
    loadChildren: () => import('./components/trailer-detail/trailer-detail.module').then((m) => m.TrailerDetailModule)
  },
  {
    path: RouterUriComponents.ADD_PRO_DETAIL_PAGE,
    loadChildren: () => import('./components/add-pro-detail/add-pro-detail.module').then((m) => m.AddProDetailModule)
  },
  {
    path: RouterUriComponents.ITEM_680_2B_PAGE,
    loadChildren: () =>
      import('./components/tools/item680calculator/item680calculator.module').then((m) => m.Item680CalculatorModule)
  },
  {
    path: RouterUriComponents.CUSTOMER_GUIDELINES,
    component: CustomerGuidelinesComponent,
    canActivate: [SequentialGuard],
    data: {
      sequentialGuards: [XpoAuthenticationGuard, RoleCheckGuard, UserProfileGuard, UnreadMessagesGuard]
    }
  },
  {
    path: RouterUriComponents.BROADCAST_MESSAGES,
    canActivate: [SequentialGuard],
    data: {
      sequentialGuards: [XpoAuthenticationGuard, RoleCheckGuard, UserProfileGuard, UnreadMessagesGuard]
    },
    loadChildren: () =>
      import('./components/broadcast-messages/broadcast-messages.module').then((m) => m.BroadcastMessagesModule)
  },
  {
    path: RouterUriComponents.PICKUP_REQUESTS,
    canActivate: [SequentialGuard],
    data: {
      sequentialGuards: [XpoAuthenticationGuard, RoleCheckGuard, UserProfileGuard, UnreadMessagesGuard]
    },
    loadChildren: () =>
      import('./components/pickup-requests/pickup-requests.module').then((m) => m.PickUpRequestsModule)
  },
  {
    path: '**',
    redirectTo: RouterUriComponents.LIST_SHIPMENTS + '/Recommended'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(RouterDefinitions, { enableTracing: false, onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
  providers: [RoleCheckGuard, SequentialGuard, UserProfileGuard, ScopeCheckGuard]
})
export class AppRoutingModule {}
