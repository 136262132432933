// NOTE: app key's maximum length is 40.
export enum UserPreferenceAppKeys {
  Flagged = 'INS-FLAGGED-LIST-GRID-SETTINGS',
  Recommended = 'INS-RECOMMENDED-LIST-GRID-SETTINGS',
  Inspected = 'INS-INSPECTED-LIST-GRID-SETTINGS',
  Completed = 'INS-COMPLETED-LIST-GRID-SETTINGS',
  Dismissed = 'INS-DISMISSED-LIST-GRID-SETTINGS',
  Lookup = 'INS-LOOKUP-LIST-GRID-SETTINGS',
  AddPros = 'INS-ADD-PROS-LIST-GRID-SETTINGS',
  RecommendationRules = 'INS-RECOMMENDED-RULES-LIST-GRID-SETTINGS',
  CustomerGuidelines = 'INS-CUST-GUIDELINES-LIST-GRID-SETTINGS',
  BroadcastMessages = 'INS-BROADCAST-MESSAGE-LIST-GRID-SETTINGS',
  PickupRequests = 'INS-PICKUP-REQUESTS-LIST-GRID-SETTINGS',

  GeneralSettings = 'INS-GENERAL-SETTINGS',
  SicShift = 'INS-SIC-SHIFT'
}
